import { Component } from 'react';
import { alpha, Box, IconButton, styled } from '@mui/material';

import { BoardController } from '../../../Controllers';
import { eventEmitter, Events } from '../../../Utils';
import { MenuView, TypoField, type IMenuItem } from '../General';

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  countdownEnabled?: boolean;
  countdownEnd?: number;
  countdownTime?: number;
  countdownLock?: boolean;
  description: string;
  id: string;
  index?: number;
  lastUpdateUnix?: number;
  lockData: boolean;
  phaseId: string;
  title: string;
  key: string;
  active: boolean;
  sessionId: string;
  asVote: boolean;
  refined?: boolean;
  isDeleted: boolean;
  isHidden: boolean;
  userIsOwner: boolean;
  parentId: string;
  parentType: string;
  isParent: boolean;
  isCollapsed: boolean;
  numberOfChildren: number;
  hasNewInput: boolean;
  level: number;
  onToggleCollapse: () => void;
  onSettings: () => void;
}

interface IState {
  hover: boolean;
  collapsed: boolean;
  anchor: Element | null;
  deleteDialog: boolean;
  editTitle: boolean;
  menu: Array<IMenuItem>;
}

interface IDiv {
  active?: boolean;
}

const Container = styled('div')({
  width: '100%',
  position: 'relative',
  borderBottom: `1px solid #aaa`,
});

const CardHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<IDiv>(({ theme, active }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme.palette.background.paper,
  boxShadow: active
    ? `0 0 0px 100px ${alpha(theme.palette.primary.light, 0.35)} inset`
    : '',
  '&:hover': {
    boxShadow: `0 0 0px 100px ${alpha(theme.palette.primary.main, 0.5)} inset`,
  },
}));

const CardBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<IDiv>(({ active, theme }) => ({
  width: '100%',
  position: 'relative',
  background: alpha(theme.palette.text.disabled, 0.25),
  ...(active && {
    background: alpha(theme.palette.primary.light, 0.2),
  }),
}));

const FilterAltSpan = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export class BoardCard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      hover: false,
      collapsed: false,
      anchor: null,
      deleteDialog: false,
      editTitle: false,
      menu: this.BoardMenu,
    };
  }

  deleteDialog = () => {
    this.setState({ deleteDialog: true, anchor: null });
  };

  deleteConfirmed = () => {
    this.setState({ deleteDialog: false, anchor: null });
    BoardController.delete(
      this.props.sessionId,
      this.props.phaseId,
      this.props.id
    );
  };

  recover = () => {
    this.setState({ deleteDialog: false, anchor: null });
    BoardController.recover(
      this.props.sessionId,
      this.props.phaseId,
      this.props.id
    );
  };

  HandleName = (name: string) => {
    BoardController.title(
      this.props.sessionId,
      this.props.phaseId,
      this.props.id,
      name
    );
  };

  Rename = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();

    this.setState({ editTitle: true, anchor: null });
    setTimeout(() => this.setState({ editTitle: false }), 1000);
  };

  BoardMenu: Array<IMenuItem> = [
    { text: 'Rename', callback: this.Rename },
    {
      text: 'Delete',
      callback: (e) => {
        e.stopPropagation();
        this.setState({
          menu: this.ConfirmMenu,
        });
      },
    },
    {
      text: 'Edit',
      callback: (e) => {
        e.stopPropagation();
        this.setState({ deleteDialog: false, anchor: null });
        this.props.onSettings();
      },
      disable: () => {
        return !this.props.parentId;
      },
    },
  ];

  BoardMenuRecover: Array<IMenuItem> = [
    { text: 'Recover', callback: this.recover },
  ];

  ConfirmMenu: Array<IMenuItem> = [
    {
      text: 'Confirm delete board',
      callback: () => this.deleteConfirmed(),
    },
  ];

  hideResults = () => {
    const SESSION = this.props.sessionId;
    const PHASE = this.props.phaseId;
    const BOARD = this.props.id;
    BoardController.hideBoard(SESSION, PHASE, BOARD);
  };

  lockToggle = () => {
    const SESSION = this.props.sessionId;
    const PHASE = this.props.phaseId;
    const BOARD = this.props.id;
    BoardController.lock(SESSION, PHASE, BOARD);
  };

  componentDidMount() {
    if (this.props.title === 'untitled') {
      this.setState({ editTitle: true, anchor: null });
      setTimeout(() => this.setState({ editTitle: false }), 1000);
    }

    eventEmitter.subscribe(Events.INITIAL_RENAME, () => {
      this.setState({ editTitle: true, anchor: null });
      setTimeout(() => this.setState({ editTitle: false }), 1000);
    });
  }

  componentWillUnmount() {
    eventEmitter.unsubscribe(Events.INITIAL_RENAME);
  }

  //TODO: Draggable cards?
  render() {
    return (
      <Container
        onClick={this.props.onClick ? this.props.onClick : undefined}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <CardHeader active={this.props.active}>
          <Box
            sx={{
              marginLeft: `${this.props.level * 15}px`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                this.props.onToggleCollapse();
              }}
              disabled={!this.props.isParent}
              style={{
                padding: '0px',
                margin: '0px 5px',
                opacity: this.props.isParent ? '1' : '0',
                backgroundColor: '#eee',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
            >
              {this.props.isCollapsed && (
                <span
                  style={{
                    marginLeft: '5px',
                    fontSize: '13px',
                    textAlign: 'center',
                  }}
                >
                  {this.props.numberOfChildren}
                </span>
              )}
              <span className="notranslate material-icons-outlined">
                {this.props.isCollapsed ? 'chevron_right' : 'expand_more'}
              </span>
            </IconButton>
            <IconButton disabled style={{ paddingLeft: '0px' }}>
              <FilterAltSpan className="notranslate material-icons-outlined">
                {this.props.parentId ? 'filter_alt' : 'exit_to_app'}
              </FilterAltSpan>
            </IconButton>
            {this.props.isDeleted ? (
              <s
                style={{
                  fontSize: '0.75rem',
                  lineHeight: 0.97,
                  fontWeight: 700,
                }}
              >
                {this.props.title}
              </s>
            ) : (
              <TypoField
                doubleClickToEdit={true}
                uppercase={false}
                variant="subtitle2"
                text={this.props.title}
                submitChange={this.HandleName}
                edit={this.state.editTitle}
                disabled={!this.props.userIsOwner}
              />
            )}
          </Box>

          {this.state.hover ? (
            <Box>
              {this.props.hasNewInput && !this.props.isDeleted && (
                <IconButton disabled size="small">
                  <span
                    style={{ fontSize: '22px' }}
                    className="notranslate material-icons-outlined"
                  >
                    create
                  </span>
                </IconButton>
              )}
              {this.props.userIsOwner && (
                <>
                  {!this.props.isDeleted && (
                    <>
                      <IconButton onClick={this.hideResults} size="small">
                        {this.props.isHidden ? (
                          <span
                            style={{ fontSize: '22px' }}
                            className="notranslate material-icons-outlined"
                          >
                            visibility_off
                          </span>
                        ) : (
                          <span
                            style={{ fontSize: '22px' }}
                            className="notranslate material-icons-outlined"
                          >
                            visibility
                          </span>
                        )}
                      </IconButton>
                      <IconButton onClick={this.lockToggle} size="small">
                        {this.props.lockData ? (
                          <span
                            className="notranslate material-icons-outlined"
                            style={{ fontSize: '22px' }}
                          >
                            lock
                          </span>
                        ) : (
                          <span
                            className="notranslate material-icons-outlined"
                            style={{ fontSize: '22px' }}
                          >
                            lock_open
                          </span>
                        )}
                      </IconButton>
                    </>
                  )}
                  <IconButton
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      this.setState({ anchor: e.currentTarget });
                    }}
                    size="small"
                  >
                    <span
                      className="notranslate material-icons-outlined"
                      style={{ fontSize: '22px' }}
                    >
                      more_vert
                    </span>
                  </IconButton>

                  <MenuView
                    items={
                      this.props.isDeleted
                        ? this.BoardMenuRecover
                        : this.state.menu
                    }
                    anchor={this.state.anchor}
                    onClose={(
                      event: React.MouseEvent<HTMLLIElement, MouseEvent>
                    ) => {
                      event.stopPropagation();
                      this.setState({ anchor: null });
                      setTimeout(() => {
                        this.setState({ menu: this.BoardMenu });
                      }, 250);
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                  />
                </>
              )}
            </Box>
          ) : (
            this.props.userIsOwner &&
            !this.props.isDeleted && (
              <Box>
                {this.props.hasNewInput && (
                  <IconButton disabled size="small">
                    <span
                      style={{ fontSize: '22px' }}
                      className="notranslate material-icons-outlined"
                    >
                      create
                    </span>
                  </IconButton>
                )}
                <IconButton disabled size="small">
                  {this.props.isHidden ? (
                    <span
                      style={{ fontSize: '22px' }}
                      className="notranslate material-icons-outlined"
                    >
                      visibility_off
                    </span>
                  ) : (
                    <span
                      style={{ fontSize: '22px', opacity: '0' }}
                      className="notranslate material-icons-outlined"
                    >
                      visibility
                    </span>
                  )}
                </IconButton>
                <IconButton disabled onClick={this.lockToggle} size="small">
                  {this.props.lockData ? (
                    <span
                      className="notranslate material-icons-outlined"
                      style={{ fontSize: '22px' }}
                    >
                      lock
                    </span>
                  ) : (
                    <span
                      className="notranslate material-icons-outlined"
                      style={{ fontSize: '22px', opacity: '0.01' }}
                    >
                      lock_open
                    </span>
                  )}
                </IconButton>
                <IconButton size="small">
                  <span
                    style={{ fontSize: '22px', opacity: '0.01' }}
                    className="notranslate material-icons-outlined"
                  >
                    more_vert
                  </span>
                </IconButton>
              </Box>
            )
          )}
        </CardHeader>
        <CardBody active={this.props.active}>{this.props.children}</CardBody>
      </Container>
    );
  }
}
