import {
  Backdrop,
  Box,
  Dialog,
  Fade,
  Typography,
  useTheme,
} from '@mui/material';

import { type InputModel } from '../../Models';

interface IProps {
  onClose: () => void;
  open: boolean;
  items: InputModel[];
}

export const InputHighlightDialog = ({ onClose, open, items }: IProps) => {
  const theme = useTheme();

  return (
    <Dialog
      aria-describedby="highlight-body"
      aria-labelledby="highlight-title"
      closeAfterTransition
      onClose={onClose}
      open={open}
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      fullWidth={true}
      maxWidth="xl"
      sx={{
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(0,0,0,0.3)',
      }}
    >
      <Fade in={open} timeout={500}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
            border: '2px solid #555',
            boxShadow: theme.shadows[5],
            padding: '20px 40px',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '25px',
            }}
          >
            <Box>
              <Typography
                variant="h2"
                sx={{
                  textAlign: 'center',
                }}
              >
                Proposals
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'grid',
                'grid-template-columns': 'repeat(auto-fit, minmax(300px, 1fr))',
                gap: '30px',
              }}
            >
              {items.map((item, idx) => (
                <Box
                  sx={{
                    padding: '20px',
                    backgroundColor: idx % 2 === 0 ? '#C8FACD' : '#E6E6FA',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    textAlign: 'center',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                >
                  <p
                    style={{
                      fontSize: '24px',
                      fontWeight: '600',
                      marginBottom: '10px',
                      color: '#555',
                    }}
                  >
                    {item.title}
                  </p>
                  <p
                    style={{
                      fontSize: '22px',
                      color: '#777',
                    }}
                  >
                    {item.description}
                  </p>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Fade>
    </Dialog>
  );
};
