import { Component } from 'react';
import { Typography, alpha, styled } from '@mui/material';

import { type InputModel } from '../../../Models';
import { type IMenuItem } from '../General/Menu';

interface IProps {
  input: InputModel;
  expand: boolean;
}

interface IState {
  hover: boolean;
  collapsed: boolean;
}

interface IDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active?: boolean;
}

interface IDiv {
  active?: boolean;
}

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<IDiv>(({ theme }) => ({
  width: '100%',
  position: 'relative',
  borderRadius: '0.5em',
  boxShadow: `1px 1px 5px 5px ${alpha(theme.palette.text.primary, 0.15)}`,
  '&:hover': {
    boxShadow: `1px 1px 2px 5px ${alpha(theme.palette.text.primary, 0.2)}`,
  },
}));

const CardHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<IDiv>(({ active, theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  borderRadius: '0.5em 0.5em 0 0',
  background: theme.palette.background.paper,
  padding: '5px 10px',
  border: active ? `1px solid ${theme.palette.primary.main}` : '',
}));

const CardBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<IDiv>(() => ({
  width: '100%',
  position: 'relative',
  padding: '5px 10px',
  borderRadius: '0 0 0.5em 0.5em',
}));

export class InputCard extends Component<IProps, IState> {
  state = {
    hover: false,
    collapsed: false,
  };

  BoardMenu: Array<IMenuItem> = [
    { text: 'Rename', callback: null },
    { text: 'Delete', callback: null },
  ];

  //TODO: Draggable cards?
  render() {
    return (
      <Container
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <CardHeader>
          <Typography variant="subtitle2">{this.props.input.title}</Typography>
        </CardHeader>

        <CardBody>
          {this.props.expand &&
            this.props.input.description !== this.props.input.title && (
              <Typography variant="body1">
                {this.props.input.description}
              </Typography>
            )}
        </CardBody>
      </Container>
    );
  }
}
