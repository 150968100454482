export const getLastEditString = (dateInSeconds: number) => {
  const dateInMillis = dateInSeconds * 1000;
  const now = Date.now();
  let time = now - dateInMillis;
  if (time < 10000) {
    return 'Last opened just now';
  } else {
    time /= 1000;
    if (time < 120) {
      return `Last opened ${Math.floor(time)} seconds ago`;
    } else {
      time /= 60;
      if (time < 120) {
        return `Last opened ${Math.floor(time)} minutes ago`;
      } else {
        time /= 60;
        if (time < 72) {
          return `Last opened ${Math.floor(time)} hours ago`;
        } else {
          time /= 24;
          if (time < 14) {
            return `Last opened ${Math.floor(time)} days ago`;
          } else {
            time /= 7;
            if (time < 8) {
              return `Last opened ${Math.floor(time)} weeks ago`;
            } else {
              time /= 4;
              if (time < 24) {
                return `Last opened ${Math.floor(time)} months ago`;
              } else {
                time /= 12;
                return `Last opened ${Math.floor(time)} years ago`;
              }
            }
          }
        }
      }
    }
  }
};
