import { Component } from 'react';
import { Draggable, type DraggableProvided } from '@hello-pangea/dnd';
import {
  Box,
  IconButton,
  styled,
  TextareaAutosize,
  Typography,
} from '@mui/material';

import { eventEmitter, Events } from '@utils';
import { EditInputDialog } from '.';
import { InputController } from '../../../../Controllers';
import { Theme } from '../../../../Theme';
import {
  MenuView as Menu,
  TooltipView as Tooltip,
  TooltipView,
  TypoField,
  type IMenuItem,
} from '../../General';

interface IProps {
  description: string;
  groupId: string;
  id: string;
  index: number;
  title: string;
  userId: string;
  selected: boolean;
  pinned?: boolean;
  setPin?: (pin: any) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  session: string;
  phase: string;
  boardId: string;
  grouped?: boolean;
  userIsOwner?: boolean;
  creationDate: string;
  isInRefinedBoard?: boolean;
  showAllDescriptions?: boolean;
}

interface IState {
  hover: boolean;
  anchor: Element | null;
  open: boolean;
  editTitle: boolean;
  showEditModal: boolean;
  currentInput: {
    title: string;
    description: string;
    id: string;
  } | null;
}

interface IDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  selected?: boolean;
}

const InputContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})<IDiv>(({ theme, selected }) => ({
  position: 'relative',
  borderRadius: 4,
  boxSizing: 'border-box',
  width: '100%',
  flexGrow: 1,
  transition: 'all 0.25s',
  verticalAlign: 'center',
  background: '#fcfcfc',
  padding: selected ? 4 : 7,
  border: selected
    ? `4px solid ${theme.palette.text.primary}`
    : `1px solid ${theme.palette.text.disabled}`,
}));

export class DraggableInput extends Component<IProps, IState> {
  constructor(props: IProps | Readonly<IProps>) {
    super(props);
    this.state = {
      hover: false,
      anchor: null,
      open: false,
      editTitle: false,
      showEditModal: false,
      currentInput: null,
    };
  }

  static getDerivedStateFromProps(props: IProps, state: IState) {
    if (props.showAllDescriptions === undefined) {
      return null;
    }

    return {
      ...state,
      open: props.showAllDescriptions,
    };
  }

  delete = () => {
    InputController.archive(
      this.props.session,
      this.props.phase,
      this.props.boardId,
      this.props.groupId,
      this.props.id
    );
    eventEmitter.dispatch(Events.INPUT_DELETED, {
      groupId: this.props.groupId,
    });
  };

  rename = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();

    this.setState({ editTitle: true, anchor: null });
    setTimeout(() => this.setState({ editTitle: false }), 1000);
  };

  handleName = (name: string) => {
    InputController.set.title(
      this.props.session,
      this.props.phase,
      this.props.boardId,
      this.props.groupId,
      this.props.id,
      name
    );
  };

  duplicate = () => {
    InputController.create(
      this.props.session,
      this.props.phase,
      this.props.boardId,
      this.props.groupId,
      this.props.title,
      this.props.description,
      this.props.userId
    );
  };

  openEditInputModal = () => {
    this.setState({ showEditModal: true, currentInput: this.props });
  };

  closeEditInputModal = () => {
    this.setState({ showEditModal: false, anchor: null, currentInput: null });
  };

  editInput = (input: { title: string; description: string; id: string }) => {
    InputController.set.title(
      this.props.session,
      this.props.phase,
      this.props.boardId,
      this.props.groupId,
      input.id,
      input.title
    );

    InputController.set.description(
      this.props.session,
      this.props.phase,
      this.props.boardId,
      this.props.groupId,
      input.id,
      input.description
    );

    this.closeEditInputModal();
  };

  inputMenu: Array<IMenuItem> = [
    { text: 'Edit', callback: this.openEditInputModal },
    {
      text: 'Pin',
      callback: (e) => {
        e.stopPropagation();
        this.props.setPin?.(true);
        this.setState({ anchor: null });
      },
      hide: () => !this.props.pinned,
    },
    {
      text: 'Unpin',
      callback: (e) => {
        e.stopPropagation();
        this.props.setPin?.(null);
        this.setState({ anchor: null });
      },
      hide: () => (this.props.pinned ? true : false),
    },
    { text: 'Duplicate', callback: this.duplicate },
    { text: 'Delete', callback: this.delete },
  ];

  open = {
    true: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      this.setState({ open: true });
      document.addEventListener('mousedown', this.open.active);
    },
    active: (event: MouseEvent) => {
      if (event.ctrlKey && event.button === 0) {
        this.setState({ open: false });
        document.removeEventListener('mousedown', this.open.active);
      }
    },
    false: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      this.setState({ open: false });
    },
  };

  handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  handleDoubleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    this.openEditInputModal();
  };

  isNewInput = () => {
    const dateToCheck = new Date(this.props.creationDate + 'Z');

    const now = new Date();

    const diffInMilliseconds: number = now.getTime() - dateToCheck.getTime();
    const diffInMinutes: number = diffInMilliseconds / (1000 * 60);

    return diffInMinutes < 30;
  };

  render() {
    const { editTitle, open, hover, anchor } = this.state;

    const {
      id,
      title,
      description,
      grouped,
      selected: isSelected,
      index,
      userIsOwner,
      pinned,
    } = this.props;

    const itemPosition = index + 1;
    const positionNeedsMoreSpace = itemPosition > 9;

    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {grouped && (
            <Typography variant="overline">
              {itemPosition}
              {positionNeedsMoreSpace ? '' : <>&nbsp;&nbsp;&nbsp;</>}
            </Typography>
          )}
          <Draggable draggableId={id} index={index} key={id}>
            {(provided: DraggableProvided) => (
              <InputContainer
                id={id}
                key={id}
                selected={isSelected}
                onClick={this.handleClick}
                onDoubleClick={this.handleDoubleClick}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <Tooltip
                  sx={{
                    '.MuiTooltip-popper': {
                      background: Theme.palette.background.default,
                    },
                  }}
                  placement="bottom"
                  title={title !== description ? description : ''}
                >
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '40px',
                      }}
                      onMouseEnter={() => this.setState({ hover: true })}
                      onMouseLeave={() => this.setState({ hover: false })}
                    >
                      <Typography
                        sx={{
                          pointerEvents: 'none',
                          display: 'inline-block',
                        }}
                        variant="subtitle2"
                      >
                        <TypoField
                          doubleClickToEdit={false}
                          uppercase={false}
                          variant="subtitle2"
                          text={title}
                          submitChange={this.handleName}
                          edit={editTitle}
                          disabled={!userIsOwner}
                        />
                      </Typography>

                      <div>
                        {this.props.isInRefinedBoard && this.isNewInput() && (
                          <TooltipView title="Item is pinned">
                            <span
                              style={{
                                backgroundColor: '#8FE66F',
                                border: '1px #164d02 solid',
                                borderRadius: '50%',
                                width: '18px',
                                height: '18px',
                                display: 'inline-block',
                                position: 'relative',
                                left: '33px',
                                top: '-20px',
                                cursor: 'pointer',
                              }}
                            />
                          </TooltipView>
                        )}
                        {pinned && (
                          <TooltipView title="Item is pinned">
                            <span
                              style={{
                                backgroundColor: '#FBF6D9',
                                border: '1px #000 solid',
                                borderRadius: '50%',
                                width: '18px',
                                height: '18px',
                                display: 'inline-block',
                                position: 'relative',
                                left: '33px',
                                top: '-20px',
                                cursor: 'pointer',
                              }}
                            />
                          </TooltipView>
                        )}
                        {userIsOwner && (
                          <IconButton
                            size="small"
                            sx={{
                              alignSelf: 'start',
                              padding: 0,
                              display: 'inline-block',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.setState({ anchor: e.currentTarget });
                            }}
                          >
                            <span
                              className="notranslate material-icons-outlined"
                              style={{
                                fontSize: '18px',
                                opacity: hover ? '1' : '0.01',
                              }}
                            >
                              more_vert
                            </span>
                          </IconButton>
                        )}
                        <Menu
                          items={this.inputMenu}
                          anchor={anchor}
                          onClose={(event) => {
                            event.stopPropagation();
                            this.setState({ anchor: null });
                          }}
                          transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                        />
                      </div>
                    </Box>
                    {description &&
                      (open ? (
                        <>
                          <TextareaAutosize
                            value={description}
                            contentEditable={false}
                            disabled={true}
                            style={{
                              width: '100%',
                              border: 'none',
                              resize: 'none',
                              background: 'transparent',
                              color: '#000',
                              fontSize: '14px',
                              padding: '0',
                              outline: 'none',
                              overflow: 'hidden',
                            }}
                          ></TextareaAutosize>
                          <IconButton
                            onClick={this.open.false}
                            sx={{
                              bottom: '-10px',
                              position: 'absolute',
                              right: '0px',
                              ':hover': {
                                cursor: 'pointer',
                                color: '',
                                background: '#00000000',
                                boxShadow:
                                  '0 0 30px 30px rbga(0, 0, 0, 0.1) inset',
                              },
                            }}
                          >
                            <span
                              className="notranslate material-icons-outlined"
                              style={{ fontSize: '18px' }}
                            >
                              expand_less
                            </span>
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          onClick={this.open.true}
                          sx={{
                            bottom: '-10px',
                            position: 'absolute',
                            right: '0px',
                            ':hover': {
                              cursor: 'pointer',
                              color: '',
                              background: '#00000000',
                              boxShadow:
                                '0 0 30px 30px rbga(0, 0, 0, 0.1) inset',
                            },
                          }}
                        >
                          <span
                            className="notranslate material-icons-outlined"
                            style={{ fontSize: '18px' }}
                          >
                            expand_more
                          </span>
                        </IconButton>
                      ))}
                  </Box>
                </Tooltip>
              </InputContainer>
            )}
          </Draggable>
        </Box>
        {}
        {this.state.showEditModal && this.state.currentInput && (
          <EditInputDialog
            onClose={this.closeEditInputModal}
            onUpdate={this.editInput}
            open={this.state.showEditModal}
            input={this.state.currentInput}
          />
        )}
      </>
    );
  }
}
