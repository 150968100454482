import { createTheme, type ThemeOptions } from '@mui/material';

const Theme = {
  palette: {
    type: 'light',
    primary: {
      main: '#0095b3',
    },
    secondary: {
      main: '#66BBCC',
    },
    text: {
      primary: '#292F40',
      //secondary: '#393E4D',
      disabled: '#a4a5a9',
      secondary: '#6A6D7A', //hint
    },
    error: {
      main: '#FF0000',
    },
    success: {
      main: '#3DD75D',
    },
    warning: {
      main: '#f57f17',
    },
    background: {
      default: '#fafafa',
    },
  },
  typography: {
    fontFamily: 'Lato, Roboto, Arial',
    h1: {
      fontSize: '3.7rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    body1: {
      lineHeight: 1.25,
      fontSize: '1rem',
      fontWeight: 500,
    },
    body2: {
      lineHeight: 1.3,
      fontSize: '0.75rem',
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: '0.75rem',
      lineHeight: 0.97,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: '1rem',
      lineHeight: 1.25,
      fontWeight: 700,
    },
    overline: {
      lineHeight: 2,
      fontSize: '1rem',
    },
    button: {
      fontSize: '1rem',
      lineHeight: 1,
    },
    fontSize: '1rem',
    caption: {
      fontSize: '1rem',
      lineHeight: 1,
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + $track': {
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: '1px solid #bdbdbd',
        backgroundColor: '#fafafa',
        opacity: 1,
        transition:
          'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  },
} as const;

type CustomTheme = {
  [Key in keyof typeof Theme]: (typeof Theme)[Key];
};
declare module '@mui/material/styles/createTheme' {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export default createTheme(Theme as unknown as ThemeOptions);
