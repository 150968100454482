import { Box, IconButton, styled, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { type TreeNode } from '@utils';
import { ActionPlanCard, BoardCard, PhaseCard, ResultCard, VoteCard } from '.';
import type {
  BoardVote,
  BoardVoteWithHasVotes,
  PhaseModel,
} from '../../../Models';
import { TooltipView } from '../General';

type LeftProps = { isCollapsed: boolean };

const Left = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<LeftProps>(({ theme, isCollapsed }) => ({
  margin: 0,
  background: theme.palette.background.default,
  borderRight: `1px solid ${theme.palette.text.disabled}`,
  height: '100vh',
  position: 'relative',
  transition: 'all 0.25s',
  ...(!isCollapsed && {
    width: '10%',
    minWidth: '390px',
  }),
  ...(isCollapsed && {
    width: '15px',
    padding: 0,
    borderRight: `15px solid ${theme.palette.text.disabled}`,
  }),
}));

type ISideNavProps = {
  isCollapsed: boolean;
  isOwner: boolean;
  hasBoards: boolean;
  hasInputs: boolean;
  phases: PhaseModel[];
  noOfPhases: number;
  sessionName: string;
  sessionId: string;
  activeSettings: {
    id: string;
    type: 'vote' | 'board';
  };
  active: {
    identity: string;
    type: string;
  };
  onLeftDrawerCollapse: () => void;
  onToggleCollapse: (id: string) => void;
  onSettingsClick: (element: BoardVote) => void;
  onNavItemClick: (element: BoardVote) => void;
  onNewBoardClick: (name: string) => void;
  onResultClick: () => void;
  isNavItemCollapsed: (id: string) => boolean;
  getElements: (phase: PhaseModel) => TreeNode<BoardVoteWithHasVotes>[];
};

export const SideNav = ({
  isCollapsed,
  isOwner,
  hasBoards,
  hasInputs,
  phases,
  noOfPhases,
  sessionId,
  sessionName,
  activeSettings,
  active,
  onLeftDrawerCollapse,
  onToggleCollapse,
  onSettingsClick,
  onNavItemClick,
  onNewBoardClick,
  onResultClick,
  isNavItemCollapsed,
  getElements,
}: ISideNavProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <Left isCollapsed={isCollapsed}>
      {isOwner && (
        <TooltipView
          title={
            isCollapsed
              ? 'Show navigator (press 1)'
              : 'Hide navigator (press 1)'
          }
        >
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              top: '5px',
              zIndex: '100',
              right: '0',
              transform: isCollapsed ? 'translateX(100%)' : 'translateX(50%)',
              borderRadius: '50%',
              background: '#DBDBDB',
              border: `1px solid ${theme.palette.text.secondary}`,
              transition: 'all 0.5s',
              ':hover': {
                background: '#DBDBDB',
                boxShadow: '0 0 30px 30px rgba(0,0,0,0.1) inset',
              },
            }}
            onClick={onLeftDrawerCollapse}
          >
            {isCollapsed ? (
              <span
                className="notranslate material-icons-outlined"
                style={{ fontSize: '18px' }}
              >
                chevron_right
              </span>
            ) : (
              <span
                className="notranslate material-icons-outlined"
                style={{ fontSize: '18px' }}
              >
                chevron_left
              </span>
            )}
          </IconButton>
        </TooltipView>
      )}

      {!isCollapsed && (
        <>
          {' '}
          <div
            style={{
              margin: '10px 0px 15px 10px',
              cursor: 'pointer',
            }}
            onClick={goToDashboard}
          >
            <span
              style={{
                color: theme.palette.primary.light,
                lineHeight: '45px',
                textAlign: 'center',
                margin: '0',
                fontSize: 45,
              }}
            >
              co
            </span>

            <span
              style={{
                lineHeight: '45px',
                textAlign: 'center',
                margin: '0',
                fontSize: 45,
              }}
            >
              boost
            </span>
          </div>
          <Typography variant="h4" sx={{ margin: '0px 0px 10px 10px' }}>
            {sessionName}
          </Typography>
          <Box
            sx={{
              width: '100%',
              borderTop: `3px solid ${theme.palette.text.secondary}`,
              borderBottom: `3px solid ${theme.palette.text.secondary}`,
              maxHeight: 'calc(100vh - 250px)',
              overflowY: 'auto',
            }}
          >
            {phases.map((phase) => {
              const elements = getElements(phase);
              if (elements.length === 0) {
                return null;
              }

              return (
                <PhaseCard key={phase.id} {...phase}>
                  {elements.filter(Boolean).map((element) => {
                    if ('hideVotes' in element) {
                      return (
                        <VoteCard
                          key={element.id}
                          sessionId={sessionId}
                          boardId={element.boardId}
                          active={
                            activeSettings
                              ? element.id === activeSettings.id
                              : active.identity === element.id
                          }
                          // asVote={active.type === "vote"}
                          userIsOwner={isOwner}
                          onClick={(e) => {
                            e.stopPropagation();
                            onNavItemClick(element);
                          }}
                          level={element.level}
                          title={element.title}
                          description={element.description}
                          phaseId={element.phaseId}
                          lockData={element.lockData}
                          id={element.id}
                          isDeleted={element.isDeleted}
                          hideVotes={element.hideVotes}
                          type={element.type}
                          isParent={element.isParent}
                          isCollapsed={isNavItemCollapsed(element.id)}
                          onToggleCollapse={() => onToggleCollapse(element.id)}
                          numberOfChildren={element.noKids}
                          onSettings={() => onSettingsClick(element)}
                          hasVotes={element.hasVotes}
                        />
                      );
                    } else if ('hasNewInput' in element) {
                      return (
                        <BoardCard
                          key={element.id}
                          sessionId={sessionId}
                          active={
                            activeSettings
                              ? element.id === activeSettings.id
                              : active.identity === element.id
                          }
                          asVote={active.type === 'vote'}
                          userIsOwner={isOwner}
                          onClick={(e) => {
                            e.stopPropagation();
                            onNavItemClick(element);
                          }}
                          level={element.level}
                          title={element.title}
                          description={element.description}
                          phaseId={element.phaseId}
                          id={element.id}
                          lockData={element.lockData}
                          isDeleted={element.isDeleted}
                          isHidden={element.isHidden}
                          hasNewInput={element.hasNewInput}
                          parentId={element.parentId}
                          parentType={element.parentType}
                          isParent={element.isParent}
                          isCollapsed={isNavItemCollapsed(element.id)}
                          onToggleCollapse={() => onToggleCollapse(element.id)}
                          onSettings={() => onSettingsClick(element)}
                          numberOfChildren={element.noKids}
                        />
                      );
                    }
                    return (
                      <ActionPlanCard
                        key={element.id}
                        sessionId={sessionId}
                        actionPlanId={element.id}
                        active={
                          activeSettings
                            ? element.id === activeSettings.id
                            : active.identity === element.id
                        }
                        userIsOwner={isOwner}
                        onClick={(e) => {
                          e.stopPropagation();
                          onNavItemClick(element);
                        }}
                        level={element.level}
                        title={element.title}
                        description={element.description}
                        phaseId={element.phaseId}
                        id={element.id}
                        isDeleted={element.isDeleted}
                        isParent={element.isParent}
                        isCollapsed={isNavItemCollapsed(element.id)}
                        onToggleCollapse={() => onToggleCollapse(element.id)}
                        numberOfChildren={element.noKids}
                      />
                    );
                  })}
                </PhaseCard>
              );
            })}
          </Box>
          {isOwner && (
            <>
              <TooltipView title="Create new board">
                <IconButton
                  sx={{
                    marginTop: '10px',
                    color: theme.palette.background.paper,
                    background: theme.palette.primary.main,
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    borderRadius: '50%',
                    transition: 'all 0.5s',
                    ':hover': {
                      background: '#DBDBDB',
                      boxShadow: '0 0 30px 30px rgba(0,0,0,0.1) inset',
                    },
                  }}
                  onClick={() => onNewBoardClick('untitled')}
                >
                  <span className="notranslate material-icons">add</span>
                </IconButton>
              </TooltipView>
              {noOfPhases === 0 && (
                <>
                  <div
                    style={{
                      textAlign: 'center',
                      position: 'relative',
                      top: '9px',
                    }}
                  >
                    <span className="notranslate material-icons-outlined">
                      arrow_upward
                    </span>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    Start a new board or vote
                  </div>
                </>
              )}
            </>
          )}
          {hasBoards && (
            <ResultCard
              isDisabled={!hasInputs}
              isActive={active.type === 'result'}
              onClick={onResultClick}
            />
          )}
        </>
      )}
    </Left>
  );
};
