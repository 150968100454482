import Axios from 'axios';

import { type ActionPlanModel } from '../Models';
import { ActionPlanRoutes } from './Routes';

export const ActionPlanController = {
  async create(
    sessionId: string,
    phaseId: string,
    title: string,
    description: string,
    assignees: [{ email: string; fullname: string }],
    items: string[],
    dueDate: string,
    parentId: string,
    parentType: string
  ) {
    const DATA = {
      Title: title,
      Description: description,
      Assignees: assignees,
      DueDate: dueDate,
      Items: items,
      ParentId: parentId,
      ParentType: parentType,
    };

    let Result: ActionPlanModel | undefined;

    await Axios.post(ActionPlanRoutes.create(sessionId, phaseId), DATA).then(
      (res) => {
        if (res.status === 201) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async delete(sessionId: string, actionPlanId: string) {
    let Result = false;

    await Axios.delete(ActionPlanRoutes.delete(sessionId, actionPlanId)).then(
      (res) => {
        if (res.status === 204) {
          Result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async recover(sessionId: string, actionPlanId: string) {
    let Result = false;

    await Axios.put(ActionPlanRoutes.recover(sessionId, actionPlanId)).then(
      (res) => {
        if (res.status === 201) {
          Result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  get: {
    bySession: async (sessionId: string) => {
      let Result: Array<ActionPlanModel> = [];

      await Axios.get(ActionPlanRoutes.getAll(sessionId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    byId: async (sessionId: string, actionPlanId: string) => {
      let Result: ActionPlanModel | null = null;

      await Axios.get(ActionPlanRoutes.getById(sessionId, actionPlanId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
  },

  async rename(sessionId: string, actionPlanId: string, name: string) {
    const DATA = {
      Name: name,
    };
    let Result: ActionPlanModel | null = null;

    await Axios.patch(
      ActionPlanRoutes.rename(sessionId, actionPlanId),
      DATA
    ).then(
      (res) => {
        Result = res.data;
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async description(
    sessionId: string,
    actionPlanId: string,
    description: string
  ) {
    const DATA = {
      Name: description,
    };
    let Result: ActionPlanModel | null = null;

    await Axios.patch(
      ActionPlanRoutes.updateDescription(sessionId, actionPlanId),
      DATA
    ).then(
      (res) => {
        Result = res.data;
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },
};
