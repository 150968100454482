import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';

import { type TreeNode } from '@utils';
import { type Handler } from '../../../Data/DataHandler';
import { type BoardVoteWithHasVotes } from '../../../Models';
import { ActionPlanResult, BoardResult, VoteResult } from './';

type ResultBlockProps = {
  dataHandler: Handler;
  element: TreeNode<BoardVoteWithHasVotes>;
  index: number;
  isSelected: boolean;
  setSelected: (value: boolean) => void;
  length: number;
};

export const ResultBlock = ({
  dataHandler,
  element,
  index,
  isSelected,
  setSelected,
  length,
}: ResultBlockProps) => {
  const [isReady, setReady] = useState(false);
  const [contentItem, setContentItem] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    if ('type' in element && element.type) {
      const content = dataHandler.GetVoteContent(element.id);
      setContentItem({
        vote: element,
        content,
        type: 'vote',
      });
    } else if ('assignees' in element && element.assignees) {
      const content = dataHandler.GetActionPlan(element.id);
      setContentItem({
        actionPlan: element,
        content,
        type: 'actionPlan',
      });
    } else {
      const content = dataHandler.GetBoardContent(element.id);
      setContentItem({
        board: element,
        content,
        type: 'board',
      });
    }
    setReady(true);
  }, []);

  return (
    isReady && (
      <div
        style={{
          display: 'inline-block',
          marginLeft: '20px',
          marginBottom: '30px',
          fontSize: '15px',
        }}
        onClick={() => setSelected(!isSelected)}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: '0', height: '100%' }}>
            <input
              style={{ margin: '60px 5px', height: '17px', width: '17px' }}
              type="checkbox"
              checked={isSelected}
            />
          </div>
          <div>
            <div
              style={{
                flexGrow: '1',
                width: '360px',
                height: '220px',
                border: isSelected ? '3px solid #000' : '2px solid #aaa',
                borderRadius: '7px',
                marginBottom: '5px',
                overflow: 'hidden',
              }}
            >
              <div style={{ scale: '1' }}>
                {contentItem.type === 'board' && (
                  <BoardResult
                    dataHandler={dataHandler}
                    contentItem={contentItem}
                    isThumbnail={true}
                  />
                )}
                {contentItem.type === 'vote' && (
                  <VoteResult contentItem={contentItem} isThumbnail={true} />
                )}
                {contentItem.type === 'actionPlan' && (
                  <ActionPlanResult
                    contentItem={contentItem}
                    isThumbnail={true}
                  />
                )}
              </div>
            </div>
            <div style={{ fontStyle: 'italic' }}>
              <span
                style={{ color: theme.palette.text.primary }}
                className="notranslate material-icons-outlined"
              >
                {getIconFromElement(element)}
              </span>
              <span
                style={{
                  marginLeft: '5px',
                  position: 'relative',
                  bottom: '5px',
                }}
              >
                {element.title}
              </span>
            </div>
            <div>
              {index}/{length}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

function getIconFromElement(element: TreeNode<BoardVoteWithHasVotes>) {
  if ('type' in element && element.type) {
    if (element.type === 'points') {
      return 'hotel_class';
    } else if (element.type === 'slider') {
      return 'tune';
    }
  } else if (element.parentId) {
    return 'filter_alt';
  } else {
    return 'exit_to_app';
  }
}
