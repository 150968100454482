import { Button, styled, type ButtonProps } from '@mui/material';

interface IProps extends ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Primary = styled(Button)<IProps>(({ theme }) => ({
  margin: 0,
  padding: '10px 20px',
  background: theme.palette.primary.main,
  borderRadius: '2.5em',
  color: theme.palette.background.paper,
  '&:hover': {
    color: theme.palette.primary.main,
    background: theme.palette.background.paper,
  },
  '&:disabled': {
    background: theme.palette.text.disabled,
    pointerEvents: 'none',
  },
}));

const Secondary = styled(Button)<IProps>(({ theme }) => ({
  margin: 0,
  padding: '10px 20px',
  background: theme.palette.background.paper,
  borderRadius: '2.5em',
  border: `2px solid ${theme.palette.text.primary}`,
  color: theme.palette.text.primary,
  '&:hover': {
    background: theme.palette.background.default,
  },
  '&:disabled': {
    opacity: '75%',
    pointerEvents: 'none',
  },
}));

const Text = styled(Button)<IProps>(({ theme }) => ({
  margin: 0,
  padding: 0,
  background: '',
  color: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '&:disabled': {
    opacity: '75%',
    pointerEvents: 'none',
  },
}));

export const PrimaryButton: React.FC<IProps> = (props) => (
  <Primary {...props} />
);

export const SecondaryButton: React.FC<IProps> = (props) => (
  <Secondary {...props} />
);

export const TextButton: React.FC<IProps> = (props) => <Text {...props} />;
