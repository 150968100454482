import { Component, createRef } from 'react';
import {
  Box,
  Divider,
  FormControlLabel,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { isMobile } from 'react-device-detect';

import { InputCard } from '..';
import { InputController, TicketController } from '../../../../Controllers';
import {
  type BoardModel,
  type InputModel,
  type OptionModel,
  type TicketModel,
  type VoteModel,
} from '../../../../Models';
import { Theme } from '../../../../Theme';
import { PrimaryButton as Button, TooltipView } from '../../General';
import { MobilePoints } from './MobilePoints';

interface IKeyValue {
  key: string;
  value: number;
}

interface IRating {
  key: string;
  value: number;
  isTouched: boolean;
}

interface IProps {
  user: string;
  session: string;
  contributions?: InputModel[];
  ticket?: TicketModel;
  type: 'locked' | 'board' | 'points' | 'slider';
  vote?: VoteModel;
  board?: BoardModel;
  options?: OptionModel[];
  groupId?: string;
  isLocked: boolean;
}

interface IState {
  user: string;
  session: string;
  title: string;
  description: string;
  totalUserVotes: number;
  ratings: IRating[];
  showAll: boolean;
  showDescription: boolean;
}

export class ParticipateView extends Component<IProps, IState> {
  voteSentRef: React.RefObject<HTMLDivElement>;
  constructor(props: IProps | Readonly<IProps>) {
    super(props);
    this.state = {
      session: this.props.session,
      user: this.props.user,
      title: '',
      description: '',
      totalUserVotes: 0,
      ratings: [],
      showAll: true,
      showDescription: true,
    };

    this.voteSentRef = createRef();
  }

  handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const NAME = e.currentTarget.name;
    const VALUE = e.currentTarget.value;

    if (NAME === 'title') {
      this.setState({ title: VALUE });
    } else if (NAME === 'description') {
      this.setState({ description: VALUE });
    }
  };

  totalPoints = (ratings: IKeyValue[]) => {
    const RATINGS = ratings;
    let Total = 0;

    for (let I = 0; I < RATINGS.length; I++) {
      Total += RATINGS[I].value;
    }

    this.setState({ totalUserVotes: Total });
  };

  handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    event.stopPropagation();

    this.setState({ showAll: checked });
  };

  setShowDescription = (checked: boolean) => {
    this.setState({ showDescription: checked });
  };

  handleRatings = (value: number, key: string) => {
    if (!this.props.vote || !this.props.options) {
      return;
    }
    const rating: IRating = {
      value: value,
      key: key,
      isTouched: true,
    };

    let ratings = this.state.ratings;
    if (ratings.length !== this.props.options.length) {
      ratings = [];

      for (let i = 0; i < this.props.options.length; i++) {
        const option: IRating = {
          value:
            this.props.vote.type === 'slider' ? this.props.vote.lowNumber : 0,
          key: this.props.options[i].id,
          isTouched: false,
        };

        ratings.push(option);
      }
    }

    const ratingIndex = ratings.findIndex((x) => x.key === rating.key);

    if (ratingIndex !== -1) {
      if (this.props.vote.type === 'points') {
        if (ratings[ratingIndex].value === rating.value) {
          rating.value = 0;
        } else if (
          this.state.totalUserVotes -
            ratings[ratingIndex].value +
            rating.value >
          this.props.vote.highNumber
        ) {
          rating.value =
            this.props.vote.highNumber -
            this.state.totalUserVotes +
            ratings[ratingIndex].value;
        }
      }
      rating.isTouched = true;
      ratings[ratingIndex] = rating;
    } else {
      ratings = [];
      for (let i = 0; i < this.props.options.length; i++) {
        if (this.props.options[i].id !== key) {
          const option: IRating = {
            value: this.props.vote.lowNumber,
            key: this.props.options[i].id,
            isTouched: false,
          };

          ratings.push(option);
        } else {
          ratings.push(rating);
        }
      }
    }

    if (this.props.vote.type === 'points') {
      this.totalPoints(ratings);
    }

    this.setState({
      ratings,
    });
  };

  commit = {
    input: async (event: React.MouseEvent<HTMLButtonElement | MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      const board = this.props.board;
      if (board && this.props.groupId) {
        let title = this.state.title;
        let description = this.state.description ?? '';

        if (!title && description && description.length > 45) {
          alert('Please write a title');
          return false;
        } else if (!title && description) {
          title = description;
          description = '';
        }

        // Split long word
        let didSplit;
        do {
          didSplit = false;
          const words = description.match(/\S*/g) ?? [];
          for (let i = 0; i < words.length; i++) {
            const word = words[i];
            if (word.length > 45) {
              const half = Math.round(word.length / 2);
              description = description.replace(
                word,
                word.substring(0, half) + ' ' + word.substring(half)
              );
              didSplit = true;
            }
          }
        } while (didSplit);

        this.setState({ description: '', title: '' });

        if (title?.length > 0 || description?.length > 0) {
          await InputController.create(
            this.state.session,
            board.phaseId,
            board.id,
            this.props.groupId,
            title,
            description,
            this.state.user,
            true
          );
        }
      }
    },
    ticket: async (event: React.MouseEvent<HTMLButtonElement | MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      const VOTE = this.props.vote;
      if (VOTE && !this.props.ticket) {
        if (this.state.ratings.length === 0) {
          const defaultNumber = Math.floor(
            (this.props.vote.highNumber + this.props.vote.lowNumber) / 2
          );
          this.props.options?.forEach((option) => {
            this.handleRatings(defaultNumber, option.id);
          });
        }

        await TicketController.create(
          this.state.session,
          this.state.session,
          VOTE.boardId,
          VOTE.id,
          this.state.ratings,
          this.state.user
        );
      }
    },
  };

  PointsRender(maxNumber: number) {
    const NUMBER_ARRAY: number[] = [];
    for (let I = 1; I <= maxNumber; I++) {
      NUMBER_ARRAY.push(I);
    }
    return NUMBER_ARRAY;
  }

  typeRenderer = {
    board: () => {
      const hasInput =
        this.state.title?.length > 0 || this.state.description?.length > 0;
      const isClosed = this.props.type === 'locked';

      const isTitleRequired =
        this.state.description.length > 60 && !this.state.title;
      return (
        <div
          style={{
            height: '100vh',
            overflowY: 'scroll',
          }}
        >
          {isMobile && (
            <h5 style={{ textAlign: 'center', margin: '10px auto 0px' }}>
              {this.props.type[0].toUpperCase() + this.props.type.slice(1)}:{' '}
              {this.props.board?.title}
            </h5>
          )}
          {isClosed && <h3 style={{ textAlign: 'center' }}>Input closed!</h3>}
          <Box sx={{ padding: '20px 5px' }}>
            <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>
              Your contributions
            </h3>
            {!isClosed && (
              <form>
                <Box sx={{ position: 'relative' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                    }}
                  >
                    <label
                      htmlFor="title"
                      style={{ marginBottom: '5px', fontWeight: 'bold' }}
                    >
                      Title{' '}
                      <span style={{ color: 'grey' }}>(Click to edit)</span>
                    </label>
                    <TextField
                      id="title"
                      InputLabelProps={{ required: false, shrink: true }}
                      name="title"
                      onChange={this.handleChange}
                      placeholder="Enter your title here..."
                      inputProps={{ maxLength: 60 }}
                      required={this.state.description.length > 60}
                      autoComplete="off"
                      type="text"
                      variant="outlined"
                      value={this.state.title}
                      sx={{
                        background: 'white',
                        borderColor: 'primary.main',
                        '&:hover': {
                          borderColor: 'primary.dark',
                        },
                        '&.Mui-focused': {
                          borderColor: 'secondary.main',
                        },
                      }}
                    />
                    {isTitleRequired && (
                      <Typography
                        sx={{
                          textAlign: 'start',
                          paddingY: '5px',
                          color: 'error.main',
                        }}
                        variant="subtitle1"
                      >
                        A title is required for a long description
                      </Typography>
                    )}
                    <TextField
                      InputLabelProps={{ required: false }}
                      multiline
                      name="description"
                      onChange={this.handleChange}
                      placeholder="Write your contribution here..."
                      required
                      minRows={6}
                      rows={6}
                      autoFocus
                      sx={{
                        paddingBottom: '1em',
                        marginTop: '10px',
                      }}
                      autoComplete="off"
                      type="text"
                      value={this.state.description}
                      variant="filled"
                      inputProps={{ style: { fontSize: 15 } }}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      bottom: '0',
                      transform: 'translate(-50%, 50%)',
                    }}
                  >
                    <Button
                      disabled={
                        !this.state.description.trim() &&
                        !this.state.title.trim()
                      }
                      sx={{ marginTop: '30px' }}
                      onClick={this.commit.input}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </form>
            )}
            <Divider sx={{ margin: '50px' }} />
            <Box sx={{ overflowY: 'auto' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      defaultChecked
                      color="primary"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        event.stopPropagation();
                        this.setState({ showAll: checked });
                      }}
                    />
                  }
                  label={
                    <div>
                      Show contributions
                      <div
                        style={{
                          marginLeft: '5px',
                          color: Theme.palette.info.contrastText,
                          backgroundColor: Theme.palette.info.dark,
                          borderRadius: '50%',
                          textAlign: 'center',
                          display: 'inline-block',
                          lineHeight: '22px',
                          width: '22px',
                          fontSize: '13px',
                        }}
                      >
                        {this.props.contributions?.length}
                      </div>
                    </div>
                  }
                  labelPlacement="top"
                />

                <FormControlLabel
                  value="top"
                  control={
                    <Switch
                      color="primary"
                      disabled={!this.state.showAll}
                      checked={this.state.showDescription}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        event.stopPropagation();
                        if (!this.state.showAll) {
                          return false;
                        }
                        this.setShowDescription(checked);
                      }}
                    />
                  }
                  label="Show descriptions"
                  labelPlacement="top"
                />
              </Box>

              <Box
                sx={{
                  marginTop: '25px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                }}
              >
                {this.state.showAll &&
                  this.props.contributions &&
                  this.props.contributions.map((con) => (
                    <InputCard
                      key={con.id}
                      expand={this.state.showDescription}
                      input={con}
                    />
                  ))}
              </Box>
            </Box>
          </Box>
        </div>
      );
    },
    points: () => {
      const OPTIONS = this.props.options;
      if (OPTIONS && this.props.vote) {
        return (
          <MobilePoints
            title={this.props.vote.title}
            description={this.props.vote.description}
            options={this.props.options}
            totalUserVotes={this.state.totalUserVotes}
            lowNumber={this.props.vote.lowNumber}
            highNumber={this.props.vote.highNumber}
            showDescription={this.state.showDescription}
            ratings={this.state.ratings}
            handleRatings={this.handleRatings}
            onShowDescriptionToggle={(event, checked) => {
              event.stopPropagation();
              if (!this.state.showAll) {
                return false;
              }
              this.setShowDescription(checked);
            }}
            onSendVote={(e) => this.commit.ticket(e)}
          />
        );
      }
    },
    slider: () => {
      const Options = this.props.options;
      const Vote = this.props.vote;
      if (!Options || !Vote) {
        return;
      }
      Options.sort((a, b) => a.title.localeCompare(b.title));

      const defaultNumber = Math.floor((Vote.highNumber + Vote.lowNumber) / 2);

      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <div
            style={{
              background: '#eaeaea',
              padding: '40px 20px 20px 20px',
              flexGrow: '1',
              overflowY: 'scroll',
            }}
          >
            <form>
              <Typography
                sx={{
                  position: 'relative',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  textAlign: 'center',
                  marginBottom: '15px',
                }}
                variant="h3"
              >
                {Vote.title}
              </Typography>

              {Vote.title && (
                <Typography
                  sx={{
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                    marginBottom: '10px',
                  }}
                  variant="body1"
                >
                  {Vote.description}
                </Typography>
              )}

              <Typography
                sx={{
                  position: 'relative',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  textAlign: 'center',
                  marginBottom: '15px',
                }}
                variant="body1"
              >
                <span>
                  {Vote.lowNumber} = {Vote.lowDescription}
                </span>
                <span style={{ marginLeft: '10px' }}>
                  {Vote.highNumber} = {Vote.highDescription}
                </span>
              </Typography>

              <FormControlLabel
                value="top"
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
                control={
                  <Switch
                    color="primary"
                    checked={this.state.showDescription}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      checked: boolean
                    ) => {
                      event.stopPropagation();
                      if (!this.state.showAll) {
                        return false;
                      }
                      this.setShowDescription(checked);
                    }}
                  />
                }
                label="Show descriptions"
                labelPlacement="start"
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                  padding: '2px 0',
                }}
              >
                {Vote &&
                  Options &&
                  Options.map(
                    (
                      option //TODO: Export Option to own component && Does the Slider Need that too?
                    ) => (
                      <div
                        key={option.id}
                        style={{
                          padding: '10px 0px 25px',
                          background: '#eaeaea',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        <div>
                          <TooltipView
                            title={
                              option.description !== option.title
                                ? option.description
                                : ''
                            }
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{
                                display: 'inline-block',
                                height: '30px',
                                lineHeight: '30px',
                                textAlign: 'left',
                                paddingLeft: '10px',
                              }}
                            >
                              {option.title}
                            </Typography>
                          </TooltipView>
                          {this.state.showDescription &&
                            option.description !== option.title && (
                              <Typography
                                variant="caption"
                                sx={{
                                  display: 'block',
                                  lineHeight: '30px',
                                  textAlign: 'left',
                                  paddingLeft: '10px',
                                }}
                              >
                                {option.description}
                              </Typography>
                            )}
                        </div>

                        <Typography
                          variant="caption"
                          sx={{
                            display: 'block',
                            textAlign: 'center',
                            position: 'relative',
                            top: '10px',
                            fontWeight: 'bold',
                          }}
                        >
                          {this.state.ratings.find(
                            (x) => x.key === option.id
                          ) &&
                          this.state.ratings.find((x) => x.key === option.id)
                            ?.isTouched ? (
                            this.state.ratings.find((x) => x.key === option.id)
                              ?.value
                          ) : (
                            <div>&nbsp;</div>
                          )}
                        </Typography>

                        <div
                          style={{
                            padding: '0px 0px',
                            marginTop: '0px',
                            zIndex: '1',
                            display: 'flex',
                          }}
                        >
                          <div>
                            <span
                              style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                              }}
                            >
                              {Vote.lowNumber}
                            </span>
                          </div>
                          <Slider
                            defaultValue={defaultNumber}
                            id={option.id}
                            marks={true}
                            sx={{
                              margin: '0px 18px',
                              padding: '12px 0 !important',
                              root: {
                                color: '#000',
                                height: 8,
                              },
                              thumb: {
                                height: 24,
                                width: 24,
                                backgroundColor: '#fff',
                                border: '4px solid currentColor',
                                marginTop: -8,
                                marginLeft: -12,
                                '&:focus,&:hover,&$active': {
                                  boxShadow: 'inherit',
                                },
                              },
                            }}
                            max={Vote.highNumber}
                            min={Vote.lowNumber}
                            onChange={(
                              event: Event,
                              value: number | number[]
                            ) =>
                              this.handleRatings(
                                Array.isArray(value) ? value[0] : value,
                                option.id
                              )
                            }
                            step={1}
                            track={false}
                          />
                          <div>
                            {' '}
                            <span
                              style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                              }}
                            >
                              {Vote.highNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </form>
            <Button
              style={{
                display: 'block',
                margin: '5px auto',
              }}
              onClick={(e: React.MouseEvent<HTMLButtonElement | MouseEvent>) =>
                this.commit.ticket(e)
              }
            >
              Send Vote
            </Button>
          </div>
        </div>
      );
    },
    results: () => {
      const Options = this.props.options;
      const Vote = this.props.vote;
      const Ticket = this.props.ticket;

      if (!Options || !Vote || !Ticket) {
        return;
      }

      Options.sort((a, b) => a.title.localeCompare(b.title));
      setTimeout(() => {
        const node = this.voteSentRef;
        if (node.current) {
          node.current.style.height = '0px';
        }
      }, 2000);

      return (
        <div
          style={{
            background: '#eaeaea',
            paddingBottom: '15px',
            overflowY: 'auto',
            height: '100vh',
          }}
        >
          <Typography
            sx={{
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
              padding: '40px 0px',
            }}
            variant="h3"
          >
            {Vote.title}
          </Typography>

          <div
            ref={this.voteSentRef}
            style={{
              background: Theme.palette.secondary.light,
              textAlign: 'center',
              height: '60px',
              fontSize: '22px',
              fontWeight: 'bold',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              overflow: 'hidden',
              transition: 'all 0.5s 0s ease',
            }}
          >
            Vote sent
          </div>

          <div
            style={{
              borderRadius: '2em',
              width: '90%',
              margin: '20px auto',
              display: 'flex',
              flexDirection: 'column',
              gap: '1px',
              background: '#fff',
              padding: '50px',
            }}
          >
            <Typography
              variant="subtitle2"
              style={{
                position: 'relative',
                textAlign: 'center',
                left: '50%',
                marginBottom: '35px',
                transform: 'translateX(-50%)',
              }}
            >
              Your Vote:
            </Typography>

            {Options.sort(
              (a, b) =>
                b.averageRating - a.averageRating ||
                a.title.localeCompare(b.title)
            ).map((option) => (
              <div
                key={option.id}
                style={{
                  position: 'relative',
                  height: '80px',
                  borderRadius: '18px',
                  color: '#B340C7',
                  padding: '2px 12px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    gap: '10px',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textAlign: 'left',
                      lineHeight: '18px',
                      color: '#282D3C',
                      fontSize: '1em',
                      fontFamily: 'Lato',
                    }}
                  >
                    {option.title}
                  </Typography>

                  <div
                    style={{
                      flexGrow: '1',
                      borderBottom: '1px dotted #AFAFAF',
                    }}
                  />

                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: 'right',
                      lineHeight: '18px',
                      color: '#282D3C',
                      fontSize: '1em',
                      fontFamily: 'Lato',
                    }}
                  >
                    {`${Ticket.ratings.find((x) => x.key === option.id)
                      ?.value} p`}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    },
  };

  render() {
    if (this.props.isLocked) {
      if (
        (this.props.type === 'points' || this.props.type === 'slider') &&
        !this.props.ticket
      ) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '1.5em',
              height: '75%',
              padding: '0px 50px',
              lineBreak: 'auto',
            }}
          >
            Voting is closed for now. The administrator will open it when it's
            time to vote.
          </div>
        );
      } else if (this.props.type === 'board') {
        return (
          <div
            style={{
              position: 'relative',
              top: '45%',
              width: '100%',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {this.props.board?.parentId ? 'Refined board' : 'Board'} is closed
            for new inputs
          </div>
        );
      }
    }

    if (
      (this.props.type === 'board' || this.props.type === 'locked') &&
      this.props.board
    ) {
      return this.typeRenderer.board();
    } else if (this.props.type !== 'board' && this.props.ticket) {
      return this.typeRenderer.results();
    } else if (this.props.type === 'points' && !this.props.ticket) {
      return this.typeRenderer.points();
    } else if (this.props.type === 'slider' && !this.props.ticket) {
      return this.typeRenderer.slider();
    } else {
      return (
        <div
          style={{
            position: 'relative',
            top: '45%',
            width: '100%',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Coboost
        </div>
      );
    }
  }
}
