import { Children, Component, type PropsWithChildren } from 'react';
import {
  DragDropContext,
  Droppable,
  type DragStart,
  type DroppableProvided,
  type DropResult,
} from '@hello-pangea/dnd';
import { Box, IconButton, styled } from '@mui/material';

import { eventEmitter, Events, getCookie } from '@utils';
import { OrganizerView, StackView, type IStackProps } from '.';
import {
  BoardController,
  GroupController,
  InputController,
} from '../../../Controllers';
import { TooltipView } from '../General';

type IProps = PropsWithChildren<
  IStackProps & {
    id: string;
    lockData: boolean;
    isHidden: boolean;
    dragType: 'input' | 'group';
    onContextMenu?: (event: React.MouseEvent<HTMLElement>) => void;
    onDragStart: (initial: DragStart) => void;
    onSelectAll: () => void;
    onClearSelected: () => void;
    onRemoveSelected: () => void;
  }
>;

const BoardContainer = styled('div')(({ theme }) => ({
  margin: 0,
  position: 'relative',
  display: 'flex',
  padding: '0',
  transition: 'all 0.25s',
  background: theme.palette.background.default,
  height: '100%',
  width: '100%',
}));

interface IDrop
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  opened: boolean;
}

const NewColumnZone = styled('div', {
  shouldForwardProp: (prop) => prop !== 'opened',
})<IDrop>(({ opened }) => ({
  height: '100%',
  ...(opened ? { flexGrow: 1, minWidth: '350px' } : { width: 0 }),
}));

export class BoardView extends Component<IProps> {
  onPasteBinded: (e: ClipboardEvent) => void;

  constructor(props: IProps) {
    super(props);

    this.props.handler.GetInputs();

    this.onPasteBinded = this.onPaste.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', (ev: KeyboardEvent) => {
      const tagName = (ev.target as HTMLElement).tagName;
      if (
        ev.key === 'Delete' &&
        tagName.toUpperCase() !== 'INPUT' &&
        tagName.toUpperCase() !== 'TEXTAREA'
      ) {
        ev.preventDefault();
        this.props.onRemoveSelected();
      }

      const isCtrlA =
        (ev.ctrlKey || ev.metaKey) && ev.key.toLowerCase() === 'a';

      if (isCtrlA) {
        ev.preventDefault();
        this.props.onSelectAll();
      }

      const isCtrlD =
        (ev.ctrlKey || ev.metaKey) && ev.key.toLowerCase() === 'd';
      if (isCtrlD) {
        ev.preventDefault();
        this.props.onClearSelected();
      }
    });

    document.addEventListener('paste', this.onPasteBinded);

    eventEmitter.subscribe(Events.INPUT_DELETED, (data) => {
      if (data.groupId === this.props.stack.id) {
        return;
      }

      setTimeout(() => {
        const deleteGroup =
          this.props.handler.GetInputs(data.groupId).length === 0;
        if (deleteGroup) {
          GroupController.archive(
            this.props.session,
            this.props.phaseId,
            this.props.id,
            data.groupId
          );
        }
      }, 500);
    });
  }

  async onPaste(e: ClipboardEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (this.props.lockData) {
      alert('Please unlock the board to add new proposals');
      return;
    }

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData?.getData('Text') ?? '';

    const LINE_SEPARATOR = /\r?\n/;
    const TAB_SEPARATOR = '\t';

    const lines = pastedData.trim().split(LINE_SEPARATOR);

    // Check if the string is tab-separated
    const isTabSeparated = lines.every((line) => line.includes(TAB_SEPARATOR));

    let items: { title: string; description?: string }[] = [];

    if (isTabSeparated) {
      items = lines.map((line) => {
        const [title, description] = line.split(TAB_SEPARATOR);
        return { title, description };
      });

      const firstItem = items[0];
      const expectedFirstItem = {
        title: 'title',
        description: 'description',
      };

      if (
        !items.length ||
        firstItem.title.toLowerCase() !== expectedFirstItem.title ||
        firstItem.description.toLowerCase() !== expectedFirstItem.description
      ) {
        console.log('Invalid paste format');
        return;
      }

      items.shift();
    } else {
      items = lines
        .map((line) => line.trim())
        .filter(Boolean)
        .map((line) => {
          return { title: line };
        });
    }

    if (items.length > 20) {
      alert(
        `You can only paste up to 20 items at a time.\nYou pasted ${items.length} items. Please try again!`
      );
      return;
    }

    if (items.length) {
      const user = getCookie('UserId');

      for (const item of items) {
        await InputController.create(
          this.props.session,
          this.props.phaseId,
          this.props.id,
          this.props.stack.id,
          item.title,
          item.description,
          user,
          true
        );
      }
    }
  }

  componentWillUnmount() {
    eventEmitter.unsubscribe(Events.INPUT_DELETED);
    document.removeEventListener('paste', this.onPasteBinded);
  }

  hideBoard = () => {
    BoardController.hideBoard(
      this.props.session,
      this.props.phaseId,
      this.props.id
    );
  };

  onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    setTimeout(() => {
      const isEmptyStackAndBuffer =
        this.props.stackChildren.length === 0 &&
        this.props.bufferChildren.length === 0;

      if (isEmptyStackAndBuffer) {
        this.props.stack.isCollapsed = true;
        this.props.handler.ForceRender();
      }
    }, 500);

    if (result.type === 'input') {
      if (result.destination.droppableId.startsWith('Col-')) {
        let Column: string = result.destination.droppableId.substring(4);
        if (Column === 'End') {
          Column = `${Children.count(this.props.children)}`;
        }
        if (parseInt(Column) < 0) {
          Column = '0';
        }

        this.onDragEndAsync.fromInput.toColumn(
          result.draggableId,
          result.source.droppableId,
          parseInt(Column)
        );
      } else if (result.source.droppableId === result.destination.droppableId) {
        if (result.source.index === result.destination.index) {
          return;
        }
        this.onDragEndAsync.fromInput.inGroup(
          result.source.droppableId,
          result.draggableId,
          result.destination.index
        );
      } else {
        this.onDragEndAsync.fromInput.toGroup(
          result.source.droppableId,
          result.draggableId,
          result.destination.droppableId,
          result.destination.index
        );
      }
    } else if (result.type === 'group') {
      if (result.source.droppableId === result.destination.droppableId) {
        if (result.source.index === result.destination.index) {
          return;
        }
        this.onDragEndAsync.fromGroup.inColumn(
          result.draggableId,
          result.destination.index
        );
      } else {
        let Column: string = result.destination.droppableId.split('-')[1];
        if (Column === 'End') {
          Column = `${Children.count(this.props.children)}`;
        }

        this.onDragEndAsync.fromGroup.toColumn(
          result.draggableId,
          parseInt(Column),
          result.destination.index
        );
      }
    }
  };

  onDragEndAsync = {
    fromInput: {
      toColumn: async (
        inputId: string,
        sourceGroup: string,
        columnIndex: number
      ) => {
        const sessionId = this.props.session;
        const phaseId = this.props.phaseId;
        const boardId = this.props.id;
        const input = this.props.handler.GetInput(inputId);
        if (!input) {
          return;
        }

        const group = await GroupController.create(
          sessionId,
          phaseId,
          boardId,
          input.title.toUpperCase(),
          columnIndex,
          false
        );

        if (!group) {
          return;
        }
        const isSelected = this.props.handler.IsSelected(boardId, inputId);

        const data: { groupId: string; inputId: string }[] = [
          { groupId: sourceGroup, inputId: inputId },
        ];
        if (isSelected) {
          const Selected = this.props.handler.GetSelected(boardId);
          if (!Selected) {
            return;
          }

          for (let i = 0; i < Selected.length; i++) {
            if (inputId === Selected[i].id) {
              continue;
            }
            const Input = {
              groupId: Selected[i].groupId,
              inputId: Selected[i].id,
            };
            data.push(Input);
          }
        }
        this.props.handler.DragEnd.input.column(data, group);

        await InputController.set.group(
          sessionId,
          phaseId,
          boardId,
          sourceGroup,
          data,
          group.id,
          0
        );
      },
      inGroup: async (
        groupId: string,
        inputId: string,
        destinationIndex: number
      ) => {
        const sessionId = this.props.session;
        const phaseId = this.props.phaseId;
        const boardId = this.props.id;

        const group = this.props.handler.GetGroup(groupId);
        if (group && group.name === 'Buffer') {
          return;
        }
        this.props.handler.DragEnd.input.internal(
          groupId,
          inputId,
          destinationIndex
        );
        await InputController.move(
          sessionId,
          phaseId,
          boardId,
          groupId,
          inputId,
          destinationIndex
        );
      },
      toGroup: async (
        groupId: string,
        inputId: string,
        destinationGroup: string,
        destinationIndex: number
      ) => {
        const sessionId = this.props.session;
        const phaseId = this.props.phaseId;
        const boardId = this.props.id;

        const isSelected = this.props.handler.IsSelected(boardId, inputId);

        const data: { groupId: string; inputId: string }[] = [
          { groupId: groupId, inputId: inputId },
        ];
        if (isSelected) {
          const selected = this.props.handler.GetSelected(boardId);
          if (!selected) {
            return;
          }

          for (let i = 0; i < selected.length; i++) {
            if (
              inputId === selected[i].id ||
              selected[i].groupId === destinationGroup
            ) {
              continue;
            }
            const input = {
              groupId: selected[i].groupId,
              inputId: selected[i].id,
            };
            data.push(input);
          }
        }

        this.props.handler.DragEnd.input.external(
          data,
          destinationGroup,
          destinationIndex
        );
        await InputController.set.group(
          sessionId,
          phaseId,
          boardId,
          groupId,
          data,
          destinationGroup,
          destinationIndex
        );
      },
    },
    fromGroup: {
      inColumn: async (groupId: string, destinationIndex: number) => {
        const sessionId = this.props.session;
        const phaseId = this.props.phaseId;
        const boardId = this.props.id;

        this.props.handler.DragEnd.group.internal(groupId, destinationIndex);

        await GroupController.move(
          sessionId,
          phaseId,
          boardId,
          groupId,
          destinationIndex
        );
      },
      toColumn: async (
        groupId: string,
        destinationColumn: number,
        destinationIndex: number
      ) => {
        const sessionId = this.props.session;
        const phaseId = this.props.phaseId;
        const boardId = this.props.id;

        this.props.handler.DragEnd.group.external(
          groupId,
          destinationColumn,
          destinationIndex
        );

        await GroupController.setColumn(
          sessionId,
          phaseId,
          boardId,
          groupId,
          destinationColumn,
          destinationIndex
        );
      },
    },
  };

  disabled = () => {
    alert(
      'This button is only here to find out if you will click it >:}\nCollapse all is Expand all if they are all collapsed\nSo the question is:\nAre all your groups collapsed currently?'
    );
  };

  lockToggle = () => {
    const sessionId = this.props.session;
    const phaseId = this.props.phaseId;
    const boardId = this.props.id;

    if (this.props.lockData && this.props.stack.isCollapsed) {
      GroupController.collapse.single(
        sessionId,
        phaseId,
        boardId,
        this.props.stack.id
      );
    }

    BoardController.lock(sessionId, phaseId, boardId);
  };

  CollapseGroup = async (id: string) => {
    const sessionId = this.props.session;
    const phaseId = this.props.session;
    const boardId = this.props.stack.boardId;
    await GroupController.collapse.single(sessionId, phaseId, boardId, id);
    if (id === this.props.buffer.id) {
      InputController.compressSwissCheese(sessionId, phaseId, boardId);
    }
  };

  onBoardDoubleClick = () => {
    if (this.props.userIsOwner) {
      this.props.handler.ClearSelected(this.props.id);
    }
  };

  render() {
    return (
      <DragDropContext
        enableDefaultSensors={this.props.userIsOwner}
        onDragEnd={this.onDragEnd}
        onBeforeDragStart={this.props.onDragStart}
      >
        <BoardContainer>
          <StackView
            session={this.props.session}
            phaseId={this.props.phaseId}
            handler={this.props.handler}
            stack={this.props.stack}
            stackChildren={this.props.stackChildren}
            buffer={this.props.buffer}
            bufferChildren={this.props.bufferChildren}
            userIsOwner={this.props.userIsOwner}
            hasNewInputsInStack={this.props.hasNewInputsInStack}
            onStackContextMenu={this.props.onStackContextMenu}
          />
          <OrganizerView
            onContextMenu={this.props.onContextMenu}
            onDoubleClick={this.onBoardDoubleClick}
            negative={
              this.props.stack.isCollapsed
                ? 40
                : this.props.buffer.isCollapsed
                ? 732
                : 366
            }
          >
            {this.props.handler.GetGroups(this.props.id).length === 2 && (
              <h4
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  padding: '10px',
                  textAlign: 'center',
                  border: '2px dashed #555',
                  borderRadius: '10px',
                }}
              >
                Drag 'n drop chosen items from proposals here
              </h4>
            )}
            {this.props.isHidden && (
              <h2
                style={{
                  margin: '20% auto auto',
                }}
              >
                Keep working. We will soon reveal result.
              </h2>
            )}
            {!this.props.isHidden && this.props.children}
            {!this.props.isHidden && (
              <>
                <Droppable droppableId={`Col-End`} type="input">
                  {(provided: DroppableProvided) => (
                    <NewColumnZone
                      opened={this.props.dragType === 'input'}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {provided.placeholder}
                    </NewColumnZone>
                  )}
                </Droppable>
                <Droppable droppableId={`Column-End`} type="group">
                  {(provided: DroppableProvided) => (
                    <NewColumnZone
                      opened={this.props.dragType === 'group'}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {provided.placeholder}
                    </NewColumnZone>
                  )}
                </Droppable>
              </>
            )}
            {this.props.userIsOwner && (
              <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                <TooltipView
                  title={
                    this.props.lockData
                      ? 'Closed board, click to open'
                      : 'Open board, click to close'
                  }
                  placement="top-end"
                >
                  <IconButton onClick={this.lockToggle}>
                    <span
                      style={{
                        color: this.props.lockData
                          ? '#e00'
                          : 'rgba(0, 0, 0, 0.54)',
                        position: 'relative',
                        width: '32px',
                        fontSize: '32px',
                      }}
                      className="notranslate material-icons-outlined"
                    >
                      {this.props.lockData ? 'lock' : 'lock_open'}
                    </span>
                  </IconButton>
                </TooltipView>
                <TooltipView
                  title={
                    this.props.isHidden
                      ? 'Results hidden, click to reveal'
                      : 'Results revealed, click to hide'
                  }
                  placement="top-end"
                >
                  <IconButton onClick={this.hideBoard}>
                    <span
                      style={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        position: 'relative',
                        top: '3px',
                        width: '32px',
                        fontSize: '32px',
                      }}
                      className="notranslate material-icons-outlined"
                    >
                      {this.props.isHidden ? 'visibility_off' : 'visibility_on'}
                    </span>
                  </IconButton>
                </TooltipView>
              </Box>
            )}
          </OrganizerView>
        </BoardContainer>
      </DragDropContext>
    );
  }

  noSelectedInputs(): number {
    const Selected = this.props.handler.GetSelected(this.props.id);
    return Selected != null ? Selected.length : 0;
  }
}
