import { Component } from 'react';
import { IconButton, Typography, alpha, styled } from '@mui/material';

import { Theme } from '../../../Theme';
import { TypoField } from './TypoField';

interface IProps {
  countdownEnabled: true;
  countdownTime: number;
  countdownEnd: number;
  countdownLock: boolean;
  set: (time: number) => void;
  toggle: (countdown: boolean) => void;
  extend: () => void;
  clockLock: (locked: boolean) => void;
}

interface IState {
  minutes: number;
  seconds: number;
  warning: boolean;
}

interface IDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  warning?: boolean;
}

const Container = styled(({ warning, ...divProps }: IDiv) => (
  <div {...divProps} />
))<IDiv>(() => ({
  margin: 0,
  position: 'fixed',
  padding: '0.5em 1em',
  zIndex: 100,
  borderRadius: '2em',
}));

const Display = styled(
  ({ warning, ...divProps }: IDiv) => <div {...divProps} />,
  { shouldForwardProp: (prop) => prop !== 'warning' }
)<IDiv>(({ warning }) => ({
  margin: 0,
  marginRight: '-5em',
  position: 'relative',
  padding: '0.5em 6.5em 0.5em 1.5em',
  zIndex: 100,
  borderRadius: '2em',
  background: warning
    ? alpha(Theme.palette.error.light, 0.5)
    : alpha(Theme.palette.primary.light, 0.5),
}));

const Controls = styled(({ warning, ...divProps }: IDiv) => (
  <div {...divProps} />
))<IDiv>(() => ({
  margin: 0,
  position: 'fixed',
  padding: '1em 0.5em',
  display: 'flex',
  zIndex: 100,
  flexDirection: 'row',
  borderRadius: '2em',
  background: Theme.palette.background.paper,
  boxShadow: `0 0 10px 10px ${alpha(Theme.palette.text.primary, 0.15)}`,
}));

export class CountdownControls extends Component<IProps, IState> {
  state = {
    minutes: 0,
    seconds: 0,
    warning: false,
  };

  interval: null | number = null;

  start = () => {
    this.props.toggle(true);
  };

  stop = () => {
    this.props.toggle(false);
  };

  begin = () => {
    if (this.props.countdownEnabled) {
      this.tick();
      this.interval = window.setInterval(() => this.tick(), 166);
    }
  };

  end = () => {
    if (!this.props.countdownEnabled && this.interval) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
    this.time();
  };

  tick = () => {
    const NOW = Date.now();
    const END = this.props.countdownEnd * 1000;
    const TIME = END - NOW;

    let Minutes = Math.floor(TIME / (60 * 1000));
    let Seconds = Math.floor((TIME % (1000 * 60)) / 1000);

    if (Minutes <= 0) {
      Minutes = 0;
    }
    if (Seconds <= 0) {
      Seconds = 0;
    }
    let Warning = false;
    if (Minutes <= 0 && Seconds <= 30) {
      Warning = true;
    }

    this.setState({ minutes: Minutes, seconds: Seconds, warning: Warning });

    if (TIME <= 0 && this.props.countdownEnabled) {
      if (this.interval) {
        window.clearInterval(this.interval);
      }
    }
  };

  time() {
    const TIME = this.props.countdownTime * 1000;

    const MINUTES = Math.floor(TIME / (60 * 1000));
    const SECONDS = Math.floor((TIME % (1000 * 60)) / 1000);

    this.setState({ minutes: MINUTES, seconds: SECONDS, warning: false });
  }

  setSeconds = (seconds: string) => {
    let Time = this.state.minutes * 60;
    this.setState({
      minutes: Time,
      seconds: parseInt(seconds),
      warning: false,
    });

    Time += parseInt(seconds);

    this.props.set(Time);
  };

  setMinutes = (minutes: string) => {
    let Time = this.state.seconds;
    this.setState({
      minutes: parseInt(minutes),
      seconds: Time,
      warning: false,
    });

    Time += parseInt(minutes) * 60;

    this.props.set(Time);
  };

  render() {
    const MINUTES = this.state.minutes;
    const SECONDS = this.state.seconds;

    //TODO: The TypoField doesn't enforce Numbers and allows seconds > 60, should at least enforce numbers!!
    return (
      <Container {...this.props}>
        {this.props.countdownEnabled ? (
          <>
            {!this.interval && this.begin()}
            <Display warning={this.state.warning}>
              <span className="notranslate material-icons-outlined">timer</span>
              <Typography variant="h4">
                {`${MINUTES < 10 ? '0' : ''}${MINUTES}:${
                  SECONDS < 10 ? '0' : ''
                }${SECONDS}`}
              </Typography>
            </Display>
            <Controls>
              <IconButton onClick={this.stop}>
                <span className="notranslate material-icons">stop</span>
              </IconButton>
              <IconButton onClick={this.props.extend}>
                <span className="notranslate material-icons">plus_one</span>
              </IconButton>
            </Controls>
          </>
        ) : (
          <>
            {this.interval && this.end()}
            <Display>
              <span className="notranslate material-icons-outlined">timer</span>
              <TypoField
                doubleClickToEdit={false}
                uppercase={false}
                edit={false}
                variant="h4"
                text={`${MINUTES < 10 ? '0' : ''}${MINUTES}`}
                submitChange={this.setMinutes}
                disabled={false}
              />
              {':'}
              <TypoField
                doubleClickToEdit={false}
                uppercase={false}
                edit={false}
                variant="h4"
                text={`${SECONDS < 10 ? '0' : ''}${SECONDS}`}
                submitChange={this.setSeconds}
                disabled={false}
              />
            </Display>
            <Controls>
              <IconButton onClick={this.start}>
                <span className="notranslate material-icons">play</span>
              </IconButton>
              <IconButton
                onClick={() => this.props.clockLock(!this.props.countdownLock)}
                sx={{
                  color: this.props.countdownLock
                    ? Theme.palette.primary.main
                    : Theme.palette.text.primary,
                }}
              >
                <span className="notranslate material-icons">lock_clock</span>
              </IconButton>
            </Controls>
          </>
        )}
      </Container>
    );
  }
}

export class CountdownDisplay extends Component<IProps, IState> {
  state = {
    minutes: 0,
    seconds: 0,
    warning: false,
  };

  interval: null | number = null;

  begin = () => {
    if (this.props.countdownEnabled) {
      this.tick();
      this.interval = window.setInterval(() => this.tick(), 166);
    }

    return null;
  };

  end = () => {
    if (!this.props.countdownEnabled && this.interval) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
    this.time();

    return null;
  };

  tick() {
    const NOW = Date.now();
    const END = this.props.countdownEnd * 1000;
    const TIME = END - NOW;

    let Minutes = Math.floor(TIME / (60 * 1000));
    let Seconds = Math.floor((TIME % (1000 * 60)) / 1000);

    if (Minutes <= 0) {
      Minutes = 0;
    }
    if (Seconds <= 0) {
      Seconds = 0;
    }
    let Warning = false;
    if (Minutes <= 0 && Seconds <= 30) {
      Warning = true;
    }

    this.setState({ minutes: Minutes, seconds: Seconds, warning: Warning });

    if (TIME <= 0 && this.props.countdownEnabled) {
      if (this.interval) {
        window.clearInterval(this.interval);
      }
    }
  }

  time() {
    const TIME = this.props.countdownTime * 1000;

    const MINUTES = Math.floor(TIME / (60 * 1000));
    const SECONDS = Math.floor((TIME % (1000 * 60)) / 1000);

    this.setState({ minutes: MINUTES, seconds: SECONDS, warning: false });
  }

  render() {
    const MINUTES = this.state.minutes;
    const SECONDS = this.state.seconds;

    //TODO: The TypoField doesn't enforce Numbers and allows seconds > 60, should at least enforce numbers!!
    return (
      <Container {...this.props}>
        {this.props.countdownEnabled ? (
          <Display>
            {!this.interval && this.begin()}
            <span className="notranslate material-icons-outlined">timer</span>
            <Typography variant="h4">
              {`${MINUTES < 10 ? '0' : ''}${MINUTES}:${
                SECONDS < 10 ? '0' : ''
              }${SECONDS}`}
            </Typography>
          </Display>
        ) : (
          <Display>
            {this.interval && this.end()}
            <span className="notranslate material-icons-outlined">timer</span>
            <Typography variant="h4">
              {`${MINUTES < 10 ? '0' : ''}${MINUTES}:${
                SECONDS < 10 ? '0' : ''
              }${SECONDS}`}
            </Typography>
          </Display>
        )}
      </Container>
    );
  }
}
