import { Component, type PropsWithChildren } from 'react';
import { styled } from '@mui/material';

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  index: number;
  id: string;
  name: string;
  size: number;
}

interface IState {
  hover: boolean;
  collapsed: boolean;
}

interface IDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active?: boolean;
}

const Container = styled('div')<IDiv>(({ theme }) => ({
  width: '100%',
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  borderBottom: `1px solid ${theme.palette.text.secondary}`,
}));

export class PhaseCard extends Component<PropsWithChildren<IProps>, IState> {
  state = {
    hover: false,
    collapsed: false,
  };

  render() {
    return (
      <Container onClick={this.props?.onClick}>{this.props.children}</Container>
    );
  }
}
