import { Component } from 'react';
import { Popover, type PopoverOrigin } from '@mui/material';
import { BlockPicker, type ColorChangeHandler } from 'react-color';

export interface IItem {
  text: string;
  callback: (() => void) | null;
}

interface IProps {
  anchor: Element | null;
  onClose: (event: Event) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  color: string;
  onChange: ColorChangeHandler;
  onChangeComplete: ColorChangeHandler;
}

const Colors = [
  '#98e0e0',
  '#e1b2e9',
  '#b2e9bd',
  '#faf687',
  '#f2b1ca',
  '#92d5c5',
  '#ffd77c',
  '#ffadad',
  '#bfa4fd',
  '#b6caef',
  '#e8e0b1',
  '#bbe8fc',
  '#ffd7d2',
  '#97d2f0',
  '#ffd6a7',
  '#b8d9c2',
  '#faa687',
  '#f0cbcb',
];

export class ColorBlock extends Component<IProps> {
  render() {
    return (
      <Popover
        anchorEl={this.props.anchor}
        anchorOrigin={
          this.props.anchorOrigin
            ? this.props.anchorOrigin
            : {
                vertical: 'bottom',
                horizontal: 'center',
              }
        }
        onClose={this.props.onClose}
        open={Boolean(this.props.anchor)}
        transformOrigin={
          this.props.transformOrigin
            ? this.props.transformOrigin
            : {
                vertical: 'top',
                horizontal: 'center',
              }
        }
      >
        <BlockPicker
          color={this.props.color}
          colors={Colors}
          onChange={this.props.onChange}
          onChangeComplete={this.props.onChangeComplete}
          triangle="top"
        />
      </Popover>
    );
  }
}
