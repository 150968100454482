/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo, useState } from 'react';

import { type TreeNode } from '@utils';
import type { BoardVoteWithHasVotes } from '../Models';
import { ResultBlock } from './Components/Results';

type ResultViewProps = {
  dataHandler: any;
  sessionId: string;
  tree: TreeNode<BoardVoteWithHasVotes>[][];
};

export const ResultView = ({
  dataHandler,
  sessionId,
  tree,
}: ResultViewProps) => {
  const [hoveredButton, setHoveredButton] = useState(null);
  const [selectedElements, setSelectedElements] = useState([]);

  const elements = useMemo(() => {
    return tree
      .flatMap((element) => element)
      .filter((element) => {
        if (!element['type']) {
          if (element['assignees']) {
            // Action plan hack
            return true;
          }

          const content = dataHandler.GetBoardContent(element.id);
          return content.columns.length > 0;
        }

        return true;
      });
  }, [dataHandler, tree]);

  return (
    <div
      style={{
        padding: '10px 10px 20px 10px',
        overflowY: 'scroll',
        height: '100vh',
      }}
    >
      <h2 style={{ marginTop: '25px', textAlign: 'center' }}>Results</h2>
      <p style={{ textAlign: 'center', margin: '30px 10% 50px' }}>
        This is an overview of all tasks that have been completed during your
        session. Select tasks and pages that you want to include in a pdf, Excel
        file or in a web presentation. Your choices define the contents for the
        downloaded file or interactive web presentation.
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginBottom: '8px',
        }}
      >
        <div></div>
        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {selectedElements.length}/{elements.length} sections selected
        </div>
        <div>
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              marginRight: '10px',
            }}
            onClick={() => setSelectedElements(elements.map((e) => e.id))}
          >
            Select all
          </span>
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setSelectedElements([])}
          >
            Unselect all
          </span>
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        {elements.map((element, index) => (
          <ResultBlock
            key={element.id}
            dataHandler={dataHandler}
            element={element}
            index={index + 1}
            length={elements.length}
            isSelected={selectedElements.indexOf(element.id) >= 0}
            setSelected={(value) => {
              if (value) {
                setSelectedElements([...selectedElements, element.id]);
              } else {
                setSelectedElements(
                  selectedElements.filter((id) => id !== element.id)
                );
              }
            }}
          />
        ))}
      </div>
      <div style={{ top: '40px', textAlign: 'center' }}>
        <button
          disabled={selectedElements.length === 0}
          style={{
            border: '2px solid #777',
            padding: '3px 15px',
            borderRadius: '20px',
            color: '#fff',
            backgroundColor:
              selectedElements.length === 0
                ? '#999'
                : hoveredButton === 'download'
                ? '#eee'
                : '#0095b3',
          }}
          onMouseEnter={() => setHoveredButton('download')}
          onMouseLeave={() => setHoveredButton(null)}
          onClick={() => {
            localStorage.setItem(
              'reportItems',
              JSON.stringify(selectedElements)
            );

            const frame = document.getElementById('frame') as HTMLIFrameElement;
            frame.src = `/report/${sessionId}?save=true`;
          }}
        >
          Download PDF
        </button>
        <a
          href={selectedElements.length > 0 ? '#' : null}
          style={{
            marginLeft: '20px',
            color: selectedElements.length === 0 ? '#999' : '#000',
          }}
        >
          Export to Excel
        </a>
        <a
          href={selectedElements.length > 0 ? '#' : null}
          style={{
            marginLeft: '20px',
            color: selectedElements.length === 0 ? '#999' : '#000',
          }}
          onClick={(e) => {
            if (selectedElements.length > 0) {
              localStorage.setItem(
                'reportItems',
                JSON.stringify(selectedElements)
              );

              window.open('/report/' + sessionId);
            }
            e.preventDefault();
          }}
        >
          Web presentation link
        </a>
        <a
          href={selectedElements.length > 0 ? '#' : null}
          style={{
            marginLeft: '20px',
            color: selectedElements.length === 0 ? '#999' : '#000',
          }}
        >
          Download as screenshots
        </a>
      </div>
      <div
        style={{
          marginBottom: '100px',
        }}
      ></div>
      <iframe
        id="frame"
        style={{ opacity: 0, height: '1px' }}
        title="report-frame"
      ></iframe>
    </div>
  );
};
