import { getCookie } from '@utils';
import {
  ActionPlanController,
  BoardController,
  CodesController,
  GroupController,
  InputController,
  OptionController,
  PhaseController,
  SessionController,
  TicketController,
  VoteController,
} from '../Controllers';
import {
  type ActionPlanModel,
  type BoardModel,
  type GroupModel,
  type InputModel,
  type OptionModel,
  type PhaseModel,
  type TicketModel,
  type VoteModel,
} from '../Models';
import { eventEmitter, Events } from '../Utils/EventEmitter';

interface IProps {
  creationDateInUnix: number;
  id: string;
  lastOpenedInUnix: number;
  name: string;
  ownerId: string;
  userIsOwner: boolean;
  forceRender: (data: Handler) => void;
}

interface IState {
  creationDateInUnix: number;
  id: string;
  lastOpenedInUnix: number;
  name: string;
  ownerId: string;
  code: number;
  forceRender: (data: Handler) => void;
  phases: Array<PhaseModel>;
  boards: Array<BoardModel>;
  groups: Array<GroupModel>;
  inputs: Array<InputModel>;
  votes: Array<VoteModel>;
  actionPlans: Array<ActionPlanModel>;
  options: Array<OptionModel>;
  tickets: Array<TicketModel>;
  selected: Array<IBoardOrVoteSelected>;
  userData: {
    noActive: number;
    noTotal: number;
    nicks?: Array<string>;
  };
  hasNewInputsInStack: boolean;
}

export interface IBoardContent {
  stack: GroupModel;
  buffer: GroupModel;
  columns: GroupModel[][];
}

export interface IVoteContent {
  tickets: TicketModel[];
  options: OptionModel[];
}

export interface IBoardOrVoteSelected {
  boardOrVote: string;
  selected: Array<string>;
}

export function IsBoardContent(object: any): object is IBoardContent {
  return 'stack' in object;
}

export function IsVoteContent(object: any): object is IVoteContent {
  return 'tickets' in object;
}

export class Handler {
  state: IState = {
    creationDateInUnix: 0,
    id: '',
    lastOpenedInUnix: 0,
    name: '',
    ownerId: '',
    code: 404,
    forceRender: () => {
      return;
    },
    phases: [],
    boards: [],
    groups: [],
    inputs: [],
    votes: [],
    actionPlans: [],
    options: [],
    tickets: [],
    selected: [],
    userData: {
      noActive: 0,
      noTotal: 0,
    },
    hasNewInputsInStack: false,
  };

  props: IProps | Readonly<IProps>;

  constructor(props: IProps | Readonly<IProps>) {
    this.props = props;
    this.state = {
      creationDateInUnix: props.creationDateInUnix,
      id: props.id,
      lastOpenedInUnix: props.lastOpenedInUnix,
      name: props.name,
      ownerId: props.ownerId,
      forceRender: props.forceRender,
      phases: [],
      boards: [],
      groups: [],
      inputs: [],
      votes: [],
      actionPlans: [],
      options: [],
      tickets: [],
      selected: [],
      code: 404,
      userData: {
        noActive: 0,
        noTotal: 0,
      },
      hasNewInputsInStack: false,
    };
  }

  async GetData() {
    const sessionId = this.state.id;
    this.state.phases = await PhaseController.get.list(sessionId);

    this.state.boards = await BoardController.get.bySession(sessionId);

    this.state.groups = await GroupController.get.bySession(sessionId);

    this.state.inputs = await InputController.get.bySession(sessionId);

    this.state.votes = await VoteController.get.bySession(sessionId);

    this.state.options = await OptionController.get.bySession(sessionId);

    this.state.tickets = await TicketController.get.bySession(sessionId);

    this.state.actionPlans =
      await ActionPlanController.get.bySession(sessionId);

    const code = await CodesController.get(sessionId);

    if (code && code !== 404 && code !== 400) {
      this.state.code = code;
    }
  }

  async getSession() {
    const sessionId = this.state.id;

    const session = await SessionController.connect(sessionId);

    if (session === null || session === undefined) {
      alert("Couldn't Connect to Session");
      return;
    }

    return session;
  }

  async NewPhaseBoard(title: string) {
    const sessionId = this.state.id;
    const user = getCookie('UserId');
    if (user) {
      const phase: PhaseModel | undefined = await PhaseController.create(
        sessionId,
        'Phase'
      );

      if (phase) {
        const board: BoardModel | undefined = await BoardController.create(
          user,
          sessionId,
          phase.id,
          title,
          'Description',
          false,
          undefined,
          undefined
        );

        if (board) {
          const stack: GroupModel | undefined = await GroupController.create(
            sessionId,
            phase.id,
            board.id,
            'Stack',
            0,
            false
          );

          const buffered: GroupModel | undefined = await GroupController.create(
            sessionId,
            phase.id,
            board.id,
            'Buffer',
            0,
            false
          );

          if (stack && buffered) {
            await this.GetData();
          }
        }

        eventEmitter.dispatch(Events.PHASE_ADDED, board);
      }
    }
  }

  Select(boardOrVoteId: string, inputId: string, clear?: boolean) {
    const input = this.GetInput(inputId);
    const board = this.GetBoard(boardOrVoteId);

    if (board) {
      if (!input) {
        return; // Input not found
      }

      // Check if the input is from the "Stack" group
      const groupName = this.GetGroup(input.groupId)?.name;
      const inputIsFromStack =
        input.groupId && (groupName === 'Stack' || groupName === 'Buffer');

      // Check if any items from stack are already selected
      const isStackSelected = this.state.selected
        .filter((sel) => sel.boardOrVote === boardOrVoteId)
        .some((selected) => {
          const representativeInput = this.GetInput(selected.selected[0]);
          const groupName = this.GetGroup(representativeInput?.groupId)?.name;
          return (
            representativeInput?.groupId &&
            (groupName === 'Stack' || groupName === 'Buffer')
          );
        });

      // Check if any items from group (non-stack) are already selected
      const isGroupSelected = this.state.selected
        .filter((sel) => sel.boardOrVote === boardOrVoteId)
        .some((selected) => {
          const representativeInput = this.GetInput(selected.selected[0]);
          const groupName = this.GetGroup(representativeInput?.groupId)?.name;
          return (
            representativeInput?.groupId &&
            groupName !== 'Stack' &&
            groupName !== 'Buffer'
          );
        });

      // If trying to select an item from stack and group items are already selected (or vice versa), return
      if (
        (inputIsFromStack && isGroupSelected) ||
        (!inputIsFromStack && isStackSelected)
      ) {
        return;
      }

      const indexOfSelectedBoardOrVote = this.state.selected.findIndex(
        (selected) => selected.boardOrVote === boardOrVoteId
      );

      if (indexOfSelectedBoardOrVote !== -1) {
        const selectedBoardOrVote =
          this.state.selected[indexOfSelectedBoardOrVote];

        if (clear) {
          selectedBoardOrVote.selected = [];
        } else {
          const indexOfSelectedInput = selectedBoardOrVote.selected.findIndex(
            (selectedInput) => selectedInput === inputId
          );

          if (indexOfSelectedInput !== -1) {
            selectedBoardOrVote.selected.splice(indexOfSelectedInput, 1);
          } else {
            selectedBoardOrVote.selected.push(inputId);
          }
        }
      } else {
        const boardOrVote =
          this.GetBoard(boardOrVoteId) || this.GetVote(boardOrVoteId);

        if (boardOrVote) {
          const newSelectedBoardOrVote: IBoardOrVoteSelected = {
            boardOrVote: boardOrVoteId,
            selected: [inputId],
          };

          this.state.selected.push(newSelectedBoardOrVote);
        }
      }
    } else {
      const indexOfSelectedBoardOrVote = this.state.selected.findIndex(
        (selected) => selected.boardOrVote === boardOrVoteId
      );

      if (indexOfSelectedBoardOrVote !== -1) {
        const selectedBoardOrVote =
          this.state.selected[indexOfSelectedBoardOrVote];
        if (clear) {
          selectedBoardOrVote.selected = [];
        }
        const indexOfSelectedInput = selectedBoardOrVote.selected.findIndex(
          (selectedInput) => selectedInput === inputId
        );

        if (clear) {
          // Perform clear action if needed
        }

        if (indexOfSelectedInput !== -1) {
          selectedBoardOrVote.selected.splice(indexOfSelectedInput, 1);
        } else {
          const input = this.GetInput(inputId);
          if (input) {
            selectedBoardOrVote.selected.push(inputId);
          }
        }
      } else {
        const boardOrVote =
          this.GetBoard(boardOrVoteId) || this.GetVote(boardOrVoteId);
        const input = this.GetInput(inputId);

        if (boardOrVote && input) {
          const newSelectedBoardOrVote: IBoardOrVoteSelected = {
            boardOrVote: boardOrVoteId,
            selected: [inputId],
          };

          this.state.selected.push(newSelectedBoardOrVote);
        }
      }
    }

    this.state.forceRender(this);
  }

  SelectGroup(boardId: string, groupId: string) {
    const HasBoard = this.state.selected.findIndex(
      (x) => x.boardOrVote === boardId
    );

    if (HasBoard !== -1) {
      const BOARD_SELECTED = this.state.selected[HasBoard];
      const inputs = this.GetInputs(groupId);

      if (inputs.length > 0) {
        let containsAll = BOARD_SELECTED.selected.some(
          (x) => x === inputs[0].id
        );
        for (let i = 0; i < inputs.length; i++) {
          const inputId = inputs[i].id;
          const IsSelected = BOARD_SELECTED.selected.findIndex(
            (x) => x === inputId
          );
          if (containsAll) {
            if (IsSelected === -1) {
              containsAll = false;
              const input = this.GetInput(inputId);
              if (input) {
                BOARD_SELECTED.selected.push(inputId);
              }
            }
          } else {
            if (IsSelected === -1) {
              const input = this.GetInput(inputId);
              if (input) {
                BOARD_SELECTED.selected.push(inputId);
              }
            }
          }
        }

        if (containsAll) {
          for (let i = 0; i < inputs.length; i++) {
            const inputId = inputs[i].id;
            const IsSelected = BOARD_SELECTED.selected.findIndex(
              (x) => x === inputId
            );

            if (IsSelected !== -1) {
              BOARD_SELECTED.selected.splice(IsSelected, 1);
            }
          }
        }
      }
    } else {
      const board = this.GetBoard(boardId);
      const inputs = this.GetInputs(groupId);

      if (board && inputs) {
        const selected: string[] = [];
        for (let i = 0; i < inputs.length; i++) {
          selected.push(inputs[i].id);
        }

        const BOARD_SELECTED: IBoardOrVoteSelected = {
          boardOrVote: boardId,
          selected: selected,
        };

        this.state.selected.push(BOARD_SELECTED);
      }
    }

    this.state.forceRender(this);
  }

  IsStackInputSelected(boardId: string): boolean {
    const boardIndex = this.getBoardIndex(boardId);
    const { stackGroupId, bufferedGroupId } = this.getGroupIds(boardId);

    if (boardIndex !== -1) {
      const selectedInputs = this.state.selected[boardIndex].selected;

      const inputs = this.filterInputs(
        'stack',
        boardId,
        stackGroupId,
        bufferedGroupId
      );

      if (selectedInputs.length === 0) {
        return false;
      }

      return selectedInputs.every((input) =>
        inputs.map((i) => i.id).includes(input)
      );
    }

    return false;
  }

  SelectAll(boardId: string, mode: 'all' | 'stack' | 'board' = 'all') {
    const boardIndex = this.getBoardIndex(boardId);
    const { stackGroupId, bufferedGroupId } = this.getGroupIds(boardId);
    const inputsToSelect = this.filterInputs(
      mode,
      boardId,
      stackGroupId,
      bufferedGroupId
    );

    if (boardIndex !== -1) {
      this.updateExistingSelected(boardIndex, inputsToSelect);
    } else {
      this.addNewSelected(boardId, inputsToSelect);
    }

    this.state.forceRender(this);
  }

  private getBoardIndex(boardId: string): number {
    return this.state.selected.findIndex((x) => x.boardOrVote === boardId);
  }

  private getGroupIds(boardId: string) {
    const groups = this.state.groups.filter((x) => x.boardId === boardId);
    return {
      stackGroupId: groups.find((x) => x.name === 'Stack')?.id,
      bufferedGroupId: groups.find((x) => x.name === 'Buffer')?.id,
    };
  }

  private filterInputs(
    mode: string,
    boardId: string,
    stackGroupId: string,
    bufferedGroupId: string
  ) {
    return this.state.inputs.filter((input) => {
      switch (mode) {
        case 'all':
          return input.boardId === boardId;
        case 'stack':
          return (
            input.boardId === boardId &&
            (input.groupId === stackGroupId ||
              input.groupId === bufferedGroupId)
          );
        case 'board':
          return (
            input.boardId === boardId &&
            input.groupId !== stackGroupId &&
            input.groupId !== bufferedGroupId
          );
        default:
          return false;
      }
    });
  }

  private updateExistingSelected(boardIndex: number, inputs: InputModel[]) {
    const selectedInputs = this.state.selected[boardIndex];
    selectedInputs.selected = inputs.map((input) => input.id);
    this.state.selected.push(selectedInputs);
  }

  private addNewSelected(boardId: string, inputs: InputModel[]) {
    const newSelected: IBoardOrVoteSelected = {
      boardOrVote: boardId,
      selected: inputs.map((input) => input.id),
    };
    this.state.selected.push(newSelected);
  }

  ClearSelected(boardId: string, isStack = false) {
    const stackGroupId = this.state.groups
      .filter((x) => x.name === 'Stack' && x.boardId === boardId)
      .at(0)?.id;
    const bufferedGroupId = this.state.groups
      .filter((x) => x.name === 'Buffer' && x.boardId === boardId)
      .at(0)?.id;

    const inputs = this.state.inputs.filter((x) =>
      isStack
        ? x.groupId === stackGroupId || x.groupId === bufferedGroupId
        : x.boardId === boardId
    );

    this.state.selected
      .filter((x) => x.boardOrVote === boardId)
      .forEach((x) => {
        if (isStack) {
          x.selected = x.selected.filter(
            (y) => !inputs.some((z) => z.id === y)
          );
        } else {
          x.selected = [];
        }
      });

    this.state.forceRender(this);
  }

  IsSelected(boardOrVoteId: string, inputId: string): boolean {
    const hasBoardOrVote = this.state.selected.findIndex(
      (x) => x.boardOrVote === boardOrVoteId
    );

    if (hasBoardOrVote !== -1) {
      const boardOrVoteSelected = this.state.selected[hasBoardOrVote];
      const isSelected = boardOrVoteSelected.selected.findIndex(
        (x) => x === inputId
      );

      if (isSelected !== -1) {
        return true;
      }
    }

    return false;
  }

  GetSelected(boardId: string) {
    const boardIdx = this.state.selected.findIndex(
      (x) => x.boardOrVote === boardId
    );

    let inputs: InputModel[] = [];

    if (boardIdx !== -1) {
      const selectedBoard = this.state.selected[boardIdx];
      inputs = [];

      for (let i = 0; i < selectedBoard.selected.length; i++) {
        const input = this.GetInput(selectedBoard.selected[i]);

        if (input) {
          inputs.push(input);
        } else {
          //TODO: Input Doesn't exist, shouldn't be Selected!
        }
      }

      return inputs;
    }

    return inputs;
  }

  AddCode(data: number) {
    if (data > 99999 && data < 1000000) {
      this.state.code = data;
      this.state.forceRender(this);
    }
  }

  AddPhase(data: PhaseModel) {
    const PHASES = this.state.phases;
    const INDEX = PHASES.findIndex((x) => x.id === data.id);

    if (INDEX !== -1) {
      PHASES[INDEX] = data;
    } else {
      PHASES.push(data);
    }
    PHASES.sort((a, b) => a.index - b.index);

    this.state.phases = PHASES;
    this.state.forceRender(this);
  }

  AddBoard(data: BoardModel) {
    if (!data) {
      return;
    }
    const BOARDS = this.state.boards;
    const INDEX = BOARDS.findIndex((x) => x.id === data.id);

    if (INDEX !== -1) {
      BOARDS[INDEX] = data;
    } else {
      BOARDS.push(data);
    }
    BOARDS.sort((a, b) => a.index - b.index);

    this.state.boards = BOARDS;
    this.state.forceRender(this);
  }

  RemoveBoard(data: BoardModel) {
    const BOARDS = this.state.boards;

    const INDEX = BOARDS.findIndex((x) => x.id === data.id);
    if (INDEX !== -1) {
      BOARDS.splice(INDEX, 1);
    }

    this.state.boards = BOARDS;

    this.state.forceRender(this);
  }

  AddGroup(data: GroupModel) {
    const GROUPS = this.state.groups;
    const INDEX = GROUPS.findIndex((x) => x.id === data.id);
    if (INDEX !== -1) {
      GROUPS[INDEX] = data;
    } else {
      GROUPS.push(data);
    }
    GROUPS.sort((a, b) => a.index - b.index);

    this.state.groups = GROUPS;
    this.state.forceRender(this);
  }

  AddGroups(data: GroupModel[]) {
    const GROUPS = this.state.groups;

    for (let I = 0; I < data.length; I++) {
      if (data[I].boardId === 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
        this.RemoveGroup(data[I]);
        continue;
      }

      const INDEX = GROUPS.findIndex((x) => x.id === data[I].id);
      if (INDEX !== -1) {
        GROUPS[INDEX] = data[I];
      } else {
        GROUPS.push(data[I]);
      }
    }
    this.state.groups = GROUPS;

    this.state.forceRender(this);
  }

  AddInput(data: InputModel) {
    const INPUTS = this.state.inputs;
    const INDEX = INPUTS.findIndex((x) => x.id === data.id);

    if (INDEX !== -1) {
      INPUTS[INDEX] = data;
    } else {
      INPUTS.push(data);
    }
    INPUTS.sort((a, b) => a.index - b.index);

    this.state.inputs = INPUTS;
    this.state.hasNewInputsInStack = true;
    this.state.forceRender(this);
  }

  AddInputs(data: InputModel[]) {
    const INPUTS = this.state.inputs;

    for (let I = 0; I < data.length; I++) {
      if (data[I].boardId === 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
        this.RemoveInput(data[I]);
        continue;
      }

      const INDEX = INPUTS.findIndex((x) => x.id === data[I].id);
      if (INDEX !== -1) {
        INPUTS[INDEX] = data[I];
      } else {
        INPUTS.push(data[I]);
      }
    }
    this.state.inputs = INPUTS;
    this.state.hasNewInputsInStack = true;
    this.state.forceRender(this);
  }

  AddVote(data: VoteModel) {
    const VOTES = this.state.votes;
    const INDEX = VOTES.findIndex((x) => x.id === data.id);

    if (INDEX !== -1) {
      VOTES[INDEX] = data;
    } else {
      VOTES.push(data);
    }
    VOTES.sort((a, b) => a.index - b.index);

    this.state.votes = VOTES;
    this.state.forceRender(this);
  }

  AddActionPlan(data: ActionPlanModel) {
    const actionPlans = this.state.actionPlans;
    const actionPlanIdx = actionPlans.findIndex((x) => x.id === data.id);

    if (actionPlanIdx !== -1) {
      actionPlans[actionPlanIdx] = data;
    } else {
      actionPlans.push(data);
    }

    this.state.actionPlans = actionPlans;
    this.state.forceRender(this);
  }

  RemoveVote(data: VoteModel) {
    const VOTES = this.state.votes;

    const INDEX = VOTES.findIndex((x) => x.id === data.id);
    if (INDEX !== -1) {
      VOTES.splice(INDEX, 1);
    }

    this.state.votes = VOTES;

    this.state.forceRender(this);
  }

  AddOption(data: OptionModel) {
    const OPTIONS = this.state.options;
    const INDEX = OPTIONS.findIndex((x) => x.id === data.id);

    if (INDEX !== -1) {
      OPTIONS[INDEX] = data;
    } else {
      OPTIONS.push(data);
    }

    this.state.options = OPTIONS;
    this.state.forceRender(this);
  }

  AddTicket(data: TicketModel) {
    const TICKETS = this.state.tickets;
    const INDEX = TICKETS.findIndex((x) => x.id === data.id);

    if (INDEX !== -1) {
      TICKETS[INDEX] = data;
    } else {
      TICKETS.push(data);
    }

    this.state.tickets = TICKETS;
    this.state.forceRender(this);
  }

  SetUserData(noActive: number, noTotal: number, nicks: Array<string>) {
    this.state.userData = {
      noActive,
      noTotal,
      nicks,
    };
    this.state.forceRender(this);
  }

  DragEnd = {
    input: {
      column: (
        data: { groupId: string; inputId: string }[],
        group: GroupModel
      ) => {
        const Models: InputModel[] = [];

        for (let i = 0; i < data.length; i++) {
          const Check = this.GetGroup(data[i].groupId);
          if (!Check) {
            alert('Original Group is null?');
            return;
          }

          const original = this.GetInputs(data[i].groupId);
          const subject = original.find((x) => x.id === data[i].inputId);
          if (!subject) {
            alert('Subject is not found!!!');
            return;
          }

          original.slice(subject.index, 1);
          if (
            Check.name !== 'Buffer' &&
            Check.name !== 'Stack' &&
            subject.index < original.length
          ) {
            for (let k = subject.index; k < original.length; k++) {
              //SHOULD MAKE SURE THIS ISN'T A ALREADY MOVED INPUT
              const moved = data.findIndex((x) => x.inputId === original[k].id);

              if (moved !== -1 && moved < i) {
                continue;
              }

              if (Models.includes(original[k])) {
                const index = Models.findIndex((x) => x.id === original[k].id);
                Models[index].index -= 1;
              } else {
                original[k].index -= 1;
                Models.push(original[k]);
              }
            }
          }

          subject.index = i;
          subject.groupId = group.id;
          Models.push(subject);
          if (original.length === 0) {
            const Remove = this.GetGroup(data[i].groupId);
            if (Remove) {
              this.RemoveGroup(Remove);
            }
          }
        }

        this.AddGroup(group);
        this.AddInputs(Models);
      },
      internal: (group: string, input: string, targetIndex: number) => {
        const inputs = this.GetInputs(group);
        const Models: InputModel[] = [];

        const subject = inputs.find((x) => x.id === input);
        if (!subject || subject.id !== inputs[subject.index].id) {
          alert('Subject is not Indexed correctly!!!');
          return;
        }

        inputs.slice(subject.index, 1);

        if (subject.index > targetIndex) {
          for (let i = targetIndex; i < subject.index; i++) {
            inputs[i].index += 1;
            Models.push(inputs[i]);
          }
        } else {
          for (let i = subject.index; i < targetIndex; i++) {
            inputs[i].index -= 1;
            Models.push(inputs[i]);
          }
        }
        subject.index = targetIndex;
        Models.push(subject);
        this.AddInputs(Models);
      },
      external_single: (
        groupOG: string,
        input: string,
        targetGroup: string,
        targetIndex: number
      ) => {
        const check = this.GetGroup(groupOG);
        if (!check) {
          alert('Original Group is null?');
          return;
        }
        const original = this.GetInputs(groupOG);
        const subject = original.find((x) => x.id === input);
        if (!subject || subject.id !== original[subject.index].id) {
          alert('Subject is not Indexed correctly!!!');
          return;
        }

        const Models: InputModel[] = [];
        original.slice(subject.index, 1);

        if (
          check.name !== 'Buffer' &&
          check.name !== 'Stack' &&
          subject.index < original.length
        ) {
          for (let i = subject.index; i < original.length; i++) {
            original[i].index -= 1;
            Models.push(original[i]);
          }
        }

        const target = this.GetInputs(targetGroup);
        if (targetIndex > target.length) {
          alert('TargetIndex too big!');
          return;
        }

        if (targetIndex < target.length) {
          for (let i = targetIndex; i < target.length; i++) {
            target[i].index += 1;
            Models.push(target[i]);
          }
        }
        subject.index = targetIndex;
        subject.groupId = targetGroup;
        Models.push(subject);
        this.AddInputs(Models);
      },
      external: (
        data: { groupId: string; inputId: string }[],
        targetGroup: string,
        targetIndex: number
      ) => {
        const Models: InputModel[] = [];

        // Move from stack to buffer - special case
        if (data.length === 1) {
          const from = this.GetGroup(data[0].groupId);
          const to = this.GetGroup(targetGroup);

          if (from?.name === 'Stack' && to?.name === 'Buffer') {
            const bufferInputs = this.GetInputs(to.id);
            const index = bufferInputs.length + 1;
            const input = this.GetInput(data[0].inputId)!;

            input.index = index;
            input.groupId = targetGroup;

            this.AddInputs([input]);
            return;
          }
        }

        // Else

        for (let i = 0; i < data.length; i++) {
          const Check = this.GetGroup(data[i].groupId);
          if (!Check) {
            alert('Original Group is null?');
            return;
          }

          const original = this.GetInputs(data[i].groupId);
          const subject = original.find((x) => x.id === data[i].inputId);
          if (!subject) {
            alert('Subject is not found!!!');
            return;
          }

          original.slice(subject.index, 1);
          if (
            Check.name !== 'Buffer' &&
            Check.name !== 'Stack' &&
            subject.index < original.length
          ) {
            for (let k = subject.index; k < original.length; k++) {
              const moved = data.findIndex((x) => x.inputId === original[k].id);

              if (moved !== -1 && moved < i) {
                continue;
              }

              if (Models.includes(original[k])) {
                const index = Models.findIndex((x) => x.id === original[k].id);
                Models[index].index -= 1;
              } else {
                original[k].index -= 1;
                Models.push(original[k]);
              }
            }
          }

          subject.index = targetIndex + i;
          subject.groupId = targetGroup;
          Models.push(subject);
        }

        const target = this.GetInputs(targetGroup);
        if (targetIndex > target.length) {
          alert('TargetIndex too big!');
          return;
        }

        if (targetIndex < target.length) {
          for (let i = targetIndex; i < target.length; i++) {
            target[i].index += data.length;
            Models.push(target[i]);
          }
        }

        this.AddInputs(Models);
      },
    },
    group: {
      internal: (group: string, index: number) => {
        const Group = this.GetGroup(group);
        if (!Group) {
          return;
        }
        const Groups = this.GetGroups(Group.boardId).filter(
          (x) => x.column === Group.column
        );
        if (!Groups) {
          return;
        }
        Groups.sort((a, b) => a.index - b.index);
        const Models: GroupModel[] = [];

        Groups.splice(Group.index, 1);

        if (Group.index > index) {
          for (let i = index; i < Group.index; i++) {
            Groups[i].index += 1;
            Models.push(Groups[i]);
          }
        } else {
          for (let i = Group.index; i < index; i++) {
            Groups[i].index -= 1;
            Models.push(Groups[i]);
          }
        }
        Group.index = index;
        Models.push(Group);
      },
      external: (group: string, column: number, index: number) => {
        const Group = this.GetGroup(group);
        if (!Group) {
          return;
        }
        const Groups = this.GetGroups(Group.boardId).filter(
          (x) => x.column === Group.column
        );
        if (!Groups) {
          return;
        }
        const Column = this.GetGroups(Group.boardId).filter(
          (x) => x.column === column
        );

        Column.sort((a, b) => a.index - b.index);
        Groups.sort((a, b) => a.index - b.index);
        const Models: GroupModel[] = [];

        Groups.splice(Group.index, 1);

        if (Group.index < Groups.length) {
          for (let i = Group.index; i < Groups.length; i++) {
            Groups[i].index -= 1;
            Models.push(Groups[i]);
          }
        }

        if (Column.length < index) {
          alert('Index too large');
          return;
        }

        if (index < Column.length) {
          for (let i = index; i < Column.length; i++) {
            Column[i].index += 1;
            Models.push(Column[i]);
          }
        }

        Group.column = column;
        Group.index = index;
        Models.push(Group);

        this.AddGroups(Models);
      },
    },
  };

  RemoveData(
    type: 'phase' | 'board' | 'group' | 'input' | 'vote' | 'option' | 'ticket',
    id: string
  ) {
    const STATE = this.state;
    switch (type) {
      case 'phase': {
        const INDEX = STATE.phases.findIndex((x) => x.id === id);

        if (INDEX !== -1) {
          STATE.phases.splice(INDEX, 1);
        }
        break;
      }
      case 'board': {
        const INDEX = STATE.boards.findIndex((x) => x.id === id);

        if (INDEX !== -1) {
          STATE.boards.splice(INDEX, 1);
        }
        break;
      }
      case 'group': {
        const INDEX = STATE.groups.findIndex((x) => x.id === id);

        if (INDEX !== -1) {
          STATE.groups.splice(INDEX, 1);
        }
        break;
      }
      case 'input': {
        const INDEX = STATE.inputs.findIndex((x) => x.id === id);

        if (INDEX !== -1) {
          STATE.inputs.splice(INDEX, 1);
        }
        break;
      }
      case 'vote': {
        const INDEX = STATE.votes.findIndex((x) => x.id === id);

        if (INDEX !== -1) {
          STATE.votes.splice(INDEX, 1);
        }
        break;
      }
      case 'option': {
        const INDEX = STATE.options.findIndex((x) => x.id === id);

        if (INDEX !== -1) {
          STATE.options.splice(INDEX, 1);
        }
        break;
      }
      case 'ticket': {
        const INDEX = STATE.tickets.findIndex((x) => x.id === id);

        if (INDEX !== -1) {
          STATE.tickets.splice(INDEX, 1);
        }
        break;
      }
    }

    this.state = STATE;
  }

  RemoveGroup(data: GroupModel) {
    const GROUPS = this.state.groups;

    const INDEX = GROUPS.findIndex((x) => x.id === data.id);
    if (INDEX !== -1) {
      GROUPS.splice(INDEX, 1);
    }

    this.state.groups = GROUPS;

    this.state.forceRender(this);
  }

  RemoveGroups(data: GroupModel[]) {
    const GROUPS = this.state.groups;

    for (let I = 0; I < data.length; I++) {
      if (data[I].boardId !== 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
        this.AddGroup(data[I]);
        continue;
      }

      const INDEX = GROUPS.findIndex((x) => x.id === data[I].id);
      if (INDEX !== -1) {
        GROUPS.splice(INDEX, 1);
      }
    }
    this.state.groups = GROUPS;

    this.state.forceRender(this);
  }

  RemoveInput(data: InputModel) {
    const INPUTS = this.state.inputs;

    const INDEX = INPUTS.findIndex((x) => x.id === data.id);
    if (INDEX !== -1) {
      INPUTS.splice(INDEX, 1);
    }

    this.state.inputs = INPUTS;

    this.state.forceRender(this);
  }

  RemoveInputs(data: InputModel[]) {
    const INPUTS = this.state.inputs;

    for (let I = 0; I < data.length; I++) {
      if (data[I].boardId !== 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
        this.AddInput(data[I]);
        continue;
      }

      const INDEX = INPUTS.findIndex((x) => x.id === data[I].id);
      if (INDEX !== -1) {
        INPUTS.splice(INDEX, 1);
      }
    }
    this.state.inputs = INPUTS;

    this.state.forceRender(this);
  }

  GetPhases(): Array<PhaseModel> {
    return this.state.phases.sort((a, b) => a.index - b.index);
  }

  GetPhase(id: string) {
    return this.state.phases.find((x) => x.id === id);
  }

  GetBoards(phaseId: string): Array<BoardModel> {
    return this.state.boards
      .filter((x) => x.phaseId === phaseId)
      .sort((a, b) => a.index - b.index);
  }

  GetBoard(id: string) {
    return this.state.boards.find((x) => x.id === id);
  }

  GetBoardContent(boardId: string): IBoardContent {
    const groups = this.state.groups.filter((x) => x.boardId === boardId);
    groups.sort((a, b) =>
      a.column - b.column !== 0 ? a.column - b.column : a.index - b.index
    );

    const STACK_INDEX = groups.findIndex((x) => x.name === 'Stack');
    const BUFFER_INDEX = groups.findIndex((x) => x.name === 'Buffer');

    let stack: GroupModel | undefined;
    let buffer: GroupModel | undefined;

    if (STACK_INDEX !== -1) {
      stack = groups[STACK_INDEX];
    }

    if (BUFFER_INDEX !== -1) {
      buffer = groups[BUFFER_INDEX];
    }

    const columns: Array<Array<GroupModel>> = [];

    for (let i = 0; i < groups.length; i++) {
      if (i === STACK_INDEX || i === BUFFER_INDEX) {
        continue;
      }

      while (columns.length < groups[i].column) {
        const COLUMN: Array<GroupModel> = [];
        columns.push(COLUMN);
      }

      if (columns.length === groups[i].column) {
        const COLUMN: Array<GroupModel> = [];
        COLUMN.push(groups[i]);
        columns.push(COLUMN);
      } else {
        columns[groups[i].column].push(groups[i]);
      }
    }

    const content: IBoardContent = {
      columns: columns,
      stack: stack,
      buffer: buffer,
    };
    return content;
  }

  GetGroups(boardId: string): Array<GroupModel> {
    return this.state.groups.filter((x) => x.boardId === boardId);
  }

  GetGroup(id: string) {
    return this.state.groups.find((x) => x.id === id);
  }

  GetInputs(groupId?: string): Array<InputModel> {
    const INPUTS = this.state.inputs.filter((x) => x.groupId === groupId);
    INPUTS.sort((a, b) => a.index - b.index);
    return INPUTS;
  }

  GetInput(id: string) {
    return this.state.inputs.find((x) => x.id === id);
  }

  GetContributions(boardId: string, userId: string) {
    const INPUTS = this.state.inputs.filter((x) => x.boardId === boardId);
    return INPUTS.filter((x) => x.userId === userId);
  }

  GetVotes(phaseId: string): Array<VoteModel> {
    return this.state.votes.filter((x) => x.phaseId === phaseId);
  }

  GetActionPlansByPhaseId(phaseId: string): Array<ActionPlanModel> {
    return this.state.actionPlans.filter((x) => x.phaseId === phaseId);
  }

  GetVote(id: string) {
    return this.state.votes.find((x) => x.id === id);
  }

  GetActionPlan(id: string) {
    return this.state.actionPlans.find((x) => x.id === id);
  }

  GetVoteContent(voteId: string) {
    const OPTIONS = this.GetOptions(voteId);
    const TICKETS = this.GetTickets(voteId);

    const Vote = this.GetVote(voteId);

    if (Vote) {
      if (Vote.type === 'points') {
        for (let k = 0; k < OPTIONS.length; k++) {
          OPTIONS[k].total = 0;
        }

        for (let i = 0; i < TICKETS.length; i++) {
          for (let j = 0; j < TICKETS[i].ratings.length; j++) {
            const Index = OPTIONS.findIndex(
              (x) => x.id === TICKETS[i].ratings[j].key
            );

            if (Index !== -1) {
              OPTIONS[Index].total += TICKETS[i].ratings[j].value;
            }
          }
        }
      } else if (Vote.type === 'slider') {
        for (let k = 0; k < OPTIONS.length; k++) {
          OPTIONS[k].averageRating = 0;
        }

        for (let i = 0; i < TICKETS.length; i++) {
          for (let j = 0; j < TICKETS[i].ratings.length; j++) {
            const Index = OPTIONS.findIndex(
              (x) => x.id === TICKETS[i].ratings[j].key
            );

            if (Index !== -1) {
              OPTIONS[Index].averageRating += TICKETS[i].ratings[j].value;
            }
          }
        }

        for (let k = 0; k < OPTIONS.length; k++) {
          OPTIONS[k].averageRating =
            Math.floor((OPTIONS[k].averageRating / TICKETS.length) * 10) / 10;
        }
      }
    }

    const CONTENT: IVoteContent = {
      options: OPTIONS,
      tickets: TICKETS,
    };
    return CONTENT;
  }

  GetOptions(voteId: string): Array<OptionModel> {
    return this.state.options.filter((x) => x.voteId === voteId);
  }

  GetOption(id: string) {
    return this.state.options.find((x) => x.id === id);
  }

  GetTickets(voteId: string): Array<TicketModel> {
    return this.state.tickets.filter((x) => x.voteId === voteId);
  }

  GetMyTicket(voteId: string, userId: string) {
    return this.GetTickets(voteId).find((x) => x.userId === userId);
  }

  GetTicket(id: string) {
    return this.state.tickets.find((x) => x.id === id);
  }

  GetUserData() {
    return this.state.userData;
  }

  ClearNewInputNotification() {
    this.state.hasNewInputsInStack = false;
    this.state.forceRender(this);
  }

  ForceRender() {
    this.state.forceRender(this);
  }
}
