import { Component } from 'react';
import { Typography, styled } from '@mui/material';

interface IProps {
  top: number;
  sum: number;
  interval: number;
  showPercent: boolean;
}

interface IDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active?: boolean;
}

const Container = styled(
  ({ active, ...divProps }: IDiv) => <div {...divProps} />,
  { shouldForwardProp: (prop) => prop !== 'active' }
)<IDiv>(({ theme }) => ({
  width: '100%',
  height: 'calc(100% - 30px)',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '0',
  left: '0',
  borderBottom: `1px solid ${theme.palette.text.disabled}`,
}));

interface IBar
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active?: boolean;
}
const Bar = styled(({ active, ...divProps }: IBar) => <div {...divProps} />, {
  shouldForwardProp: (prop) => prop !== 'active',
})<IBar>(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  flexGrow: 1,
  alignItems: 'center',
}));

interface ITransparent
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active?: boolean;
}
const Transparent = styled(
  ({ active, ...divProps }: ITransparent) => <div {...divProps} />,
  { shouldForwardProp: (prop) => prop !== 'active' }
)<ITransparent>(({ theme }) => ({
  height: '100%',
  borderLeft: `1px solid ${theme.palette.text.primary}`,
  borderRight: `1px solid ${theme.palette.text.primary}`,
  borderTop: `1px solid ${theme.palette.text.disabled}`,
  flexGrow: 1,
}));

interface ILine
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active?: boolean;
}
const Line = styled(({ active, ...divProps }: ILine) => <div {...divProps} />, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ILine>(({ theme }) => ({
  height: '1px',
  background: theme.palette.text.primary,
  width: '100%',
  position: 'relative',
  top: '50%',
}));

export class Background extends Component<IProps> {
  render() {
    const top = this.props.top;
    const lineHeights = [];
    for (let i = top; i > 0; i -= this.props.interval) {
      lineHeights.push(i);
    }
    return (
      <div
        style={{
          position: 'absolute',
          height: 'calc(100% - 28px)',
          width: '100%',
          borderLeft: '2px solid #000',
          borderBottom: '2px solid #000',
        }}
      >
        {lineHeights.map((number) => (
          <div
            key={number}
            style={{
              borderTop: '1px dashed #aaa',
              display: 'relative',
              height: 100 / lineHeights.length + '%',
            }}
          >
            <div
              style={{
                position: 'relative',
                transform: 'translateY(-50%)',
                left: '-60px',
                width: '50px',
                textAlign: 'right',
              }}
            >
              {this.props.showPercent ? this.calculatePercent(number) : number}
            </div>
          </div>
        ))}
      </div>
    );
  }

  calculatePercent(number: number) {
    if (this.props.sum === 0) {
      return '';
    }

    const percent = Math.round((number / this.props.sum) * 100);
    return percent <= 100 ? percent + ' %' : '';
  }

  render2() {
    return (
      <Container>
        <Bar>
          <Typography variant="body2" sx={{ width: '20px' }}>
            {Math.floor(this.props.top / 10) * 10}
          </Typography>

          <Transparent>
            <Line />
          </Transparent>
        </Bar>
        <Bar>
          <Typography variant="body2" sx={{ width: '20px' }}>
            {Math.floor(this.props.top / 10) * 8}
          </Typography>

          <Transparent>
            <Line />
          </Transparent>
        </Bar>
        <Bar>
          <Typography variant="body2" sx={{ width: '20px' }}>
            {Math.floor(this.props.top / 10) * 6}
          </Typography>

          <Transparent>
            <Line />
          </Transparent>
        </Bar>
        <Bar>
          <Typography variant="body2" sx={{ width: '20px' }}>
            {Math.floor(this.props.top / 10) * 4}
          </Typography>

          <Transparent>
            <Line />
          </Transparent>
        </Bar>
        <Bar>
          <Typography variant="body2" sx={{ width: '20px' }}>
            {Math.floor(this.props.top / 10) * 2}
          </Typography>

          <Transparent>
            <Line />
          </Transparent>
        </Bar>

        <Bar style={{ backgroundColor: '#0ff' }}>
          <Typography variant="body2" sx={{ width: '20px' }}>
            {Math.floor(this.props.top / 10) * 1}
          </Typography>

          <Transparent>
            <Line />
          </Transparent>
        </Bar>
      </Container>
    );
  }
}
