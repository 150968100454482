import 'bootstrap/dist/css/bootstrap.css';
// import { StrictMode } from 'react'; // TODO: re-enable strict mode
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";

import App from './App';

Sentry.init({
    dsn: "https://e28639839b1a4dca8d57d4074b4f9771@o4505237980053504.ingest.sentry.io/4505237981429760",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  

const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

root.render(<App />);
