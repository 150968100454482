import { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  Fade,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

interface IProps {
  onClose: () => void;
  onUpdate: (input: { title: string; description: string; id: string }) => void;
  open: boolean;
  input: {
    title: string;
    description: string;
    id: string;
  };
}

export const EditInputDialog = ({
  onClose,
  onUpdate,
  open,
  input: { title, description, id },
}: IProps) => {
  const theme = useTheme();

  const [input, setInput] = useState({
    title,
    description,
  });

  const isTitleRequired = description?.length > 60 && !title;

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const NAME = e.currentTarget.name;
    const VALUE = e.currentTarget.value;

    if (NAME === 'title') {
      setInput({ title: VALUE, description: input.description || null });
    } else if (NAME === 'description') {
      setInput({ title: input.title, description: VALUE });
    }
  };

  return (
    <Dialog
      aria-describedby="edit-input-body"
      aria-labelledby="edit-input-title"
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      closeAfterTransition
      onClose={onClose}
      open={open}
    >
      <Fade in={open} timeout={500}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
            border: '2px solid #555',
            boxShadow: theme.shadows[5],
            padding: '20px 20px',
            minWidth: '450px',
          }}
        >
          <Box
            style={{
              // marginTop: '16px',
              display: 'flex',
              flexDirection: 'column',
              gap: '25px',
              // padding: '20px',
            }}
          >
            <Box>
              <Typography variant="h4">Edit proposal</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0px',
              }}
            >
              <label htmlFor="title">Title</label>
              <TextField
                InputLabelProps={{ required: false }}
                name="title"
                onChange={handleChange}
                placeholder={
                  isTitleRequired ? 'Title here...' : 'Optional title here...'
                }
                inputProps={{ maxLength: 60 }}
                required={input.description?.length > 60}
                autoComplete="off"
                type="text"
                variant="standard"
                value={input.title}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0px',
              }}
            >
              <label htmlFor="description">Description</label>
              <TextField
                InputLabelProps={{ required: false }}
                multiline
                name="description"
                onChange={handleChange}
                placeholder="Write your contribution here..."
                required
                minRows={6}
                rows={6}
                autoFocus
                sx={{
                  paddingBottom: '1em',
                  marginTop: '10px',
                }}
                autoComplete="off"
                type="text"
                value={input.description || ''}
                variant="filled"
                inputProps={{ style: { fontSize: 15 } }}
              />
            </Box>
            <Button
              onClick={() => onUpdate({ ...input, id })}
              sx={{
                color: theme.palette.text.primary,
                outline: 'none !important',
                backgroundColor: `${theme.palette.primary.light}`,
              }}
              type="button"
            >
              <Typography variant="h6">Update</Typography>
            </Button>
          </Box>
        </Box>
      </Fade>
    </Dialog>
  );
};
