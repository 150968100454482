import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { BrowserView, MobileView } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { z } from 'zod';

const schema = z
  .object({
    code: z
      .string({
        required_error: 'Code is required',
      })
      .min(1, { message: 'Code is required' }),
    email: z
      .string({
        required_error: 'Email is required',
      })
      .email('Must be a valid email')
      .min(1, { message: 'Email is required' }),
    password: z
      .string({
        required_error: 'Password is required',
      })
      .min(8, { message: 'Password must be atleast 6 characters' }),
    confirmPassword: z
      .string({
        required_error: 'Please confirm your password',
      })
      .min(1, { message: 'Confirm Password is required' }),
    firstName: z
      .string({
        required_error: 'Firstname is required',
      })
      .min(1, { message: 'Firstname is required' }),
    lastName: z
      .string({
        required_error: 'Lastname is required',
      })
      .min(1, { message: 'Lastname is required' }),
    phone: z
      .string({
        required_error: 'Phone is required',
      })
      .refine<string>(isMobilePhone, {
        message: 'Must be a valid phone number',
      }),
    company: z
      .string({
        required_error: 'Company name is required',
      })
      .min(3, { message: 'Company name is required' }),
    terms: z.literal(true, {
      errorMap: () => ({ message: 'You must accept Terms and Conditions' }),
    }),
  })
  .required()
  .refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
    message: "Password don't match",
  });

export type SignupFormData = z.infer<typeof schema>;

type SignupFormProps = {
  onSignup: (data: SignupFormData) => void;
  onCancel: () => void;
};

export const SignupForm = ({ onSignup, onCancel }: SignupFormProps) => {
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupFormData>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data: SignupFormData) => {
    const code = data.code;

    if (code !== '95236') {
      alert('Invalid code, please contact the administrator.');
      return;
    }

    onSignup(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Code */}
      <Controller
        control={control}
        name="code"
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            id="register-code"
            label="Access Code"
            sx={{ marginBottom: '20px' }}
          />
        )}
      />
      <Typography
        sx={{
          color: 'red',
        }}
      >
        {errors.code?.message}
      </Typography>
      <br />
      {/* Email */}
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <TextField
            {...field}
            autoComplete="email"
            fullWidth
            id="register-email"
            label="Email"
            sx={{ marginBottom: '20px' }}
          />
        )}
      />
      <Typography
        sx={{
          color: 'red',
        }}
      >
        {errors.email?.message}
      </Typography>
      <br />

      {/* Password + Repeat Password*/}
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <TextField
            {...field}
            autoComplete="off"
            fullWidth
            id="register-password"
            inputProps={{ minLength: '8' }}
            label="Password"
            sx={{ marginBottom: '20px' }}
            type="password"
          />
        )}
      />
      <Typography
        sx={{
          color: 'red',
        }}
      >
        {errors.password?.message}
      </Typography>

      <br />
      <Controller
        control={control}
        name="confirmPassword"
        render={({ field }) => (
          <TextField
            {...field}
            autoComplete="off"
            fullWidth
            sx={{ marginBottom: '20px' }}
            id="register-repeat-password"
            inputProps={{ minLength: '8' }}
            label="Repeat Password"
            type="password"
          />
        )}
      />
      <Typography
        sx={{
          color: 'red',
        }}
      >
        {errors.root?.message}
      </Typography>

      <br />
      {/* First & Last Name */}
      <Controller
        control={control}
        name="firstName"
        render={({ field }) => (
          <TextField
            {...field}
            autoComplete="given-name"
            fullWidth
            id="register-firstName"
            sx={{ marginBottom: '20px' }}
            label="First Name"
          />
        )}
      />

      <Typography
        sx={{
          color: 'red',
        }}
      >
        {errors.firstName?.message}
      </Typography>

      <br />
      <Controller
        control={control}
        name="lastName"
        render={({ field }) => (
          <TextField
            {...field}
            autoComplete="family-name"
            fullWidth
            id="register-lastName"
            sx={{ marginBottom: '20px' }}
            label="Last Name"
          />
        )}
      />
      <Typography
        sx={{
          color: 'red',
        }}
      >
        {errors.lastName?.message}
      </Typography>

      <br />

      {/* Phone Number */}
      <Controller
        control={control}
        name="phone"
        render={({ field }) => (
          <TextField
            {...field}
            autoComplete="tel-national"
            fullWidth
            id="register-phone"
            sx={{ marginBottom: '20px' }}
            label="Phone Number"
            type="tel"
          />
        )}
      />
      <Typography
        sx={{
          color: 'red',
        }}
      >
        {errors.phone?.message.toString()}
      </Typography>

      <br />

      {/* Company */}
      <Controller
        control={control}
        name="company"
        render={({ field }) => (
          <TextField
            {...field}
            autoComplete="organization"
            fullWidth
            id="register-company"
            sx={{
              marginBottom: '20px',
            }}
            label="Company Name"
          />
        )}
      />

      <Typography
        sx={{
          color: 'red',
        }}
      >
        {errors.company?.message}
      </Typography>
      <br />
      {/* Checkbox to confirm Email
       * TODO: Create Link to Full Agreement, in New Tab!
       * */}
      <Box sx={{ marginTop: '5px' }}>
        <Controller
          control={control}
          name="terms"
          render={({ field }) => (
            <Checkbox {...field} id="register-beta-agreement" />
          )}
        />

        <Typography
          component="label"
          id="register-beta-text"
          style={{
            transform: 'translateY(50%)',
            color: errors.terms ? 'red' : 'inherit',
          }}
        >
          I agree and consent to the
          <br />
          <a href="/terms" target="_blank">
            Beta Participation Agreement
          </a>
        </Typography>
      </Box>
      <br />

      <MobileView>
        <Button
          sx={{
            marginTop: '16px',
            float: 'left',
            borderRadius: '1em',
            minWidth: '40%',
            padding: '1em',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            outline: 'none !important',
          }}
          id="register-submit"
          onClick={onCancel}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          sx={{
            marginTop: '16px',
            float: 'right',
            borderRadius: '1em',
            minWidth: '40%',
            padding: '1em',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            outline: 'none !important',
          }}
          id="register-submit"
          type="submit"
          variant="contained"
        >
          Sign Up
        </Button>
      </MobileView>

      <BrowserView>
        <Button
          sx={{
            marginTop: '16px',
            display: 'block',
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)',
            borderRadius: '1em',
            minWidth: '50%',
            padding: '1em',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            outline: 'none !important',
          }}
          id="register-submit"
          type="submit"
          variant="contained"
        >
          Sign Up
        </Button>
      </BrowserView>
    </form>
  );
};
