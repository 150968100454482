export enum Events {
  INPUT_DELETED = 'input_deleted',
  INITIAL_RENAME = 'initial_rename',
  PHASE_ADDED = 'phase_added',
}

export const eventEmitter = {
  _events: new Map<string, any>(),
  dispatch(event: Events, data: any) {
    if (!this._events.get(event)) {
      return;
    }
    this._events
      .get(event)
      .forEach((callback: (data: any) => void) => callback(data));
  },
  subscribe(event: Events, callback: (data: any) => any) {
    if (!this._events.get(event)) {
      this._events.set(event, []);
    }
    this._events.get(event).push(callback);
  },
  unsubscribe(event: Events) {
    if (!this._events.get(event)) {
      return;
    }
    this._events.delete(event);
  },
};
