import { useMemo } from 'react';
import { styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';

import { type ActionPlanModel } from '../../../Models';

type ActionPlanResultProps = {
  contentItem: {
    type: 'actionPlan';
    actionPlan: ActionPlanModel;
  };
  isThumbnail?: boolean;
};

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  padding: '10px 10px 20px 10px',
  overflowY: 'scroll',
  height: '100%',
});

const Results = styled('div')({
  height: '100%',
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '20px',
  border: '1px solid #e0e0e0',
  borderRadius: '8px',
  backgroundColor: '#f7f7f7',
});

const Title = styled(Typography)({
  marginTop: '15px',
  textAlign: 'center',
  borderBottom: '1px solid #e0e0e0',
  paddingBottom: '10px',
});

const Description = styled(Typography)({
  textAlign: 'center',
  margin: '20px 10%',
  borderBottom: '1px solid #e0e0e0',
  paddingBottom: '10px',
});

const SectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '10px',
  border: '1px solid #e0e0e0',
  borderRadius: '6px',
  backgroundColor: '#fff',
});

const UnderlinedTypography = styled(Typography)({
  textDecoration: 'underline',
  marginBottom: '10px',
});

const AssigneeContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '2px',
});

const Section = ({ title, children }) => (
  <SectionContainer>
    <UnderlinedTypography variant="subtitle2">{title}</UnderlinedTypography>
    <div>{children}</div>
  </SectionContainer>
);

export function ActionPlanResult({
  // dataHandler,
  contentItem,
  isThumbnail,
}: ActionPlanResultProps) {
  const actionPlan = contentItem.actionPlan;

  const dueDate = useMemo(
    () =>
      DateTime.fromISO(actionPlan.dueDate)
        .setLocale('no')
        .toLocaleString(DateTime.DATE_FULL),
    [actionPlan.dueDate]
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '25px',
        textAlign: 'center',
        width: isThumbnail ? '1000px' : 'inherit',
        transform: isThumbnail
          ? 'scale(0.4) translateX(-800px) translateY(-80%)'
          : 'scale(1)',
      }}
    >
      <Container>
        <Results>
          <Title variant="h2">{actionPlan.title}</Title>
          <Description variant="body1">{actionPlan.description}</Description>

          <Section title="Due date:">
            <Typography variant="body1">{dueDate}</Typography>
          </Section>

          <Section title="Items">
            {actionPlan.items.map((item, idx) => (
              <Typography key={item} variant="body1">
                {idx + 1}. {item}
              </Typography>
            ))}
          </Section>

          <Section title="Assignees:">
            {actionPlan.assignees.map((assignee, idx) => (
              <AssigneeContainer key={assignee.email}>
                <Typography variant="body1">
                  {idx + 1}. {assignee.fullname}
                </Typography>
                <Typography variant="body1">({assignee.email})</Typography>
              </AssigneeContainer>
            ))}
          </Section>
        </Results>
      </Container>
    </div>
  );
}
