import { Component, useEffect } from 'react';
import { type DragStart } from '@hello-pangea/dnd';
import { styled } from '@mui/material';
import { isMobile } from 'react-device-detect';

import {
  convert,
  eventEmitter,
  Events,
  getCookie,
  listToTree,
  removeDeleted,
} from '@utils';
import { GroupController, SessionController } from '../../Controllers';
import {
  Handler as DataHandler,
  IsBoardContent,
  ServerSentEventHandler,
  type IBoardContent,
  type IVoteContent,
} from '../../Data';
import {
  type ActionPlanModel,
  type BoardModel,
  type BoardVote,
  type InputModel,
  type OptionModel,
  type PhaseModel,
  type VoteModel,
  type VoteModelWithHasVotes,
} from '../../Models';
import { TooltipView, type IMenuItem } from '../Components/General';
import { SideNav } from '../Components/Navigation';
import { withRouter, type WithRouterProps } from '../WithRouter';
import { MiddlePanel } from './MiddlePanel';
import { RightPanel } from './RightPanel';

type ObserverProps = {
  values: any[]; // assuming any type of array for simplicity
};

const Observer: React.FC<ObserverProps> = (props) => {
  useEffect(() => {
    const style = document.getElementById('user-data')?.style;
    style.setProperty('font-size', '25px');
    style.setProperty('color', '#17a2b8');

    setTimeout(() => {
      style.setProperty('font-size', 'inherit');
      style.setProperty('color', 'rgba(0, 0, 0, 0.54)');
    }, 500);
  }, props.values);

  return null;
};

const AdminContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  margin: 0,
  padding: 0,
  background: theme.palette.background.default,
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: '0',
  width: '100%',
}));

const PublicContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  margin: 0,
  padding: 0,
  background: theme.palette.primary.main,
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: '0',
  width: '100%',
}));

interface IProps {}

interface IState {
  dataHandler: DataHandler | null;
  eventHandler: ServerSentEventHandler | null;
  collapse: {
    navigation: boolean;
    boards: Record<string, boolean>;
  };
  creationDateInUnix: number;
  id: string;
  lastOpenedInUnix: number;
  name: string;
  ownerId: string;
  active: {
    type: 'board' | 'vote' | 'actionplan' | 'result' | '';
    identity: string;
  };
  anchor: {
    toolbar_add: null | Element;
    toolbar_vote: null | Element;
    pinMenu?: Element | null;
  };
  hideCollapsed: boolean;
  rightSide: '' | 'participate' | 'slider' | 'points' | 'refine' | 'actionplan';
  dragType: 'input' | 'group';
  showOwnVote: boolean;
  activeSettings: any;
  showNicks: boolean;
  pinnedInputs: Array<string>;
  pinMenu: any;
}

const NewBoardListener = ({
  loadBoard,
}: {
  loadBoard: (id: string) => void;
}) => {
  useEffect(() => {
    eventEmitter.subscribe(Events.PHASE_ADDED, (board) => {
      loadBoard(board.id);
    });

    return () => {
      eventEmitter.unsubscribe(Events.PHASE_ADDED);
    };
  }, [loadBoard]);
  return null;
};

class SessionViewComponent extends Component<WithRouterProps<IProps>, IState> {
  constructor(props: WithRouterProps<IProps>) {
    super(props);

    const boardCollapseString = localStorage.getItem('boardCollapse') ?? '{}';
    const boardCollapse = JSON.parse(boardCollapseString);

    this.state = {
      dataHandler: null,
      eventHandler: null,
      collapse: {
        navigation: false,
        boards: boardCollapse,
      },
      active: {
        type: '',
        identity: '',
      },
      anchor: {
        toolbar_add: null,
        toolbar_vote: null,
        pinMenu: null,
      },
      hideCollapsed: false,
      rightSide: '',
      creationDateInUnix: 0,
      lastOpenedInUnix: 0,
      name: '',
      id: '',
      ownerId: '',
      dragType: 'input',
      showOwnVote: false,
      activeSettings: null,
      showNicks: true,
      pinnedInputs: [],
      pinMenu: this.pinMenu,
    };
  }

  componentDidMount() {
    this.connect();
    document.addEventListener('keydown', (ev: KeyboardEvent) => {
      const tagName = (ev.target as HTMLElement).tagName.toUpperCase();
      if (
        tagName.toUpperCase() !== 'INPUT' &&
        tagName.toUpperCase() !== 'TEXTAREA'
      ) {
        if (ev.key === '1') {
          this.drawer.Left.collapse();
        }
      }
    });
  }

  componentWillUnmount(): void {
    this.state.eventHandler?.close();
  }

  loadActive = (type: 'vote' | 'board' | 'actionplan', id: string) => {
    if (!type) {
      return;
    }

    const active = this.state.active;
    active.type = type;
    active.identity = id;

    this.setState({ active });
  };

  async connect() {
    const params = this.props.params;
    const sessionId = params.session;

    const session = await SessionController.connect(sessionId);

    if (session === null || session === undefined) {
      alert("Couldn't Connect to Session");
      return;
    }

    if (session.id === sessionId) {
      //TODO: Have Session Return Access Rights

      const DATA = new DataHandler({
        creationDateInUnix: session.creationDateInUnix,
        id: session.id,
        lastOpenedInUnix: session.lastOpenedInUnix,
        name: session.name,
        ownerId: session.ownerId,
        forceRender: this.forceRender,
        userIsOwner: this.userIsOwner(),
      });

      await DATA.GetData();

      const SSE = new ServerSentEventHandler({
        creationDateInUnix: session.creationDateInUnix,
        id: session.id,
        lastOpenedInUnix: session.lastOpenedInUnix,
        name: session.name,
        ownerId: session.ownerId,
        dataHandler: DATA,
        loadActive: this.loadActive,
      });

      SSE.open();
      this.setState({
        creationDateInUnix: session.creationDateInUnix,
        lastOpenedInUnix: session.lastOpenedInUnix,
        name: session.name,
        id: session.id,
        ownerId: session.ownerId,
        dataHandler: DATA,
        eventHandler: SSE,
      });

      this.loadActive(session.activeType, session.activeId);

      if (DATA.state.boards.length === 0 && DATA.state.votes.length === 0) {
        DATA.NewPhaseBoard('Board');

        eventEmitter.subscribe(Events.PHASE_ADDED, (board) => {
          this.loadBoard(board.id);
          eventEmitter.dispatch(Events.INITIAL_RENAME, {});
        });
      }
    }
  }

  forceRender = (handler: DataHandler) => {
    this.setState({ dataHandler: handler });
  };

  drawer = {
    Left: {
      collapse: () => {
        if (!this.userIsOwner()) {
          return;
        }
        this.setState({
          collapse: {
            navigation: !this.state.collapse.navigation,
            boards: this.state.collapse.boards,
          },
        });
      },
    },
    Right: {
      Participate: () => {
        if (this.state.rightSide === 'participate') {
          this.setState({
            rightSide: '',
            anchor: { toolbar_add: null, toolbar_vote: null },
          });
        } else {
          this.setState({
            rightSide: 'participate',
            anchor: { toolbar_add: null, toolbar_vote: null },
          });
        }
      },
      Poll: (type: 'slider' | 'points') => {
        if (!this.userIsOwner()) {
          return;
        }

        const resetAnchor = { toolbar_add: null, toolbar_vote: null };
        const newRightSide = this.state.rightSide === type ? '' : type;

        this.setState({
          rightSide: newRightSide,
          anchor: resetAnchor,
        });
      },
      Refine: () => {
        if (!this.userIsOwner()) {
          return;
        }

        if (this.state.rightSide === 'refine') {
          this.setState({
            rightSide: '',
            anchor: { toolbar_add: null, toolbar_vote: null },
          });
        } else {
          this.setState({ rightSide: 'refine' });
        }
      },
      ActionPlan: () => {
        if (!this.userIsOwner()) {
          return;
        }

        const resetAnchor = { toolbar_add: null, toolbar_vote: null };
        const newRightSide =
          this.state.rightSide === 'actionplan' ? '' : 'actionplan';

        this.setState({
          rightSide: newRightSide,
          anchor: resetAnchor,
        });
      },
      Close: () => {
        this.setState({ rightSide: '' });
      },
    },
  };

  addGroup = async () => {
    if (!this.userIsOwner()) {
      return;
    }
    const active = this.state.active;
    const handler = this.state.dataHandler;
    if (active.type !== 'board' || !handler) {
      return;
    }

    const board = handler.GetBoard(active.identity);
    if (!board) {
      return;
    }

    const groups = handler.GetGroups(board.id);
    if (!groups || groups.length < 2) {
      return;
    }

    await GroupController.create(
      this.state.id,
      board.phaseId,
      board.id,
      `Group ${groups.length - 1}`,
      0,
      false
    );
  };

  loadBoard = (id: string) => {
    if (!this.userIsOwner()) {
      return;
    }
    const active = this.state.active;
    active.type = 'board';
    active.identity = id;

    this.setState({ active });

    const handler = this.state.dataHandler;
    if (!handler) {
      return;
    }

    const board = handler.GetBoard(active.identity);
    if (!board) {
      return;
    }

    SessionController.ActivateBoard(this.state.id, board.phaseId, board.id);

    handler.ClearSelected(active.identity);

    this.drawer.Right.Close();
  };

  loadVote = (id: string) => {
    if (!this.userIsOwner()) {
      return;
    }

    const active = this.state.active;
    active.type = 'vote';
    active.identity = id;

    this.setState({ active });

    const handler = this.state.dataHandler;
    if (!handler) {
      return;
    }

    const vote = handler.GetVote(active.identity);
    if (!vote) {
      return;
    }
    const board = handler.GetBoard(vote.boardId);
    if (!board) {
      return;
    }

    SessionController.ActivateVote(
      this.state.id,
      board.phaseId,
      vote.boardId,
      vote.id
    );

    handler.ClearSelected(active.identity);

    this.drawer.Right.Close();
  };

  loadActionPlan = (id: string) => {
    if (!this.userIsOwner()) {
      return;
    }

    const active = this.state.active;
    active.type = 'actionplan';
    active.identity = id;

    this.setState({ active });

    const handler = this.state.dataHandler;
    if (!handler) {
      return;
    }

    const actionPlan = handler.GetActionPlan(active.identity);

    if (!actionPlan) {
      return;
    }

    SessionController.ActivateActionPlan(
      this.state.id,
      actionPlan.phaseId,
      actionPlan.id
    );

    handler.ClearSelected(active.identity);

    this.drawer.Right.Close();
  };

  settings = (id: string) => {
    if (!this.userIsOwner()) {
      return;
    }

    const handler = this.state.dataHandler;
    if (!handler) {
      return;
    }

    const active = this.state.active;

    let parentType;
    let parentId: string;
    const activeSettings = { id } as {
      id: string;
      title?: string;
      description?: string;
      lastUpdateUnix?: number;
      enableInputChange: boolean;
      highNumber?: number;
      lowNumber?: number;
    };

    let voteType: 'slider' | 'points' | undefined;

    const inputs = [] as (OptionModel | InputModel)[];

    if (active.type === 'board') {
      const board = handler.GetBoard(active.identity)!;
      handler.GetGroups(board.id).forEach((group) => {
        handler.GetInputs(group.id).forEach((input) => inputs.push(input));
      });

      parentType = board?.parentType;
      parentId = board?.parentId;

      activeSettings.title = board?.title;
      activeSettings.description = board?.description;
      activeSettings.lastUpdateUnix = board?.lastUpdateUnix;
      activeSettings.enableInputChange = !board?.hasNewInput;
    } else if (active.type === 'vote') {
      const vote = handler.GetVote(active.identity)!;
      handler.GetVoteContent(vote.id).options.forEach((option) => {
        inputs.push(option);
      });

      parentType = vote?.parentType;
      parentId = vote?.parentId;
      voteType = vote?.type;

      activeSettings.title = vote?.title;
      activeSettings.description = vote?.description;
      activeSettings.lastUpdateUnix = vote?.lastUpdateUnix;
      activeSettings.highNumber = vote?.highNumber;
      activeSettings.lowNumber = vote?.lowNumber;

      const tickets = handler.GetTickets(vote.id);
      activeSettings.enableInputChange = tickets.length === 0;
    }

    if (parentType === 'board') {
      const parentBoard = handler.GetBoard(parentId)!;

      SessionController.ActivateBoard(
        this.state.id,
        parentBoard.phaseId,
        parentBoard.id
      );

      const parentInputsMatching = [] as InputModel[];
      handler.GetGroups(parentBoard?.id).forEach((group) => {
        handler.GetInputs(group.id).forEach((parentInput) => {
          if (handler.IsSelected(parentId, parentInput.id)) {
            handler.Select(parentId, parentInput.id);
          }

          const found = inputs.find(
            (input) =>
              input.title === parentInput.title &&
              input.description === parentInput.description
          );

          if (found) {
            parentInputsMatching.push(parentInput);
          }
        });
      });

      parentInputsMatching.forEach((input) => {
        if (!handler.IsSelected(parentId, input.id)) {
          handler.Select(parentId, input.id);
        }
      });
    } else if (parentType === 'vote') {
      const parentVote = handler.GetVote(parentId)!;
      const parentBoard = handler.GetBoard(parentVote.boardId)!;

      SessionController.ActivateVote(
        this.state.id,
        parentBoard.phaseId,
        parentVote.boardId,
        parentVote.id
      );

      const parentInputsMatching = [] as InputModel[];

      handler.GetGroups(parentBoard?.id).forEach((group) => {
        handler.GetInputs(group.id).forEach((parentInput) => {
          const found = inputs.find(
            (input) =>
              input.title === parentInput.title &&
              input.description === parentInput.description
          );

          if (found) {
            parentInputsMatching.push(parentInput);
          }
        });
      });

      parentInputsMatching.forEach((input) => {
        if (!handler.IsSelected(parentId, input.id)) {
          handler.Select(parentId, input.id);
        }
      });
    }

    if (active.type === 'board') {
      this.drawer.Right.Refine();
    } else if (active.type === 'vote') {
      this.drawer.Right.Poll(voteType);
    }

    this.setState({
      activeSettings: activeSettings,
    });
  };

  fullscreen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  menu = {
    items: {
      Toolbar_Add: [
        { text: 'Input', callback: this.drawer.Right.Participate },
        { text: 'Group', callback: this.addGroup },
      ],
      Toolbar_Vote: [
        { text: 'Slider', callback: () => this.drawer.Right.Poll('slider') },
        { text: 'Points', callback: () => this.drawer.Right.Poll('points') },
      ],
    },
    anchor: {
      Toolbar_Add: (
        event:
          | React.MouseEvent<HTMLAnchorElement, MouseEvent>
          | React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        const anchor = event.currentTarget;

        this.setState({ anchor: { toolbar_add: anchor, toolbar_vote: null } });
      },
      Toolbar_Vote: (
        event:
          | React.MouseEvent<HTMLAnchorElement, MouseEvent>
          | React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        const anchor = event.currentTarget;

        this.setState({ anchor: { toolbar_add: null, toolbar_vote: anchor } });
      },
      close: () => {
        this.setState({ anchor: { toolbar_add: null, toolbar_vote: null } });
      },
    },
  };

  onDragStart = (initial: DragStart) => {
    if (initial.type === 'input') {
      this.setState({ dragType: 'input' });
    } else if (initial.type === 'group') {
      this.setState({ dragType: 'group' });
    }
  };

  inviteClipboard = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();

    //TODO: Change this to Domain Link
    const LINK = `https://coboost.no/s/${this.state.id}`;
    if (navigator.clipboard === undefined) {
      alert(
        "Unfortunatley this doesn't work without a HTTPS connection\nHTTPS is disabled on this server, but needs to be enabled before this gets released to the public\nIn the meantime you can copy it from your adressbar(and later for that matter)\n\nP.S. That toolbar needs to be placed somewhere better, got any ideas?"
      );
      return;
    }
    navigator.clipboard.writeText(LINK);
  };

  getElements = (phase: PhaseModel) => {
    const handler = this.state.dataHandler;
    const boards = handler.GetBoards(phase.id);
    const votes = handler.GetVotes(phase.id).map((vote) => {
      const tickets = handler.GetTickets(vote.id);
      // vote.hasVotes = tickets.length > 0;
      const extendedVote: VoteModelWithHasVotes = {
        ...vote,
        hasVotes: tickets.length > 0,
      };
      return extendedVote;
    });
    const actionPlans = handler.GetActionPlansByPhaseId(phase.id);

    let boardsAndVotes = [...boards, ...votes, ...actionPlans];
    for (let i = 0; i < 100; i++) {
      const result = removeDeleted(boardsAndVotes);

      boardsAndVotes = result.elements;
      if (!result.hasIgnored) {
        break;
      }
    }

    const tree = listToTree(
      this.state.collapse.boards,
      boardsAndVotes.sort((a, b) => a.lastUpdateUnix - b.lastUpdateUnix)
    );

    return convert(tree, 0);
  };

  getResultElements = (phase: PhaseModel) => {
    const handler = this.state.dataHandler;
    const boards = handler.GetBoards(phase.id);
    const votes = handler.GetVotes(phase.id).map((vote) => {
      const tickets = handler.GetTickets(vote.id);
      // vote.hasVotes = tickets.length > 0;
      const extendedVote: VoteModelWithHasVotes = {
        ...vote,
        hasVotes: tickets.length > 0,
      };
      return extendedVote;
    });
    const actionPlans = handler.GetActionPlansByPhaseId(phase.id);

    let boardsAndVotes = [...boards, ...votes, ...actionPlans];
    for (let i = 0; i < 100; i++) {
      const result = removeDeleted(boardsAndVotes);

      boardsAndVotes = result.elements;
      if (!result.hasIgnored) {
        break;
      }
    }

    const tree = listToTree(
      this.state.collapse.boards,
      boardsAndVotes.sort((a, b) => a.lastUpdateUnix - b.lastUpdateUnix),
      true
    );

    return convert(tree, 0);
  };

  pinMenu: Array<IMenuItem> = [
    {
      text: 'Select pinned inputs',
      callback: (e) => {
        e.stopPropagation();

        this.setState({
          anchor: {
            ...this.state.anchor,
            pinMenu: null,
          },
        });

        this.state.dataHandler?.ClearSelected(this.state.active.identity);
        this.state.pinnedInputs.forEach(
          (inputId) =>
            this.state.dataHandler?.Select(
              this.state.active.identity,
              inputId,
              false
            )
        );
      },
      disable: () => {
        return this.state.pinnedInputs.length === 0;
      },
    },
    {
      text: 'Clear pins',
      callback: (e) => {
        e.stopPropagation();

        this.setState({
          pinMenu: this.pinConfirmMenu,
        });
      },
      disable: () => {
        return this.state.pinnedInputs.length === 0;
      },
    },
    {
      text: 'Pin selected items',
      callback: (e) => {
        e.stopPropagation();

        const selected = this.state.dataHandler?.GetSelected(
          this.state.active.identity
        );

        this.setState({
          anchor: {
            ...this.state.anchor,
            pinMenu: null,
          },
          pinnedInputs: [
            ...this.state.pinnedInputs,
            ...selected
              ?.filter((s) => !this.state.pinnedInputs.includes(s.id))
              .map((s) => s.id)!,
          ],
        });
        this.state.dataHandler?.ClearSelected(this.state.active.identity);
      },
      disable: () => {
        const selected = this.state.dataHandler?.GetSelected(
          this.state.active.identity
        );
        return selected?.length === 0;
      },
    },
    {
      text: 'Unpin selected items',
      callback: (e) => {
        e.stopPropagation();

        const selected = this.state.dataHandler?.GetSelected(
          this.state.active.identity
        );

        this.setState({
          anchor: {
            ...this.state.anchor,
            pinMenu: null,
          },
          pinnedInputs: this.state.pinnedInputs.filter(
            (p) => selected?.filter((s) => s.id === p).length === 0
          ),
        });
        this.state.dataHandler?.ClearSelected(this.state.active.identity);
      },
      disable: () => {
        const selected = this.state.dataHandler?.GetSelected(
          this.state.active.identity
        );
        return selected?.length === 0;
      },
    },
  ];

  pinConfirmMenu: Array<IMenuItem> = [
    {
      text: 'Confirm clear pins',
      callback: (e) => {
        e.stopPropagation();
        this.setState({
          pinnedInputs: [],
          pinMenu: this.pinMenu,
          anchor: {
            ...this.state.anchor,
            pinMenu: null,
          },
        });
      },
    },
  ];

  userIsOwner() {
    return this.state.ownerId === getCookie('UserId');
  }

  isShowingSettings() {
    return !!this.state.activeSettings;
  }

  onNavItemClick = (element: BoardVote) => {
    if (!element) {
      console.log('element is null');
      return;
    }

    const isVote = 'hideVotes' in element;
    const isBoard = 'hasNewInput' in element;

    this.setState({ activeSettings: null });
    if (!element.isDeleted) {
      if (isVote) {
        this.loadVote(element.id);
      } else if (isBoard) {
        this.loadBoard(element.id);
      } else {
        this.loadActionPlan(element.id);
      }

      // get boards not in phase
      const phases = this.state.dataHandler
        ?.GetPhases()
        .filter((phase) => phase.id !== element.phaseId);

      // get boards in phases
      const boards = phases?.flatMap(
        (phase) => this.state.dataHandler?.GetBoards(phase.id)
      );

      this.setState((state) => {
        state.collapse.boards[element.id] = false;

        Object.keys(state.collapse.boards).forEach((key) => {
          for (const board of boards) {
            if (board.id === key) {
              state.collapse.boards[key] = true;
            }
          }
        });

        localStorage.setItem(
          'boardCollapse',
          JSON.stringify(this.state.collapse.boards)
        );
        return state;
      });
    }
  };

  createNewPhaseBoard = () => {
    const handler = this.state.dataHandler;

    handler.NewPhaseBoard('untitled');
  };

  onToggleCollapse = (id: string) => {
    this.setState((state) => {
      state.collapse.boards[id] = !state.collapse.boards[id];
      localStorage.setItem(
        'boardCollapse',
        JSON.stringify(this.state.collapse.boards)
      );
      return state;
    });
  };

  render() {
    const handler: DataHandler | null = this.state.dataHandler;

    if (handler) {
      const active = this.state.active;

      let hasOwnVote = false;

      let Display: VoteModel | BoardModel | ActionPlanModel | undefined;
      let Content: IBoardContent | IVoteContent | undefined;

      if (active.type === 'board') {
        Display = handler.GetBoard(active.identity);
        if (Display) {
          Content = handler.GetBoardContent(Display.id);
        }
      } else if (active.type === 'actionplan') {
        Display = handler.GetActionPlan(active.identity);
        // if (Display) {
        //   Content = handler.GetBoardContent(Display.id);
        // }
      } else if (active.type === 'vote') {
        Display = handler.GetVote(active.identity);
        if (Display) {
          Content = handler.GetVoteContent(Display.id);

          const voteContent = Content;
          const voteModel = Display;
          const userId = getCookie('UserId');
          const tickets = voteContent.tickets.filter(
            (ticket) =>
              ticket.voteId === voteModel.id && ticket.userId === userId
          );

          const userRatings = {};

          if (tickets.length > 0) {
            tickets[0].ratings.forEach((rating) => {
              userRatings[rating.key] = rating.value;
            });
          }
          hasOwnVote = Object.keys(userRatings).length > 0;
        }
      }

      const hasBoards =
        handler
          .GetPhases()
          .flatMap((el) => handler.GetBoards(el.id))
          .filter((b) => !b.isDeleted).length > 0;

      let hasInputs = false;
      handler
        .GetPhases()
        .flatMap((el) => handler.GetBoards(el.id))
        .forEach((b) =>
          handler.GetGroups(b.id).forEach((g) => {
            if (handler.GetInputs(g.id).length > 0) {
              hasInputs = true;
            }
          })
        );

      return (
        <>
          {this.userIsOwner() ? (
            <AdminContainer>
              {!isMobile && (
                <SideNav
                  isCollapsed={this.state.collapse.navigation}
                  isOwner={this.userIsOwner()}
                  hasBoards={hasBoards}
                  hasInputs={hasInputs}
                  phases={handler.GetPhases()}
                  noOfPhases={handler.GetPhases().length}
                  sessionName={this.state.name}
                  sessionId={this.state.id}
                  activeSettings={this.state.activeSettings}
                  active={active}
                  onLeftDrawerCollapse={this.drawer.Left.collapse}
                  onSettingsClick={(element) => {
                    if (!element) {
                      console.log('element is null');
                      return;
                    }

                    const isVote = 'hideVotes' in element;
                    const isBoard = 'hasNewInput' in element;

                    if (isVote) {
                      this.loadVote(element.id);
                    } else if (isBoard) {
                      this.loadBoard(element.id);
                    } else {
                      this.loadActionPlan(element.id);
                    }

                    this.settings(element.id);
                  }}
                  onNavItemClick={this.onNavItemClick}
                  onNewBoardClick={this.createNewPhaseBoard}
                  onResultClick={() => {
                    if (!this.userIsOwner()) {
                      return;
                    }
                    this.setState({ activeSettings: null });

                    const ACTIVE = this.state.active;
                    ACTIVE.type = 'result';
                    ACTIVE.identity = '';
                    this.setState({ active: ACTIVE });

                    this.drawer.Right.Close();
                  }}
                  isNavItemCollapsed={(id) => {
                    return this.state.collapse.boards
                      ? !!this.state.collapse.boards[id]
                      : false;
                  }}
                  onToggleCollapse={this.onToggleCollapse}
                  getElements={this.getElements}
                />
              )}
              {!isMobile && (
                <MiddlePanel
                  sessionId={this.state.id}
                  view={Display}
                  activeType={active.type}
                  activeId={active.identity}
                  isCollapsed={this.state.rightSide === ''}
                  dragType={this.state.dragType}
                  handler={handler}
                  content={Content}
                  userIsOwner={this.userIsOwner()}
                  showNicks={this.state.showNicks}
                  showOwnVote={this.state.showOwnVote}
                  collapse={this.state.collapse}
                  hasOwnVote={hasOwnVote}
                  anchor={this.state.anchor}
                  onOpenActionPlan={this.drawer.Right.ActionPlan}
                  onOpenParticipate={this.drawer.Right.Participate}
                  onOpenRefine={this.drawer.Right.Refine}
                  fullscreen={this.fullscreen}
                  hideCollapsed={this.state.hideCollapsed}
                  inviteClipboard={this.inviteClipboard}
                  isShowingSettings={this.isShowingSettings()}
                  menu={this.menu}
                  pinMenu={this.state.pinMenu}
                  pinnedInputs={this.state.pinnedInputs}
                  resultViewTree={handler
                    .GetPhases()
                    .map((phase) => this.getResultElements(phase))}
                  onDragStart={this.onDragStart}
                  onPinClick={(e) => {
                    e.stopPropagation();

                    this.setState({
                      anchor: {
                        ...this.state.anchor,
                        pinMenu: e.currentTarget,
                      },
                    });
                  }}
                  onPinMenuClick={(event) => {
                    event.stopPropagation();
                    this.setState({
                      pinMenu: this.pinMenu,
                      anchor: {
                        ...this.state.anchor,
                        pinMenu: null,
                      },
                    });
                  }}
                  onSetPin={(value, inputId) => {
                    if (value) {
                      this.setState({
                        pinnedInputs: [...this.state.pinnedInputs, inputId],
                      });
                      const isSelected = handler.IsSelected(
                        this.state.active.identity,
                        inputId
                      );
                      if (isSelected) {
                        handler.Select(
                          this.state.active.identity,
                          inputId,
                          false
                        );
                      }
                    } else {
                      this.setState({
                        pinnedInputs: this.state.pinnedInputs.filter(
                          (i) => i !== inputId
                        ),
                      });
                    }
                  }}
                  onShowNicks={() => {
                    this.setState({
                      showNicks: !this.state.showNicks,
                    });
                  }}
                  onShowOwnVote={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => {
                    event.stopPropagation();
                    this.setState({
                      showOwnVote: checked,
                    });
                  }}
                />
              )}
              <RightPanel
                sessionId={this.state.id}
                isSessionOwner={this.userIsOwner()}
                activeId={this.state.active.identity}
                handler={handler}
                isCollapsed={this.state.rightSide === ''}
                panelKind={this.state.rightSide}
                activeType={active.type}
                view={Display}
                groupId={
                  active.type === 'board' && Content && IsBoardContent(Content)
                    ? Content.stack.id
                    : undefined
                }
                activeSettings={this.state.activeSettings}
                lastUpdateUnix={
                  this.state.activeSettings
                    ? this.state.activeSettings.lastUpdateUnix
                    : 0
                }
                loadVote={this.loadVote}
                loadBoard={this.loadBoard}
                loadActionPlan={this.loadActionPlan}
                onClose={(clear) => {
                  if (clear) {
                    this.setState({
                      activeSettings: null,
                    });
                  }

                  this.drawer.Right.Close();
                }}
              />

              <TooltipView title="Active users / Total users">
                <div
                  id="user-data"
                  style={{
                    display: isMobile ? 'none' : 'block',
                    position: 'fixed',
                    right: '25px',
                    bottom: '5px',
                    textAlign: 'center',
                    color: 'rgba(0, 0, 0, 0.54)',
                    transition: 'all 0.2s ease-in-out',
                  }}
                >
                  <div className="notranslate">
                    <b>{handler.GetUserData().noActive}</b>/
                    {handler.GetUserData().noTotal}
                  </div>
                  <span
                    style={{
                      fontSize: '45px',
                      position: 'relative',
                      top: '-5px',
                      borderRadius: '50%',
                      width: '45px',
                      height: '45px',
                      boxShadow: '0 0 10px 10px rgb(41 47 64 / 15%)',
                      opacity: '1',
                    }}
                    className="notranslate material-icons"
                  >
                    person2
                  </span>
                </div>
              </TooltipView>
              <Observer
                values={[
                  handler.GetUserData().noActive,
                  handler.GetUserData().noTotal,
                ]}
              />
              <NewBoardListener loadBoard={this.loadBoard} />
              <i
                style={{
                  position: 'fixed',
                  left: '15px',
                  bottom: '15px',
                  opacity: 0.5,
                }}
              >
                Admin access
              </i>
            </AdminContainer>
          ) : (
            <PublicContainer>
              <RightPanel
                sessionId={this.state.id}
                isSessionOwner={this.userIsOwner()}
                activeId={this.state.active.identity}
                handler={handler}
                isCollapsed={this.state.rightSide === ''}
                panelKind={this.state.rightSide}
                activeType={active.type}
                view={Display}
                groupId={
                  active.type === 'board' && Content && IsBoardContent(Content)
                    ? Content.stack.id
                    : undefined
                }
                activeSettings={this.state.activeSettings}
                lastUpdateUnix={
                  this.state.activeSettings
                    ? this.state.activeSettings.lastUpdateUnix
                    : 0
                }
                loadVote={this.loadVote}
                loadBoard={this.loadBoard}
                loadActionPlan={this.loadActionPlan}
                onClose={(clear) => {
                  if (clear) {
                    this.setState({
                      activeSettings: null,
                    });
                  }

                  this.drawer.Right.Close();
                }}
              />
            </PublicContainer>
          )}
        </>
      );
    } else {
      return <PublicContainer>Loading Session...</PublicContainer>;
    }
  }
}

export const SessionView = withRouter(SessionViewComponent);
