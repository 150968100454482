import { Component } from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';

import {
  BoardController,
  OptionController,
  VoteController,
} from '../../../Controllers';
import { type Handler } from '../../../Data';
import { type InputModel } from '../../../Models';
import { Theme } from '../../../Theme';
import { PrimaryButton, TextButton } from '../General';

//TODO: Add check to see what kind of vote it is
//TODO: Add Button Styles

interface IProps {
  type: 'slider' | 'points';
  selectedItems?: InputModel[];
  onClose: () => void;
  onRemove: (input: string) => void;
  loadVote: (id: string) => void;
  session: string;
  phase: string;
  board: string;
  vote: string;
  isLocked: boolean;
  activeType: string;
  handler: Handler;
  activeSettings: any;
  lastUpdateUnix: number;
}

interface IState {
  min: number | string;
  max: number | string;
  minDescription: string;
  maxDescription: string;
  title?: string;
  defaultTitle: string;
  description: string;
  settings: boolean;
  time: number;
}

export class Ballot extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const sliderTitle = 'Slider vote';
    const pointTitle = 'Point vote';
    const defaultTitle =
      this.props.type === 'points' ? pointTitle : sliderTitle;

    const highestNumber = Math.max(
      ...this.props.handler
        .GetVotes(this.props.phase)
        .filter((vote) => !vote.isDeleted)
        .map((vote) => vote.title)
        .filter((title) => title.startsWith(defaultTitle))
        .map((title) => parseInt(title.replace(defaultTitle, '')))
        .map((number) => (isNaN(number) ? 0 : number))
    );

    this.state = {
      min: this.props.activeSettings
        ? this.props.activeSettings.lowNumber
        : this.props.type === 'points'
        ? 3
        : 1,
      minDescription: 'Least important',
      max: this.props.activeSettings ? this.props.activeSettings.highNumber : 5,
      maxDescription: 'Most important',
      time: 0,
      title: this.props.activeSettings ? this.props.activeSettings.title : null,
      defaultTitle: `${defaultTitle}${
        highestNumber >= 0 ? ' ' + (highestNumber + 1) : ''
      }`,
      description: this.props.activeSettings
        ? this.props.activeSettings.description
        : null,
      settings: true,
    };
  }

  getSnapshotBeforeUpdate(prevProps: IProps) {
    if (prevProps.type !== this.props.type) {
      this.setState({
        min: this.props.type === 'points' ? 3 : 1,
      });
    }
  }

  handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const Name = event.currentTarget.name;
    const Value = event.currentTarget.value;

    this.setState({
      ...this.state,
      [Name]: Value,
    });
  };

  onSubmit = (
    event:
      | React.MouseEvent<HTMLButtonElement | MouseEvent>
      | React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    this.createVote();
  };

  async createVote() {
    const {
      activeSettings,
      loadVote,
      selectedItems,
      session: sessionId,
      phase: phaseId,
      board: boardId,
      vote: voteId,
      type,
      activeType,
      isLocked,
      onClose,
      lastUpdateUnix,
    } = this.props;
    const {
      title,
      description,
      min,
      max,
      minDescription,
      maxDescription,
      defaultTitle,
    } = this.state;

    if (activeSettings && !activeSettings.enableInputChange) {
      // Only update name and description
      await VoteController.rename(sessionId, activeSettings.id, title);

      await VoteController.description(
        sessionId,
        activeSettings.id,
        description
      );

      loadVote(activeSettings.id);
    }

    if (selectedItems && selectedItems.length < 2) {
      return;
    }

    if (activeSettings?.enableInputChange) {
      VoteController.delete(sessionId, phaseId, boardId, activeSettings.id);
    }

    const createdVote = await VoteController.create(
      sessionId,
      phaseId,
      boardId,
      type,
      title != null ? title : defaultTitle,
      description,
      parseInt(min.toString()),
      minDescription,
      parseInt(max.toString()),
      maxDescription,
      false,
      activeType === 'vote' ? voteId : boardId,
      activeType,
      activeSettings ? lastUpdateUnix : 0
    );

    if (createdVote && selectedItems) {
      for (const selectedItem of selectedItems) {
        await OptionController.create(
          sessionId,
          phaseId,
          boardId,
          createdVote.id,
          selectedItem.id,
          selectedItem.title,
          selectedItem.description
        );
      }
    }

    onClose();

    if (!isLocked) {
      if (activeType === 'vote') {
        VoteController.lockData(sessionId, phaseId, boardId, voteId);
      } else if (activeType === 'board') {
        BoardController.lock(sessionId, phaseId, boardId);
      }
    }

    if (createdVote?.id) {
      loadVote(createdVote?.id);
    }
  }

  toggleSettings = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    this.setState({ settings: !this.state.settings });
  };

  getTitle = () => {
    const sliderTitle = 'Slider vote';
    const pointTitle = 'Point vote';
    const defaultTitle =
      this.props.type === 'points' ? pointTitle : sliderTitle;

    if (
      this.state.title === null ||
      this.state.title === sliderTitle ||
      this.state.title === pointTitle
    ) {
      return defaultTitle;
    }
    return this.state.title;
  };

  render() {
    const { onClose, type, activeSettings, selectedItems, onRemove } =
      this.props;

    const {
      settings,
      title,
      defaultTitle,
      description,
      max,
      maxDescription,
      min,
      minDescription,
    } = this.state;

    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ marginTop: '10px' }}>
          <form onSubmit={this.onSubmit}>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: '1.25em', paddingLeft: '20px' }}
            >
              Voting {type}
            </Typography>

            <Typography variant="body2" style={{ margin: '15px' }}>
              Provide the settings for the vote session and click&nbsp;"
              {activeSettings ? 'Update' : 'Create'}
              &nbsp;vote&nbsp;session"
            </Typography>

            <div
              style={{
                padding: '10px 15px',
                border: `1px solid #ddd`,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                }}
              >
                <Typography variant="subtitle2">Vote settings</Typography>
                <IconButton onChange={this.toggleSettings}>
                  {settings ? (
                    <span
                      className="notranslate material-icons-outlined"
                      style={{ fontSize: '18px' }}
                    >
                      expand_less
                    </span>
                  ) : (
                    <span
                      className="notranslate material-icons-outlined"
                      style={{ fontSize: '18px' }}
                    >
                      expand_more
                    </span>
                  )}
                </IconButton>
              </Box>

              <div style={{ padding: '10px' }}>
                <TextField
                  InputLabelProps={{ required: false }}
                  label="Title"
                  name="title"
                  onChange={this.handleChange}
                  size="small"
                  type="text"
                  fullWidth
                  required
                  value={title != null ? title : defaultTitle}
                  variant="outlined"
                />
              </div>

              {type === 'points' &&
                (activeSettings == null ||
                  activeSettings.enableInputChange) && (
                  <>
                    <div style={{ padding: '10px' }}>
                      <div>
                        <TextField
                          InputLabelProps={{ required: false }}
                          name="max"
                          onChange={this.handleChange}
                          required
                          size="small"
                          type="tel"
                          value={max}
                          variant="outlined"
                          label="Total Points"
                        />
                      </div>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <div>
                        <TextField
                          InputLabelProps={{ required: false }}
                          name="min"
                          onChange={this.handleChange}
                          required
                          size="small"
                          type="tel"
                          value={min}
                          variant="outlined"
                          label="Single Option Maximum"
                        />
                      </div>
                    </div>
                  </>
                )}
              {type === 'slider' &&
                (activeSettings == null ||
                  activeSettings.enableInputChange) && (
                  <>
                    <div style={{ padding: '10px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '20px',
                          alignItems: 'center',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        <TextField
                          InputLabelProps={{ required: false }}
                          name="min"
                          onChange={this.handleChange}
                          required
                          sx={{
                            width: '5em',
                            height: '3em',
                            lineHeight: '3em',
                            textAlign: 'center',
                          }}
                          size="small"
                          type="tel"
                          value={min}
                          variant="outlined"
                          label="Min.value"
                        />

                        <TextField
                          InputLabelProps={{ required: false }}
                          name="minDescription"
                          onChange={this.handleChange}
                          required
                          sx={{
                            width: '11em',
                            height: '3em',
                            lineHeight: '3em',
                            textAlign: 'center',
                          }}
                          size="small"
                          type="text"
                          value={minDescription}
                          variant="outlined"
                          label="Minimum text"
                        />
                      </div>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '20px',
                          alignItems: 'center',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        <TextField
                          InputLabelProps={{ required: false }}
                          name="max"
                          onChange={this.handleChange}
                          required
                          size="small"
                          sx={{
                            width: '5em',
                            height: '3em',
                            lineHeight: '3em',
                            textAlign: 'right',
                          }}
                          type="tel"
                          value={max}
                          variant="outlined"
                          label="Max.value"
                        />

                        <TextField
                          InputLabelProps={{ required: false }}
                          name="maxDescription"
                          onChange={this.handleChange}
                          required
                          sx={{
                            width: '11em',
                            height: '3em',
                            lineHeight: '3em',
                            textAlign: 'center',
                          }}
                          size="small"
                          type="text"
                          value={maxDescription}
                          variant="outlined"
                          label="Maximum text"
                        />
                      </div>
                    </div>
                  </>
                )}
            </div>
            {selectedItems &&
              selectedItems.length > 0 &&
              (activeSettings == null || activeSettings.enableInputChange) && (
                <Typography style={{ margin: '10px' }} variant="caption">
                  Selected items to vote on:
                </Typography>
              )}
          </form>
        </div>
        <div
          style={{
            width: '100%',
            overflowY: 'scroll',
            position: 'relative',
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            alignItems: 'left',
            flexGrow: '1',
            paddingLeft: '20px',
          }}
        >
          <div style={{ padding: '10px' }}>
            {(!selectedItems || selectedItems.length === 0) &&
              (activeSettings == null || activeSettings.enableInputChange) && (
                <div
                  style={{
                    fontStyle: 'italic',
                    color: 'red',
                  }}
                >
                  Select inputs in board you want to vote on
                </div>
              )}
            {selectedItems &&
              (activeSettings == null || activeSettings.enableInputChange) &&
              selectedItems.map((option, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    alignItems: 'left',
                    marginLeft: '25px',
                  }}
                  key={`${option.id}-${option.groupId}-${index}`}
                >
                  <TextButton
                    size="small"
                    sx={{
                      color: Theme.palette.text.disabled,
                      minWidth: '25px',
                    }}
                    onClick={() => onRemove(option.id)}
                  >
                    ⨉
                  </TextButton>
                  <Typography variant="subtitle2">{option.title}</Typography>
                </div>
              ))}
          </div>
        </div>
        <div style={{ padding: '10px' }}>
          <TextField
            InputLabelProps={{ required: false }}
            label="Explanation of selection"
            multiline
            name="description"
            fullWidth
            minRows={4}
            onChange={this.handleChange}
            type="text"
            value={description}
            variant="outlined"
            style={{ marginTop: '10px' }}
          />
        </div>
        <div
          style={{
            background: `${Theme.palette.background.default}`,
            padding: '10px 0px 100px 0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderTop: '1px solid #ddd',
          }}
        >
          <div>
            <TextButton onClick={onClose}>cancel</TextButton>
          </div>
          <div>
            <PrimaryButton onClick={this.onSubmit}>
              {activeSettings ? 'Update' : 'Create'} vote session
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}
