import { Typography } from '@mui/material';

import { type VoteModel } from '../../../Models';
import Theme from '../../../Theme/ThemeOptions';

type Props = {
  contentItem: {
    type: 'vote';
    vote: VoteModel;
  };
  isThumbnail?: boolean;
};

export function VoteResult({ contentItem, isThumbnail }: Props) {
  if (contentItem.vote.type === 'slider') {
    return <SliderResult contentItem={contentItem} isThumbnail={isThumbnail} />;
  } else {
    return <PointsResult contentItem={contentItem} isThumbnail={isThumbnail} />;
  }
}

function SliderResult({ contentItem, isThumbnail }) {
  const offset = contentItem.vote.lowNumber;
  const options = contentItem.content.options;
  options.sort(
    (a, b) =>
      b.averageRating - a.averageRating || a.title.localeCompare(b.title)
  );
  return (
    <div
      style={{
        padding: '25px 10%',
        textAlign: 'center',
        width: isThumbnail ? '750px' : 'inherit',
        transform: isThumbnail
          ? 'scale(0.4) translateX(-500px) translateY(-75%)'
          : 'scale(1)',
      }}
    >
      <div style={{ fontSize: '19px', marginBottom: '20px' }}>
        {contentItem.vote.title}
      </div>
      <div>
        {options.map((option) => {
          const percent = isNaN(option.averageRating)
            ? 0
            : (option.averageRating - offset) /
              (contentItem.vote.highNumber - offset);
          return (
            <div key={option.title} style={{ marginBottom: '35px' }}>
              <div
                style={{
                  margin: '0px auto',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  backgroundColor: '#f0f0f0',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    height: '25px',
                    lineHeight: '25px',
                  }}
                >
                  {option.title}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  backgroundColor: '#eee',
                  height: '10px',
                  marginTop: '15px',
                }}
              >
                <div
                  style={{
                    flexGrow: percent,
                    backgroundColor: Theme.palette.secondary.light,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ height: '50%' }}>&nbsp;</span>
                </div>
                <div
                  style={{
                    width: '35px',
                    height: '35px',
                    transform: 'translateY(-12.5px)',
                    backgroundColor: Theme.palette.secondary.dark,
                    borderRadius: '50%',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#fff',
                    left: 79.5 * percent + 9 + '%',
                  }}
                >
                  <span>{option.averageRating}</span>
                </div>
                <div
                  style={{ flexGrow: 1 - percent, backgroundColor: '#ddd' }}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function PointsResult({ contentItem, isThumbnail }) {
  const offset = 0;
  const options = contentItem.content.options;
  options.sort((a, b) => b.total - a.total);
  return (
    <div
      style={{
        padding: '25px 10%',
        textAlign: 'center',
        width: isThumbnail ? '750px' : 'inherit',
        transform: isThumbnail
          ? 'scale(0.4) translateX(-500px) translateY(-75%)'
          : 'scale(1)',
      }}
    >
      <div style={{ fontSize: '19px', marginBottom: '20px' }}>
        {contentItem.vote.title}
      </div>
      <div>
        {options.map((option) => {
          const percent = isNaN(option.total)
            ? 0
            : (option.total - offset) / (contentItem.vote.highNumber - offset);
          return (
            <div key={option.title} style={{ marginBottom: '35px' }}>
              <div
                style={{
                  margin: '0px auto',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  backgroundColor: '#f0f0f0',
                  fontSize: '13px',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    height: '25px',
                    lineHeight: '25px',
                  }}
                >
                  {option.title}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  backgroundColor: option.color,
                  height: '30px',
                  marginTop: '0px',
                }}
              >
                <div
                  style={{
                    flexGrow: percent,
                    backgroundColor: option.color,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ height: '50%' }}>&nbsp;</span>
                </div>
                <div
                  style={{
                    width: '35px',
                    height: '55px',
                    transform: 'translateY(-12.5px)',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#fff',
                    left: 79.5 * percent + 9 + '%',
                  }}
                >
                  <span>{option.total}</span>
                </div>
                <div
                  style={{ flexGrow: 1 - percent, backgroundColor: '#fff' }}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
