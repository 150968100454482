import { useCallback, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { ActionPlanController } from '../../../Controllers';
import { type InputModel } from '../../../Models';
import { PrimaryButton, TextButton } from '../General';

const Container = styled('div')({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

const SectionHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'start',
  width: '100%',
  margin: '10px 0',
});

const TitleSection = styled('section')({
  margin: '20px 0',
});

const ItemsContainer = styled('div')({
  overflowY: 'auto',
  maxHeight: '25vh',
  paddingLeft: '0',
  width: '100%',
  margin: '5px 0',
});

type CreateActionPlanProps = {
  sessionId: string;
  phaseId: string;
  activeId: string;
  activeType: string;
  selectedItem?: InputModel;
  isCreate?: boolean;
  onClose: () => void;
  onRemoveSelected: (inputId: string) => void;
  loadActionPlan: (id: string) => void;
};

const schema = z
  .object({
    title: z
      .string({
        required_error: 'Title is required',
        invalid_type_error: 'Title is required',
      })
      .min(2, { message: 'Title must be atleast 2 characters' }),
    description: z
      .string()
      .min(2, { message: 'Description must be atleast 2 characters' })
      .nullable(),
    actionPlanItems: z.array(z.string()).min(1, {
      message: 'Please add atleast one action item',
    }),
    assignees: z
      .array(
        z.object({
          fullname: z.string().min(2, {
            message: 'Full name must be atleast 2 characters',
          }),
          email: z.string().email({ message: 'Email is required' }),
        })
      )
      .min(1, { message: 'Please add atleast one assignee' })
      .max(10, { message: 'Maximum of 10 assignees only' }),

    dueDate: z.string().datetime({
      message: 'Due date is required',
    }),
  })
  .required();

type FormData = z.infer<typeof schema>;

const actionItemSchema = z.object({
  title: z.string().min(2, {
    message: 'Title must be atleast 2 characters',
  }),
});

type ActionItemFormData = z.infer<typeof actionItemSchema>;

const assigneeSchema = z.object({
  fullname: z.string().min(2, {
    message: 'Full name must be atleast 2 characters',
  }),
  email: z.string().email({ message: 'Email is required' }),
});

type AssigneeFormData = z.infer<typeof assigneeSchema>;

export const ActionPlan = ({
  sessionId,
  phaseId,
  activeId,
  activeType,
  isCreate = false,
  selectedItem,
  onClose,
  loadActionPlan,
}: CreateActionPlanProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: selectedItem?.title ?? '',
      description: selectedItem?.description ?? null,
      actionPlanItems: [],
      assignees: [],
    },
  });

  useEffect(() => {
    if (selectedItem) {
      setValue('title', selectedItem.title);
      setValue('description', selectedItem.description);
    } else {
      setValue('title', '');
      setValue('description', '');
    }
  }, [selectedItem, setValue]);

  const onSubmit = (data: FormData) => {
    void createActionPlan(data);
  };

  const createActionPlan = async (data: FormData) => {
    if (!selectedItem) {
      return;
    }

    const { title, description, assignees, actionPlanItems, dueDate } = data;

    const createdActionPlan = await ActionPlanController.create(
      sessionId,
      phaseId,
      title,
      description,
      assignees as [{ fullname: string; email: string }],
      actionPlanItems,
      dueDate,
      activeId,
      activeType
    );

    onClose();

    if (createdActionPlan?.id) {
      loadActionPlan(createdActionPlan?.id);
    }
  };

  const {
    register: actionItemRegister,
    handleSubmit: handleActionItemSubmit,
    reset: resetActionItem,
    formState: { errors: actionItemErrors },
  } = useForm<ActionItemFormData>({
    resolver: zodResolver(actionItemSchema),
    defaultValues: {
      title: '',
    },
  });

  const actionPlanItems = watch('actionPlanItems');

  const addActionItem = useCallback(
    ({ title }: ActionItemFormData) => {
      if (!title) {
        alert('Please enter title and try again');
        return;
      }

      if (actionPlanItems.some((item) => item === title)) {
        alert(`${title} is already added`);
        return;
      }

      setValue('actionPlanItems', [...actionPlanItems, title]);

      resetActionItem();
    },
    [actionPlanItems, resetActionItem, setValue]
  );

  const removeActionPlanItem = useCallback(
    (title: string) => {
      setValue(
        'actionPlanItems',
        actionPlanItems.filter((item) => item !== title)
      );
    },
    [actionPlanItems, setValue]
  );

  const {
    register: assigneeRegister,
    handleSubmit: handleAssigneeSubmit,
    reset: resetAssignee,
    formState: { errors: assigneeErrors },
  } = useForm<AssigneeFormData>({
    resolver: zodResolver(assigneeSchema),
    defaultValues: {
      fullname: '',
      email: '',
    },
  });

  const assignees = getValues('assignees');

  const addAssignee = useCallback(
    ({ fullname, email }: AssigneeFormData) => {
      if (!fullname || !email) {
        alert('Please enter fullname and email and try again');
        return;
      }

      if (assignees.some((assignee) => assignee.email === email)) {
        alert(`${email} is already added`);
        return;
      }

      setValue('assignees', [
        ...assignees,
        {
          fullname,
          email,
        },
      ]);

      resetAssignee();
    },
    [assignees, resetAssignee, setValue]
  );

  const removeAssignee = useCallback(
    (email: string) => {
      setValue(
        'assignees',
        assignees.filter((assignee) => assignee.email !== email),
        {
          shouldValidate: true,
        }
      );
    },
    [assignees, setValue]
  );

  return (
    <Container>
      <div
        style={{
          padding: '15px',
        }}
      >
        <SectionHeader>
          <Typography variant="subtitle2">
            {isCreate ? 'Create' : 'Update'} Action Plan
          </Typography>

          <Typography variant="body1">
            Below you can define an action plan for the selected items.
          </Typography>
        </SectionHeader>

        <Divider
          sx={{
            border: `1px solid lightgray`,
            width: '100%',
            margin: '10px 0',
          }}
        />

        {selectedItem ? (
          <>
            <form>
              <TitleSection>
                <TextField
                  label="Title"
                  size="small"
                  fullWidth
                  variant="outlined"
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  {...register('title')}
                />

                <TextField
                  label="Description"
                  multiline
                  minRows={4}
                  fullWidth
                  variant="outlined"
                  style={{ margin: '10px auto' }}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  {...register('description')}
                />
              </TitleSection>
            </form>

            <SectionHeader
              style={{
                marginBottom: '20px',
              }}
            >
              <Typography variant="subtitle2">Action items</Typography>

              <Typography variant="body1">
                Below you can define action items for the selected item.
              </Typography>

              {actionPlanItems.length > 0 ? (
                <ItemsContainer>
                  <List sx={{ width: '100%' }} dense={true}>
                    {actionPlanItems.map((item, idx) => (
                      <ListItem
                        key={item}
                        sx={{ paddingLeft: '0' }}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => removeActionPlanItem(item)}
                          >
                            <span className="notranslate material-icons-outlined">
                              delete
                            </span>
                          </IconButton>
                        }
                      >
                        {/* <ListItemText primary={item} secondary={idx + 1} /> */}
                        <Typography variant="subtitle2">
                          {idx + 1}.&nbsp;&nbsp;&nbsp; {item}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </ItemsContainer>
              ) : (
                <Typography variant="body2">
                  No action items added yet.
                </Typography>
              )}

              <form
                onSubmit={handleActionItemSubmit(addActionItem)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '5px',
                  margin: '8px 0',
                  width: '100%',
                }}
              >
                <TextField
                  label="New item here"
                  size="small"
                  fullWidth
                  variant="outlined"
                  error={!!errors.actionPlanItems}
                  helperText={
                    actionItemErrors.title?.message ||
                    errors.actionPlanItems?.message
                  }
                  {...actionItemRegister('title')}
                />
              </form>
            </SectionHeader>

            <SectionHeader
              style={{
                marginBottom: '20px',
              }}
            >
              <Typography variant="subtitle2">Assignments</Typography>

              <Typography variant="body1">
                Below you can assign people to the action plan. Add a full name
                and email address for each person and press enter to add them.
              </Typography>

              {assignees.length > 0 ? (
                <ItemsContainer>
                  <List sx={{ width: '100%' }} dense={true}>
                    {assignees.map((person, idx) => (
                      <ListItem
                        key={person.email}
                        sx={{
                          paddingLeft: '0',
                        }}
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => removeAssignee(person.email)}
                          >
                            <span className="notranslate material-icons-outlined">
                              delete
                            </span>
                          </IconButton>
                        }
                      >
                        <ListItemText
                          primary={
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginRight: '10px',
                                }}
                              >
                                <Typography variant="subtitle1">
                                  {idx + 1}.
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {person.fullname}
                                </Typography>
                                <i>{person.email}</i>
                              </div>
                            </div>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </ItemsContainer>
              ) : (
                <Typography variant="body2">No assignees added yet.</Typography>
              )}

              <form
                onSubmit={handleAssigneeSubmit(addAssignee)}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  margin: '8px 0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '5px',
                    width: '100%',
                  }}
                >
                  <TextField
                    label="Full name"
                    size="small"
                    fullWidth
                    variant="outlined"
                    error={!!errors.assignees}
                    helperText={
                      assigneeErrors.fullname?.message ||
                      errors.assignees?.message
                    }
                    {...assigneeRegister('fullname')}
                  />

                  <TextField
                    label="Email"
                    size="small"
                    fullWidth
                    variant="outlined"
                    error={!!errors.assignees}
                    helperText={assigneeErrors.email?.message}
                    {...assigneeRegister('email')}
                  />
                  <input type="submit" hidden />
                </div>
              </form>
            </SectionHeader>

            <SectionHeader>
              <Typography variant="subtitle2">Due date</Typography>

              <Typography variant="body1">
                The action plan will be due on the following date.
              </Typography>

              <DatePicker
                label="Pick a date"
                {...register('dueDate')}
                format="DD-MM-YYYY"
                disablePast
                onChange={(date: Date) => {
                  setValue('dueDate', date.toISOString());
                }}
                slotProps={{
                  textField: {
                    error: !!errors.dueDate,
                    helperText: errors.dueDate?.message,
                  },
                }}
              />
            </SectionHeader>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '20px 0',
              height: '100%',
              color: 'red',
            }}
          >
            <Typography variant="body1">
              Please select an item to create an action plan.
            </Typography>
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderTop: '1px solid #ddd',
            padding: '10px 0',
          }}
        >
          <div>
            <TextButton onClick={onClose}>Cancel</TextButton>
          </div>

          <div
            style={{
              margin: '10px 0',
            }}
          >
            <PrimaryButton
              type="button"
              disabled={!selectedItem}
              onClick={handleSubmit(onSubmit)}
            >
              {isCreate ? 'Create' : 'Update'} Action Plan
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Container>
  );
};
