import { Component } from 'react';
import { Divider, TextField, Typography } from '@mui/material';

import { getCookie } from '@utils';
import {
  BoardController,
  GroupController,
  InputController,
  VoteController,
} from '../../../Controllers';
import { type Handler } from '../../../Data';
import { type GroupModel, type InputModel } from '../../../Models';
import { Theme } from '../../../Theme';
import { PrimaryButton, TextButton } from '../General';

interface IProps {
  selectedItems?: InputModel[];
  groups?: GroupModel[];
  onSubmit?: (refined: { groups: GroupModel[]; inputs: InputModel[] }) => void;
  onRemove: (inputId: string) => void;
  onClose: () => void;
  loadBoard: (id: string) => void;
  session: string;
  phase: string;
  board: string;
  vote: string;
  handler: Handler;
  isLocked: boolean;
  activeType: string;
  activeSettings: any;
  lastUpdateUnix: number;
}

interface IState {
  title: string | null;
  defaultTitle: string;
  description: string | null;
  votes: string[];
}

export class Refine extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const highestNumber = Math.max(
      ...this.props.handler
        .GetBoards(this.props.phase)
        .filter((board) => !board.isDeleted)
        .map((board) => board.title)
        .filter((title) => title.startsWith('Refined board'))
        .map((title) => parseInt(title.replace('Refined board', '')))
        .map((number) => (isNaN(number) ? 0 : number))
    );

    this.state = {
      votes: [],
      title: this.props.activeSettings ? this.props.activeSettings.title : null,
      defaultTitle: `Refined board${
        highestNumber >= 0 ? ' ' + (highestNumber + 1) : ''
      }`,
      description: this.props.activeSettings
        ? this.props.activeSettings.description
        : null,
    };
  }

  handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const Name = event.currentTarget.name;
    const Value = event.currentTarget.value;

    this.setState({
      ...this.state,
      [Name]: Value,
    });
  };

  onSubmit = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    this.props.onClose();
    this.submitAsync();
  };

  async submitAsync() {
    const {
      session: sessionId,
      phase: phaseId,
      board: boardId,
      vote: voteId,
      activeSettings,
      activeType,
      lastUpdateUnix,
      selectedItems,
      loadBoard,
      isLocked,
      handler,
    } = this.props;

    const { title, defaultTitle, description } = this.state;

    const userId = getCookie('UserId');
    if (!userId) {
      return;
    }

    if (activeSettings && !activeSettings.enableInputChange) {
      // Only update name and description
      await BoardController.title(sessionId, phaseId, activeSettings.id, title);

      await BoardController.description(
        sessionId,
        phaseId,
        activeSettings.id,
        description
      );

      loadBoard(activeSettings.id);
    }

    const newGroups: { from: string; to: string }[] = [];

    if (activeSettings?.enableInputChange) {
      BoardController.delete(sessionId, phaseId, activeSettings.id);
    }

    const createdBoard = await BoardController.create(
      userId,
      sessionId,
      phaseId,
      title != null ? title : defaultTitle,
      description,
      true,
      activeType === 'vote' ? voteId : boardId,
      activeType,
      activeSettings ? lastUpdateUnix : 0
    );

    if (!createdBoard) {
      return;
    }

    const createdStack = await GroupController.create(
      sessionId,
      phaseId,
      createdBoard.id,
      'Stack',
      0,
      true
    );
    const createdBuffer = await GroupController.create(
      sessionId,
      phaseId,
      createdBoard.id,
      'Buffer',
      0,
      false
    );

    if (!createdStack || !createdBuffer) {
      return;
    }

    if (!selectedItems) {
      return;
    }

    for (const item of selectedItems) {
      const isCreated = newGroups.findIndex((x) => x.from === item.groupId);

      if (isCreated !== -1) {
        await InputController.create(
          sessionId,
          phaseId,
          createdBoard.id,
          newGroups[isCreated].to,
          item.title,
          item.description,
          item.userId
        );
      } else {
        const group = handler.GetGroup(item.groupId);
        if (group) {
          if (group.name === 'Stack') {
            await InputController.create(
              sessionId,
              phaseId,
              createdBoard.id,
              createdStack.id,
              item.title,
              item.description,
              item.userId
            );
          } else if (group.name === 'Buffer') {
            await InputController.create(
              sessionId,
              phaseId,
              createdBoard.id,
              createdBuffer.id,
              item.title,
              item.description,
              item.userId
            );
          } else {
            const toGroup = await GroupController.create(
              sessionId,
              phaseId,
              createdBoard.id,
              group.name,
              group.column,
              false
            );
            if (toGroup) {
              await GroupController.setColor(
                sessionId,
                phaseId,
                createdBoard.id,
                toGroup.id,
                group.color
              );

              const fromTo = { from: group.id, to: toGroup.id };
              newGroups.push(fromTo);

              await InputController.create(
                sessionId,
                phaseId,
                createdBoard.id,
                toGroup.id,
                item.title,
                item.description,
                item.userId
              );
            }
          }
        }
      }
    }

    if (!isLocked) {
      if (activeType === 'vote') {
        VoteController.lockData(sessionId, phaseId, boardId, voteId);
      } else if (activeType === 'board') {
        BoardController.lock(sessionId, phaseId, boardId);
      }
    }

    loadBoard(createdBoard.id);
  }

  render() {
    const { onClose, activeSettings, onRemove, selectedItems } = this.props;

    const { title, defaultTitle, description } = this.state;

    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            padding: '15px',
          }}
        >
          <form
            style={{ height: '100%', width: '100%' }}
            onSubmit={this.onSubmit}
          >
            <div
              style={{
                padding: '5px 10px',
                width: '100%',
                height: '100%',
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'start',
                alignItems: 'start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  alignItems: 'start',
                  width: '100%',
                }}
              >
                <Typography variant="subtitle2">
                  Selection for Refined Board
                </Typography>

                <Typography variant="body2">
                  Provide the settings for the refined board
                  and&nbsp;click&nbsp;"
                  {activeSettings ? 'Update' : 'Create'}
                  &nbsp;refined&nbsp;board"{' '}
                </Typography>
              </div>

              <Divider
                sx={{
                  border: `1px solid lightgray`,
                  width: '100%',
                  margin: '10px 0',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  alignItems: 'start',
                  width: '100%',
                }}
              >
                <TextField
                  InputLabelProps={{ required: false }}
                  label="Title"
                  name="title"
                  onChange={this.handleChange}
                  size="small"
                  type="text"
                  fullWidth
                  value={title != null ? title : defaultTitle}
                  variant="outlined"
                />
              </div>
            </div>
            {selectedItems &&
              selectedItems.length > 0 &&
              (activeSettings == null || activeSettings.enableInputChange) && (
                <Typography variant="caption">
                  Selected for refinement
                </Typography>
              )}
          </form>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            alignItems: 'start',
            width: '100%',
            flexGrow: '1',
            overflowY: 'scroll',
            paddingLeft: '20px',
            marginTop: '20px',
          }}
        >
          {(!selectedItems || selectedItems.length === 0) &&
            (activeSettings == null || activeSettings.enableInputChange) && (
              <div
                style={{
                  fontStyle: 'italic',
                  color: 'red',
                }}
              >
                Select inputs in board
              </div>
            )}

          {selectedItems &&
            (activeSettings == null || activeSettings.enableInputChange) &&
            selectedItems.map((option, index) => (
              <div
                key={`${option.id}-${option.groupId}-${index}`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'left',
                  marginLeft: '25px',
                }}
              >
                <TextButton
                  size="small"
                  sx={{
                    color: Theme.palette.text.disabled,
                    minWidth: '25px',
                  }}
                  onClick={() => onRemove(option.id)}
                >
                  ⨉
                </TextButton>
                <Typography variant="subtitle2">{option.title}</Typography>
              </div>
            ))}
        </div>
        <div style={{ padding: '10px' }}>
          <TextField
            InputLabelProps={{ required: false }}
            label="Explanation of selection"
            multiline
            name="description"
            onChange={this.handleChange}
            required
            minRows={4}
            fullWidth
            type="text"
            value={description}
            variant="outlined"
            style={{ margin: '10px auto' }}
          />
        </div>
        <div
          style={{
            background: `${Theme.palette.background.default}`,
            padding: '10px 0px 100px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderTop: '1px solid #ddd',
          }}
        >
          <div>
            <TextButton onClick={onClose}>Cancel</TextButton>
          </div>

          <div>
            <PrimaryButton onClick={this.onSubmit}>
              {activeSettings ? 'Update' : 'Create'} Refined Board
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}
