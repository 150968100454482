import { Menu, MenuItem, type PopoverPosition } from '@mui/material';

interface IProps {
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  anchorPosition?: PopoverPosition;
  hasSelectedItems: boolean;
  onExpandCollapseGroups: () => void;
  onSelectAll: () => void;
  onUnselectAll: () => void;
  onToggleDescription: () => void;
  onRemoveSelected: () => void;
  hideRemoveSelected?: boolean;
  hideExpandCollapseGroups?: boolean;
}

export const ContextMenu = ({
  anchorPosition,
  hasSelectedItems,
  hideExpandCollapseGroups,
  hideRemoveSelected,
  onClose,
  onExpandCollapseGroups,
  onRemoveSelected,
  onSelectAll,
  onUnselectAll,
  onToggleDescription,
}: IProps) => {
  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      id="simple-menu"
      keepMounted={false}
      onClose={onClose}
      open={Boolean(anchorPosition)}
    >
      {!hideExpandCollapseGroups && (
        <MenuItem onClick={onExpandCollapseGroups}>
          Expand/collapse groups
        </MenuItem>
      )}
      <MenuItem onClick={onSelectAll}>Select all</MenuItem>
      <MenuItem onClick={onUnselectAll}>Unselect all</MenuItem>
      <MenuItem onClick={onToggleDescription}>Toggle description</MenuItem>
      {!hideRemoveSelected && (
        <MenuItem onClick={onRemoveSelected} disabled={!hasSelectedItems}>
          Remove selected
        </MenuItem>
      )}
    </Menu>
  );
};
