import { useState } from 'react';
import { IconButton, alpha } from '@mui/material';

import Theme from '../../../Theme/ThemeOptions';

type ResultCardProps = {
  onClick: () => void;
  isActive: boolean;
  isDisabled: boolean;
};

export const ResultCard = ({
  onClick,
  isActive,
  isDisabled,
}: ResultCardProps) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <div
      style={{
        backgroundColor: isHovered
          ? alpha(Theme.palette.primary.light, 0.2)
          : isActive
          ? alpha(Theme.palette.primary.light, 0.35)
          : Theme.palette.background.paper,
        marginTop: '10px',
        borderTop: '4px solid #aaa',
        borderBottom: '1px solid #aaa',
        cursor: isDisabled ? 'inherit' : 'pointer',
      }}
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
      onMouseEnter={() => {
        if (!isDisabled) {
          setHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (!isDisabled) {
          setHovered(false);
        }
      }}
    >
      <IconButton disabled style={{ paddingLeft: '33px' }}>
        <span
          style={{
            color: isDisabled ? '#999' : Theme.palette.text.primary,
          }}
          className="notranslate material-icons-outlined"
        >
          description
        </span>
      </IconButton>
      <span
        style={{
          fontSize: '1rem',
          lineHeight: '0.97',
          fontWeight: '700',
          fontFamily: 'Lato,Roboto,Arial',
          cursor: 'text',
          color: isDisabled ? '#999' : '#000',
          position: 'relative',
          top: '2px',
        }}
      >
        Results
      </span>
    </div>
  );
};
