import { alpha, styled } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  margin: '0px 20px 0px 0px',
  padding: '0.5em 1em',
  display: 'flex',
  zIndex: 100,
  height: '56px',
  transition: 'all 0.25s',
  flexDirection: 'row',
  borderRadius: '2em',
  background: theme.palette.background.paper,
  boxShadow: `0 0 10px 10px ${alpha(theme.palette.text.primary, 0.15)}`,
}));

export const Toolbar = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return <Container {...props}>{props.children}</Container>;
};
