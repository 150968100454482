import { Component } from 'react';
import { Box, IconButton, alpha, styled } from '@mui/material';

import { ActionPlanController } from '../../../Controllers';
import { MenuView, TypoField, type IMenuItem } from '../General';

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  description: string;
  actionPlanId: string;
  phaseId: string;
  title: string;
  active: boolean;
  sessionId: string;
  isDeleted: boolean;
  userIsOwner: boolean;
  parentId?: string;
  parentType?: string;
  isParent: boolean;
  isCollapsed: boolean;
  numberOfChildren: number;
  level: number;
  onToggleCollapse: () => void;
}

interface IState {
  hover: boolean;
  collapsed: boolean;
  editTitle: boolean;
  anchor: Element | null;
  deleteDialog: boolean;
  menu: Array<IMenuItem>;
}

interface IDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active?: boolean;
}

const Container = styled('div')({
  width: '100%',
  position: 'relative',
  borderBottom: `1px solid #aaa`,
});

const CardHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<IDiv>(({ theme, active }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.palette.background.paper,
  boxShadow: active
    ? `0 0 0px 100px ${alpha(theme.palette.primary.light, 0.35)} inset`
    : '',
  '&:hover': {
    boxShadow: `0 0 0px 100px ${alpha(theme.palette.primary.main, 0.5)} inset`,
  },
}));

const SpanIcon = styled('span')(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export class ActionPlanCard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hover: false,
      collapsed: false,
      editTitle: false,
      anchor: null,
      deleteDialog: false,
      menu: this.Menu,
    };
  }

  deleteDialog = () => {
    this.setState({ deleteDialog: true, anchor: null });
  };

  deleteConfirmed = () => {
    this.setState({ deleteDialog: false, anchor: null });
    ActionPlanController.delete(this.props.sessionId, this.props.actionPlanId);
  };

  recover = () => {
    this.setState({ deleteDialog: false, anchor: null });
    ActionPlanController.recover(this.props.sessionId, this.props.actionPlanId);
  };

  rename = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();

    this.setState({ editTitle: true, anchor: null });
    setTimeout(() => this.setState({ editTitle: false }), 1000);
  };

  Menu: Array<IMenuItem> = [
    { text: 'Rename', callback: this.rename },
    {
      text: 'Delete',
      callback: (e) => {
        e.stopPropagation();
        this.setState({
          menu: this.ConfirmMenu,
        });
      },
    },
  ];

  MenuRecover: Array<IMenuItem> = [{ text: 'Recover', callback: this.recover }];

  ConfirmMenu: Array<IMenuItem> = [
    {
      text: 'Confirm delete action plan',
      callback: () => this.deleteConfirmed(),
    },
  ];

  render() {
    return (
      <Container
        onClick={this.props?.onClick}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <CardHeader active={this.props.active}>
          <Box
            sx={{
              marginLeft: `${this.props.level * 15}px`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                this.props.onToggleCollapse();
              }}
              disabled={!this.props.isParent}
              style={{
                padding: '0px',
                margin: '0px 5px',
                opacity: this.props.isParent ? '1' : '0',
                backgroundColor: '#eee',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
            >
              {this.props.isCollapsed && (
                <span
                  style={{
                    marginLeft: '5px',
                    fontSize: '13px',
                    textAlign: 'center',
                  }}
                >
                  {this.props.numberOfChildren}
                </span>
              )}
              <span className="notranslate material-icons-outlined">
                {this.props.isCollapsed ? 'chevron_right' : 'expand_more'}
              </span>
            </IconButton>

            <IconButton disabled style={{ paddingLeft: '0px' }}>
              <SpanIcon className="notranslate material-icons-outlined">
                checklist
              </SpanIcon>
            </IconButton>
            {this.props.isDeleted ? (
              <s
                style={{
                  fontSize: '0.75rem',
                  lineHeight: 0.97,
                  fontWeight: 700,
                }}
              >
                {this.props.title}
              </s>
            ) : (
              <TypoField
                doubleClickToEdit={true}
                uppercase={false}
                variant="subtitle2"
                text={this.props.title}
                submitChange={(name: string) => {
                  ActionPlanController.rename(
                    this.props.sessionId,
                    this.props.actionPlanId,
                    name
                  );
                }}
                edit={this.state.editTitle}
                disabled={!this.props.userIsOwner}
              />
            )}
          </Box>
          {this.state.hover ? (
            <Box>
              {this.props.userIsOwner && (
                <>
                  {!this.props.isDeleted && (
                    <>
                      {/* <IconButton onClick={this.hideResults} size="small">
                        {this.props.hideVotes ? (
                          <span
                            style={{ fontSize: '22px' }}
                            className="notranslate material-icons-outlined"
                          >
                            visibility_off
                          </span>
                        ) : (
                          <span
                            style={{ fontSize: '22px' }}
                            className="notranslate material-icons-outlined"
                          >
                            visibility
                          </span>
                        )}
                      </IconButton> */}
                      {/* <IconButton onClick={this.lockToggle} size="small">
                        {this.props.lockData ? (
                          <span
                            style={{ fontSize: '22px' }}
                            className="notranslate material-icons-outlined"
                          >
                            lock
                          </span>
                        ) : (
                          <span
                            style={{ fontSize: '22px' }}
                            className="notranslate material-icons-outlined"
                          >
                            lock_open
                          </span>
                        )}
                      </IconButton> */}
                    </>
                  )}
                  <IconButton
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      this.setState({ anchor: e.currentTarget });
                    }}
                    size="small"
                  >
                    <span
                      className="notranslate material-icons-outlined"
                      style={{ fontSize: '22px' }}
                    >
                      more_vert
                    </span>
                  </IconButton>
                  <MenuView
                    items={
                      this.props.isDeleted ? this.MenuRecover : this.state.menu
                    }
                    anchor={this.state.anchor}
                    onClose={(
                      event: React.MouseEvent<HTMLLIElement, MouseEvent>
                    ) => {
                      event.stopPropagation();
                      this.setState({ anchor: null });
                      setTimeout(() => {
                        this.setState({
                          menu: this.Menu,
                        });
                      }, 250);
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                  />
                </>
              )}
            </Box>
          ) : (
            !this.props.isDeleted && (
              <Box>
                {this.props.userIsOwner && (
                  <>
                    {/* <IconButton disabled size="small">
                      {this.props.hideVotes ? (
                        <span
                          style={{ fontSize: '22px' }}
                          className="notranslate material-icons-outlined"
                        >
                          visibility_off
                        </span>
                      ) : (
                        <span
                          style={{ fontSize: '22px', opacity: '0' }}
                          className="notranslate material-icons-outlined"
                        >
                          visibility
                        </span>
                      )}
                    </IconButton>
                    <IconButton disabled size="small">
                      {this.props.lockData ? (
                        <span
                          style={{ fontSize: '22px' }}
                          className="notranslate material-icons-outlined"
                        >
                          lock
                        </span>
                      ) : (
                        <span
                          style={{ fontSize: '22px', opacity: '0.01' }}
                          className="notranslate material-icons-outlined"
                        >
                          lock_open
                        </span>
                      )}
                    </IconButton> */}
                    <IconButton disabled size="small">
                      <span
                        style={{ fontSize: '22px', opacity: '0.01' }}
                        className="notranslate material-icons-outlined"
                      >
                        more_vert
                      </span>
                    </IconButton>
                  </>
                )}
              </Box>
            )
          )}
        </CardHeader>
      </Container>
    );
  }
}
