import { Component } from 'react';
import {
  Box,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';

import { Background, OptionView } from '.';
import { VoteController } from '../../../Controllers';
import { type Handler } from '../../../Data';
import { type OptionModel, type TicketModel } from '../../../Models';
import { PrimaryButton, TooltipView } from '../General';

interface IProps {
  countdownEnabled: boolean;
  countdownEnd: number;
  countdownTime: number;
  countdownLock: boolean;
  lockData: boolean;
  session: string;
  description: string;
  id: string;
  index: number;
  highDescription: string;
  highNumber: number;
  lowDescription: string;
  lowNumber: number;
  hideVotes: boolean;
  boardId: string;
  title: string;
  type: string;
  options: Array<OptionModel>;
  tickets: Array<TicketModel>;
  isDeleted: boolean;
  userIsOwner: boolean;
  dataHandler: Handler;
  noVotes: number;
  navigationIsCollapsed: boolean;
  rightSide: boolean;
}

interface IState {
  showPercent: boolean;
}

const Container = styled('div')({
  width: '100%',
  height: '90%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
});

const Results = styled('div')<{ size: number }>(({ size }) => ({
  width: size > 5 ? `${size * 250}px` : '80%',
  position: 'relative',
  flexGrow: 1,
  display: 'flex',
  left: size > 5 ? 'inherit' : '50%',
  paddingLeft: size > 5 ? '120px' : '20px',
  transform:
    size > 5 ? 'translateY(10px)' : 'translateX(-50%) translateY(10px)',
  flexDirection: 'row',
  justifyContent: 'space-around',
  height: '95%',
  gap: '5px',
}));

export class PointsVote extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { showPercent: true };
  }

  lockToggle = () => {
    const { session, boardId, id } = this.props;
    VoteController.lockData(session, session, boardId, id);

    try {
      const votesStartedKey = 'votesStarted';
      const votesStarted = JSON.parse(
        localStorage.getItem(votesStartedKey) || '[]'
      );

      if (!votesStarted.includes(id)) {
        votesStarted.push(id);
        localStorage.setItem(votesStartedKey, JSON.stringify(votesStarted));
      }
    } catch (error) {
      console.error('Error parsing or updating votesStarted:', error);
    }
  };

  hideResults = () => {
    const SESSION = this.props.session;
    const BOARD = this.props.boardId;
    const VOTE = this.props.id;
    VoteController.hideResults(SESSION, SESSION, BOARD, VOTE);
  };

  render() {
    const OPTIONS = this.props.options;
    if (!this.props.hideVotes) {
      OPTIONS.sort((a, b) => b.total - a.total);
    } else {
      OPTIONS.sort((a, b) => a.title.localeCompare(b.title));
    }

    const sum = OPTIONS.reduce((prev, cur) => prev + cur.total, 0);
    const max = OPTIONS.length > 0 ? OPTIONS[0].total : 0;

    let top: number;
    let interval: number;

    if (max <= 5) {
      top = 6;
      interval = 1;
    } else if (max <= 10) {
      top = 10;
      interval = 2;
    } else if (max <= 15) {
      top = 15;
      interval = 5;
    } else if (max <= 50) {
      top = Math.ceil(max / 10) * 10;
      interval = 5;
    } else if (max <= 100) {
      top = Math.ceil(max / 10) * 10;
      interval = 10;
    } else {
      top = Math.ceil(max / 100) * 100;
      interval = 25;
    }

    const hasStarted =
      localStorage.getItem('votesStarted')?.indexOf(this.props.id) !== -1;

    return (
      <Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '100px 20px 20px',
          }}
        >
          <Typography
            sx={{
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
            }}
            variant="h4"
          >
            <TooltipView title="The maximum number of votes each user can cast across all items.">
              <span style={{ marginRight: '30px' }}>
                Total votes allowed: {this.props.highNumber}
              </span>
            </TooltipView>
            <TooltipView title="The maximum number of votes an item can receive from a participant.">
              <span>Votes per Item: {this.props.lowNumber}</span>
            </TooltipView>
          </Typography>

          {!this.props.hideVotes && (
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              defaultValue={this.state.showPercent ? 'percent' : 'points'}
              name="radio-buttons-group"
              onChange={(e, value) => {
                this.setState({
                  showPercent: value === 'percent',
                });
              }}
              row={true}
              sx={{
                marginTop: '-5px',
              }}
            >
              <FormControlLabel
                value="points"
                control={<Radio />}
                label="Points"
              />
              <FormControlLabel
                value="percent"
                control={<Radio />}
                label="Percent"
              />
            </RadioGroup>
          )}
        </div>

        {this.props.userIsOwner &&
          this.props.hideVotes &&
          this.props.lockData && (
            <div
              style={{
                textAlign: 'center',
                position: 'fixed',
                right: this.props.rightSide ? '390px' : '11px',
                bottom: '0',
                left: this.props.navigationIsCollapsed ? '15px' : '390px',
                zIndex: '100',
                marginBottom: '15px',
              }}
            >
              <PrimaryButton
                style={{
                  padding: '15px 30px',
                  fontSize: '18px',
                }}
                onClick={this.lockToggle}
              >
                {hasStarted ? 'Open voting session' : 'Start voting session'}
              </PrimaryButton>
            </div>
          )}

        {this.props.hideVotes && !this.props.lockData && (
          <div
            style={{
              textAlign: 'center',
              position: 'fixed',
              right: this.props.rightSide ? '390px' : '11px',
              bottom: '0px',
              left: this.props.navigationIsCollapsed ? '15px' : '390px',
              zIndex: '100',
              backgroundColor: '#fff',
              borderTop: '1px solid #ddd',
              padding: '15px 15px',
            }}
          >
            <h2 style={{ marginBottom: '10px' }}>
              Results of voting will soon be revealed. Please wait...
            </h2>

            {this.props.userIsOwner && (
              <PrimaryButton
                style={{
                  padding: '15px 30px',
                  fontSize: '18px',
                }}
                onClick={this.hideResults}
              >
                <span style={{ color: '#fff' }}>{this.props.noVotes}</span>
                <span
                  style={{
                    fontSize: '25px',
                    position: 'relative',
                    top: '-1px',
                    marginRight: '8px',
                  }}
                  className="notranslate material-icons-outlined"
                >
                  how_to_vote
                </span>
                Reveal results
              </PrimaryButton>
            )}
          </div>
        )}

        {
          <div
            style={{
              height: '100%',
              overflow: 'visible',
              paddingBottom: '30px',
            }}
          >
            <Results size={OPTIONS.length}>
              {OPTIONS.map((option) => (
                <OptionView
                  key={option.id}
                  height={
                    this.props.hideVotes
                      ? '0%'
                      : `${100 * (option.total / top)}%`
                  }
                  showPercent={this.state.showPercent}
                  sum={sum}
                  dataHandler={this.props.dataHandler}
                  userIsOwner={this.props.userIsOwner}
                  {...option}
                ></OptionView>
              ))}
              <Background
                top={top}
                sum={sum}
                interval={interval}
                showPercent={this.state.showPercent}
              />
            </Results>
          </div>
        }

        {this.props.userIsOwner && (
          <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
            <TooltipView
              title={
                this.props.lockData
                  ? 'Closed vote, click to open'
                  : 'Open vote, click to close'
              }
              placement="top-end"
            >
              <IconButton onClick={this.lockToggle}>
                <span
                  style={{
                    color: this.props.lockData ? '#e00' : 'rgba(0, 0, 0, 0.54)',
                    position: 'relative',
                    width: '32px',
                    fontSize: '32px',
                  }}
                  className="notranslate material-icons-outlined"
                >
                  {this.props.lockData ? 'lock' : 'lock_open'}
                </span>
              </IconButton>
            </TooltipView>
            <TooltipView
              title={
                this.props.hideVotes
                  ? 'Results hidden, click to reveal'
                  : 'Results revealed, click to hide'
              }
              placement="top-end"
            >
              <IconButton onClick={this.hideResults}>
                <span
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    position: 'relative',
                    top: '3px',
                    width: '32px',
                    fontSize: '32px',
                  }}
                  className="notranslate material-icons-outlined"
                >
                  {this.props.hideVotes ? 'visibility_off' : 'visibility_on'}
                </span>
              </IconButton>
            </TooltipView>
          </Box>
        )}
      </Container>
    );
  }
}
