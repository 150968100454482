import Axios from 'axios';

import { type BoardModel } from '../Models';
import { BoardRoutes } from './Routes';

export const BoardController = {
  async create(
    ownerId: string,
    sessionId: string,
    phaseId: string,
    title: string,
    description: string,
    locked: boolean,
    parentId?: string,
    parentType?: string,
    lastUpdateUnix?: number
  ) {
    const DATA = {
      Title: title,
      Description: description,
      UserId: ownerId,
      Locked: locked,
      ParentId: parentId,
      ParentType: parentType,
      LastUpdateUnix: lastUpdateUnix,
    };
    let Result: BoardModel | undefined;

    await Axios.post(BoardRoutes.create(sessionId, phaseId), DATA).then(
      (res) => {
        if (res.status === 201) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async delete(sessionId: string, phaseId: string, boardId: string) {
    let Result = false;

    await Axios.delete(BoardRoutes.delete(sessionId, phaseId, boardId)).then(
      (res) => {
        if (res.status === 204) {
          Result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async recover(sessionId: string, phaseId: string, boardId: string) {
    let Result = false;

    await Axios.put(BoardRoutes.recover(sessionId, phaseId, boardId)).then(
      (res) => {
        if (res.status === 201) {
          Result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  timer: {
    set: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      seconds: number
    ) => {
      const DATA = {
        TimeInSeconds: seconds,
      };

      let Result: BoardModel | null = null;
      await Axios.patch(
        BoardRoutes.SetTimer(sessionId, phaseId, boardId),
        DATA
      ).then(
        (res) => {
          if (res.status === 200) {
            Result = res.data;
          }
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    start: async (sessionId: string, phaseId: string, boardId: string) => {
      let Result: BoardModel | null = null;
      await Axios.post(
        BoardRoutes.StartTimer(sessionId, phaseId, boardId)
      ).then(
        (res) => {
          if (res.status === 200) {
            Result = res.data;
          }
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
  },

  async move(
    sessionId: string,
    phaseId: string,
    boardId: string,
    targetIndex: number
  ) {
    const DATA = {
      TargetIndex: targetIndex,
    };
    let Result: Array<BoardModel> = [];

    await Axios.patch(BoardRoutes.move(sessionId, phaseId, boardId), DATA).then(
      (res) => {
        if (res.status === 200) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  get: {
    byPhase: async (sessionId: string, phaseId: string) => {
      let Result: Array<BoardModel> = [];
      await Axios.get(BoardRoutes.getByPhase(sessionId, phaseId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    bySession: async (sessionId: string) => {
      let Result: Array<BoardModel> = [];
      await Axios.get(BoardRoutes.getByAll(sessionId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    byId: async (sessionId: string, phaseId: string, boardId: string) => {
      let Result: BoardModel | null = null;
      await Axios.get(BoardRoutes.getById(sessionId, phaseId, boardId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
  },

  async lock(sessionId: string, phaseId: string, boardId: string) {
    let Result: BoardModel | undefined;
    await Axios.patch(BoardRoutes.LockData(sessionId, phaseId, boardId)).then(
      (res) => {
        if (res.status === 200) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async title(
    sessionId: string,
    phaseId: string,
    boardId: string,
    title: string
  ) {
    let Result: BoardModel | undefined;
    await Axios.patch(
      BoardRoutes.EditTitle(sessionId, phaseId, boardId, title)
    ).then(
      (res) => {
        if (res.status === 200) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async description(
    sessionId: string,
    phaseId: string,
    boardId: string,
    description: string
  ) {
    let Result: BoardModel | undefined;
    await Axios.patch(
      BoardRoutes.Description(sessionId, phaseId, boardId, description)
    ).then(
      (res) => {
        if (res.status === 200) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async hideBoard(sessionId: string, phaseId: string, boardId: string) {
    let Result: BoardModel | undefined;
    await Axios.patch(BoardRoutes.HideBoard(sessionId, phaseId, boardId)).then(
      (res) => {
        if (res.status === 200) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },
};
