import Axios from 'axios';

import { type InputModel } from '../Models';
import { InputRoutes } from './Routes';

export const InputController = {
  async create(
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    title: string,
    description: string,
    userId: string,
    isNewInput = false
  ) {
    const DATA = {
      Title: title,
      Description: description,
      UserId: userId,
      IsNewInput: isNewInput,
    };
    let Result: InputModel | null = null;

    await Axios.post(
      InputRoutes.add(sessionId, phaseId, boardId, groupId),
      DATA
    ).then(
      (res) => {
        if (res.status === 201) {
          Result = res.data;
        }
      },
      (err) => {
        if (err.status === 400) {
          console.error(err);
        }
      }
    );

    return Result;
  },

  async update(
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    inputId: string,
    title: string,
    description: string,
    userId: string
  ) {
    const DATA = {
      Title: title,
      Description: description,
      UserId: userId,
    };

    let Result: InputModel | null = null;

    await Axios.put(
      InputRoutes.update(sessionId, phaseId, boardId, groupId, inputId),
      DATA
    ).then(
      (res) => {
        if (res.status === 202) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async archive(
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    inputId: string
  ) {
    let Result = false;

    await Axios.delete(
      InputRoutes.archive(sessionId, phaseId, boardId, groupId, inputId)
    ).then(
      (res) => {
        if (res.status === 204) {
          Result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  set: {
    title: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      groupId: string,
      inputId: string,
      title: string
    ) => {
      const DATA = {
        Title: title,
      };
      let Result: InputModel | null = null;

      await Axios.patch(
        InputRoutes.setTitle(sessionId, phaseId, boardId, groupId, inputId),
        DATA
      ).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    description: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      groupId: string,
      inputId: string,
      description: string
    ) => {
      const DATA = {
        Description: description,
      };
      let Result: InputModel | null = null;

      await Axios.patch(
        InputRoutes.setDescription(
          sessionId,
          phaseId,
          boardId,
          groupId,
          inputId
        ),
        DATA
      ).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    group: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      groupId: string,
      inputIds: { groupId: string; inputId: string }[],
      targetGroup: string,
      targetIndex: number
    ) => {
      let Result: Array<InputModel> = [];

      await Axios.patch(
        InputRoutes.setGroup(
          sessionId,
          phaseId,
          boardId,
          groupId,
          targetGroup,
          targetIndex
        ),
        inputIds
      ).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
  },

  async move(
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    inputId: string,
    targetIndex: number
  ) {
    const DATA = {
      TargetIndex: targetIndex,
    };

    let Result: Array<InputModel> = [];

    await Axios.patch(
      InputRoutes.move(sessionId, phaseId, boardId, groupId, inputId),
      DATA
    ).then(
      (res) => {
        if (res.status === 200) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async compressSwissCheese(
    sessionId: string,
    phaseId: string,
    boardId: string
  ) {
    let Result: Array<InputModel> = [];

    await Axios.patch(
      InputRoutes.CompressSwissCheese(sessionId, phaseId, boardId)
    ).then(
      (res) => {
        if (res.status === 200) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async favorite(
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    hexColor: string
  ) {
    const DATA = {
      ColorInHex: hexColor,
    };
    let Result: InputModel | null = null;

    await Axios.patch(
      InputRoutes.favorite(sessionId, phaseId, boardId, groupId),
      DATA
    ).then(
      (res) => {
        Result = res.data;
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  get: {
    byGroup: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      groupId: string
    ) => {
      let Result: Array<InputModel> = [];

      await Axios.get(
        InputRoutes.getAllInGroup(sessionId, phaseId, boardId, groupId)
      ).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    bySession: async (sessionId: string) => {
      let Result: Array<InputModel> = [];

      await Axios.get(InputRoutes.getAll(sessionId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    byId: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      groupId: string,
      inputId: string
    ) => {
      let Result: InputModel | null = null;

      await Axios.get(
        InputRoutes.getById(sessionId, phaseId, boardId, groupId, inputId)
      ).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
  },
};
