import { Component, type PropsWithChildren } from 'react';
import { Droppable, type DroppableProvided } from '@hello-pangea/dnd';
import { styled } from '@mui/material';

type IProps = PropsWithChildren<{
  id: string;
  index: number;
  dragType: 'input' | 'group';
}>;

interface IDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

const ColumnContainer = styled(({ ...divProps }: IDiv) => (
  <div {...divProps} />
))<IDiv>(({ theme }) => ({
  width: '358px',
  boxSizing: 'border-box',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '5px',
  paddingRight: '5px',
  borderRight: `3px solid ${theme.palette.background.paper}`,
}));

export class ColumnView extends Component<IProps> {
  render() {
    return (
      <ColumnContainer id={this.props.id}>
        <Droppable droppableId={`Column-${this.props.index}`} type="group">
          {(provided: DroppableProvided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                minHeight: this.props.dragType === 'group' ? '95%' : '0%',
              }}
            >
              {this.props.children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Droppable droppableId={`Col-${this.props.index}`} type="input">
          {(provided: DroppableProvided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ minHeight: '5%', flexGrow: 1 }}
            ></div>
          )}
        </Droppable>
      </ColumnContainer>
    );
  }
}
