import { type Handler } from '../../../Data';
import { type BoardModel } from '../../../Models';

type BoardResultProps = {
  dataHandler: Handler;
  contentItem: {
    type: 'board';
    board: BoardModel;
  };
  isThumbnail?: boolean;
};

export function BoardResult({
  dataHandler,
  contentItem,
  isThumbnail,
}: BoardResultProps) {
  return (
    <div
      style={{
        padding: '25px',
        textAlign: 'center',
        width: isThumbnail ? '1000px' : 'inherit',
        transform: isThumbnail
          ? 'scale(0.4) translateX(-800px) translateY(-80%)'
          : 'scale(1)',
      }}
    >
      <div style={{ fontSize: '19px', marginBottom: '20px' }}>
        {contentItem.board.title}
      </div>
      <div>
        {dataHandler.GetGroups(contentItem.board.id).map((group) => {
          return (
            group.name !== 'Stack' &&
            group.name !== 'Buffer' && (
              <div
                key={group.id}
                style={{
                  backgroundColor: group.color,
                  opacity: 0.99,
                  borderRadius: '10px',
                  display: 'inline-block',
                  margin: '10px',
                  verticalAlign: 'top',
                  padding: '5px',
                  width: '350px',
                  fontSize: '13px',
                }}
              >
                <div>{group.name}</div>
                <div>
                  {dataHandler.GetInputs(group.id).map((input) => {
                    return (
                      <div
                        key={input.id}
                        style={{
                          backgroundColor: '#fff',
                          margin: '5px',
                          border: '1px solid #aaa',
                          borderRadius: '5px',
                          padding: '5px',
                          zIndex: 1000,
                        }}
                      >
                        <div>{input.title}</div>
                        {input.description &&
                          input.title !== input.description && (
                            <div>{input.description}</div>
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}
