import { useState } from 'react';
import { styled } from '@mui/material';
import { isMobile } from 'react-device-detect';

import { getCookie } from '@utils';
import { type Handler } from '../../Data';
import {
  type ActionPlanModel,
  type BoardModel,
  type VoteModel,
} from '../../Models';
import { TextButton } from '../Components/General';
import {
  ActionPlan,
  Ballot,
  ParticipateView,
  Refine,
} from '../Components/Participate';

type RightPanelProps = {
  sessionId: string;
  isCollapsed: boolean;
  panelKind: '' | 'participate' | 'slider' | 'points' | 'refine' | 'actionplan';
  activeType: 'board' | 'vote' | 'actionplan' | 'result' | '';
  activeId: string;
  groupId?: string;
  isSessionOwner: boolean;
  view: BoardModel | VoteModel | ActionPlanModel;
  lastUpdateUnix: number;
  activeSettings: any;
  handler: Handler;
  loadBoard: (id: string) => void;
  loadVote: (id: string) => void;
  loadActionPlan: (id: string) => void;
  onClose: (clearActiveSettings?: boolean) => void;
};

const AdminContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCollapsed' && prop !== 'isMobile',
})<{
  isMobile: boolean;
  isCollapsed: boolean;
}>(({ theme, isCollapsed, isMobile }) => ({
  margin: 0,
  padding: 0,
  zIndex: 300,
  background: theme.palette.background.default,
  height: '100%',
  borderLeft: `1px solid ${theme.palette.text.disabled}`,
  transition: 'all 0.25s',
  ...(isMobile && {
    width: '100%',
    borderWidth: 0,
  }),
  ...(!isCollapsed &&
    !isMobile && {
      width: '10%',
      minWidth: '390px',
    }),
  ...(isCollapsed &&
    !isMobile && {
      width: '10px',
      borderRight: `10px solid ${theme.palette.text.disabled}`,
    }),
}));

const PublicWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
});

const PublicContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{
  isMobile: boolean;
}>(({ theme, isMobile }) => ({
  margin: 0,
  padding: 0,
  zIndex: 300,
  background: theme.palette.background.default,
  height: '100%',
  transition: 'all 0.25s',
  ...(isMobile && {
    width: '100%',
    borderWidth: 0,
  }),
  ...(!isMobile && {
    width: '10%',
    minWidth: '390px',
  }),
  ...(!isMobile && {
    width: '10px',
  }),
}));

export const RightPanel = ({
  panelKind,
  isCollapsed,
  activeType,
  activeId,
  view,
  lastUpdateUnix,
  sessionId,
  groupId,
  activeSettings,
  handler,
  isSessionOwner,
  loadBoard,
  loadVote,
  loadActionPlan,
  onClose,
}: RightPanelProps) => {
  const [user] = useState<string>(getCookie('UserId') ?? '');

  const isProposalsView = panelKind === 'participate';
  const isSliderView = panelKind === 'slider';
  const isPointsView = panelKind === 'points';
  const isRefineView = panelKind === 'refine';
  const isActionPlanView = panelKind === 'actionplan';

  const isBoard = activeType === 'board' && view && 'hasNewInput' in view;

  const isVote = activeType === 'vote' && view &&  'hideVotes' in view;

  const hasActive = !!activeId;

  return isSessionOwner ? (
    <AdminContainer isMobile={isMobile} isCollapsed={isCollapsed}>
      {((hasActive && isProposalsView) || isMobile) && (
        <ParticipateView
          board={isBoard ? view : undefined}
          options={isVote ? handler.GetOptions(activeId) : undefined}
          session={sessionId}
          contributions={
            user && isBoard
              ? handler.GetContributions(activeId, user)
              : undefined
          }
          ticket={
            user && isVote ? handler.GetMyTicket(activeId, user) : undefined
          }
          groupId={groupId}
          type={view ? (isBoard ? 'board' : (view as VoteModel).type) : null}
          user={user ? user : ''}
          vote={isVote ? view : undefined}
          isLocked={view ? view['lockData'] : true}
        />
      )}

      {!isMobile && hasActive && (isSliderView || isPointsView) && (
        <Ballot
          session={sessionId}
          phase={view.phaseId}
          lastUpdateUnix={lastUpdateUnix}
          board={isVote ? view.boardId : (view as BoardModel).id}
          handler={handler}
          vote={(view as VoteModel)?.id}
          activeType={activeType}
          isLocked={view['lockData']}
          type={panelKind}
          onClose={() => onClose(true)}
          selectedItems={handler.GetSelected(activeId)}
          loadVote={loadVote}
          onRemove={(input: string) => handler.Select(activeId, input)}
          activeSettings={activeSettings}
        />
      )}

      {!isMobile && hasActive && isRefineView && (
        <Refine
          session={sessionId}
          handler={handler}
          phase={view?.phaseId}
          board={view?.id}
          lastUpdateUnix={lastUpdateUnix}
          vote={(view as VoteModel)?.id}
          activeType={activeType}
          isLocked={view['lockData']}
          onClose={() => onClose(true)}
          selectedItems={handler.GetSelected(activeId)}
          loadBoard={loadBoard}
          onRemove={(input: string) => handler.Select(activeId, input)}
          activeSettings={activeSettings}
        />
      )}

      {!isMobile && hasActive && isActionPlanView && (
        <ActionPlan
          sessionId={sessionId}
          phaseId={view?.phaseId}
          activeType={activeType}
          activeId={activeId}
          isCreate={!activeSettings}
          onClose={onClose}
          selectedItem={handler.GetSelected(activeId)[0]}
          onRemoveSelected={(input: string) => handler.Select(activeId, input)}
          loadActionPlan={loadActionPlan}
        />
      )}

      {!isMobile && !isCollapsed && (
        <TextButton
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: '5px',
            top: '5px',
            padding: '5px',
          }}
        >
          <span
            style={{ fontSize: '37px' }}
            className="notranslate material-icons-outlined"
          >
            close
          </span>
        </TextButton>
      )}
    </AdminContainer>
  ) : (
    <PublicWrapper>
      <PublicContainer isMobile={isMobile}>
        {
          <ParticipateView
            board={isBoard ? view : undefined}
            options={isVote ? handler.GetOptions(activeId) : undefined}
            session={sessionId}
            contributions={
              user && isBoard
                ? handler.GetContributions(activeId, user)
                : undefined
            }
            ticket={
              user && isVote ? handler.GetMyTicket(activeId, user) : undefined
            }
            groupId={groupId}
            type={view ? (isBoard ? 'board' : (view as VoteModel).type) : null}
            user={user ? user : ''}
            vote={isVote ? view : undefined}
            isLocked={view ? view['lockData'] : true}
          />
        }
      </PublicContainer>
    </PublicWrapper>
  );
};
