import Axios from 'axios';

import { type OptionModel } from '../Models';
import { OptionRoutes } from './Routes';

export const OptionController = {
  async create(
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string,
    inputId: string,
    inputTitle: string,
    inputDescription: string
  ) {
    const DATA = {
      InputId: inputId,
      Title: inputTitle,
      Description: inputDescription,
    };
    let Result: OptionModel | null = null;

    await Axios.post(
      OptionRoutes.create(sessionId, phaseId, boardId, voteId),
      DATA
    ).then(
      (res) => {
        if (res.status === 201) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async remove(
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string,
    optionId: string
  ) {
    let Result = false;

    await Axios.delete(
      OptionRoutes.remove(sessionId, phaseId, boardId, voteId, optionId)
    ).then(
      (res) => {
        if (res.status === 204) {
          Result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async setColor(
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string,
    optionId: string,
    hexColor: string
  ) {
    const DATA = {
      ColorInHex: hexColor,
    };
    let Result: OptionModel | null = null;

    await Axios.patch(
      OptionRoutes.setColor(sessionId, phaseId, boardId, voteId, optionId),
      DATA
    ).then(
      (res) => {
        Result = res.data;
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  get: {
    byVote: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      voteId: string
    ) => {
      let Result: Array<OptionModel> = [];

      await Axios.get(
        OptionRoutes.getAllInVote(sessionId, phaseId, boardId, voteId)
      ).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    bySession: async (sessionId: string) => {
      let Result: Array<OptionModel> = [];

      await Axios.get(OptionRoutes.getAll(sessionId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    byId: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      voteId: string,
      optionId: string
    ) => {
      let Result: OptionModel | null = null;

      await Axios.get(
        OptionRoutes.getById(sessionId, phaseId, boardId, voteId, optionId)
      ).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
  },
};
