import Axios from 'axios';

import { type VoteModel } from '../Models';
import { VoteRoutes } from './Routes';

export const VoteController = {
  async create(
    sessionId: string,
    phaseId: string,
    boardId: string,
    type: string,
    title: string,
    description: string,
    lowNumber: number,
    lowDesc: string,
    highNumber: number,
    highDesc: string,
    isDeleted: boolean,
    parentId: string,
    parentType: string,
    lastUpdateUnix: number
  ) {
    const DATA = {
      Type: type,
      Title: title,
      Description: description,
      LowNumber: lowNumber,
      LowDescription: lowDesc,
      HighNumber: highNumber,
      HighDescription: highDesc,
      idDeleted: isDeleted,
      ParentId: parentId,
      ParentType: parentType,
      LastUpdateUnix: lastUpdateUnix,
    };
    let Result: VoteModel | undefined;

    await Axios.post(VoteRoutes.create(sessionId, phaseId, boardId), DATA).then(
      (res) => {
        if (res.status === 201) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async delete(
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) {
    let Result = false;

    await Axios.delete(
      VoteRoutes.delete(sessionId, phaseId, boardId, voteId)
    ).then(
      (res) => {
        if (res.status === 204) {
          Result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async recover(
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) {
    let Result = false;

    await Axios.put(
      VoteRoutes.recover(sessionId, phaseId, boardId, voteId)
    ).then(
      (res) => {
        if (res.status === 201) {
          Result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  timer: {
    set: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      voteId: string,
      seconds: number
    ) => {
      const DATA = {
        TimeInSeconds: seconds,
      };

      let Result: VoteModel | undefined;
      await Axios.patch(
        VoteRoutes.setTimer(sessionId, phaseId, boardId, voteId),
        DATA
      ).then(
        (res) => {
          if (res.status === 200) {
            Result = res.data;
          }
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    start: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      voteId: string
    ) => {
      let Result: VoteModel | undefined;
      await Axios.post(
        VoteRoutes.startTimer(sessionId, phaseId, boardId, voteId)
      ).then(
        (res) => {
          if (res.status === 200) {
            Result = res.data;
          }
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
  },

  get: {
    byPhase: async (sessionId: string, phaseId: string) => {
      let Result: Array<VoteModel> = [];

      await Axios.get(VoteRoutes.getAllInPhase(sessionId, phaseId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    bySession: async (sessionId: string) => {
      let Result: Array<VoteModel> = [];

      await Axios.get(VoteRoutes.getAll(sessionId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    byId: async (
      sessionId: string,
      phaseId: string,
      boardId: string,
      voteId: string
    ) => {
      let Result: VoteModel | null = null;

      await Axios.get(
        VoteRoutes.getById(sessionId, phaseId, boardId, voteId)
      ).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
  },

  async lockData(
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) {
    let Result: VoteModel | undefined;
    await Axios.patch(
      VoteRoutes.LockData(sessionId, phaseId, boardId, voteId)
    ).then(
      (res) => {
        if (res.status === 200) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async hideResults(
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) {
    let Result: VoteModel | undefined;
    await Axios.patch(
      VoteRoutes.HideResults(sessionId, phaseId, boardId, voteId)
    ).then(
      (res) => {
        if (res.status === 200) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async rename(sessionId: string, voteId: string, name: string) {
    const DATA = {
      Name: name,
    };
    let Result: VoteModel | null = null;

    await Axios.patch(VoteRoutes.rename(sessionId, voteId), DATA).then(
      (res) => {
        Result = res.data;
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async description(sessionId: string, voteId: string, description: string) {
    const DATA = {
      Name: description,
    };
    let Result: VoteModel | null = null;

    await Axios.patch(VoteRoutes.description(sessionId, voteId), DATA).then(
      (res) => {
        Result = res.data;
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },
};
