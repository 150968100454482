export function removeDeleted<
  T extends {
    id: string;
    isDeleted: boolean;
    parentId?: string;
  },
>(elements: T[]) {
  const deletedIds = elements
    .filter((element) => element.isDeleted)
    .map((element) => element.id);

  const parentIds = elements
    .filter((element) => element.parentId !== undefined)
    .map((element) => element.parentId);

  const ignoredElements = deletedIds.filter((id) => parentIds.indexOf(id) < 0);

  const filteredElements = elements.filter(
    (element) => ignoredElements.indexOf(element.id) < 0
  );

  return { hasIgnored: ignoredElements.length > 0, elements: filteredElements };
}

export type TreeNode<T> = T & {
  children: TreeNode<T>[];
  noKids: number;
  isParent?: boolean;
  level?: number;
};

export function listToTree<T extends { id: string; parentId?: string }>(
  collapsed: Record<string, boolean>,
  list: T[],
  includeAll = false
): TreeNode<T>[] {
  const map: { [key: string]: number } = {};
  const roots: TreeNode<T>[] = [];
  const extendedList = list.map((item, index) => {
    map[item.id] = index;
    return {
      ...item,
      children: [] as TreeNode<T>[],
      noKids: 0,
      isParent: false,
    };
  });

  extendedList.forEach((item: any) => {
    const { parentId } = item;

    if (parentId) {
      const parentNode = extendedList[map[parentId]];
      parentNode.isParent = true;

      let currentParentId = parentId;
      while (currentParentId) {
        const boardOrVoteElement = extendedList[map[currentParentId]];
        boardOrVoteElement.noKids++;
        currentParentId = boardOrVoteElement.parentId;
      }

      if (includeAll) {
        parentNode.children.push(item);
      }

      if (!collapsed[parentId] && !includeAll) {
        parentNode.children.push(item);
      }
    } else {
      roots.push(item);
    }
  });

  return roots;
}

// Process a single ListItem and return the flattened tree structure
function processNode<T>(
  node: TreeNode<T>,
  currentLevel: number
): TreeNode<T>[] {
  // Set the node's level, limiting the maximum level to 9
  node.level = Math.min(currentLevel, 9);

  // If the node has children, flatten them and include them in the result
  if (node.children) {
    const flattenedChildren = flattenTree(node.children, currentLevel + 1);
    return [node, ...flattenedChildren];
  }

  // If the node has no children, return an array with just the node
  return [node];
}

// Flatten a tree structure while keeping track of each node's level
function flattenTree<T>(tree: TreeNode<T>[], level: number): TreeNode<T>[] {
  const flattenedNodes: TreeNode<T>[] = [];

  for (const node of tree) {
    const processedNode = processNode(node, level);
    flattenedNodes.push(...processedNode);
  }

  return flattenedNodes;
}

export function convert<T>(tree: TreeNode<T>[], level: number): TreeNode<T>[] {
  return flattenTree(tree, level);
}
