import { Component } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Fade,
  TextField,
  Typography,
} from '@mui/material';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

import { SignupForm, type SignupFormData } from '.';
import {
  UserController as UserService,
  type IResult,
} from '../../../Controllers';
import { Theme } from '../../../Theme';

interface IProps {
  onClose: (loggedIn: boolean) => void;
  open: boolean;
}

interface IState {
  working: boolean;
  tab: 'login' | 'register' | 'forgot' | 'register-success';

  login: {
    email: string;
    password: string;
  };

  register: {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    phone: string;
    password: string;
    repeatPassword: string;
    agreement: boolean;
    error: string;
  };
  forgot: {
    email: string;
    password: string;
    repeatPassword: string;
  };
}

/**
 * Pop-up Login Modal
 * Required props:
 *      open => Boolean that decides if the modal is visible
 *      onClose => function that closes modal
 */
export class LoginModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      working: false,
      tab: 'login',

      /**
       * Log in data
       */
      login: {
        email: '',
        password: '',
      },
      /**
       * Register data
       */
      register: {
        email: '',
        firstName: '',
        lastName: '',
        company: '',
        phone: '',
        password: '',
        repeatPassword: '',
        agreement: false,
        error: '',
      },
      forgot: {
        email: '',
        password: '',
        repeatPassword: '',
      },
    };
  }

  /**
   * Handles the input field,
   * each field in the form needs a name that matches the state variable name
   * @param {Event} e the event form the input field
   */
  handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const tab = this.state.tab;

    if (tab === 'login') {
      const name = event.target.name;
      const value: string = event.target.value;

      if (name === 'email' || name === 'password') {
        const data = this.state.login;

        data[name] = value;

        this.setState({ login: data });
      }
    } else if (tab === 'register') {
      const name = event.target.name;
      const value: string = event.target.value;

      if (
        name === 'email' ||
        name === 'password' ||
        name === 'firstName' ||
        name === 'lastName' ||
        name === 'company' ||
        name === 'phone' ||
        name === 'repeatPassword'
      ) {
        const data = this.state.register;

        data[name] = value;

        this.setState({ register: data });
      }
    } else if (tab === 'forgot') {
      const name = event.target.name;
      const value: string = event.target.value;
      if (
        name === 'email' ||
        name === 'password' ||
        name === 'repeatPassword'
      ) {
        const data = this.state.forgot;

        data[name] = value;

        this.setState({ login: data });
      }
    }
  };

  /**
   * Changes the active tab
   * @param {string} key the name of the new active tab
   */
  handleTab = (key: 'login' | 'register' | 'forgot') => {
    this.setState({
      tab: key,
    });
  };

  /**
   * This is where all of our "form - onSubmit" functions are stored.
   * They check the data, before sending the request to this.server
   * It Is also where we set "state.working" to true and false.
   * If the server function returns true, this modal will then close.
   */
  handleSubmit = {
    /**
     * Prevents form refresh & Calls "server.login" with "state.login"
     * @param {Event} e form event
     * @returns {boolean} logged in === true
     */
    login: async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!e.currentTarget.checkValidity()) {
        e.stopPropagation();
      }

      this.setState({ working: true }); //This makes the user know work is being done, should always be set to false at end of what made it true!

      const data = this.state.login;
      const results: IResult | null = await UserService.login(
        data.email,
        data.password
      );

      //Axios Login
      if (results) {
        // localStorage.setItem('user', results.id);
        localStorage.setItem('name', 'The Architect');
        localStorage.setItem('AURT', results.refreshToken);

        this.setState({ working: false });
        this.props.onClose(true);
        return true;
      } else {
        this.setState({ working: false });
        return false;
      }
    },

    register: async (data: SignupFormData) => {
      this.setState({ working: true }); //This makes the user know work is being done, should always be set to false at end of what made it true!

      const results = await UserService.register(
        data.company,
        data.email,
        data.firstName,
        data.lastName,
        data.password,
        data.phone
      );

      //Axios Register
      if (results.success) {
        this.setState({ working: false });
        this.setState({ tab: 'register-success' });

        return true;
      } else {
        const register = this.state.register;
        register.error = results.errorText;

        this.setState({ register: register });
        this.setState({ working: false });

        return false;
      }
    },
    /**
     * Prevents form refresh && checks form validity && calls "server.forgot" with "state.forgot"
     * @param {Event} e form event
     * @returns {boolean} (Password Change Email === Sent)
     * (Shitty name, i know. ATM this takes the new password RIGHT NOW, stores it in hashed form, but doesn't replace the old one before the link sent to your email is accessed.)
     */
    forgot: async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!e.currentTarget.checkValidity()) {
        e.stopPropagation();
      }

      if (this.state.forgot.password !== this.state.forgot.repeatPassword) {
        const forgot = this.state.forgot;
        forgot.password = '';
        forgot.repeatPassword = '';

        this.setState({ forgot });

        alert('Passwords did not match!');
        return false;
      }

      this.setState({ working: true }); //This makes the user know work is being done, should always be set to false at end of what made it true!
    },
  };

  /**
   * Where all of this class JSX forms are located. The Forms are:
   * .login, .register & .forgot
   */
  forms = {
    /**
     * JSX form for user log in
     * @returns {JSX}
     */
    login: () => {
      return (
        <form onSubmit={this.handleSubmit.login}>
          <TextField
            autoComplete="username"
            fullWidth
            id="login-email"
            InputLabelProps={{ required: false }}
            label="Email"
            name="email"
            onChange={this.handleChange}
            required
            sx={{ marginBottom: '20px' }}
            type="email"
            value={this.state.login.email}
          />
          <br />

          <TextField
            autoComplete="off"
            fullWidth
            id="login-password"
            InputLabelProps={{ required: false }}
            label="Password"
            name="password"
            onChange={this.handleChange}
            required
            type="password"
            value={this.state.login.password}
          />
          <br />

          <Button
            id="login-forgot-password"
            onClick={() => this.handleTab('forgot')}
            size="small"
            variant="text"
          >
            Forgot Password?
          </Button>

          <br style={{ marginBottom: '5px' }} />

          <MobileView>
            <Button
              sx={{
                marginTop: '16px',
                float: 'left',
                borderRadius: '1em',
                minWidth: '40%',
                padding: '1em',
                backgroundColor: Theme.palette.primary.main,
                color: '#fff',
                outline: 'none !important',
              }}
              id="login-submit"
              onClick={() => {
                this.props.onClose(false);
              }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{
                marginTop: '16px',
                float: 'right',
                borderRadius: '1em',
                minWidth: '40%',
                padding: '1em',
                backgroundColor: Theme.palette.primary.main,
                color: '#fff',
                outline: 'none !important',
              }}
              id="login-submit"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </MobileView>

          <BrowserView>
            <Button
              sx={{
                marginTop: '16px',
                display: 'block',
                position: 'relative',
                left: '50%',
                transform: 'translateX(-50%)',
                borderRadius: '1em',
                minWidth: '50%',
                padding: '1em',
                backgroundColor: Theme.palette.primary.main,
                color: '#fff',
                outline: 'none !important',
              }}
              id="login-submit"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </BrowserView>
        </form>
      );
    },
    /**
     * JSX form for registering new user
     * @returns {JSX}
     */
    register: () => {
      return (
        <SignupForm
          onCancel={() => this.props.onClose(false)}
          onSignup={this.handleSubmit.register}
        />
      );
    },

    'register-success': () => {
      return (
        <div>
          <Typography variant="h3">Thank you for signing up!</Typography>
          <Typography variant="body1" sx={{ marginTop: '16px' }}>
            We're excited to have you as a member of our community. You can now
            log in to your account using the button below:
          </Typography>
          <Button
            sx={{
              marginTop: '16px',
              display: 'block',
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
              borderRadius: '1em',
              minWidth: '50%',
              padding: '1em',
              backgroundColor: Theme.palette.primary.main,
              color: '#fff',
              outline: 'none !important',
            }}
            type="button"
            variant="contained"
            onClick={() => {
              this.setState({ tab: 'login' });
            }}
          >
            Log in
          </Button>
        </div>
      );
    },
    /**
     * JSX form for changing password.
     * TODO: Right now, you "change password" then have to click the link in your email to confirm it.
     * TODO: (Should be the other way because clicking the link, immediately confirms the password change that someone else might have requested)         *
     * TODO: Security In Prototype 1 is so laughable that it is either done like this, or one could be able to bypass the Email Entirely
     * TODO: (I.E. If you know/figure out the code, the only request you need to use is the final one. So it has to be directly from the email)
     * @returns {JSX} JSX form
     */
    forgot: () => {
      return (
        <form onSubmit={this.handleSubmit.forgot}>
          <Typography>
            Please Enter your email and your desired password.
            <br />
            You will receive an email to confirm the change.
          </Typography>

          <TextField
            autoComplete="email"
            fullWidth
            id="forgot-email"
            label="Email"
            name="email"
            onChange={this.handleChange}
            required
            type="email"
            value={this.state.forgot.email}
          />
          <br />

          {/* Password + Repeat Password */}
          <TextField
            autoComplete="off"
            fullWidth
            id="forgot-password"
            inputProps={{ minLength: '8' }}
            label="Password"
            name="password"
            onChange={this.handleChange}
            required
            type="password"
            value={this.state.forgot.password}
          />
          <br />
          <TextField
            autoComplete="off"
            fullWidth
            id="forgot-repeat-password"
            label="Repeat Password"
            name="repeatPassword"
            onChange={this.handleChange}
            required
            type="password"
            value={this.state.forgot.repeatPassword}
          />

          <Button
            sx={{
              marginTop: '16px',
              display: 'block',
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
              borderRadius: '1em',
              minWidth: '50%',
              padding: '1em',
              backgroundColor: Theme.palette.primary.main,
              color: '#fff',
              outline: 'none !important',
            }}
            id="forgot-submit"
            type="submit"
            variant="contained"
          >
            Confirm
          </Button>
        </form>
      );
    },
  };

  /**
   * Provides us with the White background &&
   * Has the Tabs, and controls for them.
   */
  body() {
    return (
      <Box
        sx={{
          backgroundColor: Theme.palette.background.paper,
          borderRadius: '5px',
          border: isMobile ? '0px solid #555' : '2px solid #555',
          boxShadow: Theme.shadows[5],
          padding: Theme.spacing(6.5, 4, 3),
          minWidth: isMobile ? '350px' : '450px',
        }}
      >
        {/* Modal Tabs, This is how we navigate between Login and Sign Up*/}
        <Box id="login-title">
          <Button
            disableElevation={this.state.tab !== 'login'}
            id="loginTab"
            onClick={() => this.handleTab('login')}
            sx={{
              width: 'calc(50% - 1px)',
              borderRadius: '5px 0 0 0',
              height: '52px',
              position: 'absolute',
              top: '2px',
              left: '2px',
              color: Theme.palette.text.primary,
              outline: 'none !important',
              backgroundColor: `${
                this.state.tab === 'login'
                  ? Theme.palette.primary.light
                  : Theme.palette.primary.dark
              }`,
              boxShadow: `${
                this.state.tab === 'login'
                  ? ''
                  : '0 0 5px 5px rgba(0, 0, 0, 0.2) inset'
              }`,
              ':hover': {
                backgroundColor: Theme.palette.primary.light,
                boxShadow: `${
                  this.state.tab === 'login'
                    ? '0 0 10px 10px rgba(255, 255, 255, 0.1) inset'
                    : '0 0 15px 10px rgba(0, 0, 0, 0.3) inset'
                }`,
              },
            }}
            type="button"
          >
            <Typography variant="h6">Log In</Typography>
          </Button>
          {/* The Styling used, does 2 things. Places them at different "lefts" ( left: 0 on the one above and right: 0 on the one below would have the same effect.)
           * And gives the Non-active tab a gray color, so the user can easily tell which one is active. The rest of the CSS is provided by classes.tab */}
          <Button
            disableElevation={this.state.tab !== 'register'}
            id="registerTab"
            onClick={() => this.handleTab('register')}
            sx={{
              width: 'calc(50% - 1px)',

              borderRadius: '0 5px 0 0',
              height: '52px',
              position: 'absolute',
              left: 'calc(50% - 1px)',
              color: Theme.palette.text.primary,
              top: '2px',
              outline: 'none !important',
              backgroundColor: `${
                this.state.tab === 'register'
                  ? Theme.palette.primary.light
                  : Theme.palette.primary.dark
              }`,
              boxShadow: `${
                this.state.tab === 'register'
                  ? ''
                  : '0 0 5px 5px rgba(0, 0, 0, 0.2) inset'
              }`,
              ':hover': {
                backgroundColor: Theme.palette.primary.light,
                boxShadow: `${
                  this.state.tab === 'register'
                    ? '0 0 10px 10px rgba(255, 255, 255, 0.1) inset'
                    : '0 0 15px 10px rgba(0, 0, 0, 0.3) inset'
                }`,
              },
            }}
            type="button"
          >
            <Typography variant="h6">Sign Up</Typography>
          </Button>
        </Box>

        {/* Here we are simply adding a Box with some marginTop to get a little space
         * And then we are calling one of our forms (based on the active tab)
         *
         * IT IS REQUIRED that the sub classes in this.forms has IDENTICAL names as its respective state.tab
         * (I.E. this.forms.login === this.forms["login"])
         */}
        <Box
          id="login-body"
          style={{
            marginTop: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            padding: '20px',
          }}
        >
          {this.forms[this.state.tab]()}
        </Box>
      </Box>
    );
  }

  /**
   * Main Render Method
   * Contains the Modal and Transition Elements, then calls body() for the "entire" modal card.
   * Also has "The server is processing your request" backdrop, activated when "this.state.working" is true.
   */
  render() {
    return (
      <Dialog
        fullScreen={isMobile}
        aria-describedby="login-body"
        aria-labelledby="login-title"
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        closeAfterTransition
        onClose={() => this.props.onClose(false)}
        open={this.props.open}
      >
        <Box>
          {/* Modal & Fade give us A PopUp With a Fading Transition */}

          <Fade in={this.props.open} timeout={500}>
            {this.body() /* All the interface of the Modal (The White Card) */}
          </Fade>

          {/* This Backdrop element, is used to communicate back end/database work to the user
           *  Activate this before every Axios request, and deactivate it when the request is over.
           * Otherwise users will go insane in the 0-5.0 seconds it takes, and bombard our back end with useless requests.
           */}
          <Backdrop open={this.state.working}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      </Dialog>
    );
  }
}
