import Axios from 'axios';

import { type SessionModel } from '../Models';
import { SessionRoutes } from './Routes';

export const SessionController = {
  async create(title: string, userId: string) {
    const Data = {
      Name: title,
      OwnerId: userId,
    };
    let result: SessionModel | null = null;

    await Axios.post(SessionRoutes.create(), Data).then(
      (res) => {
        if (res.status === 201) {
          result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return result;
  },

  async delete(ownerId: string, sessionId: string) {
    let result = false;

    await Axios.delete(SessionRoutes.delete(ownerId, sessionId)).then(
      (res) => {
        if (res.status === 204) {
          result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return result;
  },

  async recover(ownerId: string, sessionId: string) {
    let result = false;

    await Axios.put(SessionRoutes.recover(ownerId, sessionId)).then(
      (res) => {
        if (res.status === 204) {
          result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return result;
  },

  async ActivateBoard(sessionId: string, phaseId: string, boardId: string) {
    let result = false;

    await Axios.patch(
      SessionRoutes.SetActiveBoard(sessionId, phaseId, boardId)
    ).then(
      (res) => {
        if (res.status === 200) {
          result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return result;
  },

  async ActivateVote(
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) {
    let result = false;

    await Axios.patch(
      SessionRoutes.SetActiveVote(sessionId, phaseId, boardId, voteId)
    ).then(
      (res) => {
        if (res.status === 200) {
          result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return result;
  },

  async ActivateActionPlan(
    sessionId: string,
    phaseId: string,
    actionPlanId: string
  ) {
    let result = false;

    await Axios.patch(
      SessionRoutes.SetActiveActionPlan(sessionId, phaseId, actionPlanId)
    ).then(
      (res) => {
        if (res.status === 200) {
          result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return result;
  },

  async rename(ownerId: string, sessionId: string, title: string) {
    const Data = {
      Name: title,
    };

    let result: SessionModel | null = null;

    await Axios.patch(SessionRoutes.rename(ownerId, sessionId), Data).then(
      (res) => {
        result = res.data;
      },
      (err) => {
        console.error(err);
      }
    );

    return result;
  },

  connect: async (sessionId: string): Promise<SessionModel | null> => {
    let result: SessionModel | null = null;
    await Axios.get(SessionRoutes.connect(sessionId)).then(
      (res) => {
        result = res.data;
      },
      (err) => {
        console.error(err);
      }
    );
    return result;
  },

  get: {
    owned: async (ownerId: string) => {
      let result: Array<SessionModel> = [];

      await Axios.get(SessionRoutes.getOwned(ownerId)).then(
        (res) => {
          result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );
      return result;
    },
    byId: async (ownerId: string, sessionId: string) => {
      let result: SessionModel | null = null;

      await Axios.get(SessionRoutes.getById(ownerId, sessionId)).then(
        (res) => {
          result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return result;
    },
  },
};
