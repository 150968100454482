import { Component } from 'react';
import { alpha, IconButton, styled, Typography } from '@mui/material';

import { getLastEditString } from '@utils';
import { MenuView, TypoField, type IMenuItem } from '../General';

interface IProps {
  creationDateInUnix: number;
  id: string;
  lastOpenedInUnix: number;
  name: string;
  ownerId: string;
  isDeleted: boolean;
  isFirst?: boolean;
  onClick: (id: string) => Promise<void>;
  onRename: (name: string) => void;
  onDelete: () => void;
  onRecover?: () => void | undefined;
}

interface IState {
  hover: boolean;
  collapsed: boolean;
  anchor: Element | null;
  menu: Array<IMenuItem>;
  editTitle: boolean;
}

interface IDiv {
  active?: boolean;
}

const Container = styled('div')<IDiv>(({ theme }) => ({
  width: '200px',
  height: '120px',
  position: 'relative',
  border: `1px solid ${theme.palette.text.disabled}`,
  transition: 'background 1s, box-shadow 1s',
  background: theme.palette.background.paper,
  cursor: 'pointer',
  '&:hover': {
    background: alpha(theme.palette.primary.main, 0.4),
    boxShadow: `0 0 30px 60px ${alpha(
      theme.palette.background.paper,
      0.4
    )} inset`,
  },
}));

const CardHeader = styled('div')<IDiv>(() => ({
  width: '100%',
  position: 'relative',
  height: '40px',
  display: 'flex',
  justifyContent: 'end',
}));

const CardBody = styled('div')({
  width: '100%',
  height: '80px',
  padding: '0 10px',
  position: 'relative',
});

const LastOpened = styled(Typography)<{ isFirst: boolean }>(
  ({ theme, isFirst }) => ({
    color: isFirst ? '#000099' : theme.palette.text.secondary,
    fontStyle: 'italic',
    position: 'absolute',
    bottom: '10px',
    left: '10px',
  })
);

export class SessionCard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      hover: false,
      collapsed: false,
      anchor: null,
      menu: props.isDeleted ? this.RecoverMenu : this.SessionMenu,
      editTitle: false,
    };
  }
  UNSAFE_componentWillUpdate(nextProps: IProps) {
    if (this.props.isDeleted !== nextProps.isDeleted) {
      this.setState({
        menu: nextProps.isDeleted ? this.RecoverMenu : this.SessionMenu,
      });
    }
  }

  Rename = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();

    this.setState({ editTitle: true, anchor: null });
    setTimeout(() => this.setState({ editTitle: false }), 1000);
  };

  SessionMenu: Array<IMenuItem> = [
    { text: 'Rename', callback: this.Rename },
    {
      text: 'Delete',
      callback: (e) => {
        e.stopPropagation();
        this.setState({
          menu: this.ConfirmMenu,
        });
      },
    },
  ];

  ConfirmMenu: Array<IMenuItem> = [
    {
      text: 'Confirm delete board',
      callback: (e) => {
        e.stopPropagation();
        this.props.onDelete();
        this.setState({
          anchor: null,
        });
      },
    },
  ];

  RecoverMenu: Array<IMenuItem> = [
    {
      text: 'Recover',
      callback: (e) => {
        e.stopPropagation();
        this.props.onRecover();
        this.setState({
          anchor: null,
        });
      },
    },
  ];

  render() {
    return (
      <Container
        style={{ display: 'inline-block', margin: '5px' }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={() => this.props.onClick(this.props.id)}
      >
        <CardHeader>
          <IconButton disabled>
            <span className="notranslate material-icons-outlined">
              admin_panel_settings
            </span>
          </IconButton>
          <IconButton
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              this.setState({ anchor: e.currentTarget });
            }}
          >
            <span className="notranslate material-icons-outlined">
              more_vert
            </span>
          </IconButton>
        </CardHeader>
        <CardBody>
          <TypoField
            doubleClickToEdit={true}
            uppercase={false}
            variant="body1"
            text={this.props.name}
            submitChange={this.props.onRename}
            edit={this.state.editTitle}
            disabled={false}
          />
          <LastOpened variant="body2" isFirst={this.props.isFirst}>
            {getLastEditString(this.props.lastOpenedInUnix)}
          </LastOpened>
        </CardBody>

        <MenuView
          items={this.state.menu}
          anchor={this.state.anchor}
          onClose={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            event.stopPropagation();
            this.setState({ anchor: null });
            setTimeout(() => {
              this.setState({ menu: this.SessionMenu });
            }, 250);
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        />
      </Container>
    );
  }
}
