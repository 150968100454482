import { Children, Component, type PropsWithChildren } from 'react';
import {
  Draggable,
  Droppable,
  type DraggableProvided,
  type DroppableProvided,
} from '@hello-pangea/dnd';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { type ColorChangeHandler, type ColorResult } from 'react-color';

import { GroupController } from '../../../Controllers';
import Theme from '../../../Theme/ThemeOptions';
import { ColorBlock, MenuView, TypoField, type IMenuItem } from '../General';

type IProps = PropsWithChildren<{
  color: string;
  column: number;
  id: string;
  index: number;
  isCollapsed: boolean;
  name: string;
  sessionId: string;
  phaseId: string;
  boardId: string;
  userIsOwner: boolean;
  onClick: () => void;
}>;

interface IState {
  color: string;
  column: number;
  id: string;
  index: number;
  isCollapsed: boolean;
  name: string;
  anchors: {
    color: Element | null;
    menu: Element | null;
  };
  editTitle: boolean;
  deleteDialog: boolean;
}

interface IDiv {
  selected?: boolean;
  color: string;
}
const GroupContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'color',
})<IDiv>(({ selected, color }) => ({
  borderRadius: 20,
  position: 'relative',
  boxSizing: 'border-box',
  width: '350px',
  verticalAlign: 'top',
  background: color,
  marginBottom: '15px',
  padding: '105px',
  transition: 'all 0.25s',
  ...(selected
    ? { padding: 2, border: `8px solid${Theme.palette.text.primary}` }
    : { padding: '5 10', border: `0px solid${Theme.palette.text.primary}` }),
}));

export class DraggableGroup extends Component<IProps, IState> {
  constructor(props: IProps | Readonly<IProps>) {
    super(props);
    this.state = {
      color: props.color,
      column: props.column,
      id: props.id,
      index: props.index,
      isCollapsed: props.isCollapsed,
      name: props.name,
      anchors: {
        color: null,
        menu: null,
      },
      editTitle: false,
      deleteDialog: false,
    };
  }

  openColor = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    const Anchor = this.state.anchors.menu;

    this.setState({
      anchors: {
        menu: null,
        color: Anchor,
      },
    });
  };

  changeColor: ColorChangeHandler = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.stopPropagation();
    const Color = color.hex;

    this.setState({ color: Color });
  };

  changeColorComplete: ColorChangeHandler = async (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.stopPropagation();

    const Color = color.hex;
    this.closeColor();
    await GroupController.setColor(
      this.props.sessionId,
      this.props.phaseId,
      this.props.boardId,
      this.props.id,
      Color
    );
  };

  groupCollapse = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (!this.props.userIsOwner) {
      return;
    }

    this.closeMenu();
    GroupController.collapse.single(
      this.props.sessionId,
      this.props.phaseId,
      this.props.boardId,
      this.props.id
    );
  };

  groupCollapseAll = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    GroupController.collapse.all(
      this.props.sessionId,
      this.props.phaseId,
      this.props.boardId
    );
  };

  openMenu(
    event: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>
  ) {
    event.stopPropagation();
    const Anchor = event.currentTarget;

    this.setState({
      anchors: {
        menu: Anchor,
        color: null,
      },
    });
  }

  remove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    GroupController.archive(
      this.props.sessionId,
      this.props.phaseId,
      this.props.boardId,
      this.props.id
    );

    this.setState({ deleteDialog: false });

    this.setState({
      anchors: {
        menu: null,
        color: null,
      },
    });
  };

  deleteDialog = () => {
    this.setState({ deleteDialog: true });
  };

  closeMenu = () => {
    this.setState({
      anchors: {
        ...this.state.anchors,
        menu: null,
      },
    });
  };

  closeColor = () => {
    this.setState({
      anchors: {
        ...this.state.anchors,
        color: null,
      },
    });
  };

  handleName = (name: string) => {
    //TODO: Write this method ? Done?
    GroupController.rename(
      this.props.sessionId,
      this.props.phaseId,
      this.props.boardId,
      this.props.id,
      name.toUpperCase()
    );
  };

  rename = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();

    this.closeMenu();
    this.setState({ editTitle: true });
    setTimeout(() => this.setState({ editTitle: false }), 1000);
  };

  groupMenu: Array<IMenuItem> = [
    { text: 'Rename', callback: this.rename },
    {
      text: `${this.props.isCollapsed ? 'Expand' : 'Collapse'}`,
      callback: this.groupCollapse,
    },
    { text: 'Color', callback: this.openColor },
    { text: 'Remove', callback: this.deleteDialog },
  ];

  handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <Draggable
        draggableId={this.props.id}
        index={this.props.index}
        key={this.props.id}
      >
        {(provided: DraggableProvided) => (
          <GroupContainer
            color={
              Boolean(this.state.anchors.color)
                ? this.state.color
                : this.props.color
            }
            onClick={this.props.onClick}
            selected={false}
            id={this.props.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Droppable droppableId={this.props.id} type="input">
              {(provided: DroppableProvided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    width: '100%',
                    height: '100%',
                    padding: '15px 10px 13px 10px',
                    minHeight: '40px',
                  }}
                >
                  <Box
                    sx={{
                      padding: '0 0 0 3px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'start',
                    }}
                  >
                    <TypoField
                      doubleClickToEdit={false}
                      uppercase={false}
                      variant="h4"
                      text={this.props.name.toUpperCase()}
                      submitChange={this.handleName}
                      edit={this.state.editTitle}
                      disabled={!this.props.userIsOwner}
                    />

                    {this.props.userIsOwner && (
                      <IconButton
                        size="small"
                        sx={{ padding: 0 }}
                        onClick={(
                          e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => this.openMenu(e)}
                      >
                        <span
                          className="notranslate material-icons-outlined"
                          style={{ fontSize: '18px' }}
                        >
                          menu
                        </span>
                      </IconButton>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                      marginBottom: '10px',
                    }}
                  >
                    {!this.props.isCollapsed && this.props.children}
                  </Box>
                  {provided.placeholder}

                  <IconButton
                    size="small"
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => this.groupCollapse(event)}
                    sx={{
                      padding: '0px 12px',
                      border: `1px solid ${Theme.palette.background.default}`,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      transition: 'all 0.5s',
                      position: 'absolute',
                      bottom: '4px',
                      left: '50%',
                      transform: 'translate(-50%, 50%)',
                      background: Boolean(this.state.anchors.color)
                        ? this.state.color
                        : this.props.color,
                      ':hover': {
                        background: this.props.color,
                        boxShadow: '0 0 30px 30px rgba(0, 0, 0, 0.1) inset',
                      },
                    }}
                  >
                    {this.props.children &&
                      Children.count(this.props.children) > 0 &&
                      this.props.isCollapsed && (
                        <Typography
                          variant="subtitle2"
                          sx={{
                            textAlign: 'center',
                            verticalAlign: 'center',
                            fontSize: '14px',
                          }}
                        >
                          {Children.count(this.props.children)}
                        </Typography>
                      )}
                    {this.props.isCollapsed ? (
                      <span
                        className="notranslate material-icons-outlined"
                        style={{ fontSize: '18px' }}
                      >
                        expand_more
                      </span>
                    ) : (
                      <span
                        className="notranslate material-icons-outlined"
                        style={{ fontSize: '18px' }}
                      >
                        expand_less
                      </span>
                    )}
                  </IconButton>
                </div>
              )}
            </Droppable>

            <MenuView
              items={this.groupMenu}
              anchor={this.state.anchors.menu}
              onClose={this.closeMenu}
            />
            <ColorBlock
              anchor={this.state.anchors.color}
              onClose={this.closeColor}
              onChange={this.changeColor}
              onChangeComplete={this.changeColorComplete}
              color={this.state.color ? this.state.color : this.props.color}
            />

            <Dialog
              open={this.state.deleteDialog}
              onClose={() => this.setState({ deleteDialog: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Delete group?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This action cannot be undone, and the content will be
                  permanently erased.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{
                    color: '#000',
                    background: Theme.palette.error.main,
                  }}
                  onClick={this.remove}
                >
                  Remove group
                </Button>
              </DialogActions>
            </Dialog>
          </GroupContainer>
        )}
      </Draggable>
    );
  }
}
