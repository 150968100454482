import { useCallback, useMemo } from 'react';
import { styled, Typography } from '@mui/material';

import { type Handler } from '../../../Data';
import { TooltipView } from '../General';

interface ISlider {
  color: string;
  inputId: string;
  id: string;
  hide: boolean;
  total: number;
  title: string;
  description: string;
  averageRating: number;
  voteId: string;
  highNumber: number;
  lowNumber: number;
  userRating?: number;
  showOwnVote: boolean;
  dataHandler: Handler;
  userIsOwner: boolean;
}

const Container = styled('div')({
  marginBottom: '25px',
});

const Header = styled('div')({
  margin: '0px auto',
  textAlign: 'center',
  fontWeight: 'bold',
  marginLeft: '40px',
  backgroundColor: '#f0f0f0',
});

const Checkbox = styled('input')({
  marginRight: '5px',
  float: 'left',
  width: '20px',
  height: '20px',
  marginTop: '5px',
  marginLeft: '7px',
});

const SliderContainer = styled('div')({
  display: 'flex',
  backgroundColor: '#eee',
  height: '10px',
  marginTop: '15px',
});

const SliderFill = styled('div', {
  shouldForwardProp: (prop) => prop !== 'percent',
})<{ percent: number }>(({ theme, percent }) => ({
  flexGrow: percent,
  backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  alignItems: 'center',
}));

const SliderUser = styled('div', {
  shouldForwardProp: (prop) => prop !== 'percent' && prop !== 'userPercent',
})<{ percent: number; userPercent: number }>(({ percent, userPercent }) => ({
  position: 'absolute',
  width: userPercent === percent ? '42px' : '35px',
  height: userPercent === percent ? '42px' : '35px',
  transform:
    userPercent === percent
      ? 'translateX(-3.5px) translateY(-16px)'
      : 'translateY(-12.5px)',
  backgroundColor: '#aaa',
  borderRadius: '50%',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  color: '#fff',
  left: 79.5 * percent + 9 + '%',
}));

const SliderAverage = styled('div', {
  shouldForwardProp: (prop) => prop !== 'percent' && prop !== 'averagePercent',
})<{ percent: number; hide: boolean }>(({ theme, percent, hide }) => ({
  position: 'absolute',
  width: '35px',
  height: '35px',
  transform: 'translateY(-12.5px)',
  backgroundColor: hide ? '#aaa' : theme.palette.secondary.dark,
  borderRadius: '50%',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  color: '#fff',
  left: 79.5 * percent + 9 + '%',
}));

const TitleTooltip: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <TooltipView title={title !== description ? description : ''}>
    <Typography
      variant="subtitle2"
      sx={{
        height: '30px',
        lineHeight: '30px',
        zIndex: '11115',
      }}
    >
      {title}
      {description && description !== title && (
        <span
          className="notranslate material-icons-outlined"
          style={{
            position: 'relative',
            left: '0px',
            top: '10px',
            color: '#999',
            margin: '0px',
            padding: '0px',
          }}
        >
          expand_more
        </span>
      )}
    </Typography>
  </TooltipView>
);

export const OptionSlider: React.FC<ISlider> = ({
  lowNumber,
  highNumber,
  averageRating,
  userRating,
  hide,
  showOwnVote,
  userIsOwner,
  dataHandler,
  voteId,
  inputId,
  title,
  description,
}) => {
  const offset = lowNumber;

  const percent = useMemo(
    () =>
      isNaN(averageRating) || hide
        ? 0
        : (averageRating - offset) / (highNumber - offset),
    [averageRating, hide, highNumber, offset]
  );

  const userPercent = useMemo(
    () =>
      userRating && !hide
        ? (userRating - offset) / (highNumber - offset)
        : null,
    [hide, highNumber, offset, userRating]
  );

  const isSelected = dataHandler.IsSelected(voteId, inputId);

  const handleInputChange = useCallback(() => {
    dataHandler.Select(voteId, inputId, false);
  }, [dataHandler, inputId, voteId]);

  return (
    <Container>
      <Header>
        {userIsOwner && (
          <Checkbox
            checked={isSelected}
            onChange={handleInputChange}
            type="checkbox"
          />
        )}
        <TitleTooltip title={title} description={description} />
      </Header>
      <SliderContainer>
        <SliderFill percent={percent}>
          <span style={{ height: '50%' }}> </span>
        </SliderFill>
        {!!userPercent && showOwnVote && (
          <SliderUser percent={percent} userPercent={userPercent}>
            <span>{userRating && !hide ? userRating : ''}</span>
          </SliderUser>
        )}
        <SliderAverage percent={percent} hide={hide}>
          <span>{averageRating && !hide ? averageRating : ''}</span>
        </SliderAverage>
        <div style={{ flexGrow: 1 - percent, backgroundColor: '#ddd' }}></div>
      </SliderContainer>
    </Container>
  );
};
