import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Route } from 'react-router';

import Theme from './Theme/ThemeOptions';

import './custom.css';

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import { DashboardView, LandingView, SessionView } from './View';
import { Nickname, Terms } from './View/Components/Landing';
import { Report } from './View/Components/Results';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<LandingView />} path="/" />
      <Route element={<SessionView />} path="/s/:session" />
      <Route element={<Report />} path="/report/:session" />
      <Route element={<DashboardView />} path="/dashboard" />
      <Route element={<Nickname />} path="/t/:sessionCode" />
      <Route element={<Terms />} path="/terms" />
    </>
  )
);

export default function App() {
  return (
    <ThemeProvider theme={Theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
