import { Component, useEffect } from 'react';
import { Droppable, type DroppableProvided } from '@hello-pangea/dnd';
import { Box, IconButton, styled, Typography } from '@mui/material';

import { DraggableInput, TransparentInput } from '.';
import { GroupController, InputController } from '../../../Controllers';
import { type Handler } from '../../../Data';
import type { GroupModel, InputModel } from '../../../Models';
import { Theme } from '../../../Theme';
import { TooltipView } from '../General';

type ObserverProps = {
  values: any[]; // assuming any type of array for simplicity
};

const Observer = (props: ObserverProps) => {
  useEffect(() => {
    const style = document.getElementById('stack-info')?.style;
    style.setProperty('font-size', '25px');

    setTimeout(() => {
      style.setProperty('font-size', 'inherit');
    }, 500);
  }, props.values);
  return null; // component does not render anything
};

export interface IStackProps {
  session: string;
  phaseId: string;
  stack: GroupModel;
  stackChildren: InputModel[];
  buffer: GroupModel;
  bufferChildren: InputModel[];
  handler: Handler;
  userIsOwner: boolean;
  hasNewInputsInStack: boolean;
  onStackContextMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

interface IState {
  flip: boolean;
  topOverflow: boolean;
  compactPending: boolean;
}

const Container = styled('div')({
  bottom: 0,
  position: 'relative',
  padding: '24px 24px 24px 10px',
  background: '#DBDBDB',
  height: '100%',
  transition: 'all 0.25s',
});

const Inputs = styled('div')({
  display: 'flex',
  height: 'calc(100% - 76px)',
  overflowY: 'hidden',
  overflowX: 'hidden',
  flexDirection: 'column',
  justifyContent: 'space-around',
  gap: '10px',
  transition: 'all 0.25s',
});

const TopHalf = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '95%',
  transition: 'all 0.25s',
  overflowY: 'auto',
  flexGrow: '1',
});

const BottomHalf = styled('div')({
  minHeight: '5%',
  flexGrow: '0',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.25s',
  overflowY: 'auto',
});

export class StackView extends Component<IStackProps, IState> {
  constructor(props: IStackProps) {
    super(props);
    this.state = {
      flip: false,
      topOverflow: false,
      compactPending: false,
    };
  }

  UNSAFE_componentWillUpdate(nextProps: IStackProps) {
    const movedFromStack =
      nextProps.stackChildren.length === this.props.stackChildren.length - 1;
    const movedFromBuffer =
      nextProps.bufferChildren.length === this.props.bufferChildren.length - 1;
    const movedBottomStackInput =
      this.props.stackChildren.length > 0 &&
      nextProps.stackChildren.length > 0 &&
      this.props.stackChildren[this.props.stackChildren.length - 1].id !==
        nextProps.stackChildren[nextProps.stackChildren.length - 1].id;
    const movedBottomBufferInput =
      this.props.bufferChildren.length > 0 &&
      nextProps.bufferChildren.length > 0 &&
      this.props.bufferChildren[this.props.bufferChildren.length - 1].id !==
        nextProps.bufferChildren[nextProps.bufferChildren.length - 1].id;
    const movedLastStack = nextProps.stackChildren.length === 0;
    const movedLastBuffer = nextProps.bufferChildren.length === 0;

    if (
      (movedFromStack && !movedBottomStackInput && !movedLastStack) ||
      (movedFromBuffer && !movedBottomBufferInput && !movedLastBuffer)
    ) {
      this.setState({ compactPending: true });
    } else if (movedLastStack) {
      if (this.state.compactPending) {
        this.setState({ compactPending: false });
      }
    }
  }

  keydownListener: any;

  RenderSwissCheese(inputs: InputModel[], transparent: boolean) {
    return inputs.map((input, idx) => {
      return (
        <div
          key={input.id}
          id={input.id + '-container'}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: this.props.buffer.isCollapsed ? 'calc(50% - 5px)' : '100%',
          }}
        >
          <Typography
            sx={{ marginRight: '10px', width: '15px', textAlign: 'center' }}
            variant="subtitle1"
          >
            {idx + 1}
          </Typography>
          {transparent ? (
            <TransparentInput
              session={this.props.session}
              phase={this.props.session}
              onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                this.props.handler.Select(
                  this.props.stack.boardId,
                  input.id,
                  event.ctrlKey
                )
              }
              selected={this.props.handler.IsSelected(
                this.props.stack.boardId,
                input.id
              )}
              {...input}
              index={idx}
            />
          ) : (
            <DraggableInput
              session={this.props.session}
              phase={this.props.session}
              userIsOwner={this.props.userIsOwner}
              onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                this.props.handler.Select(
                  this.props.stack.boardId,
                  input.id,
                  event.ctrlKey
                )
              }
              selected={this.props.handler.IsSelected(
                this.props.stack.boardId,
                input.id
              )}
              {...input}
              index={idx}
            />
          )}
        </div>
      );
    });
  }

  CompressSwissCheese() {
    const SESSION = this.props.session;
    const PHASE = this.props.session;
    const BOARD = this.props.stack.boardId;

    InputController.compressSwissCheese(SESSION, PHASE, BOARD);
    this.setState({ compactPending: false });
  }

  CollapseGroup = async (id: string) => {
    const SESSION = this.props.session;
    const PHASE = this.props.session;
    const BOARD = this.props.stack.boardId;
    await GroupController.collapse.single(SESSION, PHASE, BOARD, id);
    if (id === this.props.buffer.id) {
      InputController.compressSwissCheese(SESSION, PHASE, BOARD);
    }
  };

  StackRender(isFlipped: boolean) {
    return (
      <Droppable droppableId={this.props.stack.id} isDropDisabled type="input">
        {(provided: DroppableProvided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              height:
                this.state.flip && this.state.topOverflow ? '150px' : '100%',
              display: 'flex',
              alignContent: 'start',
              flexDirection: this.props.buffer.isCollapsed ? 'row' : 'column',
              flexWrap: this.props.buffer.isCollapsed ? 'wrap' : 'nowrap',
              gap: '10px',
              paddingRight: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                alignItems: 'center',
                paddingTop: '20px',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  border: '1px solid #000',
                  padding: '3px',
                }}
                variant="subtitle1"
              >
                INCOMING
              </Typography>

              {this.props.stackChildren.length === 0 &&
                this.props.bufferChildren.length === 0 && (
                  <div
                    style={{ borderTop: '2px dotted #707070', flexGrow: 1 }}
                  />
                )}
              {(this.props.stackChildren.length > 0 ||
                this.props.bufferChildren.length > 0) && (
                <div
                  style={{
                    display: 'flex',
                    flexGrow: '1',
                    gap: '10px',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.setState({ flip: !this.state.flip });
                  }}
                >
                  <div
                    style={{ borderTop: '2px dotted #707070', flexGrow: 1 }}
                  />
                  <TooltipView title="Switch to Parking lot (press 3)">
                    <IconButton
                      size="small"
                      sx={{
                        borderRadius: '50%',
                        background: '#DBDBDB',
                        border: `1px solid ${Theme.palette.text.secondary}`,
                        transition: 'all 0.5s',
                        ':hover': {
                          background: '#DBDBDB',
                          boxShadow: '0 0 30px 30px rgba(0,0,0,0.1) inset',
                        },
                      }}
                      disabled={this.props.bufferChildren.length === 0}
                    >
                      <span className="notranslate material-icons">
                        swap_vert
                      </span>
                    </IconButton>
                  </TooltipView>
                  <div
                    style={{ borderTop: '2px dotted #707070', flexGrow: 1 }}
                  />
                </div>
              )}
            </div>

            {(this.state.topOverflow ||
              this.props.stackChildren.length === 0) &&
              isFlipped && (
                <div
                  style={{
                    backgroundColor: '#eee',
                    borderRadius: '3px',
                    fontStyle: 'italic',
                    width: '100%',
                    textAlign: 'center',
                    height: '50px',
                    lineHeight: '50px',
                    fontSize: '14px',
                    cursor:
                      this.props.stackChildren.length > 0
                        ? 'pointer'
                        : 'inherit',
                  }}
                  onClick={() => {
                    if (this.props.stackChildren.length > 0) {
                      this.setState({ flip: !this.state.flip });
                    }
                  }}
                >
                  {`${this.props.stackChildren.length} input${
                    this.props.stackChildren.length > 1 ? 's' : ''
                  } in proposals`}
                </div>
              )}

            {((!this.state.topOverflow &&
              this.props.stackChildren.length > 0) ||
              !isFlipped) &&
              this.RenderSwissCheese(this.props.stackChildren, false)}
          </div>
        )}
      </Droppable>
    );
  }

  BufferRender(isFlipped: boolean) {
    if (
      this.props.stackChildren.length === 0 &&
      this.props.bufferChildren.length === 0
    ) {
      return <></>;
    }

    return (
      <Droppable droppableId={this.props.buffer.id} type="input">
        {(provided: DroppableProvided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              height:
                !this.state.flip && this.state.topOverflow ? '150px' : '100%',
              display: 'flex',
              alignContent: 'start',
              flexDirection: this.props.buffer.isCollapsed ? 'row' : 'column',
              flexWrap: this.props.buffer.isCollapsed ? 'wrap' : 'nowrap',
              gap: '10px',
              paddingRight: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                alignItems: 'center',
                paddingTop: '20px',
                width: '100%',
              }}
            >
              <TooltipView title="Proposals here may be added to the board later">
                <Typography
                  sx={{ fontWeight: '500', opacity: '0.7' }}
                  variant="subtitle1"
                >
                  PARKING LOT
                </Typography>
              </TooltipView>

              <div
                style={{
                  display: 'flex',
                  flexGrow: '1',
                  gap: '10px',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.setState({ flip: !this.state.flip });
                }}
              >
                <div style={{ borderTop: '2px dotted #707070', flexGrow: 1 }} />
                <TooltipView title="Switch to Incoming proposals (press 3)">
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: '50%',
                      background: '#DBDBDB',
                      border: `1px solid ${Theme.palette.text.secondary}`,
                      transition: 'all 0.5s',
                      ':hover': {
                        background: '#DBDBDB',
                        boxShadow: '0 0 30px 30px rgba(0,0,0,0.1) inset',
                      },
                    }}
                    disabled={this.props.bufferChildren.length === 0}
                  >
                    <span className="notranslate material-icons">
                      swap_vert
                    </span>
                  </IconButton>
                </TooltipView>
                <div style={{ borderTop: '2px dotted #707070', flexGrow: 1 }} />
              </div>
            </div>

            {(this.state.topOverflow ||
              this.props.bufferChildren.length === 0) &&
              !isFlipped && (
                <div
                  style={{
                    backgroundColor: '#eee',
                    borderRadius: '3px',
                    fontStyle: 'italic',
                    width: '100%',
                    textAlign: 'center',
                    height: '50px',
                    lineHeight: '50px',
                    fontSize: '14px',
                    cursor:
                      this.props.bufferChildren.length > 0
                        ? 'pointer'
                        : 'inherit',
                  }}
                  onClick={() => {
                    if (this.props.bufferChildren.length > 0) {
                      this.setState({ flip: !this.state.flip });
                    }
                  }}
                >
                  {this.props.bufferChildren.length === 0
                    ? 'Drag and drop items to the parking lot.'
                    : `${this.props.bufferChildren.length} input${
                        this.props.bufferChildren.length > 1 ? 's' : ''
                      } in parking lot`}
                </div>
              )}

            {((!this.state.topOverflow &&
              this.props.bufferChildren.length > 0) ||
              isFlipped) &&
              this.RenderSwissCheese(
                this.props.bufferChildren,
                !isFlipped
                // false
              )}
          </div>
        )}
      </Droppable>
    );
  }

  componentDidMount() {
    this.keydownListener = this.keydownFunc.bind(this);
    document.addEventListener('keydown', this.keydownListener);
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.keydownListener);
  }

  keydownFunc(ev: KeyboardEvent): void {
    const tagName = (ev.target as HTMLElement).tagName.toUpperCase();
    if (
      tagName.toUpperCase() !== 'INPUT' &&
      tagName.toUpperCase() !== 'TEXTAREA'
    ) {
      if (ev.key === '2') {
        this.props.stack.isCollapsed = !this.props.stack.isCollapsed;
        if (this.props.stack.isCollapsed) {
          this.props.buffer.isCollapsed = false;
        } else {
          this.props.buffer.isCollapsed = true;
        }

        this.props.handler.ClearNewInputNotification();
        this.props.handler.ForceRender();

        if (this.props.stack.isCollapsed) {
          const orgContainer = document.querySelector('#org-container');
          orgContainer?.scrollTo(0, 0);
        }
      } else if (ev.key === '3') {
        if (
          !this.props.stack.isCollapsed &&
          this.props.bufferChildren.length > 0
        ) {
          this.setState({ flip: !this.state.flip });
        }
      }
    }
  }

  componentDidUpdate(_: any, prevState: IState) {
    const total =
      this.props.stackChildren.length + this.props.bufferChildren.length;
    const limit = this.props.buffer.isCollapsed ? 16 : 8;
    const topOverflow = total > limit;

    if (prevState.topOverflow !== topOverflow) {
      this.setState({
        topOverflow: topOverflow,
      });
    }
  }

  render() {
    const numberOfInputs =
      this.props.stackChildren.length + this.props.bufferChildren.length;
    return (
      <Container
        style={{
          width: this.props.stack.isCollapsed
            ? '0px'
            : this.props.buffer.isCollapsed
            ? '732px'
            : '366px',
        }}
        onContextMenu={this.props.onStackContextMenu}
      >
        {this.props.userIsOwner &&
          (!this.props.buffer.isCollapsed || this.props.stack.isCollapsed) && (
            <>
              {this.props.stack.isCollapsed && numberOfInputs > 0 && (
                <>
                  <div
                    id="stack-info"
                    onClick={() => {
                      this.props.stack.isCollapsed = false;
                      this.props.handler.ClearNewInputNotification();
                      this.props.handler.ForceRender();
                    }}
                    style={{
                      position: 'absolute',
                      top: '75px',
                      zIndex: '100',
                      left: '2px',
                      width: '30px',
                      height: '30px',
                      textAlign: 'center',
                      background: '#bababa',
                      color: this.props.hasNewInputsInStack ? '#d00' : '#000',
                      fontWeight: this.props.hasNewInputsInStack
                        ? 'bold'
                        : 'normal',
                      transition: 'all 0.2s ease-in-out',
                      borderRadius: '50%',
                      lineHeight: '30px',
                      cursor: 'pointer',
                    }}
                  >
                    {numberOfInputs}
                  </div>
                  <Observer values={[this.props.stackChildren.length]} />
                </>
              )}
              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: '5px',
                  zIndex: '100',
                  right: '0',
                  transform: 'translateX(50%)',
                  borderRadius: '50%',
                  background: '#DBDBDB',
                  border: `1px solid ${Theme.palette.text.secondary}`,
                  transition: 'all 0.5s',
                  ':hover': {
                    background: '#DBDBDB',
                    boxShadow: '0 0 30px 30px rgba(0,0,0,0.1) inset',
                  },
                }}
                onClick={() => {
                  this.props.stack.isCollapsed = !this.props.stack.isCollapsed;
                  this.props.handler.ClearNewInputNotification();
                  this.props.handler.ForceRender();
                  if (this.props.stack.isCollapsed) {
                    const orgContainer =
                      document.querySelector('#org-container');
                    orgContainer?.scrollTo(0, 0);
                  }
                }}
              >
                {this.props.stack.isCollapsed ? (
                  <TooltipView title="Show proposals">
                    <span
                      className="notranslate material-icons-outlined"
                      style={{ fontSize: '18px' }}
                    >
                      chevron_right
                    </span>
                  </TooltipView>
                ) : (
                  <TooltipView title="Hide proposals (press 2)">
                    <span
                      className="notranslate material-icons-outlined"
                      style={{ fontSize: '18px' }}
                    >
                      chevron_left
                    </span>
                  </TooltipView>
                )}
              </IconButton>
            </>
          )}
        {this.props.userIsOwner &&
          ((!this.props.buffer.isCollapsed && this.props.stack.isCollapsed) ||
            (this.props.buffer.isCollapsed &&
              !this.props.stack.isCollapsed)) && (
            <TooltipView
              title={
                this.props.buffer.isCollapsed
                  ? 'Hide proposals (press 2)'
                  : 'Show wide (press 2)'
              }
            >
              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: '40px',
                  zIndex: '100',
                  right: '0',
                  transform: 'translateX(50%)',
                  borderRadius: '50%',
                  background: '#DBDBDB',
                  border: `1px solid ${Theme.palette.text.secondary}`,
                  transition: 'all 0.5s',
                  ':hover': {
                    background: '#DBDBDB',
                    boxShadow: '0 0 30px 30px rgba(0,0,0,0.1) inset',
                  },
                }}
                onClick={() => {
                  this.props.stack.isCollapsed = !this.props.stack.isCollapsed;
                  if (this.props.stack.isCollapsed) {
                    this.props.buffer.isCollapsed = false;
                  } else {
                    this.props.buffer.isCollapsed = true;
                  }

                  this.props.handler.ClearNewInputNotification();
                  this.props.handler.ForceRender();

                  if (this.props.stack.isCollapsed) {
                    const orgContainer =
                      document.querySelector('#org-container');
                    orgContainer?.scrollTo(0, 0);
                  }
                }}
              >
                <span
                  className="notranslate material-icons-outlined"
                  style={{
                    fontSize: '18px',
                    padding: '0px 1px',
                    width: '9px',
                    position: 'relative',
                    left: '-4px',
                  }}
                >
                  {this.props.buffer.isCollapsed
                    ? 'chevron_left'
                    : 'chevron_right'}
                </span>
                <span
                  className="notranslate material-icons-outlined"
                  style={{
                    fontSize: '18px',
                    padding: '0px 1px',
                    width: '9px',
                    position: 'relative',
                    left: '-6px',
                  }}
                >
                  {this.props.buffer.isCollapsed
                    ? 'chevron_left'
                    : 'chevron_right'}
                </span>
              </IconButton>
            </TooltipView>
          )}
        {this.props.userIsOwner && !this.props.stack.isCollapsed && (
          <TooltipView
            title={
              this.props.buffer.isCollapsed
                ? 'Reduce proposals'
                : 'Expand proposals'
            }
          >
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                top: this.props.buffer.isCollapsed ? '5px' : '40px',
                zIndex: '100',
                right: '0',
                transform: 'translateX(50%)',
                borderRadius: '50%',
                background: '#DBDBDB',
                border: `1px solid ${Theme.palette.text.secondary}`,
                transition: 'all 0.5s',
                ':hover': {
                  background: '#DBDBDB',
                  boxShadow: '0 0 30px 30px rgba(0,0,0,0.1) inset',
                },
              }}
              onClick={() => {
                this.props.buffer.isCollapsed = !this.props.buffer.isCollapsed;
                this.props.handler.ClearNewInputNotification();
                this.props.handler.ForceRender();
              }}
            >
              {!this.props.buffer.isCollapsed ? (
                <span
                  className="notranslate material-icons-outlined"
                  style={{ fontSize: '18px' }}
                >
                  chevron_right
                </span>
              ) : (
                <span
                  className="notranslate material-icons-outlined"
                  style={{ fontSize: '18px' }}
                >
                  chevron_left
                </span>
              )}
            </IconButton>
          </TooltipView>
        )}
        {!this.props.stack.isCollapsed && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <Typography variant="h4">Proposals</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <TooltipView title="Compact and renumber items">
                <IconButton
                  onClick={() => this.CompressSwissCheese()}
                  size="small"
                  sx={{
                    borderRadius: '4px',
                    background: this.state.compactPending ? '#fff' : '#DBDBDB',
                    color: this.state.compactPending ? 'red' : 'inherit',
                    border: `1px solid ${Theme.palette.text.secondary}`,
                    transition: 'all 0.5s',
                    ':hover': {
                      background: '#DBDBDB',
                      boxShadow: '0 0 30px 30px rgba(0,0,0,0.1) inset',
                    },
                  }}
                >
                  <span className="notranslate material-icons-outlined">
                    compress
                  </span>
                </IconButton>
              </TooltipView>
            </Box>
          </div>
        )}
        <Inputs>
          <TopHalf id="top">
            {
              this.state.flip
                ? this.BufferRender(this.state.flip)
                : this.StackRender(this.state.flip) //Default
            }
          </TopHalf>
          <BottomHalf>
            {
              this.state.flip
                ? this.StackRender(this.state.flip)
                : this.BufferRender(this.state.flip) //Default
            }
          </BottomHalf>
        </Inputs>
      </Container>
    );
  }
}
