import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  InputAdornment,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router';

import { getCookie } from '@utils';
import { CodesController, SessionController } from '../Controllers';
import { Theme } from '../Theme';
import { PrimaryButton as Button } from './Components/General';
import { LoginModal } from './Components/Landing';

const CodeTextField = styled(TextField)(({ theme }) => ({
  borderRadius: '3em',
  boxShadow: `0 0 8px 16px ${theme.palette.primary.main}`,
  outline: '0',
  '& .MuiFilledInput-root': {
    borderRadius: '3em',
    background: theme.palette.background.paper,
    outline: 0,
    border: '0',
    margin: '0 20px',
    ':hover': {
      border: 'none !important',
    },
    ':active': {
      border: 'none !important',
    },
  },
  '& .MuiFilledInput-input': {
    outline: 0,
    border: 'none !important',
    padding: '12px',
    ':hover': {
      border: 'none !important',
    },
    ':active': {
      border: 'none !important',
    },
  },
  ':hover': {
    boxShadow: `0 0 4px 16px ${theme.palette.primary.light}`,

    border: 'none !important',
  },
  ':active': {
    boxShadow: `0 0 4px 16px ${theme.palette.primary.light}`,

    border: 'none !important',
  },
}));

export const LandingView = () => {
  const [code, setCode] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [canViewDashboard, setCanViewDashboard] = useState<boolean>(false);

  const navigate = useNavigate();

  const narrowView = useMediaQuery('(max-width: 360px)');

  const openLoginModal = useCallback(() => setShowLoginModal(true), []);

  useEffect(() => {
    const userId = getCookie('UserId');

    async function getSessions() {
      const sessions = await SessionController.get.owned(userId);
      return sessions;
    }

    if (userId) {
      getSessions().then((sessions) => {
        if (sessions?.length > 0) {
          setCanViewDashboard(true);
        }
      });
    }
  }, []);

  const closeLoginModal = useCallback(
    (loggedIn: boolean) => {
      if (loggedIn) {
        navigate('/dashboard');
      }
      setShowLoginModal(false);
    },
    [navigate]
  );

  const handleCode = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCode(event.target.value);
  };

  const connect = async () => {
    const CODE = parseInt(code);

    if (CODE > 99999 && CODE < 1000000) {
      const found = await CodesController.connect(CODE);
      if (found) {
        navigate(found);
      }
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        margin: 0,
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: narrowView ? '100vw' : 'calc(100% - 60px)',
          height: '90px',
          margin: isMobile ? '5px' : '30px',
        }}
      >
        {!canViewDashboard ? (
          <Button
            onClick={openLoginModal}
            sx={{
              background: Theme.palette.text.primary,
              color: Theme.palette.background.paper,
              float: 'right',
              display: 'inline-block',
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            type="submit"
          >
            {isMobile ? 'Login' : 'Login / Sign up'}
          </Button>
        ) : (
          <Button
            onClick={() => navigate('/dashboard')}
            sx={{
              background: Theme.palette.text.primary,
              color: Theme.palette.background.paper,
              float: 'right',
              display: 'inline-block',
              position: 'relative',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            type="submit"
          >
            Dashboard
          </Button>
        )}

        {!isMobile && (
          <>
            <Typography
              sx={{
                lineHeight: '90px',
                textAlign: 'center',
                margin: '0 20px',
                float: 'right',
              }}
              variant="subtitle2"
            >
              Contact us
            </Typography>

            <Typography
              sx={{
                lineHeight: '90px',
                textAlign: 'center',
                margin: '0 20px',
                float: 'right',
              }}
              variant="subtitle2"
            >
              Pricing
            </Typography>

            <Typography
              sx={{
                lineHeight: '90px',
                textAlign: 'center',
                margin: '0 20px',
                float: 'right',
              }}
              variant="subtitle2"
            >
              About
            </Typography>
          </>
        )}
        <Typography
          sx={{
            color: Theme.palette.primary.light,
            lineHeight: '90px',
            textAlign: 'center',
            margin: '0',
            float: 'left',
            fontSize: narrowView ? 40 : 50,
          }}
          variant="h1"
        >
          co
        </Typography>

        <Typography
          sx={{
            lineHeight: '90px',
            textAlign: 'center',
            margin: '0',
            float: 'left',
            fontSize: narrowView ? 40 : 50,
          }}
          variant="h1"
        >
          boost
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: !isMobile ? 'center' : 'start',
          height: 'calc(100% - 90px)',
          margin: isMobile ? '30px 10px' : '0 30px',
          flexDirection: 'row',
          width: '100vw',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              marginBottom: '20px',
              fontSize: isMobile ? '22px' : '40px',
            }}
            variant="h2"
          >
            The Tool for Digital Co-Creation
          </Typography>

          <Typography sx={{ marginBottom: '40px' }} variant="body1">
            Enter code to co-create and innovate with your team members
          </Typography>

          <form>
            <CodeTextField
              id="code"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ borderRadius: '3em' }}
                  >
                    <Button onClick={connect}>Join</Button>
                  </InputAdornment>
                ),
              }}
              name="code"
              value={code}
              autoComplete="off"
              onChange={handleCode}
              variant="filled"
              placeholder="Type session code"
            />
          </form>
        </Box>
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: "center'",
              alignItems: 'center',
            }}
          >
            <img id="main-image" src={'./Illustrasjon.svg'} alt="main" />
          </Box>
        )}
      </Box>

      {showLoginModal && (
        <LoginModal onClose={closeLoginModal} open={showLoginModal} />
      )}
    </Box>
  );
};
