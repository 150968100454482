import { Component } from 'react';
import { Box, IconButton, styled } from '@mui/material';

import { getCookie } from '@utils';
import { OptionSlider } from '.';
import { VoteController } from '../../../Controllers';
import { type Handler } from '../../../Data';
import type { OptionModel, TicketModel } from '../../../Models';
import { PrimaryButton, TooltipView } from '../General';

interface IProps {
  countdownEnabled: boolean;
  countdownEnd: number;
  countdownTime: number;
  countdownLock: boolean;
  lockData: boolean;
  session: string;
  description: string;
  id: string;
  index: number;
  highDescription: string;
  highNumber: number;
  lowDescription: string;
  lowNumber: number;
  hideVotes: boolean;
  boardId: string;
  title: string;
  type: string;
  options: Array<OptionModel>;
  tickets: Array<TicketModel>;
  isDeleted: boolean;
  userIsOwner: boolean;
  showOwnVote: boolean;
  dataHandler: Handler;
  noVotes: number;
  navigationIsCollapsed: boolean;
  rightSide: boolean;
}

interface IDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active?: boolean;
}

const Container = styled(
  ({ active, ...divProps }: IDiv) => <div {...divProps} />,
  { shouldForwardProp: (prop) => prop !== 'active' }
)<IDiv>(() => ({
  width: '100%',
  height: '90%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
}));

const Results = styled(
  ({ active, ...divProps }: IDiv) => <div {...divProps} />,
  { shouldForwardProp: (prop) => prop !== 'active' }
)<IDiv>(() => ({
  width: '80%',
  display: 'flex',
  margin: '-75px auto 100px',
  flexDirection: 'column',
  gap: '20px',
}));

type IState = {
  overflow: boolean;
};

export class SliderVote extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      overflow: false,
    };
  }

  resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    const target = entries[0].target;
    this.setState({
      overflow: target.scrollHeight > target.clientHeight,
    });
  });

  componentDidMount() {
    const container = document.getElementById('slidervote')!;
    this.resizeObserver.observe(container);
  }

  componentWillUnmount(): void {
    this.resizeObserver.disconnect();
  }

  componentDidUpdate(_, prevState) {
    const container = document.getElementById('slidervote')!;
    const overflow = container.scrollHeight > container.clientHeight;
    if (overflow !== prevState.overflow) {
      this.setState({
        overflow,
      });
    }
  }

  lockToggle = () => {
    const SESSION = this.props.session;
    const BOARD = this.props.boardId;
    const VOTE = this.props.id;
    VoteController.lockData(SESSION, SESSION, BOARD, VOTE);

    const votesStartedString = localStorage.getItem('votesStarted') ?? '[]';

    const votesStarted: string[] = JSON.parse(votesStartedString);

    if (!votesStarted.includes(VOTE)) {
      votesStarted.push(VOTE);
    }

    localStorage.setItem('votesStarted', JSON.stringify(votesStarted));
  };

  hideResults = () => {
    const SESSION = this.props.session;
    const BOARD = this.props.boardId;
    const VOTE = this.props.id;
    VoteController.hideResults(SESSION, SESSION, BOARD, VOTE);
  };

  render() {
    const OPTIONS = this.props.options;

    const userId = getCookie('UserId');
    const tickets = this.props.tickets.filter(
      (ticket) => ticket.voteId === this.props.id && ticket.userId === userId
    );

    const userRatings = {};

    if (tickets.length > 0) {
      tickets[0].ratings.forEach((rating) => {
        userRatings[rating.key] = rating.value;
      });
    }

    if (!this.props.hideVotes) {
      OPTIONS.sort(
        (a, b) =>
          b.averageRating - a.averageRating || a.title.localeCompare(b.title)
      );
    } else {
      OPTIONS.sort((a, b) => a.title.localeCompare(b.title));
    }

    const hasStarted =
      localStorage.getItem('votesStarted')?.indexOf(this.props.id) >= 0;

    return (
      <Container
        id="slidervote"
        style={{
          height: '100vh',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <div style={{ height: '100px' }}></div>

        {this.props.userIsOwner &&
          this.props.hideVotes &&
          this.props.lockData && (
            <div
              style={{
                textAlign: 'center',
                position: 'fixed',
                right: this.props.rightSide ? '390px' : '11px',
                bottom: '0',
                left: this.props.navigationIsCollapsed ? '15px' : '390px',
                zIndex: '100',
                marginBottom: '15px',
              }}
            >
              <PrimaryButton
                style={{
                  padding: '15px 30px',
                  fontSize: '18px',
                }}
                onClick={this.lockToggle}
              >
                {hasStarted ? 'Open voting session' : 'Start voting session'}
              </PrimaryButton>
            </div>
          )}

        {this.props.hideVotes && !this.props.lockData && (
          <div
            style={{
              textAlign: 'center',
              position: 'fixed',
              right: this.props.rightSide ? '390px' : '11px',
              bottom: '0px',
              left: this.props.navigationIsCollapsed ? '15px' : '390px',
              zIndex: '100',
              backgroundColor: '#fff',
              borderTop: '1px solid #ddd',
              padding: '15px 15px',
            }}
          >
            <h2 style={{ marginBottom: '10px' }}>
              Results of voting will soon be revealed. Please wait...
            </h2>

            {this.props.userIsOwner && (
              <PrimaryButton
                style={{
                  padding: '15px 30px',
                  fontSize: '18px',
                }}
                onClick={this.hideResults}
              >
                <span style={{ color: '#fff' }}>{this.props.noVotes}</span>
                <span
                  style={{
                    fontSize: '25px',
                    position: 'relative',
                    top: '-1px',
                    marginRight: '8px',
                  }}
                  className="notranslate material-icons-outlined"
                >
                  how_to_vote
                </span>
                Reveal results
              </PrimaryButton>
            )}
          </div>
        )}

        {
          <Results>
            <div
              style={{
                position: this.state.overflow ? 'sticky' : 'relative',
                marginLeft: '-155px',
                fontSize: '25px',
                fontWeight: 'bold',
                top: this.state.overflow ? '50%' : 'calc(50% + 50px)',
                pointerEvents: 'none',
              }}
            >
              <div
                style={{
                  transform: 'rotate(-90deg)',
                  display: 'inline-block',
                  width: '200px',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
              >
                {this.props.lowNumber + ': ' + this.props.lowDescription}
              </div>
            </div>
            <div
              style={{
                position: this.state.overflow ? 'sticky' : 'relative',
                margin: '0 -135px 0 auto',
                fontSize: '25px',
                fontWeight: 'bold',
                top: this.state.overflow ? '50%' : 'calc(50% - 5px)',
                pointerEvents: 'none',
              }}
            >
              <div
                style={{
                  transform: 'rotate(-90deg)',
                  display: 'inline-block',
                  width: '200px',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}
              >
                {this.props.highNumber + ': ' + this.props.highDescription}
              </div>
            </div>
            {OPTIONS.map((option) => (
              <OptionSlider
                key={option.id}
                hide={this.props.hideVotes}
                lowNumber={this.props.lowNumber}
                highNumber={this.props.highNumber}
                userRating={userRatings[option.id]}
                showOwnVote={this.props.showOwnVote}
                dataHandler={this.props.dataHandler}
                userIsOwner={this.props.userIsOwner}
                {...option}
              ></OptionSlider>
            ))}
          </Results>
        }
        {this.props.userIsOwner && (
          <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
            <TooltipView
              title={
                this.props.lockData
                  ? 'Closed vote, click to open'
                  : 'Open vote, click to close'
              }
              placement="top-end"
            >
              <IconButton onClick={this.lockToggle}>
                <span
                  style={{
                    color: this.props.lockData ? '#e00' : 'rgba(0, 0, 0, 0.54)',
                    position: 'relative',
                    width: '32px',
                    fontSize: '32px',
                  }}
                  className="notranslate material-icons-outlined"
                >
                  {this.props.lockData ? 'lock' : 'lock_open'}
                </span>
              </IconButton>
            </TooltipView>
            <TooltipView
              title={
                this.props.hideVotes
                  ? 'Results hidden, click to reveal'
                  : 'Results revealed, click to hide'
              }
              placement="top-end"
            >
              <IconButton onClick={this.hideResults}>
                <span
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    position: 'relative',
                    top: '3px',
                    width: '32px',
                    fontSize: '32px',
                  }}
                  className="notranslate material-icons-outlined"
                >
                  {this.props.hideVotes ? 'visibility_off' : 'visibility_on'}
                </span>
              </IconButton>
            </TooltipView>
          </Box>
        )}
      </Container>
    );
  }
}
