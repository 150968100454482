import { Component } from 'react';
import {
  alpha,
  Box,
  Divider,
  IconButton,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { getCookie } from '@utils';
import { SessionController } from '../Controllers';
import { type SessionModel } from '../Models';
import { Theme } from '../Theme';
import { SessionCard } from './Components/Dashboard';
import {
  PrimaryButton as Button,
  MenuView,
  SecondaryButton,
  type IMenuItem,
} from './Components/General';
import { withRouter, type WithRouterProps } from './WithRouter';

interface IProps {}

interface IState {
  sessions: SessionModel[];
  creating: boolean;
  title: string;
  description: string;
  access: 'admin' | 'password' | 'public';
  password: string;
  anchor: Element | null;
  showDeleted: boolean;
}

export class DashboardViewComponent extends Component<
  WithRouterProps<IProps>,
  IState
> {
  constructor(props: WithRouterProps<IProps>) {
    super(props);
    this.state = {
      sessions: [],
      creating: false,
      title: '',
      description: '',
      access: 'admin',
      password: '',
      anchor: null,
      showDeleted: false,
    };
  }

  showDeletedMenu: Array<IMenuItem> = [
    {
      text: 'Show deleted',
      callback: (e) => {
        e.stopPropagation();
        this.setState({
          showDeleted: true,
          anchor: null,
        });
      },
    },
  ];

  showAllMenu: Array<IMenuItem> = [
    {
      text: 'Show all',
      callback: (e) => {
        e.stopPropagation();
        this.setState({
          showDeleted: false,
          anchor: null,
        });
      },
    },
  ];

  async componentDidMount() {
    const userId = getCookie('UserId');

    if (userId) {
      const sessions = await SessionController.get.owned(userId);
      if (sessions) {
        this.setState({
          sessions: sessions,
        });
      }
    }
  }

  async CreateSession(
    event:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    const userId = getCookie('UserId');
    const title = this.state.title;

    if (userId) {
      const Session: SessionModel | null = await SessionController.create(
        title,
        userId
      );

      if (Session) {
        window.location.replace(`/s/${Session.id}`);
      }
    }
  }

  async LoadSession(id: string) {
    this.props.navigate(`/s/${id}`);
  }

  HandleTitle = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const VALUE = event.target.value;

    this.setState({ title: VALUE });
  };

  HandleDescription = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const VALUE = event.target.value;

    this.setState({ description: VALUE });
  };

  unixToDate(unix: number) {
    //TODO: Move this out of this class
    const Time = new Date(unix);

    let month = '';
    switch (Time.getMonth() + 1) {
      case 1:
        month = 'January';
        break;
      case 2:
        month = 'February';
        break;
      case 3:
        month = 'March';
        break;
      case 4:
        month = 'April';
        break;
      case 5:
        month = 'May';
        break;
      case 6:
        month = 'June';
        break;
      case 7:
        month = 'July';
        break;
      case 8:
        month = 'August';
        break;
      case 9:
        month = 'September';
        break;
      case 10:
        month = 'October';
        break;
      case 11:
        month = 'November';
        break;
      case 12:
        month = 'December';
        break;
    }

    return `${Time.getDate()}. ${month} ${Time.getFullYear()}`;
  }

  create = {
    open: () => {
      this.setState({ creating: true });
    },
    close: () => {
      this.setState({ creating: false });
    },
  };

  toLanding = () => {
    this.props.navigate('/');
  };

  render() {
    //TODO: Get userID from JWT or/as Cookie
    const Creating = this.state.creating;
    const Sessions = this.state.sessions;
    return Creating ? (
      //Creating === true
      <form>
        <Box
          sx={{
            width: '100%',
            height: '50vh',
            minHeight: '375px',
            background: Theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '14px',
          }}
        >
          <Box
            sx={{
              minWidth: '500px',
              width: '1000px',
              maxWidth: '80%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              gap: '14px',
            }}
          >
            <Typography variant="h2" sx={{ marginBottom: '20px' }}>
              Create New Session
            </Typography>

            <TextField
              fullWidth
              id="code"
              InputLabelProps={{ shrink: true, color: 'primary' }}
              label="Session title"
              name="code"
              onChange={this.HandleTitle}
              placeholder="type session title here..."
              onKeyPress={(e) => {
                if (e.key === 'Enter' && this.state.title.length > 0) {
                  this.CreateSession(e);
                }
              }}
              sx={{
                '.MuiInputLabel-shrink': {
                  color: Theme.palette.text.primary,
                  fontFamily: 'Lato, Roboto, Arial',
                  fontSize: '1.25em',
                  margin: '0 -10px',
                  lineHeight: 1.25,
                  fontWeight: 700,
                },
                '.MuiOutlinedInput-root': {
                  outline: 'none !important',
                  padding: '15px 0',
                },
                '.MuiOutlinedInput-input': {
                  border: `2px solid ${alpha(
                    Theme.palette.text.disabled,
                    0.5
                  )}`,
                  padding: '10px',
                  borderRadius: '5px',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  outline: 'none',
                  border: 'none',
                },
              }}
              value={this.state.title}
              variant="outlined"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '50px',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
          >
            <SecondaryButton onClick={() => this.setState({ creating: false })}>
              Cancel
            </SecondaryButton>
            <Button onClick={(event) => this.CreateSession(event)}>
              Create Session
            </Button>
          </Box>
        </Box>
      </form>
    ) : (
      //Creating === false
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '50%',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            flexDirection: 'column',
            background: Theme.palette.background.paper,
            padding: '50px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              height: '250px',
              width: '675px',
              minWidth: '512px',
              background: alpha(Theme.palette.primary.main, 0.5),
              borderRadius: '0.5em',
              marginTop: '50px',
              position: 'relative',
              padding: '20px',
            }}
          >
            <Box>
              <Button
                onClick={() => this.create.open()}
                sx={{
                  postition: 'absolute',
                  left: 0,
                  top: 0,
                  transform: 'translateY(-50%)',
                }}
              >
                Create new session
                <span className="notranslate material-icons">add_circle</span>
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  position: 'absolute',
                  right: 0,
                  top: '0',
                  transform: 'translateY(-100%)',
                }}
              >
                <span
                  className="notranslate material-icons-outlined"
                  style={{ fontSize: '1.25em' }}
                >
                  person
                </span>
                <Typography variant="body1">
                  {localStorage.getItem('name')}
                </Typography>
                <span
                  className="notranslate material-icons"
                  style={{ fontSize: '1.25em' }}
                >
                  expand_more
                </span>
              </Box>
            </Box>
            <Typography variant="h4">Recent sessions</Typography>
            <div>
              {[...Sessions]
                .filter((x) => !x.isDeleted)
                .sort((a, b) => b.lastOpenedInUnix - a.lastOpenedInUnix)
                .slice(0, 3)
                .map((session, index) => {
                  return (
                    <SessionCard
                      key={session.id}
                      isFirst={index === 0}
                      {...session}
                      onClick={(id) => this.LoadSession(id)}
                      onRename={(name) => {
                        SessionController.rename(
                          session.ownerId,
                          session.id,
                          name
                        );

                        const sessions = this.state.sessions;
                        this.state.sessions
                          .filter((s) => s.id === session.id)
                          .map((s) => {
                            s.name = name;
                            return s;
                          });
                        this.setState({
                          sessions,
                        });
                      }}
                      onDelete={() => {
                        SessionController.delete(session.ownerId, session.id);

                        session.isDeleted = true;

                        this.setState({
                          sessions: this.state.sessions,
                        });
                      }}
                    />
                  );
                })}
            </div>
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            height: '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            padding: '50px',
            background: Theme.palette.background.default,
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '50%',
              minWidth: '512px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <Typography variant="h4">
              {this.state.showDeleted ? 'Deleted sessions' : 'All sessions'}
              <IconButton
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  this.setState({ anchor: e.currentTarget });
                }}
              >
                <span
                  style={{ position: 'relative', top: '-2px' }}
                  className="notranslate material-icons-outlined"
                >
                  more_vert
                </span>
              </IconButton>
              <MenuView
                items={
                  this.state.showDeleted
                    ? this.showAllMenu
                    : this.showDeletedMenu
                }
                anchor={this.state.anchor}
                onClose={(
                  event: React.MouseEvent<HTMLLIElement, MouseEvent>
                ) => {
                  event.stopPropagation();
                  this.setState({ anchor: null });
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
              />
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Select
                autoWidth
                defaultValue={2}
                size="small"
                variant="outlined"
              >
                <ListSubheader>SORT BY</ListSubheader>
                <MenuItem value={1}>Alphabetical</MenuItem>
                <MenuItem value={2}>Date Modified</MenuItem>
                <MenuItem value={3}>Date Created</MenuItem>

                <ListSubheader>ORDER</ListSubheader>
                <MenuItem value={4}>Ascending</MenuItem>
                <MenuItem value={5}>Descending</MenuItem>
              </Select>

              <IconButton>
                <span className="notranslate material-icons-outlined">
                  create_new_folder
                </span>
              </IconButton>

              <Divider orientation="vertical" />

              <IconButton>
                <span className="notranslate material-icons-outlined">
                  apps
                </span>
              </IconButton>

              <IconButton>
                <span className="notranslate material-icons-outlined">
                  format_list_bulleted
                </span>
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              width: '75%',
              minWidth: '512px',
              position: 'relative',
              textAlign: 'center',
              background: '#efefef',
              padding: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'start',
              }}
            >
              {Sessions.filter(
                (x) => x.isDeleted === this.state.showDeleted
              ).map((session) => {
                return (
                  <SessionCard
                    key={session.id}
                    {...session}
                    onClick={(id) => this.LoadSession(id)}
                    onRename={(name) => {
                      SessionController.rename(
                        session.ownerId,
                        session.id,
                        name
                      );

                      const sessions = this.state.sessions;
                      this.state.sessions
                        .filter((s) => s.id === session.id)
                        .map((s) => {
                          s.name = name;
                          return s;
                        });
                      this.setState({
                        sessions,
                      });
                    }}
                    onDelete={() => {
                      SessionController.delete(session.ownerId, session.id);

                      session.isDeleted = true;

                      this.setState({
                        sessions: this.state.sessions,
                      });
                    }}
                    isDeleted={session.isDeleted}
                    onRecover={() => {
                      SessionController.recover(session.ownerId, session.id);

                      session.isDeleted = false;

                      this.setState({
                        sessions: this.state.sessions,
                      });
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export const DashboardView = withRouter(DashboardViewComponent);
