import { Component } from 'react';
import { Menu, MenuItem, type PopoverOrigin } from '@mui/material';

export interface IMenuItem {
  text: string;
  callback:
    | (() => void)
    | ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void)
    | null;
  disable?: () => boolean;
  hide?: () => boolean;
}

interface IProps {
  anchor: Element | null;
  onClose: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  items: Array<IMenuItem>;
}

export class MenuView extends Component<IProps> {
  render() {
    return (
      <Menu
        anchorEl={this.props.anchor}
        anchorOrigin={
          this.props.anchorOrigin
            ? this.props.anchorOrigin
            : {
                vertical: 'top',
                horizontal: 'right',
              }
        }
        id="simple-menu"
        keepMounted
        onClose={this.props.onClose}
        open={Boolean(this.props.anchor)}
        transformOrigin={
          this.props.transformOrigin
            ? this.props.transformOrigin
            : {
                vertical: 'top',
                horizontal: 'right',
              }
        }
      >
        {this.props.items
          ?.filter((item) => !item.hide || item.hide())
          .map((item) => (
            <MenuItem
              key={item.text + '-menu'}
              disabled={item.disable ? item.disable() : false}
              onClick={(e) => {
                e.stopPropagation();
                item?.callback(e);
              }}
            >
              {item.text}
            </MenuItem>
          ))}
      </Menu>
    );
  }
}
