import { useCallback, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { CodesController, UserController } from '../../../Controllers';

const NICKNAME = 'USER';

const MAX_RETRIES = 3;

async function createTemporaryUserWithRetry(
  nickname: string,
  retries = MAX_RETRIES
): Promise<any> {
  try {
    const user = await UserController.temporary(nickname);
    return user;
  } catch (error) {
    if (retries === 0) {
      throw error;
    }
    console.error(
      `Failed to create temporary user, retrying (${retries} retries left)...`
    );
    await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1 second before retrying
    return createTemporaryUserWithRetry(nickname, retries - 1);
  }
}

export const Nickname = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { sessionCode } = useParams<{ sessionCode: string }>();

  const handleSubmit = useCallback(async () => {
    console.log('Creating Temporary User');

    let user = null;

    try {
      user = await createTemporaryUserWithRetry(NICKNAME);
    } catch (error) {
      console.error(
        'Failed to create temporary user after multiple retries:',
        error
      );
    }

    if (user) {
      localStorage.setItem('name', NICKNAME);
      localStorage.setItem('AURT', user.refreshToken);

      const code = parseInt(sessionCode);

      const connection = await CodesController.connect(code);

      if (connection) {
        navigate(connection);
      }
    } else {
      alert('Temporary User Creation Failed');
      console.log('User:', user);
      console.log('Nickname:', NICKNAME);
    }
  }, [navigate, sessionCode]);

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          navigate('/');
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.light,
            lineHeight: '90px',
            textAlign: 'center',
            margin: '0',
            float: 'left',
          }}
          variant="h1"
        >
          co
        </Typography>

        <Typography
          sx={{
            lineHeight: '90px',
            textAlign: 'center',
            margin: '0',
            float: 'left',
          }}
          variant="h1"
        >
          boost
        </Typography>
      </div>
    </Box>
  );
};
