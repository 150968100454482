import { Fragment, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { ActionPlanResult, BoardResult, VoteResult } from '.';
import { SessionController } from '../../../Controllers';
import { Handler } from '../../../Data';
import {
  type ActionPlanModel,
  type BoardModel,
  type VoteModel,
} from '../../../Models';

declare let html2pdf: any;

type BoardContent = {
  type: 'board';
  board: BoardModel;
};

type VoteContent = {
  type: 'vote';
  vote: VoteModel;
};

type ActionPlanContent = {
  type: 'actionPlan';
  actionPlan: ActionPlanModel;
};

type IContentMap = Record<
  string,
  (BoardContent | VoteContent | ActionPlanContent) & { content: any }
>;

export function Report() {
  const [contentMap, setContentMap] = useState<IContentMap>({});
  const [isReady, setReady] = useState(false);

  const { session: sessionId } = useParams<{ session: string }>();
  const [searchParams] = useSearchParams();

  const handler = useRef<Handler | null>(null);

  useEffect(() => {
    const run = async () => {
      const reportSession = await SessionController.connect(sessionId);

      if (!reportSession) {
        console.error('Session not found', sessionId);

        return;
      }

      const dataHandler = new Handler({
        creationDateInUnix: reportSession.creationDateInUnix,
        id: reportSession.id,
        lastOpenedInUnix: reportSession.lastOpenedInUnix,
        name: reportSession.name,
        ownerId: reportSession.ownerId,
        forceRender: () => {},
        userIsOwner: true,
      });

      handler.current = dataHandler;

      await dataHandler.GetData();

      const boards = dataHandler
        .GetPhases()
        .flatMap((phase) => dataHandler.GetBoards(phase.id));

      const votes = dataHandler
        .GetPhases()
        .flatMap((phase) => dataHandler.GetVotes(phase.id));

      const actionPlans = dataHandler
        .GetPhases()
        .flatMap((phase) => dataHandler.GetActionPlansByPhaseId(phase.id));

      const contentMap = {} as IContentMap;

      boards.forEach((board) => {
        const content = dataHandler.GetBoardContent(board.id);
        contentMap[board.id] = {
          board,
          content,
          type: 'board',
        };
      });

      votes.forEach((vote) => {
        const content = dataHandler.GetVoteContent(vote.id);
        contentMap[vote.id] = {
          content,
          vote,
          type: 'vote',
        };
      });

      actionPlans.forEach((actionPlan) => {
        const content = dataHandler.GetActionPlan(actionPlan.id);
        contentMap[actionPlan.id] = {
          content,
          actionPlan,
          type: 'actionPlan',
        };
      });

      setContentMap(contentMap);
      setReady(true);
    };

    run();
  }, [sessionId]);

  useEffect(() => {
    if (isReady && searchParams.get('save')) {
      const opt = {
        filename: 'coboost.pdf',
        margin: 0,
        image: { type: 'jpeg', quality: 1 },
        jsPDF: {
          unit: 'in',
          format: 'letter',
          orientation: 'portrait',
        },
        pagebreak: { mode: 'avoid-all' },
      };
      html2pdf().set(opt).from(document.body).save();
    }
  }, [isReady, searchParams]);

  const reportItems = JSON.parse(localStorage.getItem('reportItems') ?? '[]');

  const dataHandler = handler.current;

  return (
    isReady && (
      <div>
        {reportItems.map((item) => {
          const contentItem = contentMap[item];
          if (contentItem.type === 'board') {
            return (
              <Fragment key={item}>
                {item !== reportItems[0] && <hr />}
                <BoardResult
                  dataHandler={dataHandler}
                  contentItem={contentItem}
                />
              </Fragment>
            );
          }

          if (contentItem.type === 'vote') {
            return (
              <Fragment key={item}>
                {item !== reportItems[0] && <hr />}
                <VoteResult contentItem={contentItem} />
              </Fragment>
            );
          }

          if (contentItem.type === 'actionPlan') {
            return (
              <Fragment key={item}>
                {item !== reportItems[0] && <hr />}
                <ActionPlanResult contentItem={contentItem} />
              </Fragment>
            );
          }

          return null;
        })}
      </div>
    )
  );
}
