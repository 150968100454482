import { Component } from 'react';
import { Draggable, type DraggableProvided } from '@hello-pangea/dnd';
import { Box, IconButton, Typography, styled } from '@mui/material';

import { InputController } from '../../../../Controllers';
import Theme from '../../../../Theme/ThemeOptions';
import {
  MenuView as Menu,
  TooltipView as Tooltip,
  type IMenuItem,
} from '../../General';

interface IProps {
  description: string;
  groupId: string;
  id: string;
  index: number;
  title: string;
  userId: string;
  selected: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  session: string;
  phase: string;
  boardId: string;
}

interface IState {
  hover: boolean;
  anchor: Element | null;
  open: boolean;
  editTitle: boolean;
}

interface IDiv {
  selected?: boolean;
}

const TransparentContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})<IDiv>(({ theme, selected }) => ({
  position: 'relative',
  borderRadius: 4,
  boxSizing: 'border-box',
  width: '100%',
  flexGrow: 1,
  verticalAlign: 'top',
  ...(selected
    ? {
        background: '#fcfcfc',
        padding: 4,
        border: `4px solid${theme.palette.text.primary}`,
      }
    : { padding: 7, border: `1px solid${theme.palette.text.disabled}` }),
}));

export class TransparentInput extends Component<IProps, IState> {
  constructor(props: IProps | Readonly<IProps>) {
    super(props);
    this.state = {
      hover: false,
      anchor: null,
      open: false,
      editTitle: false,
    };
  }

  Delete = () => {
    InputController.archive(
      this.props.session,
      this.props.phase,
      this.props.boardId,
      this.props.groupId,
      this.props.id
    );
  };

  Duplicate = () => {
    InputController.create(
      this.props.session,
      this.props.phase,
      this.props.boardId,
      this.props.groupId,
      this.props.title,
      this.props.description,
      this.props.userId
    );
  };

  Rename = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();

    this.setState({ editTitle: true, anchor: null });
    setTimeout(() => this.setState({ editTitle: false }), 1000);
  };

  InputMenu: Array<IMenuItem> = [
    { text: 'Edit2', callback: this.Rename },
    { text: 'Duplicate', callback: this.Duplicate },
    { text: 'Delete', callback: this.Delete },
  ];

  open = {
    true: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      this.setState({ open: true });
      document.addEventListener('mousedown', this.open.active);
    },
    active: (event: MouseEvent) => {
      if (event.ctrlKey && event.button === 0) {
        this.setState({ open: false });
        document.removeEventListener('mousedown', this.open.active);
      }
    },
    false: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      this.setState({ open: false });
    },
  };

  handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };
  render() {
    return (
      <>
        <Draggable
          draggableId={this.props.id}
          index={this.props.index}
          key={this.props.id}
        >
          {(provided: DraggableProvided) => (
            <TransparentContainer
              id={this.props.id}
              key={this.props.id}
              onClick={this.handleClick}
              selected={this.props.selected}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Tooltip
                sx={{
                  '&.MuiTooltip-tooltip': {
                    background: Theme.palette.background.default,
                  },
                }}
                placement="bottom"
                title={
                  this.props.title !== this.props.description
                    ? this.props.description
                    : ''
                }
              >
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '40px',
                    }}
                    onMouseEnter={() => this.setState({ hover: true })}
                    onMouseLeave={() => this.setState({ hover: false })}
                  >
                    <Typography
                      sx={{
                        pointerEvents: 'none',
                        display: 'inline-block',
                        fontWeight: 'bold',
                      }}
                      variant="subtitle2"
                    >
                      {this.props.title}
                    </Typography>

                    <>
                      <IconButton
                        size="small"
                        sx={{
                          alignSelf: 'start',
                          padding: 0,
                          display: 'inline-block',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setState({ anchor: e.currentTarget });
                        }}
                      >
                        <span
                          className="notranslate material-icons-outlined"
                          style={{
                            fontSize: '18px',
                            opacity: this.state.hover ? '1' : '0.01',
                          }}
                        >
                          more_vert
                        </span>
                      </IconButton>
                      <Menu
                        items={this.InputMenu}
                        anchor={this.state.anchor}
                        onClose={(event) => {
                          event.stopPropagation();
                          this.setState({ anchor: null });
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                      />
                    </>
                  </Box>
                  {this.props.title !== this.props.description &&
                    (this.state.open ? (
                      <>
                        <Typography
                          sx={{
                            pointerEvents: 'none',
                            display: 'inline-block',
                            padding: '10px',
                          }}
                          variant="body2"
                        >
                          {this.props.description}
                        </Typography>
                        <IconButton
                          onClick={this.open.false}
                          sx={{
                            bottom: '-10px',
                            position: 'absolute',
                            left: '95%',
                            transform: 'translateX(-50%)',
                            ':hover': {
                              cursor: 'pointer',
                              color: '',
                              background: '#00000000',
                              boxShadow:
                                '0 0 30px 30px rbga(0, 0, 0, 0.1) inset',
                            },
                          }}
                        >
                          <span
                            className="notranslate material-icons-outlined"
                            style={{ fontSize: '18px' }}
                          >
                            expand_less
                          </span>
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        onClick={this.open.true}
                        sx={{
                          bottom: '-10px',
                          position: 'absolute',
                          left: '95%',
                          transform: 'translateX(-50%)',
                          ':hover': {
                            cursor: 'pointer',
                            color: '',
                            background: '#00000000',
                            boxShadow: '0 0 30px 30px rbga(0, 0, 0, 0.1) inset',
                          },
                        }}
                      >
                        <span
                          className="notranslate material-icons-outlined"
                          style={{ fontSize: '18px' }}
                        >
                          more_horiz
                        </span>
                      </IconButton>
                    ))}
                </Box>
              </Tooltip>
            </TransparentContainer>
          )}
        </Draggable>
      </>
    );
  }
}
