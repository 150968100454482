import { useCallback, useMemo } from 'react';
import { styled, Typography } from '@mui/material';

import { type Handler } from '../../../Data';
import { TooltipView } from '../General';

interface IProps {
  color: string;
  inputId: string;
  total: number;
  title: string;
  description: string;
  voteId: string;
  height: string;
  showPercent: boolean;
  sum: number;
  dataHandler: Handler;
  userIsOwner: boolean;
}

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Bar = styled('div')({
  width: '100%',
  flexGrow: 1,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

const Transparent = styled('div')({
  width: '100%',
  flexGrow: '1',
  position: 'relative',
});

const InfoSpan = styled('span')({
  position: 'relative',
  cursor: 'pointer',
  left: '0px',
  top: '6px',
  color: '#999',
  margin: '0px',
  marginLeft: '2px',
  padding: '0px',
  ':hover': {
    color: '#666',
  },
});

interface IOpaque {
  height: string;
  color: string;
}

const Opaque = styled('div')<IOpaque>(({ color, height }) => ({
  width: '50%',
  marginLeft: '25%',
  position: 'relative',
  height,
  zIndex: 5,
  borderRadius: '0em 0em 0 0',
  background: color,
  paddingTop: '0px',
}));

export const OptionView = (props: IProps) => {
  const {
    color,
    height,
    total,
    showPercent,
    sum,
    title,
    description,
    userIsOwner,
    dataHandler,
    voteId,
    inputId,
  } = props;

  const displayValue = useMemo(
    () =>
      total > 0
        ? showPercent
          ? Math.round((total / sum) * 100) + ' %'
          : total + ' p'
        : '',
    [showPercent, sum, total]
  );

  const isSelected = dataHandler.IsSelected(voteId, inputId);

  const handleInputChange = useCallback(() => {
    dataHandler.Select(voteId, inputId, false);
  }, [dataHandler, inputId, voteId]);

  return (
    <Container>
      <Bar>
        <Transparent />
        <Opaque
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            paddingTop: '5px',
            color: 'white',
          }}
          height={height}
          color={color}
        >
          {displayValue}
        </Opaque>
      </Bar>
      <TooltipView title={title !== description ? description : ''}>
        <Typography
          variant="subtitle2"
          sx={{
            height: '30px',
            lineHeight: '20px',
            textAlign: 'center',
            zIndex: '0',
            marginTop: '0px',
            overflow: 'visible',
          }}
          style={{ lineHeight: '20px', verticalAlign: 'middle' }}
        >
          {userIsOwner && (
            <input
              checked={isSelected}
              onChange={handleInputChange}
              type="checkbox"
              style={{
                marginRight: '5px',
                width: '20px',
                height: '20px',
                marginTop: '10px',
                marginLeft: '7px',
                position: 'relative',
                top: '-4px',
              }}
            />
          )}
          <span
            style={{
              position: 'relative',
              top: '-6px',
            }}
          >
            {title}
            {description && (
              <InfoSpan className="notranslate material-icons-outlined">
                info
              </InfoSpan>
            )}
          </span>
        </Typography>
      </TooltipView>
    </Container>
  );
};
