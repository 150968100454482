import { useEffect, useState } from 'react';
import { type DragStart } from '@hello-pangea/dnd';
import {
  alpha,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  styled,
  Typography,
  type PopoverPosition,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { eventEmitter, Events, type TreeNode } from '@utils';
import { GroupController, InputController } from '../../Controllers';
import {
  IsBoardContent,
  IsVoteContent,
  type Handler,
  type IBoardContent,
  type IVoteContent,
} from '../../Data';
import {
  type ActionPlanModel,
  type BoardModel,
  type BoardVoteWithHasVotes,
  type VoteModel,
} from '../../Models';
import { Theme } from '../../Theme';
import {
  ContextMenu,
  MenuView as Menu,
  MenuView,
  Toolbar,
  TooltipView,
} from '../Components/General';
import {
  ActionPlanView,
  BoardView,
  ColumnView,
  DraggableGroup,
  DraggableInput,
} from '../Components/Organize';
import { PointsVote, SliderVote } from '../Components/Voting';
import { ResultView } from '../ResultView';
import { InputHighlightDialog } from './InputHighlightDialog';

const Middle = styled('div')(({ theme }) => ({
  position: 'relative',
  margin: 0,
  padding: 0,
  background: theme.palette.background.default,
  height: '100%',
  flexGrow: 1,
  width: '80%',
  transition: 'all 0.25s',
  overflow: 'hidden',
}));

const NavToolbar = styled(Toolbar)(({ theme }) => ({
  margin: '0px 20px 0px 0px',
  padding: '0.5em 1em',
  display: 'flex',
  zIndex: 100,
  height: '56px',
  transition: 'all 0.25s',
  flexDirection: 'row',
  borderRadius: '2em',
  background: theme.palette.background.paper,
  boxShadow: `0 0 10px 10px ${alpha(theme.palette.text.primary, 0.15)}`,
}));

const IconNavToolbar = styled(NavToolbar)({
  opacity: 0.3,
  '&:hover': {
    opacity: 1,
  },
});

const JoinSessionWithCode = ({ code }: { code: number }) => {
  return (
    <>
      Enter <b>{code}</b> at coboost.no to join
    </>
  );
};

type MiddlePanelProps = {
  sessionId: string;
  isCollapsed: boolean;
  activeType: 'board' | 'vote' | 'actionplan' | 'result' | '';
  activeId: string;
  view: BoardModel | VoteModel | ActionPlanModel;
  handler: Handler;
  dragType: 'input' | 'group';
  content: IBoardContent | IVoteContent;
  userIsOwner: boolean;
  showNicks: boolean;
  showOwnVote: boolean;
  collapse: {
    navigation: boolean;
    boards: Record<string, boolean>;
  };
  hasOwnVote: boolean;
  isShowingSettings: boolean;
  hideCollapsed: boolean;
  pinnedInputs: string[];
  menu: any;
  pinMenu: any;
  anchor: {
    toolbar_add: null | Element;
    toolbar_vote: null | Element;
    pinMenu?: Element | null;
  };
  resultViewTree?: TreeNode<BoardVoteWithHasVotes>[][];
  onOpenActionPlan: React.MouseEventHandler;
  onOpenRefine: React.MouseEventHandler;
  onOpenParticipate: React.MouseEventHandler;
  inviteClipboard: React.MouseEventHandler;
  fullscreen: React.MouseEventHandler;
  onDragStart: (e: DragStart) => void;
  onSetPin: (value: any, id: string) => void;
  onPinMenuClick: React.MouseEventHandler;
  onPinClick: React.MouseEventHandler;
  onShowOwnVote: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onShowNicks: React.MouseEventHandler;
};

export const MiddlePanel: React.FC<MiddlePanelProps> = ({
  isCollapsed,
  activeType,
  activeId,
  view,
  content,
  userIsOwner,
  sessionId,
  pinnedInputs,
  showOwnVote,
  isShowingSettings,
  handler,
  dragType,
  hasOwnVote,
  showNicks,
  pinMenu,
  collapse,
  menu,
  anchor,
  hideCollapsed,
  resultViewTree,
  onOpenActionPlan,
  onOpenRefine,
  onOpenParticipate,
  fullscreen,
  inviteClipboard,
  onDragStart,
  onSetPin,
  onPinMenuClick,
  onPinClick,
  onShowOwnVote,
  onShowNicks,
}) => {
  const navigate = useNavigate();
  const [showDeleteSelectedDialog, setShowDeleteSelectedDialog] =
    useState(false);
  const [showSessionCode, setShowSessionCode] = useState(
    (localStorage.getItem('coobost:showSessionCode') ?? 'true') === 'true'
  );
  const [showAllDescriptions, setShowAllDescriptions] = useState<{
    lastValue: boolean;
    currentValue?: boolean;
  }>({
    lastValue: false,
    currentValue: undefined,
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuAnchorPosition, setMenuAnchorPosition] =
    useState<PopoverPosition>();
  const [stackContextMenuClicked, setStackContextMenuClicked] = useState(false);
  const [showHighlightModal, setShowHighlightModal] = useState(false);

  useEffect(() => {
    if (showAllDescriptions.currentValue !== undefined) {
      setTimeout(() => {
        setShowAllDescriptions({
          lastValue: showAllDescriptions.currentValue,
          currentValue: undefined,
        });
      }, 500);
    }
  }, [showAllDescriptions.currentValue]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuAnchorPosition({
      top: event.clientY,
      left: event.clientX,
    });
    setStackContextMenuClicked(false);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuAnchorPosition(undefined);
  };

  const selectAll = () => {
    handleMenuClose();
    handler.SelectAll(view.id, stackContextMenuClicked ? 'stack' : 'board');
  };

  const unSelectAll = () => {
    const boardId = view.id;
    handleMenuClose();
    handler.ClearSelected(boardId, stackContextMenuClicked);
  };

  const toggleDescription = () => {
    handleMenuClose();
    setShowAllDescriptions({
      lastValue: showAllDescriptions.lastValue,
      currentValue: !showAllDescriptions.lastValue,
    });
  };

  const showDeleteSelectedDialogHandler = () => {
    setShowDeleteSelectedDialog(true);
    handleMenuClose();
  };

  const deleteSelectedItems = async () => {
    const phaseId = view.phaseId;
    const boardId = view.id;

    handleMenuClose();
    setShowDeleteSelectedDialog(false);

    const Selected = handler.GetSelected(boardId);

    const groupIds = new Set();

    if (Selected && Selected.length > 0) {
      for (let i = 0; i < Selected.length; i++) {
        await InputController.archive(
          sessionId,
          phaseId,
          boardId,
          Selected[i].groupId,
          Selected[i].id
        );
        groupIds.add(Selected[i].groupId);
      }
    }

    groupIds.forEach((groupId) => {
      eventEmitter.dispatch(Events.INPUT_DELETED, {
        groupId,
      });
    });
  };

  const groupCollapseAll = () => {
    const phaseId = view.phaseId;
    const boardId = view.id;
    GroupController.collapse.all(sessionId, phaseId, boardId);

    handleMenuClose();
  };

  const onContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!userIsOwner) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    const XPos = event.clientX;
    const YPos = event.clientY;

    setMenuAnchorEl(event.currentTarget);
    setMenuAnchorPosition({ top: YPos, left: XPos });
  };

  const onStackContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setStackContextMenuClicked(true);
    onContextMenu(event);
  };

  const onBoardContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setStackContextMenuClicked(false);
    onContextMenu(event);
  };

  const closeHighlightModal = () => {
    setShowHighlightModal(false);
  };

  const openHighlightModal = () => {
    setShowHighlightModal(true);
  };

  const toggleSessionCode = () => {
    setShowSessionCode(!showSessionCode);
    localStorage.setItem(
      'coobost:showSessionCode',
      JSON.stringify(!showSessionCode)
    );
  };

  const isBoard = activeType === 'board' && view && 'hasNewInput' in view;

  const isVote = activeType === 'vote' && view && 'hideVotes' in view;

  const isActionPlan = activeType === 'actionplan' && view;

  const isResult = activeType === 'result';

  const numOfVotes = (content as IVoteContent)?.tickets?.length;

  const selectedItems = handler.GetSelected(activeId);

  const pinMenuDisabled =
    (!selectedItems || selectedItems?.length === 0) &&
    pinnedInputs.length === 0;

  const hasSelectedItems = selectedItems.length > 0;

  const isStackInputSelected = handler.IsStackInputSelected(activeId);

  const nicks = handler.GetUserData().nicks;

  let divider = 2;
  if (nicks?.length > 10) {
    divider = 4;
  }
  if (nicks?.length > 20) {
    divider = 5;
  }
  if (nicks?.length > 40) {
    divider = 6;
  }

  const nickBreakIndex = Math.round(nicks?.length / divider) - 1;

  return (
    <Middle>
      {isBoard && view && content && IsBoardContent(content) && (
        <BoardView
          dragType={dragType}
          onDragStart={onDragStart}
          onRemoveSelected={showDeleteSelectedDialogHandler}
          onClearSelected={unSelectAll}
          onContextMenu={onBoardContextMenu}
          onStackContextMenu={onStackContextMenu}
          session={sessionId}
          handler={handler}
          buffer={content.buffer}
          bufferChildren={handler.GetInputs(content.buffer.id)}
          stack={content.stack}
          userIsOwner={userIsOwner}
          stackChildren={handler.GetInputs(content.stack.id)}
          hasNewInputsInStack={handler.state.hasNewInputsInStack}
          onSelectAll={selectAll}
          {...view}
        >
          {content.columns.map((column, index) => (
            <ColumnView
              key={`Column-${index}`}
              dragType={dragType}
              id={`Column-${index}`}
              index={index}
            >
              {column.map((group) => {
                if (!group.isCollapsed || !hideCollapsed) {
                  return (
                    <DraggableGroup
                      key={group.id}
                      sessionId={sessionId}
                      userIsOwner={userIsOwner}
                      onClick={() =>
                        handler.SelectGroup(group.boardId, group.id)
                      }
                      phaseId={view.phaseId}
                      {...group}
                    >
                      {handler.GetInputs(group.id).map((input, idx) => (
                        <DraggableInput
                          key={input.id}
                          grouped
                          onClick={(
                            event: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            if (userIsOwner) {
                              handler.Select(activeId, input.id, event.ctrlKey);
                            }
                          }}
                          session={sessionId}
                          phase={view.phaseId}
                          selected={handler.IsSelected(activeId, input.id)}
                          {...input}
                          userIsOwner={userIsOwner}
                          pinned={pinnedInputs.indexOf(input.id) >= 0}
                          setPin={(value) => onSetPin(value, input.id)}
                          showAllDescriptions={showAllDescriptions.currentValue}
                          index={idx}
                          isInRefinedBoard={!!view.parentId}
                        />
                      ))}
                    </DraggableGroup>
                  );
                } else {
                  return null;
                }
              })}
            </ColumnView>
          ))}
        </BoardView>
      )}

      {isActionPlan && <ActionPlanView actionPlan={view as ActionPlanModel} />}

      {isBoard && content && IsBoardContent(content) && (
        <>
          <IconNavToolbar
            style={{
              position: 'fixed',
              alignItems: 'center',
              pointerEvents: 'auto',
              top: '10px',
              left: `calc(20px${
                collapse.navigation ? ' + 30px' : ' + max(390px, 10%)'
              }${
                content.stack.isCollapsed
                  ? ' + 40px'
                  : content.buffer.isCollapsed
                  ? ' + 732px'
                  : ' + 366px'
              })`,
            }}
          >
            {userIsOwner && (
              <TooltipView title="Go back to Dashboard">
                <IconButton
                  sx={{ width: '50px' }}
                  onClick={() => navigate('/dashboard')}
                >
                  <span className="notranslate material-icons-outlined">
                    home
                  </span>
                </IconButton>
              </TooltipView>
            )}

            <TooltipView title="Copy Invite Link to Clipboard">
              <IconButton sx={{ width: '50px' }} onClick={inviteClipboard}>
                <span className="notranslate material-icons-outlined">
                  share
                </span>
              </IconButton>
            </TooltipView>

            <TooltipView title="Enter Fullscreen Mode">
              <IconButton sx={{ width: '50px' }} onClick={fullscreen}>
                <span className="notranslate material-icons-outlined">
                  {document.fullscreenElement
                    ? 'fullscreen_exit'
                    : 'fullscreen'}
                </span>
              </IconButton>
            </TooltipView>
            <TooltipView title="Toggle session code">
              <IconButton sx={{ width: '50px' }} onClick={toggleSessionCode}>
                <span className="notranslate material-icons-outlined">
                  vpn_key
                </span>
              </IconButton>
            </TooltipView>
          </IconNavToolbar>

          {showSessionCode && (
            <NavToolbar
              style={{
                position: 'fixed',
                alignItems: 'center',
                pointerEvents: 'auto',
                top: '10px',
                left: `calc(260px${
                  collapse.navigation ? ' + 30px' : ' + max(390px, 10%)'
                }${
                  content.stack.isCollapsed
                    ? ' + 40px'
                    : content.buffer.isCollapsed
                    ? ' + 732px'
                    : ' + 366px'
                })`,
              }}
            >
              {handler.state.code > 500 && (
                <Typography
                  variant="h4"
                  sx={{
                    marginLeft: '10px',
                    color: Theme.palette.text.secondary,
                  }}
                >
                  <JoinSessionWithCode code={handler.state.code} />
                </Typography>
              )}
            </NavToolbar>
          )}

          {userIsOwner && (
            <Toolbar
              style={{
                display: 'none',
                alignItems: 'center',
                pointerEvents: 'auto',
                top: '10px',
                left: `calc(320px${
                  collapse.navigation ? ' + 30px' : ' + max(390px, 10%)'
                }${
                  content.stack.isCollapsed
                    ? ' + 40px'
                    : content.buffer.isCollapsed
                    ? ' + 732px'
                    : ' + 366px'
                })`,
                position: 'fixed',
              }}
            >
              <TooltipView title="Undo">
                <IconButton
                  sx={{ width: '50px' }}
                  onClick={() => alert('Disabled function!')}
                >
                  <span className="notranslate material-icons-outlined">
                    undo
                  </span>
                </IconButton>
              </TooltipView>
              <TooltipView title="Redo">
                <IconButton
                  sx={{ width: '50px' }}
                  onClick={() => alert('Disabled function!')}
                >
                  <span className="notranslate material-icons-outlined">
                    redo
                  </span>
                </IconButton>
              </TooltipView>
            </Toolbar>
          )}
        </>
      )}

      {isVote && content && IsVoteContent(content) && (
        <>
          <IconNavToolbar
            style={{
              alignItems: 'center',
              pointerEvents: 'auto',
              position: 'fixed',
              top: '10px',
              left: `calc(20px${
                collapse.navigation ? ' + 30px' : ' + max(390px, 10%)'
              })`,
            }}
          >
            {userIsOwner && (
              <TooltipView title="Go back to Dashboard">
                <IconButton
                  sx={{ width: '50px' }}
                  onClick={() => navigate('/dashboard')}
                >
                  <span className="notranslate material-icons-outlined">
                    home
                  </span>
                </IconButton>
              </TooltipView>
            )}

            <TooltipView title="Copy Invite Link to Clipboard">
              <IconButton sx={{ width: '50px' }} onClick={inviteClipboard}>
                <span className="notranslate material-icons-outlined">
                  share
                </span>
              </IconButton>
            </TooltipView>

            <TooltipView title="Enter Fullscreen Mode">
              <IconButton sx={{ width: '50px' }} onClick={fullscreen}>
                {document.fullscreenElement ? (
                  <span className="notranslate material-icons-outlined">
                    fullscreen
                  </span>
                ) : (
                  <span className="notranslate material-icons-outlined">
                    fullscreen_exit
                  </span>
                )}
              </IconButton>
            </TooltipView>
            <TooltipView title="Toggle session code">
              <IconButton sx={{ width: '50px' }} onClick={toggleSessionCode}>
                <span className="notranslate material-icons-outlined">
                  vpn_key
                </span>
              </IconButton>
            </TooltipView>
          </IconNavToolbar>
          {showSessionCode && (
            <NavToolbar
              style={{
                alignItems: 'center',
                pointerEvents: 'auto',
                position: 'fixed',
                top: '10px',
                left: `calc(260px${
                  collapse.navigation ? ' + 30px' : ' + max(390px, 10%)'
                })`,
              }}
            >
              {handler.state.code > 500 && (
                <Typography
                  variant="h3"
                  sx={{
                    marginLeft: '10px',
                    color: Theme.palette.text.secondary,
                  }}
                >
                  <JoinSessionWithCode code={handler.state.code} />
                </Typography>
              )}
            </NavToolbar>
          )}
          {userIsOwner && (
            <Toolbar
              style={{
                display: 'none',
                alignItems: 'center',
                pointerEvents: 'auto',
                top: '10px',
                left: `calc(320px${
                  collapse.navigation ? ' + 30px' : ' + max(390px, 10%)'
                })`,
                position: 'fixed',
              }}
            >
              <TooltipView title="Undo">
                <IconButton
                  sx={{ width: '50px' }}
                  onClick={() => alert('Disabled function!')}
                >
                  <span className="notranslate material-icons-outlined">
                    undo
                  </span>
                </IconButton>
              </TooltipView>
              <TooltipView title="Redo">
                <IconButton
                  sx={{ width: '50px' }}
                  onClick={() => alert('Disabled function!')}
                >
                  <span className="notranslate material-icons-outlined">
                    redo
                  </span>
                </IconButton>
              </TooltipView>
            </Toolbar>
          )}
        </>
      )}

      {isResult && (
        <ResultView
          dataHandler={handler}
          sessionId={sessionId}
          tree={resultViewTree}
        />
      )}

      {!activeId && !content && (
        <>
          <Toolbar
            style={{
              alignItems: 'center',
              pointerEvents: 'auto',
              position: 'fixed',
              top: '10px',
              left: `calc(20px${
                collapse.navigation ? ' + 30px' : ' + max(390px, 10%)'
              })`,
            }}
          >
            {userIsOwner && (
              <TooltipView title="Go back to Dashboard">
                <IconButton
                  sx={{ width: '50px' }}
                  onClick={() => navigate('/dashboard')}
                >
                  <span className="notranslate material-icons-outlined">
                    home
                  </span>
                </IconButton>
              </TooltipView>
            )}

            <TooltipView title="Copy Invite Link to Clipboard">
              <IconButton sx={{ width: '50px' }} onClick={inviteClipboard}>
                <span className="notranslate material-icons-outlined">
                  share
                </span>
              </IconButton>
            </TooltipView>

            <TooltipView title="Enter Fullscreen Mode">
              <IconButton sx={{ width: '50px' }} onClick={fullscreen}>
                {document.fullscreenElement ? (
                  <span className="notranslate material-icons-outlined">
                    fullscreen
                  </span>
                ) : (
                  <span className="notranslate material-icons-outlined">
                    fullscreen_exit
                  </span>
                )}
              </IconButton>
            </TooltipView>
          </Toolbar>
          {userIsOwner && (
            <Toolbar
              style={{
                display: 'none',
                alignItems: 'center',
                pointerEvents: 'auto',
                top: '10px',
                left: `calc(320px${
                  collapse.navigation ? ' + 30px' : ' + max(390px, 10%)'
                })`,
                position: 'fixed',
              }}
            >
              <TooltipView title="Undo">
                <IconButton
                  sx={{ width: '50px' }}
                  onClick={() => alert('Disabled function!')}
                >
                  <span className="notranslate material-icons-outlined">
                    undo
                  </span>
                </IconButton>
              </TooltipView>
              <TooltipView title="Redo">
                <IconButton
                  sx={{ width: '50px' }}
                  onClick={() => alert('Disabled function!')}
                >
                  <span className="notranslate material-icons-outlined">
                    redo
                  </span>
                </IconButton>
              </TooltipView>
            </Toolbar>
          )}
          <div
            style={{
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <p>Laster...</p>
            </div>
            {!showNicks && (
              <div style={{ textAlign: 'center', fontSize: '35px' }}>
                {handler.GetUserData().noTotal} user
                {handler.GetUserData().noTotal !== 1 ? 's' : ''}
              </div>
            )}
            <div
              style={{
                textAlign: 'center',
                fontSize: '20px',
                margin: '10px',
                overflowWrap: 'initial',
              }}
            >
              {showNicks &&
                nicks?.map((n, index) => (
                  <>
                    <span style={{ margin: '10px' }}>{n}</span>
                    {index % nickBreakIndex === 0 && <br />}
                  </>
                ))}

              {handler.GetUserData().noTotal > 0 && (
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '15px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: '#009',
                    marginTop: '5px',
                  }}
                  onClick={onShowNicks}
                >
                  {showNicks ? 'Hide' : 'Show'} nicknames
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {isVote &&
        view &&
        content &&
        IsVoteContent(content) &&
        (view.type === 'points' ? (
          <PointsVote
            {...view}
            session={sessionId}
            userIsOwner={userIsOwner}
            dataHandler={handler}
            noVotes={numOfVotes}
            navigationIsCollapsed={collapse.navigation}
            rightSide={!isCollapsed}
            {...content}
          />
        ) : (
          <SliderVote
            {...view}
            session={sessionId}
            userIsOwner={userIsOwner}
            showOwnVote={showOwnVote}
            dataHandler={handler}
            noVotes={numOfVotes}
            navigationIsCollapsed={collapse.navigation}
            rightSide={!isCollapsed}
            {...content}
          />
        ))}
      <div
        style={{
          position: 'fixed',
          display: 'flex',
          zIndex: '200',
          width: collapse.navigation ? '100%' : 'calc(100% - max(390px, 10%))',
          bottom: '30px',
          justifyContent: 'center',
          left: collapse.navigation ? '0px' : 'max(390px, 10%)',
        }}
      >
        {userIsOwner &&
          activeType !== 'result' &&
          activeType !== 'actionplan' &&
          !isShowingSettings &&
          (activeType !== 'vote' || !(view as VoteModel)?.hideVotes) && (
            <Toolbar style={{ display: 'inline-block' }}>
              <TooltipView
                title={
                  isStackInputSelected || !hasSelectedItems
                    ? 'Select items above to activate'
                    : 'Create voting session from selected items'
                }
              >
                <span>
                  <IconButton
                    disabled={isStackInputSelected || !hasSelectedItems}
                    sx={{ width: '50px' }}
                    onClick={menu.anchor.Toolbar_Vote}
                  >
                    <span className="notranslate material-icons-outlined">
                      how_to_vote
                    </span>
                  </IconButton>
                </span>
              </TooltipView>

              <Menu
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                items={menu.items.Toolbar_Vote}
                onClose={menu.anchor.close}
                anchor={anchor.toolbar_vote}
              />

              {userIsOwner && (
                <TooltipView
                  title={
                    !hasSelectedItems || isStackInputSelected
                      ? 'Select items above to activate'
                      : 'Take selected items to refined board'
                  }
                >
                  <span>
                    <IconButton
                      sx={{ width: '50px' }}
                      disabled={!hasSelectedItems || isStackInputSelected}
                      onClick={onOpenRefine}
                    >
                      <span className="notranslate material-icons-outlined">
                        filter_alt
                      </span>
                    </IconButton>
                  </span>
                </TooltipView>
              )}

              {userIsOwner && (
                <TooltipView
                  title={
                    !hasSelectedItems ||
                    selectedItems.length > 1 ||
                    isStackInputSelected
                      ? 'Choose only one item to make an action plan'
                      : 'Create action plan'
                  }
                >
                  <span>
                    <IconButton
                      sx={{ width: '50px' }}
                      disabled={
                        !hasSelectedItems ||
                        selectedItems.length > 1 ||
                        isStackInputSelected
                      }
                      onClick={onOpenActionPlan}
                    >
                      <span className="notranslate material-icons-outlined">
                        checklist
                      </span>
                    </IconButton>
                  </span>
                </TooltipView>
              )}

              {userIsOwner && isBoard && hasSelectedItems && (
                <TooltipView title="Highlight selected items">
                  <span>
                    <IconButton
                      sx={{ width: '50px' }}
                      onClick={openHighlightModal}
                    >
                      <span className="notranslate material-icons-outlined">
                        highlight
                      </span>
                    </IconButton>
                  </span>
                </TooltipView>
              )}

              {userIsOwner && isBoard && (
                <>
                  <TooltipView
                    title={
                      pinMenuDisabled || isStackInputSelected
                        ? 'Select items above to activate'
                        : 'Pin menu'
                    }
                  >
                    <span>
                      <IconButton
                        sx={{ width: '50px' }}
                        disabled={pinMenuDisabled || isStackInputSelected}
                        onClick={onPinClick}
                      >
                        <span className="notranslate material-icons-outlined">
                          push_pin
                        </span>
                      </IconButton>
                    </span>
                  </TooltipView>
                  <MenuView
                    items={pinMenu}
                    anchor={anchor.pinMenu}
                    onClose={onPinMenuClick}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  />
                </>
              )}

              {userIsOwner && isBoard && (
                <>
                  <TooltipView title="More options">
                    <IconButton
                      sx={{ width: '50px' }}
                      onClick={handleMenuClick}
                    >
                      <span className="notranslate material-icons-outlined">
                        more_vert
                      </span>
                    </IconButton>
                  </TooltipView>
                  {menuAnchorEl && (
                    <ContextMenu
                      hasSelectedItems={hasSelectedItems}
                      onClose={handleMenuClose}
                      onExpandCollapseGroups={groupCollapseAll}
                      onSelectAll={selectAll}
                      onUnselectAll={unSelectAll}
                      onToggleDescription={toggleDescription}
                      onRemoveSelected={showDeleteSelectedDialogHandler}
                      anchorPosition={menuAnchorPosition}
                      hideExpandCollapseGroups={stackContextMenuClicked}
                    />
                  )}
                </>
              )}
            </Toolbar>
          )}

        {isBoard && 'hasNewInput' in view && !isShowingSettings && (
          <Toolbar style={{ display: 'inline-block' }}>
            <TooltipView title="Give your input">
              <span>
                <IconButton
                  sx={{ width: '50px' }}
                  disabled={view ? view.lockData : true}
                  onClick={onOpenParticipate}
                >
                  <span className="notranslate material-icons-outlined">
                    create
                  </span>
                </IconButton>
              </span>
            </TooltipView>
          </Toolbar>
        )}
        {isVote && !isShowingSettings && !view.hideVotes && (
          <>
            {view != null && view.type === 'slider' && hasOwnVote && (
              <Toolbar style={{ display: 'inline-block' }}>
                <FormControlLabel
                  sx={{
                    display: 'inline-block',
                    lineHeight: '40px',
                  }}
                  control={
                    <Checkbox checked={showOwnVote} onChange={onShowOwnVote} />
                  }
                  label="Show own vote"
                />
              </Toolbar>
            )}
            <Toolbar style={{ display: 'inline-block' }}>
              <TooltipView title="Participate with a vote">
                <span>
                  <IconButton
                    sx={{ width: '50px' }}
                    onClick={onOpenParticipate}
                    disabled={view ? view.lockData : true}
                  >
                    <span className="notranslate material-icons-outlined">
                      poll
                    </span>
                  </IconButton>
                </span>
              </TooltipView>
            </Toolbar>
          </>
        )}
        {isVote && view.hideVotes && !view.lockData && (
          <Toolbar
            style={{
              position: 'fixed',
              right: '100px',
              bottom: '10px',
            }}
          >
            <TooltipView title="Participate with a vote">
              <span>
                <IconButton
                  sx={{ width: '50px', zIndex: '1000' }}
                  onClick={onOpenParticipate}
                  disabled={view ? view.lockData : true}
                >
                  <span className="notranslate material-icons-outlined">
                    poll
                  </span>
                </IconButton>
              </span>
            </TooltipView>
          </Toolbar>
        )}
        {/* {view && isBoard && !isShowingSettings && userIsOwner && (
          <Toolbar style={{ display: 'inline-block' }}>
            <TooltipView title="timer">
              <span>
                <IconButton sx={{ width: '50px' }}>
                  <span className="notranslate material-icons-outlined">
                    timer
                  </span>
                </IconButton>
              </span>
            </TooltipView>

            <TooltipView title="Focus on Selected ...">
              <IconButton sx={{ width: '50px' }}>
                <span className="notranslate material-icons">screen_share</span>
              </IconButton>
            </TooltipView>
          </Toolbar>
        )} */}
        {isVote && !isShowingSettings && !view.hideVotes && (
          <div
            style={{
              display: isMobile ? 'none' : 'inline-block',
              textAlign: 'center',
              color: 'rgba(0, 0, 0, 0.54)',
              borderRadius: '2em',
              height: '56px',
              padding: '0px 20px',
              boxShadow: '0 0 10px 10px rgb(41 47 64 / 15%)',
              lineHeight: '56px',
              backgroundColor: '#fff',
            }}
          >
            {numOfVotes} {numOfVotes === 1 ? 'Vote' : 'Votes'}
          </div>
        )}
      </div>
      {showHighlightModal && selectedItems.length && (
        <InputHighlightDialog
          onClose={closeHighlightModal}
          open={showHighlightModal}
          items={selectedItems}
        />
      )}
      <Dialog
        open={showDeleteSelectedDialog}
        onClose={() => setShowDeleteSelectedDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Remove selected item?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone, and the content will be permanently
            erased.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              color: '#000',
              background: Theme.palette.error.main,
            }}
            onClick={deleteSelectedItems}
          >
            Remove {selectedItems.length} item
            {selectedItems.length > 1 ? 's' : ''}
          </Button>
        </DialogActions>
      </Dialog>
    </Middle>
  );
};
