import { useMemo } from 'react';
import { FormControlLabel, Switch, Typography, useTheme } from '@mui/material';
import { isMobile } from 'react-device-detect';

import { type OptionModel } from '../../../../Models';
import { PrimaryButton as Button } from '../../General';

type MobilePointsProps = {
  title: string;
  description: string;
  showDescription: boolean;
  lowNumber: number;
  highNumber: number;
  totalUserVotes: number;
  ratings: IRating[];
  options: OptionModel[];
  handleRatings: (rating: number, optionId: string) => void;
  onShowDescriptionToggle: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onSendVote: (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => void;
};

interface IRating {
  key: string;
  value: number;
  isTouched: boolean;
}

export const MobilePoints = ({
  title,
  description,
  showDescription,
  lowNumber,
  highNumber,
  totalUserVotes,
  options,
  ratings,
  handleRatings,
  onShowDescriptionToggle,
  onSendVote,
}: MobilePointsProps) => {
  const theme = useTheme();

  const sortedOptions = useMemo(
    () => options.slice().sort((a, b) => a.title.localeCompare(b.title)),
    [options]
  );

  const remainingPoints = useMemo(
    () => highNumber - totalUserVotes,
    [highNumber, totalUserVotes]
  );

  const getScale = useMemo(() => {
    const scale: number[] = [];
    for (let i = 1; i <= lowNumber; i++) {
      scale.push(i);
    }
    return scale;
  }, [lowNumber]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div
        style={{
          position: 'relative',
          top: '0',
          left: '0',
          height: '100%',
          width: '100%',
          background: '#eaeaea',
          padding: '40px 20px 20px 20px',
          flexGrow: '1',
          overflowY: 'scroll',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '2rem',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 20px',
            textAlign: 'center',
          }}
        >
          <Typography variant="caption">
            {`You can give up to ${lowNumber} points to each item, and you have a total of ${highNumber} points to distribute.`}
          </Typography>
        </div>
        {title && (
          <Typography
            sx={{
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
              marginBottom: '10px',
            }}
            variant="body1"
          >
            {description}
          </Typography>
        )}

        <FormControlLabel
          value="top"
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          control={
            <Switch
              color="primary"
              checked={showDescription}
              onChange={onShowDescriptionToggle}
            />
          }
          label="Show description"
          labelPlacement="end"
        />
        <form>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              padding: '2px 0',
            }}
          >
            {sortedOptions &&
              sortedOptions.map(
                (
                  option //TODO: Export Option to own component && Does the Slider Need that too?
                ) => (
                  <div
                    key={option.id}
                    style={{
                      padding: '10px 0px 20px',
                      background: '#eaeaea',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '5px',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ textAlign: 'center' }}
                    >
                      {option.title}
                    </Typography>

                    {showDescription && option.description !== option.title && (
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          lineHeight: '30px',
                          textAlign: 'center',
                        }}
                      >
                        {option.description}
                      </Typography>
                    )}

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {getScale.map((pnt) => (
                        <span
                          key={pnt}
                          style={{
                            fontSize: '35px',
                            cursor: 'pointer',
                          }}
                          className="notranslate material-icons-outlined"
                          onClick={() => handleRatings(pnt, option.id)}
                        >
                          {ratings.find((x) => x.key === option.id)?.value >=
                          pnt
                            ? 'star'
                            : 'star_border'}
                        </span>
                      ))}
                    </div>
                  </div>
                )
              )}
          </div>
        </form>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderTop: '1px solid #aaa',
          backgroundColor: theme.palette.background.default,
          paddingTop: '10px',
          paddingBottom: isMobile ? '50px' : '80px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            paddingBottom: '10px',
            color: remainingPoints > 0 ? theme.palette.primary.main : 'inherit',
          }}
          variant="subtitle2"
        >
          {`You have ${remainingPoints} vote${
            remainingPoints === 1 ? '' : 's'
          } remaining`}
        </Typography>
        <Button
          style={{
            display: 'block',
            margin: '5px auto',
          }}
          disabled={remainingPoints !== 0}
          onClick={onSendVote}
        >
          Send Vote
        </Button>
      </div>
    </div>
  );
};
