import { useRef, type PropsWithChildren } from 'react';
import { styled } from '@mui/material';

interface ICtrlAListenerProps
  extends PropsWithChildren<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  negative?: number;
}

interface IDiv
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  negative?: number;
}

const OrganizedContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'negative',
})<IDiv>(({ negative }) => ({
  position: 'relative',
  display: 'flex',
  padding: 'calc(20px + 4em) 15px 15px 15px',
  flexGrow: 1,
  maxWidth: `calc(100% - ${negative}px)`,
  height: '100%',
  transition: 'all 0.25s',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  overflow: 'scroll',
}));

export const OrganizerView = ({
  onContextMenu,
  onDoubleClick,
  negative,
  children,
}: ICtrlAListenerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <OrganizedContainer
      onContextMenu={onContextMenu}
      onDoubleClick={onDoubleClick}
      ref={containerRef}
      tabIndex={0}
      id="org-container"
      negative={negative}
    >
      {children}
    </OrganizedContainer>
  );
};
