import { type PropsWithChildren } from 'react';
import { Tooltip, type TooltipProps } from '@mui/material';

interface IProps extends TooltipProps {
  title: string;
}

export const TooltipView = ({
  title,
  children,
  ...restProps
}: PropsWithChildren<IProps>) => {
  return (
    <Tooltip
      {...restProps}
      enterDelay={500}
      leaveDelay={50}
      arrow
      title={title}
    >
      {children}
    </Tooltip>
  );
};
