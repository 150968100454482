import Axios from 'axios';

import { type PhaseModel } from '../Models';
import { PhaseRoutes } from './Routes';

export const PhaseController = {
  async create(sessionId: string, name: string) {
    const DATA = {
      Name: name,
    };
    let Result: PhaseModel | undefined;

    await Axios.post(PhaseRoutes.add(sessionId), DATA).then(
      (res) => {
        if (res.status === 201) {
          Result = res.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async delete(sessionId: string, phaseId: string) {
    let Result = false;

    await Axios.delete(PhaseRoutes.remove(sessionId, phaseId)).then(
      (res) => {
        if (res.status === 204) {
          Result = true;
        }
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  async rename(sessionId: string, phaseId: string, name: string) {
    const DATA = {
      Name: name,
    };

    let Result: PhaseModel | undefined;

    await Axios.patch(PhaseRoutes.rename(sessionId, phaseId), DATA).then(
      (res) => {
        Result = res.data;
      },
      (err) => {
        console.error(err);
      }
    );

    return Result;
  },

  get: {
    list: async (sessionId: string) => {
      let Result: Array<PhaseModel> = [];

      await Axios.get(PhaseRoutes.getList(sessionId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
    single: async (sessionId: string, phaseId: string) => {
      let Result: PhaseModel | undefined;

      await Axios.get(PhaseRoutes.getSingle(sessionId, phaseId)).then(
        (res) => {
          Result = res.data;
        },
        (err) => {
          console.error(err);
        }
      );

      return Result;
    },
  },
};
