import { SessionRoutes } from '../Controllers/Routes';
import type {
  ActionPlanModel,
  BoardModel,
  GroupModel,
  InputModel,
  OptionModel,
  PhaseModel,
  SessionModel,
  TicketModel,
  VoteModel,
} from '../Models';
import { type Handler as DataHandler } from './DataHandler';

interface IProps {
  creationDateInUnix: number;
  id: string;
  lastOpenedInUnix: number;
  name: string;
  ownerId: string;
  dataHandler: DataHandler;
  loadActive: (type: 'vote' | 'board', id: string) => void;
}

export class ServerSentEventHandler {
  creationDateInUnix: number;
  id: Readonly<string>;
  lastOpenedInUnix: number;
  name: string;
  ownerId: string;
  eventSource: null | EventSource = null;
  handler: DataHandler;
  loadActive: (type: 'vote' | 'board', id: string) => void;

  constructor(props: IProps) {
    this.creationDateInUnix = props.creationDateInUnix;
    this.id = props.id;
    this.lastOpenedInUnix = props.lastOpenedInUnix;
    this.name = props.name;
    this.ownerId = props.ownerId;
    this.handler = props.dataHandler;
    this.loadActive = props.loadActive;
  }

  parse = {
    active: (event: Event) => {
      const MESSAGE = event as MessageEvent;
      const DATA: SessionModel = JSON.parse(MESSAGE.data);
      console.log(DATA);
      this.loadActive(DATA.activeType, DATA.activeId);
    },
    session: (_: Event) => {},
    code: (event: Event) => {
      const MESSAGE = event as MessageEvent;
      const DATA: number = JSON.parse(MESSAGE.data);
      this.handler.AddCode(DATA);
    },
    phase: (event: Event) => {
      const MESSAGE = event as MessageEvent;
      const DATA: PhaseModel = JSON.parse(MESSAGE.data);

      if (DATA.name === 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
        //TODO: Delete
      } else {
        this.handler.AddPhase(DATA);
      }
    },
    board: (event: Event) => {
      const MESSAGE = event as MessageEvent;

      const DATA: BoardModel = JSON.parse(MESSAGE.data);
      this.handler.AddBoard(DATA);
    },
    group: (event: Event) => {
      const MESSAGE = event as MessageEvent;

      const DATA: GroupModel[] | GroupModel = JSON.parse(MESSAGE.data);

      if (Array.isArray(DATA)) {
        if (DATA[0].boardId === 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
          this.handler.RemoveGroups(DATA);
        } else {
          this.handler.AddGroups(DATA);
        }
      } else {
        if (DATA.boardId === 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
          //TODO: Delete
        } else {
          this.handler.AddGroup(DATA);
        }
      }
    },
    input: (event: Event) => {
      const MESSAGE = event as MessageEvent;

      const DATA: InputModel[] | InputModel = JSON.parse(MESSAGE.data);
      if (Array.isArray(DATA)) {
        if (DATA[0].boardId === 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
          this.handler.RemoveInputs(DATA);
        } else {
          this.handler.AddInputs(DATA);
        }
      } else {
        if (DATA.groupId === 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
          //TODO: Delete
        } else {
          this.handler.AddInput(DATA);
        }
      }
    },
    vote: (event: Event) => {
      const MESSAGE = event as MessageEvent;

      const DATA: VoteModel = JSON.parse(MESSAGE.data);
      this.handler.AddVote(DATA);
    },
    actionPlan: (event: Event) => {
      const MESSAGE = event as MessageEvent;

      const DATA: ActionPlanModel = JSON.parse(MESSAGE.data);
      this.handler.AddActionPlan(DATA);
    },
    option: (event: Event) => {
      const MESSAGE = event as MessageEvent;
      const DATA: OptionModel = JSON.parse(MESSAGE.data);
      if (DATA.voteId === 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
        //TODO: Delete
      } else {
        this.handler.AddOption(DATA);
      }
    },
    ticket: (event: Event) => {
      const MESSAGE = event as MessageEvent;

      const DATA: TicketModel = JSON.parse(MESSAGE.data);
      if (DATA.voteId === 'DELETE_ME_NOW._THAT_MEANS_RIGHT_NOW!') {
        //TODO: Delete
      } else {
        this.handler.AddTicket(DATA);
      }
    },
    userData: (event: Event) => {
      const MESSAGE = event as MessageEvent;
      const [noActive, noTotal, nickNames] = (MESSAGE.data as string).split(
        '\n'
      );
      const nicks = nickNames?.split(';').filter((n) => n && n.length > 0);
      this.handler.SetUserData(parseInt(noActive), parseInt(noTotal), nicks);
    },
  };

  onWindowFocus = async () => {
    const SESSION = await this.handler.getSession();

    if (!SESSION) {
      console.error('Session is null');
    } else {
      this.loadActive(SESSION.activeType, SESSION.activeId);
    }
  };

  open = () => {
    const SESSION = this.id;
    this.close();

    this.handler.GetData();

    this.eventSource = new window.EventSource(SessionRoutes.connect(SESSION));

    window.addEventListener('focus', this.onWindowFocus);

    this.eventSource.addEventListener('session', this.parse.session);

    this.eventSource.addEventListener('code', this.parse.code);

    this.eventSource.addEventListener('active', this.parse.active);

    this.eventSource.addEventListener('phase', this.parse.phase);

    this.eventSource.addEventListener('board', this.parse.board);

    this.eventSource.addEventListener('group', this.parse.group);

    this.eventSource.addEventListener('input', this.parse.input);

    this.eventSource.addEventListener('vote', this.parse.vote);

    this.eventSource.addEventListener('actionplan', this.parse.actionPlan);

    this.eventSource.addEventListener('option', this.parse.option);

    this.eventSource.addEventListener('ticket', this.parse.ticket);

    this.eventSource.addEventListener('userData', this.parse.userData);
  };

  close = () => {
    if (
      this.eventSource !== undefined &&
      this.eventSource !== null &&
      this.eventSource.readyState !== window.EventSource.CLOSED
    ) {
      window.removeEventListener('focus', this.onWindowFocus);

      this.eventSource.removeEventListener('session', this.parse.session);
      this.eventSource.removeEventListener('code', this.parse.code);
      this.eventSource.removeEventListener('active', this.parse.active);
      this.eventSource.removeEventListener('phase', this.parse.phase);
      this.eventSource.removeEventListener('board', this.parse.board);
      this.eventSource.removeEventListener('group', this.parse.group);
      this.eventSource.removeEventListener('input', this.parse.input);
      this.eventSource.removeEventListener('vote', this.parse.vote);
      this.eventSource.removeEventListener('actionplan', this.parse.actionPlan);
      this.eventSource.removeEventListener('option', this.parse.option);
      this.eventSource.removeEventListener('ticket', this.parse.ticket);
      this.eventSource.removeEventListener('userData', this.parse.userData);

      this.eventSource.close();
    }
  };
}
