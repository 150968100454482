import Axios from 'axios';

import { CodesRoutes } from './Routes';

export const CodesController = {
  connect: async (sessionCode: number): Promise<string | undefined> => {
    let result: string | undefined;
    await Axios.get(CodesRoutes.connect(sessionCode)).then(
      (res) => {
        result = res.data;
      },
      (err) => {
        if (err.toJSON().status) {
          alert('Session not found');
        } else {
          console.error(err);
        }
      }
    );
    return result;
  },
  get: async (sessionId: string) => {
    let result: number | undefined;
    await Axios.get(CodesRoutes.get(sessionId)).then(
      (res) => {
        result = res.data;
      },
      (err) => {
        console.error(err);
      }
    );
    return result;
  },
};
