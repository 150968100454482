const Root = 'api';

const Version = 'v1';

const Base = Root + '/' + Version;

// -------
// SessionController
// -------
const SessionBase = Base + '/sessions';
export const SessionRoutes = {
  /**
   * HttpPost
   * Create a new session
   * @returns {string} Versioned Api route for this action
   */
  create: () => {
    return SessionBase;
  },

  /**
   * HttpGet
   * All Sessions TODO: Delete this before releasing to the public
   * @returns {string} Versioned Api route for this action
   */
  getAll: (sessionId: string) => {
    return Base + `/sessions/${sessionId}`;
  },

  /**
   * HttpGet
   * all sessions belonging to this user ID
   * @param {string} ownerId The Id of the User
   * @returns {string} Versioned Api route for this action
   */
  getOwned: (ownerId: string) => {
    return Base + `/${ownerId}/sessions`;
  },

  /**
   * HttpGet
   * A single session
   * @param {string} ownerId The Id of the session Owner
   * @param {string} sessionId The Id of the session
   * @returns {string} Versioned Api route for this action
   */
  getById: (ownerId: string, sessionId: string) => {
    return SessionRoutes.getOwned(ownerId) + `/${sessionId}`;
  },

  /**
   * HttpGet
   * A single session
   * @param {string} ownerId The Id of the session Owner
   * @param {string} sessionId The Id of the session
   * @returns {string} Versioned Api route for this action
   */
  connect: (sessionId: string) => {
    return SessionBase + `/${sessionId}`;
  },

  /**
   * HttpDelete
   * Delete a session
   * @param {string} ownerId The Id of the session Owner
   * @param {string} sessionId The Id of the session
   * @returns {string} Versioned Api route for this action
   */
  delete: (ownerId: string, sessionId: string) => {
    return SessionRoutes.getById(ownerId, sessionId);
  },

  /**
   * HttpPut
   * Recover a session
   * @param {string} ownerId The Id of the session Owner
   * @param {string} sessionId The Id of the session
   * @returns {string} Versioned Api route for this action
   */
  recover: (ownerId: string, sessionId: string) => {
    return SessionRoutes.getById(ownerId, sessionId) + '/recover';
  },

  /**
   * HttpPatch
   * Change the Name/Title of a session
   * @param {string} ownerId The Id of the session Owner
   * @param {string} sessionId The Id of the session
   * @returns {string} Versioned Api route for this action
   */
  rename: (ownerId: string, sessionId: string) => {
    return SessionRoutes.getById(ownerId, sessionId);
  },

  /**
   * HttpPatch
   * Change the Active Board of a session
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase Board is in
   * @param {string} boardId The Id of the board to Activate
   * @returns {string} Versioned Api route for this action
   */
  SetActiveBoard: (sessionId: string, phaseId: string, boardId: string) => {
    return (
      SessionRoutes.connect(sessionId) + `/phase/${phaseId}/board/${boardId}`
    );
  },

  /**
   * HttpPatch
   * Change the Active Vote of a session
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase the Vote is in
   * @param {string} boardId The Id of the board the Vote is in
   * @param {string} voteId The Id of the vote to Activate
   * @returns {string} Versioned Api route for this action
   */
  SetActiveVote: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return (
      SessionRoutes.SetActiveBoard(sessionId, phaseId, boardId) +
      `/vote/${voteId}`
    );
  },

  /**
   * HttpPatch
   * Change the Active Action plan of a session
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase the Vote is in
   * @param {string} actionPlanId The Id of actionPlan
   * @returns {string} Versioned Api route for this action
   */
  SetActiveActionPlan: (
    sessionId: string,
    phaseId: string,
    actionPlanId: string
  ) => {
    return (
      SessionRoutes.connect(sessionId) +
      `/phase/${phaseId}/actionplans/${actionPlanId}`
    );
  },
};

// -------
// PhaseController
// -------
const PhaseBase = (sessionId: string) => SessionBase + `/${sessionId}/phases`;
export const PhaseRoutes = {
  /**
   * HttpPost
   * Add new phase
   * @param {string} sessionId The Id of the session
   * @returns {string} Versioned Api route for this action
   */
  add: (sessionId: string) => {
    return PhaseBase(sessionId);
  },

  /**
   * HttpGet
   * List of phases
   * @param {string} sessionId The Id of the session
   * @returns {string} Versioned Api route for this action
   */
  getList: (sessionId: string) => {
    return PhaseBase(sessionId);
  },

  /**
   * HttpGet
   * A single board
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @returns {string} Versioned Api route for this action
   */
  getSingle: (sessionId: string, phaseId: string) => {
    return PhaseBase(sessionId) + `/${phaseId}`;
  },

  /**
   * HttpDelete
   * Remove a Column
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @returns {string} Versioned Api route for this action
   */
  remove: (sessionId: string, phaseId: string) => {
    return PhaseRoutes.getSingle(sessionId, phaseId);
  },

  /**
   * HttpPatch
   * Move the position of the column
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @returns {string} Versioned Api route for this action
   */
  rename: (sessionId: string, phaseId: string) => {
    return PhaseRoutes.getSingle(sessionId, phaseId);
  },

  /**
   * HttpPatch
   * Set the width of a column
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @returns {string} Versioned Api route for this action
   */
  setColor: (sessionId: string, phaseId: string) => {
    return PhaseRoutes.getSingle(sessionId, phaseId) + '/color';
  },
};

// -------
// BoardController
// -------
const BoardBase = (sessionId: string, phaseId: string) =>
  PhaseRoutes.getSingle(sessionId, phaseId) + `/boards`;
export const BoardRoutes = {
  /**
   * HttpPost
   * Create a new board
   * @param {string} ownerId The Id of the session Owner
   * @param {string} sessionId The Id of the session
   * @returns {string} Versioned Api route for this action
   */
  create: (sessionId: string, phaseId: string) => {
    return BoardBase(sessionId, phaseId);
  },

  /**
   * HttpGet
   * All boards TODO: Delete this before releasing to the public
   * @returns {string} Versioned Api route for this action
   */
  getAll: (sessionId: string) => {
    return SessionRoutes.getAll(sessionId) + `/boards`;
  },

  getByAll: (sessionId: string) => {
    return SessionRoutes.getAll(sessionId) + `/boards`;
  },

  /**
   * HttpGet
   * All boards in session
   * @param {string} sessionId The Id of the session
   * @returns {string} Versioned Api route for this action
   */
  getByPhase: (sessionId: string, phaseId: string) => {
    return BoardBase(sessionId, phaseId);
  },

  /**
   * HttpGet
   * A single board
   * @param {string} sessionId The Id of the session
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  getById: (sessionId: string, phaseId: string, boardId: string) => {
    return BoardBase(sessionId, phaseId) + `/${boardId}`;
  },

  /**
   * HttpDelete
   * Delete a board
   * @param {string} sessionId The Id of the session
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  delete: (sessionId: string, phaseId: string, boardId: string) => {
    return BoardRoutes.getById(sessionId, phaseId, boardId);
  },

  /**
   * HttpDelete
   * Delete a board
   * @param {string} sessionId The Id of the session
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  recover: (sessionId: string, phaseId: string, boardId: string) => {
    return BoardRoutes.getById(sessionId, phaseId, boardId);
  },

  /**
   * HttpPatch
   * Move the position
   * @param {string} sessionId The Id of the session
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  move: (sessionId: string, phaseId: string, boardId: string) => {
    return BoardRoutes.getById(sessionId, phaseId, boardId);
  },

  /**
   * HttpPatch
   * Set the countdown time for Board
   * @param {string} sessionId The Id of the session
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  SetTimer: (sessionId: string, phaseId: string, boardId: string) => {
    return BoardRoutes.getById(sessionId, phaseId, boardId) + `/countdown`;
  },

  /**
   * HttpPost
   * Start the countdown for Board
   * @param {string} sessionId The Id of the session
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  StartTimer: (sessionId: string, phaseId: string, boardId: string) => {
    return BoardRoutes.getById(sessionId, phaseId, boardId) + `/countdown`;
  },

  /**
   * HttpPatch
   * Lock Data for Board
   * @param {string} sessionId The Id of the session
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  LockData: (sessionId: string, phaseId: string, boardId: string) => {
    return BoardRoutes.getById(sessionId, phaseId, boardId) + `/lock`;
  },

  /**
   * HttpPatch
   * Change Title for board
   * @param {string} sessionId The Id of the session
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  EditTitle: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    title: string
  ) => {
    return BoardRoutes.getById(sessionId, phaseId, boardId) + `/${title}`;
  },

  /**
   * HttpPatch
   * Change Description for board
   * @param {string} sessionId The Id of the session
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  Description: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    description: string
  ) => {
    return (
      BoardRoutes.getById(sessionId, phaseId, boardId) +
      `/description/${description}`
    );
  },

  HideBoard: (sessionId: string, phaseId: string, boardId: string) => {
    return BoardRoutes.getById(sessionId, phaseId, boardId) + '/hide';
  },
};

// -------
// GroupController
// -------
const GroupBase = (sessionId: string, phaseId: string, boardId: string) =>
  BoardRoutes.getById(sessionId, phaseId, boardId) + `/groups`;
export const GroupRoutes = {
  /**
   * HttpPost
   * Create Group
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  create: (sessionId: string, phaseId: string, boardId: string) => {
    return GroupBase(sessionId, phaseId, boardId);
  },

  getAll: (sessionId: string) => {
    return SessionRoutes.getAll(sessionId) + `/groups`;
  },

  /**
   * HttpGet
   * All groups in column
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  getByBoard: (sessionId: string, phaseId: string, boardId: string) => {
    return GroupBase(sessionId, phaseId, boardId);
  },

  /**
   * HttpGet
   * A single group
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @returns {string} Versioned Api route for this action
   */
  getById: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string
  ) => {
    return GroupBase(sessionId, phaseId, boardId) + `/${groupId}`;
  },

  /**
   * HttpDelete
   * Archive a group TODO: Right now this deletes
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @returns {string} Versioned Api route for this action
   */
  archive: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string
  ) => {
    return GroupRoutes.getById(sessionId, phaseId, boardId, groupId);
  },

  /**
   * HttpPatch
   * Collapse All Groups
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @returns {string} Versioned Api route for this action
   */
  collapseAll: (sessionId: string, phaseId: string, boardId: string) => {
    return GroupBase(sessionId, phaseId, boardId) + '/collapse';
  },

  /**
   * HttpPatch
   * Collapse Group
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @returns {string} Versioned Api route for this action
   */
  collapse: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string
  ) => {
    return GroupBase(sessionId, phaseId, boardId) + `/${groupId}/collapse`;
  },

  move: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string
  ) => {
    return GroupRoutes.getById(sessionId, phaseId, boardId, groupId);
  },

  /**
   * HttpPatch
   * Set the name of a group
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @returns {string} Versioned Api route for this action
   */
  rename: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string
  ) => {
    return GroupRoutes.getById(sessionId, phaseId, boardId, groupId) + '/title';
  },

  /**
   * HttpPatch
   * Set the color of a group
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @returns {string} Versioned Api route for this action
   */
  setColor: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string
  ) => {
    return GroupRoutes.getById(sessionId, phaseId, boardId, groupId) + '/color';
  },

  /**
   * HttpPatch
   * Set group column
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @param {string} targetColumn The Id of the Column to move the Group into
   * @returns {string} Versioned Api route for this action
   */
  setColumn: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    targetColumn: number,
    targetIndex: number
  ) => {
    return (
      GroupRoutes.getById(sessionId, phaseId, boardId, groupId) +
      `/${targetColumn}/${targetIndex}`
    );
  },
};

// -------
// InputController
// -------
const InputBase = (
  sessionId: string,
  phaseId: string,
  boardId: string,
  groupId: string
) => GroupRoutes.getById(sessionId, phaseId, boardId, groupId) + `/inputs`;
export const InputRoutes = {
  /**
   * HttpPost
   * add input
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @returns {string} Versioned Api route for this action
   */
  add: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string
  ) => {
    return InputBase(sessionId, phaseId, boardId, groupId);
  },

  getAll: (sessionId: string) => {
    return SessionRoutes.getAll(sessionId) + `/inputs`;
  },

  /**
   * HttpGet
   * All inputs in group
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @returns {string} Versioned Api route for this action
   */
  getAllInGroup: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string
  ) => {
    return InputBase(sessionId, phaseId, boardId, groupId);
  },

  /**
   * HttpGet
   * A single input
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @param {string} inputpId The Id of the input
   * @returns {string} Versioned Api route for this action
   */
  getById: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    inputId: string
  ) => {
    return InputBase(sessionId, phaseId, boardId, groupId) + `/${inputId}`;
  },

  /**
   * HttpDelete
   * Archive a input TODO: Right now this deletes
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @param {string} inputId The Id of the Input
   * @returns {string} Versioned Api route for this action
   */
  archive: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    inputId: string
  ) => {
    return InputRoutes.getById(sessionId, phaseId, boardId, groupId, inputId);
  },

  /**
   * HttpPut
   * Update a input TODO: Right now this deletes
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @param {string} inputId The Id of the Input
   * @returns {string} Versioned Api route for this action
   */
  update: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    inputId: string
  ) => {
    return InputRoutes.getById(sessionId, phaseId, boardId, groupId, inputId);
  },

  /**
   * HttpPatch
   * Favorite inputs
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @returns {string} Versioned Api route for this action
   */
  favorite: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string
  ) => {
    return InputBase(sessionId, phaseId, boardId, groupId);
  },

  /**
   * HttpPatch
   * Move input within group
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @param {string} inputId The Id of the Input
   * @returns {string} Versioned Api route for this action
   */
  move: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    inputId: string
  ) => {
    return InputRoutes.getById(sessionId, phaseId, boardId, groupId, inputId);
  },

  /**
   * HttpPatch
   * Set the description of input
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @param {string} inputId The Id of the Input
   * @returns {string} Versioned Api route for this action
   */
  setDescription: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    inputId: string
  ) => {
    return (
      InputRoutes.getById(sessionId, phaseId, boardId, groupId, inputId) +
      '/description'
    );
  },

  /**
   * HttpPatch
   * Set the title of input
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @param {string} inputId The Id of the Input
   * @returns {string} Versioned Api route for this action
   */
  setTitle: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    inputId: string
  ) => {
    return (
      InputRoutes.getById(sessionId, phaseId, boardId, groupId, inputId) +
      '/title'
    );
  },

  /**
   * HttpPatch
   * Set input group
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} groupId The Id of the Group
   * @param {string} targetGroup The Id of the Group to move the Group into
   * @returns {string} Versioned Api route for this action
   */
  setGroup: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    groupId: string,
    targetGroup: string,
    targetIndex: number
  ) => {
    return (
      InputBase(sessionId, phaseId, boardId, groupId) +
      `/group/${targetGroup}/${targetIndex}`
    );
  },

  /**
   * HttpPatch
   * Compresses the Stack and Buffer
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  CompressSwissCheese: (
    sessionId: string,
    phaseId: string,
    boardId: string
  ) => {
    return BoardRoutes.getById(sessionId, phaseId, boardId) + '/stack';
  },
};

// -------
// VotesController
// -------
const VoteBase = (sessionId: string, phaseId: string, boardId: string) =>
  BoardRoutes.getById(sessionId, phaseId, boardId) + `/votes`;
export const VoteRoutes = {
  /**
   * HttpPost
   * Create new vote
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  create: (sessionId: string, phaseId: string, boardId: string) => {
    return VoteBase(sessionId, phaseId, boardId);
  },

  /**
   * HttpGet
   * All votes TODO: Delete this before releasing to the public
   * @returns {string} Versioned Api route for this action
   */
  getAll: (sessionId: string) => {
    return SessionRoutes.getAll(sessionId) + `/votes`;
  },

  /**
   * HttpGet
   * All votes in board
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @returns {string} Versioned Api route for this action
   */
  getAllInPhase: (_: string, __: string) => {
    return Root + '/sessions/{sessionId}/{phaseId}/votes';
  },

  /**
   * HttpGet
   * A single vote
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  getById: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return VoteBase(sessionId, phaseId, boardId) + `/${voteId}`;
  },

  /**
   * HttpDelete
   * Delete a vote
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  delete: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return VoteRoutes.getById(sessionId, phaseId, boardId, voteId);
  },

  /**
   * HttpDelete
   * Delete a vote
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  recover: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return VoteRoutes.getById(sessionId, phaseId, boardId, voteId);
  },

  /**
   * HttpPatch
   * Set Vote Timer
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  setTimer: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return VoteRoutes.getById(sessionId, phaseId, boardId, voteId) + '/timer';
  },

  /**
   * HttpPost
   * Start vote timer
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  startTimer: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return VoteRoutes.setTimer(sessionId, phaseId, boardId, voteId);
  },

  /**
   * HttpPatch
   * Lock Vote Data
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  LockData: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return VoteRoutes.getById(sessionId, phaseId, boardId, voteId) + '/lock';
  },

  /**
   * HttpPatch
   * Hide Vote Results
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  HideResults: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return VoteRoutes.getById(sessionId, phaseId, boardId, voteId) + '/hide';
  },

  /**
   * HttpPatch
   * Set the name of a vote
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  rename: (sessionId: string, voteId: string) => {
    return Base + `/sessions/${sessionId}/votes/${voteId}/title`;
  },

  description: (sessionId: string, voteId: string) => {
    return Base + `/sessions/${sessionId}/votes/${voteId}/description`;
  },
};

// -------
// ActionPlanController
// -------
const ActionPlanBase = (sessionId: string) =>
  SessionRoutes.getAll(sessionId) + `/actionplans`;
export const ActionPlanRoutes = {
  /**
   * HttpPost
   * Create new action plan
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @returns {string} Versioned Api route for this action
   */
  create: (sessionId: string, phaseId: string) => {
    return PhaseRoutes.getSingle(sessionId, phaseId) + `/actionplans`;
  },

  /**
   * HttpGet
   * All action plan TODO: Delete this before releasing to the public
   * @returns {string} Versioned Api route for this action
   */
  getAll: (sessionId: string) => {
    return SessionRoutes.getAll(sessionId) + `/actionplans`;
  },

  /**
   * HttpGet
   * A single action plan
   * @param {string} sessionId The Id of the session
   * @param {string} actionPlanId The Id of the action plan
   * @returns {string} Versioned Api route for this action
   */
  getById: (sessionId: string, actionPlanId: string) => {
    return ActionPlanBase(sessionId) + `/${actionPlanId}`;
  },

  /**
   * HttpDelete
   * Delete an action plan
   * @param {string} sessionId The Id of the session
   * @param {string} actionPlanId The Id of the action plan
   * @returns {string} Versioned Api route for this action
   */
  delete: (sessionId: string, actionPlanId: string) => {
    return ActionPlanRoutes.getById(sessionId, actionPlanId);
  },

  /**
   * HttpDelete
   * Delete an action plan
   * @param {string} sessionId The Id of the session
   * @param {string} actionPlanId The Id of the action plan
   * @returns {string} Versioned Api route for this action
   */
  recover: (sessionId: string, actionPlanId: string) => {
    return ActionPlanRoutes.getById(sessionId, actionPlanId) + '/recover';
  },

  /**
   * HttpPatch
   * Set the name of a action plan
   * @param {string} sessionId The Id of the session
   * @param {string} actionPlanId The Id of the action plan
   * @returns {string} Versioned Api route for this action
   */
  rename: (sessionId: string, actionPlanId: string) => {
    return `${ActionPlanRoutes.getById(sessionId, actionPlanId)}/title`;
  },

  updateDescription: (sessionId: string, actionPlanId: string) => {
    return (
      Base + `${ActionPlanRoutes.getById(sessionId, actionPlanId)}/description`
    );
  },
};

// -------
// OptionController
// -------
const OptionBase = (
  sessionId: string,
  phaseId: string,
  boardId: string,
  voteId: string
) => VoteRoutes.getById(sessionId, phaseId, boardId, voteId) + `/options`;
export const OptionRoutes = {
  /**
   * HttpPost
   * Create new option
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  create: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return OptionBase(sessionId, phaseId, boardId, voteId);
  },

  /**
   * HttpGet
   * All options TODO: Delete this before releasing to the public
   * @returns {string} Versioned Api route for this action
   */
  getAll: (sessionId: string) => {
    return SessionRoutes.getAll(sessionId) + `/options`;
  },

  /**
   * HttpGet
   * All options in vote
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  getAllInVote: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return OptionBase(sessionId, phaseId, boardId, voteId);
  },

  /**
   * HttpGet
   * A single option
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @param {string} optionId The Id of the option
   * @returns {string} Versioned Api route for this action
   */
  getById: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string,
    optionId: string
  ) => {
    return OptionBase(sessionId, phaseId, boardId, voteId) + `/${optionId}`;
  },

  /**
   * HttpDelete
   * Remove a option
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @param {string} optionId The Id of the option
   * @returns {string} Versioned Api route for this action
   */
  remove: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string,
    optionId: string
  ) => {
    return OptionRoutes.getById(sessionId, phaseId, boardId, voteId, optionId);
  },

  /**
   * HttpPatch
   * Set Option Color
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @param {string} optionId The Id of the option
   * @returns {string} Versioned Api route for this action
   */
  setColor: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string,
    optionId: string
  ) => {
    return OptionRoutes.getById(sessionId, phaseId, boardId, voteId, optionId);
  },
};

// -------
// TicketController
// -------
const TicketBase = (
  sessionId: string,
  phaseId: string,
  boardId: string,
  voteId: string
) => VoteRoutes.getById(sessionId, phaseId, boardId, voteId) + `/tickets`;
export const TicketRoutes = {
  /**
   * HttpPost
   * Add ticket
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  add: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return TicketBase(sessionId, phaseId, boardId, voteId);
  },

  /**
   * HttpGet
   * All tickets TODO: Delete this before releasing to the public
   * @returns {string} Versioned Api route for this action
   */
  getAll: (sessionId: string) => {
    return SessionRoutes.getAll(sessionId) + `/tickets`;
  },

  /**
   * HttpGet
   * All tickets in vote
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @returns {string} Versioned Api route for this action
   */
  getAllInVote: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return TicketBase(sessionId, phaseId, boardId, voteId);
  },

  /**
   * HttpGet
   * A single ticket
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @param {string} ticketId The Id of the ticket
   * @returns {string} Versioned Api route for this action
   */
  getById: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string,
    ticketId: string
  ) => {
    return TicketBase(sessionId, phaseId, boardId, voteId) + `/${ticketId}`;
  },

  /**
   * HttpDelete
   * Remove a ticket
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @param {string} ticketId The Id of the ticket
   * @returns {string} Versioned Api route for this action
   */
  remove: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string,
    ticketId: string
  ) => {
    return TicketRoutes.getById(sessionId, phaseId, boardId, voteId, ticketId);
  },

  /**
   * HttpPatch
   * Update/replace a ticket(practically HttpPut, changes the same things as a createRequest)
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the vote
   * @param {string} ticketId The Id of the ticket
   * @returns {string} Versioned Api route for this action
   */
  update: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string,
    ticketId: string
  ) => {
    return TicketRoutes.getById(sessionId, phaseId, boardId, voteId, ticketId);
  },

  /**
   * HttpPatch
   * Set the name of a vote
   * @param {string} sessionId The Id of the session
   * @param {string} phaseId The Id of the phase
   * @param {string} boardId The Id of the board
   * @param {string} voteId The Id of the Vote
   * @returns {string} Versioned Api route for this action
   */
  rename: (
    sessionId: string,
    phaseId: string,
    boardId: string,
    voteId: string
  ) => {
    return VoteRoutes.getById(sessionId, phaseId, boardId, voteId) + '/title';
  },
};

// -------
// CodesController
// -------
const CodesBase = Base + '/codes';
export const CodesRoutes = {
  connect: (code: number) => {
    return CodesBase + `/${code}`;
  },
  get: (id: string) => {
    return CodesBase + `/get/${id}`;
  },
};

// -------
// UserController
// -------
const UserBase = Base + '/user';
export const UserRoutes = {
  login: () => {
    return UserBase + '/login';
  },
  register: () => {
    return UserBase + '/register';
  },
  refresh: () => {
    return UserBase + '/refresh';
  },
  temporary: () => {
    return UserBase + '/temporary';
  },
  permanency: () => {
    return UserBase + '/permanency';
  },
};
