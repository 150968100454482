import { useCallback, useEffect, useRef, useState } from 'react';
import { TextField, Typography } from '@mui/material';

interface IProps {
  variant:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'body1'
    | 'body2'
    | 'subtitle1'
    | 'subtitle2';
  submitChange: (text: string) => void;
  text: string;
  uppercase: boolean;
  edit: boolean;
  doubleClickToEdit: boolean;
  disabled: boolean;
}

export const TypoField: React.FC<IProps> = ({
  variant,
  submitChange,
  text,
  uppercase,
  edit,
  doubleClickToEdit,
  disabled,
}) => {
  const [editing, setEditing] = useState(false);
  const [inputText, setInputText] = useState('');

  const textFieldRef = useRef<HTMLDivElement>(null);

  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    const value = event.currentTarget.value.substring(0, 45);
    setInputText(uppercase ? value.toUpperCase() : value);
  };

  const handleEdit = useCallback(() => {
    if (!disabled) {
      setEditing(true);
      setInputText(uppercase ? text.toUpperCase() : text);
    }
  }, [disabled, text, uppercase]);

  const handleSubmit = useCallback(
    (event: KeyboardEvent) => {
      if (!editing) {
        return;
      }

      if (event.key === 'Enter') {
        event.preventDefault();
        submitChange(inputText);
        setEditing(false);
        setInputText('');
      } else if (event.key === 'Escape') {
        setEditing(false);
        setInputText('');
      }
    },
    [editing, inputText, submitChange]
  );

  useEffect(() => {
    const textField = textFieldRef.current;
    textField?.addEventListener('keydown', handleSubmit, false);
    return () => {
      textField?.removeEventListener('keydown', handleSubmit, false);
    };
  }, [handleSubmit]);

  useEffect(() => {
    if (edit && !editing) {
      handleEdit();
    }
  }, [edit, editing, handleEdit]);

  return editing ? (
    <TextField
      sx={{
        marginBottom: '10px',
        padding: '10px',
        width: '100%',
      }}
      ref={textFieldRef}
      autoFocus
      onBlur={() => {
        submitChange(inputText);
        setEditing(false);
      }}
      onChange={handleTitle}
      value={inputText}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  ) : (
    <Typography
      variant={variant}
      sx={{
        cursor: 'text',
        fontWeight: 'bold',
        paddingY: '10px',
        paddingX: '10px',
      }}
      onClick={() => {
        if (!doubleClickToEdit) {
          handleEdit();
        }
      }}
      onDoubleClick={() => {
        if (doubleClickToEdit) {
          handleEdit();
        }
      }}
    >
      {text}
    </Typography>
  );
};
