import Axios from 'axios';

import { UserRoutes } from './Routes';

export interface IResult {
  id: string;
  refreshToken: string;
}

export const UserController = {
  login: async (email: string, password: string) => {
    const Data = {
      Email: email,
      Password: password,
    };
    let result: null | IResult = null;

    await Axios.post(UserRoutes.login(), Data).then(
      (res) => {
        result = res.data as IResult;
      },
      () => {
        alert('Login Failed');
      }
    );

    return result;
  },
  temporary: async (nickname: string) => {
    const Data = {
      Nickname: nickname,
    };
    let result: undefined | IResult;

    await Axios.post(UserRoutes.temporary(), Data).then(
      (res) => {
        result = res.data as IResult;
      },
      () => {
        alert('Login Failed');
      }
    );

    return result;
  },
  register: async (
    company: string,
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    phoneNumber: string
  ) => {
    const Data = {
      Company: company,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      PhoneNumber: phoneNumber,
    };
    let success = false;
    let errorText = '';

    await Axios.post(UserRoutes.register(), Data).then(
      () => {
        success = true;
      },
      (error) => {
        const errors = error.response.data.errors;
        if (Array.isArray(errors)) {
          errorText = errors[0];
        } else {
          const values = Object.values(errors);
          errorText = (values[0] as [string])[0]; //[0];
        }
      }
    );

    return { success, errorText };
  },
  refresh: async (jwt: string, refresh: string) => {
    const Data = {
      Token: jwt,
      RefreshToken: refresh,
    };
    let result: null | IResult = null;

    await Axios.post(UserRoutes.refresh(), Data).then(
      (res) => {
        result = res.data as IResult;
      },
      (error) => {
        console.error(error);
      }
    );

    return result;
  },
};
